import styled from '@emotion/styled';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { useApp } from '../Providers/AppProvider/useApp';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { multilineCSS } from '../helpers/multilineCSS';
import { Flex } from './Flex';
import { Paragraph, Variant } from './Typography';

interface RichTextEditorProps {
  disabled?: boolean;
  focusBackground?: string;
  initialValue: string;
  onSuccess: (value: string) => void;
  style?: React.CSSProperties;
  variant?: Variant;
}

export function RichTextEditor({
  disabled = false,
  focusBackground = 'var(--black-50)',
  initialValue,
  onSuccess,
  style,
  variant,
}: RichTextEditorProps) {
  const { tinyMceApiKey } = useApp();
  const { presentationMode } = useAuth();
  const [richText, setRichText] = useState(initialValue);
  const [toolbarFocus, setToolbarFocus] = useState(false);

  return (
    <StyledFlex
      column
      className={variant}
      focusBackground={focusBackground}
      gap="0.4rem"
      style={{ flexGrow: '1', ...style }}
      toolbarFocus={toolbarFocus}
      variant={variant}
    >
      <Editor
        apiKey={tinyMceApiKey}
        disabled={disabled || presentationMode}
        init={{
          entity_encoding: 'raw',
          branding: false,
          codesample_languages: [
            { text: 'HTML/XML', value: 'markup' },
            { text: 'JavaScript', value: 'javascript' },
            { text: 'CSS', value: 'css' },
            { text: 'PHP', value: 'php' },
            { text: 'Ruby', value: 'ruby' },
            { text: 'Python', value: 'python' },
            { text: 'Java', value: 'java' },
            { text: 'C', value: 'c' },
            { text: 'C#', value: 'csharp' },
            { text: 'C++', value: 'cpp' },
          ],
          content_css: 'dark',
          fontsize_formats: '12pt 14pt 18pt 24pt 36pt',
          inline: true,
          media_live_embeds: true,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'codesample',
          ],
          setup: (editor) => {
            editor.on('keydown', (e) => {
              if (e.key === 'Escape') {
                editor.getElement().blur();
              }
            });
          },
          skin: 'oxide-dark',
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor fontselect | fontsize| alignleft aligncenter ' +
            'alignright alignjustify  media| bullist numlist outdent indent | ' +
            'blockquote | superscript subscript |' +
            'codesample  code |' +
            'removeformat  | help',
          width: '100%',
        }}
        initialValue={initialValue}
        onEditorChange={(value) => setRichText(value)}
        onFocusOut={(e, editor) => {
          if ((e.relatedTarget as HTMLDivElement)?.className.includes('tox')) {
            setToolbarFocus(true);
            return;
          }

          setToolbarFocus(false);

          const newRichText = editor.getContent();

          if (!newRichText) {
            setRichText(initialValue);
            return;
          }

          if (
            !(e.relatedTarget as HTMLDivElement)?.className.includes('tox') &&
            newRichText &&
            newRichText !== initialValue
          ) {
            onSuccess(newRichText);
          }
        }}
        value={richText}
      />
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{
  focusBackground: string;
  toolbarFocus: boolean;
  error?: boolean;
  variant?: Variant;
}>`
  padding: 2px;
  line-height: ${({ variant }) => multilineCSS[variant as Paragraph] ?? 'unset'};

  div {
    ${({ focusBackground, toolbarFocus }) =>
      toolbarFocus ? `background: ${focusBackground};` : ''}
  }

  ${({ focusBackground }) =>
    `div:focus {
      background: ${focusBackground};
      border-radius: 6px;
    }`}

  .mce-content-body {
    cursor: text;
    border-radius: 6px;
  }

  h1 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.04em;
  }

  h2 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 16px;
  }

  h3 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.08em;
  }

  h4,
  h5,
  h6 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 12px;
  }

  p,
  li {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }
`;
