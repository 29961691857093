import { AnimatePresence, motion } from 'framer-motion';
import { useAlerts } from '../Providers/ToasterProvider/useAlerts';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { Alert } from './Alert';
import { Expire } from './Expire';

export function ToasterDisplay() {
  const alerts = useAlerts();
  const { setAlerts } = useToaster();

  return (
    <AnimatePresence>
      {alerts.map((alert, i) => (
        <motion.div
          key={alert.id}
          transition={{ type: 'spring', mass: 0.5, damping: 11, bounce: 0.09 }}
          style={{
            position: 'fixed',
            width: '100%',
            padding: '2rem 30vw',
            bottom: 0,
            zIndex: 99999,
          }}
          initial={{ translateY: 150, opacity: 0 }}
          animate={{ translateY: i * -75, opacity: 1 }}
          exit={{ translateY: 150, opacity: 0 }}
        >
          {alert.onDismiss ? (
            <Alert
              title={alert.title}
              text={alert?.text}
              severity={alert?.severity}
              onDismiss={() => {
                setAlerts((items) => items.filter((item) => item.id !== alert.id));
                alert.onDismiss?.();
              }}
            />
          ) : (
            <Expire
              until={alert.timeout ?? 3000}
              onExpire={() => setAlerts((items) => items.filter((item) => item.id !== alert.id))}
            >
              <Alert text={alert?.text} title={alert.title} severity={alert?.severity} />
            </Expire>
          )}
        </motion.div>
      ))}
    </AnimatePresence>
  );
}
