import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useCollectionHelpers } from './useCollectionHelpers';

export const useCreateCustomAnalysis = () => {
  const { t } = useTranslation();
  const { createPage } = useCollectionMutations();
  const { formatNewPage } = useCollectionHelpers();
  const navigate = useNavigate();
  const { sendAlert } = useToaster();

  return async () => {
    try {
      const newPage = await createPage(
        formatNewPage({
          title: 'New Analysis',
          settings: {
            layoutType: 'talkingPoints',
            workflowId: 'test',
            solutionType: 'custom',
          },
          tabs: [
            {
              row: 0,
              title: 'New Talking Point',
              elements: [],
              text: 'Generate a summary of your talking point here.',
            },
          ],
        })
      );

      navigate(`/page/${newPage.id}`);
    } catch (e) {
      captureException(e);

      sendAlert({
        text: t('util.errorCreatingPage'),
        severity: 'error',
      });
    }
  };
};
