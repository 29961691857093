import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { FilterDefinition } from '../../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { FilterMenuContent } from '../../Filters/Components/FilterMenuContent';
import { FilterMenuFooter } from '../../Filters/Components/FilterMenuFooter';
import { StagedFiltersProvider } from '../../Filters/StagedFiltersProvider/StagedFiltersProvider';
import { StagedPAFiltersProvider } from '../../Filters/StagedPAFiltersProvider/StagedPAFiltersProvider';
import { AppFilters } from '../../Filters/filterTypes';
import { PopoverContent } from '../../Popover/PopoverContent';

interface FilterMenuProps {
  onSave(filters: AppFilters): Promise<void>;
  allowedFilters?: Record<string, string[]>;
  linkedDatasources?: string[];
  missingFilters?: (keyof AppFilters)[];
  emptyFilters?: (keyof AppFilters)[];
  typeOverrides?: Record<string, FilterDefinition['type']>;
}

export function FilterMenuPopoverContent({
  onSave,
  allowedFilters,
  linkedDatasources,
  missingFilters,
  emptyFilters,
  typeOverrides,
}: FilterMenuProps) {
  const { t } = useTranslation();

  return (
    <PopoverContent>
      <ContextMenu direction="column" gap="0.5rem" width="400px">
        <Flex direction="column" width="100%" gap="0.4rem" padding="0.8rem 0.8rem 0.5rem 0.8rem">
          <Typography as="h2">{t('filterMenu.title')}</Typography>
          {/* <Typography color="var(--text-secondary)">{t('filterMenu.description')}</Typography> */}
        </Flex>
        <StagedPAFiltersProvider>
          <StagedFiltersProvider>
            <FilterMenuContent
              allowedFilters={allowedFilters}
              linkedDatasources={linkedDatasources}
              missingFilters={missingFilters}
              emptyFilters={emptyFilters}
              onSave={onSave}
              typeOverrides={typeOverrides}
            />
            <FilterMenuFooter onSave={onSave} />
          </StagedFiltersProvider>
        </StagedPAFiltersProvider>
      </ContextMenu>
    </PopoverContent>
  );
}
