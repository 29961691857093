import { ShareIcon } from '../../Icons/ShareIcon';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { headerIconSharedProps } from '../../helpers/headerIconSharedProps';
import { SharingPopoverContent } from './SharingPopoverContent';

export function SharingPopover() {
  return (
    <PopoverProvider placement="bottom-start">
      <PopoverTrigger asChild>
        <ShareIcon as="button" {...headerIconSharedProps} />
      </PopoverTrigger>
      <SharingPopoverContent />
    </PopoverProvider>
  );
}
