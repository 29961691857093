import isEmpty from 'lodash/isEmpty';
import { FC, useEffect } from 'react';
import 'react-mosaic-component/react-mosaic-component.css';
import { useNavigate, useParams } from 'react-router-dom';
import { TalkingPoints } from '../ChatCpg/TalkingPoints';
import { Flex } from '../Core/Flex';
import { TabMosaic } from '../MosaicLayout/TabMosaic';
import { useCurrentTabs } from '../hooks/useCurrentTabs';

export const TalkingPointsPage: FC = () => {
  const { pageId, tabId } = useParams();
  const navigate = useNavigate();
  const { tabs, isLoading } = useCurrentTabs();

  useEffect(() => {
    if (
      (!tabId && !isEmpty(tabs)) ||
      (tabId && !isEmpty(tabs) && !tabs?.find(({ id }) => id === tabId))
    ) {
      navigate(`/page/${pageId}/tab/${tabs[0].id}`);
    }
  }, [tabs, navigate, pageId, tabId]);

  if (isLoading) return null;

  return (
    <Flex
      width="100%"
      overflow="hidden"
      height="calc(100vh - 62px)" // - 62px for the header
      style={{ minHeight: 0 }}
      gap="12px"
    >
      <TalkingPoints />
      <Flex column overflow="scroll" height="100%" padding="0 24px 24px 0" grow="1">
        <TabMosaic />
      </Flex>
    </Flex>
  );
};
