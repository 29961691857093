import { Variant } from '../../Core/Typography';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';
import { AppFilters } from '../filterTypes';
import { CheckboxFilterItem } from './CheckboxFilterDisplay';
import { RadioFilterDisplay } from './RadioFilterDisplay';

interface RadioFilterProps {
  autofocus?: boolean;
  filterId: keyof AppFilters;
  hideUntilSearch?: boolean;
  inputBorderColor?: string;
  searchPlaceholder?: string;
  searchWidth?: string;
  style?: React.CSSProperties;
  values?: CheckboxFilterItem[];
  variant?: Variant;
}

export function RadioFilter({
  autofocus,
  filterId,
  hideUntilSearch,
  inputBorderColor,
  searchPlaceholder,
  searchWidth,
  style,
  values = [],
  variant,
}: RadioFilterProps) {
  const { stagedFilters } = useStagedFilters();
  const dispatchStagedFilters = useStagedFiltersDispatch();

  return (
    <RadioFilterDisplay
      autofocus={autofocus}
      hideUntilSearch={hideUntilSearch}
      values={values}
      onChange={(value) => {
        dispatchStagedFilters({
          type: 'add',
          payload: {
            [filterId]: value,
          },
        });
      }}
      searchPlaceholder={searchPlaceholder}
      searchWidth={searchWidth}
      selected={stagedFilters[filterId]}
      inputBorderColor={inputBorderColor}
      variant={variant}
      style={style}
    />
  );
}
