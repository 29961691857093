import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InfluencerData,
  PriceChartData,
  PriceRevenueVolumeData,
} from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { formatTrend } from '../helpers/chartHelpers';
import { initialComparisonChartData } from '../helpers/comparisonChartHelpers';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { HighchartsContainer } from './HighchartsContainer';

export function PriceComparison() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const dateTimeLabelFormats = useDateTimeLabelFormats();
  const influencerData = useChatCpgData<InfluencerData>(['influencers', workflowId]);
  const priceChartData = useChatCpgData<PriceChartData>(['price-chart', workflowId]);
  const pvsChartData = useChatCpgData<PriceRevenueVolumeData>(['pvs-chart', workflowId]);

  const chartData = useMemo(() => {
    return influencerData && priceChartData && pvsChartData
      ? {
          myProduct: {
            id: influencerData.id,
            name: influencerData.name,
            trend: formatTrend({
              trend: pvsChartData?.price,
              daysToAdd: 6,
            }),
          },
          competitor1: {
            id: influencerData.influencers[0].id,
            name: influencerData.influencers[0].name,
            trend: formatTrend({
              trend: priceChartData[influencerData.influencers[0].id]?.price,
              daysToAdd: 6,
            }),
          },
          competitor2: {
            id: influencerData.influencers[1].id,
            name: influencerData.influencers[1].name,
            trend: formatTrend({
              trend: priceChartData[influencerData.influencers[1].id]?.price,
              daysToAdd: 6,
            }),
          },
        }
      : initialComparisonChartData;
  }, [influencerData, priceChartData, pvsChartData]);

  const options = useMemo(() => {
    const highchartsOptions: Highcharts.Options = {
      tooltip: {
        shared: true,
        dateTimeLabelFormats,
        valuePrefix: '$',
        borderColor: 'var(--geyser)',
      },
      chart: {
        animation: false,
      },
      xAxis: {
        type: 'datetime',
        startOfWeek: 6,
        crosshair: true,
      },
      legend: {
        enabled: false,
      },
      yAxis: [
        {
          type: 'linear',
          labels: {
            formatter: function () {
              return `$${Highcharts.numberFormat(this.value as number, 2)}`;
            },
          },
        },
      ],
      series: [
        {
          name: t('metrics.myPrice'),
          type: 'line',
          color: 'var(--white)',
          dashStyle: 'Solid',
          lineWidth: 2,
          data: chartData.myProduct.trend,
        },
        {
          name: t('metrics.competitor1'),
          type: 'line',
          color: 'var(--lavender)',
          dashStyle: 'Solid',
          lineWidth: 2,
          data: chartData.competitor1?.trend,
        },
        {
          name: t('metrics.competitor2'),
          type: 'line',
          color: 'var(--tickle-me-pink)',
          dashStyle: 'Solid',
          lineWidth: 2,
          data: chartData.competitor2?.trend,
        },
      ],
    };

    return highchartsOptions;
  }, [t, chartData, dateTimeLabelFormats]);

  const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick();

  return (
    <Flex
      width="100%"
      height="100%"
      justify="stretch"
      align="stretch"
      direction="column"
      style={{ minHeight: '200px', minWidth: 0 }}
    >
      <SlideCarousel width="100%" gap="0.2rem" alignItems="flex-start">
        {chartData.myProduct.id && (
          <InfoTooltip
            text={chartData.myProduct.name}
            isHidden={hiddenTrends.includes(chartData.myProduct.id)}
            leaveOpenOnHover
          >
            <ValueBox
              label={t('metrics.myPrice')}
              subtitle={chartData.myProduct.id}
              valueType="latest"
              color="var(--white)"
              secondary
              allNeutral
              onClick={() => handleLegendClick(chartData.myProduct.id, 0)}
              style={{
                opacity: hiddenTrends.includes(chartData.myProduct.id) ? 0.5 : 1,
              }}
            />
          </InfoTooltip>
        )}
        {chartData.competitor1?.id !== undefined && (
          <InfoTooltip
            text={chartData.competitor1.name}
            isHidden={hiddenTrends.includes(chartData.competitor1.id)}
            leaveOpenOnHover
          >
            <ValueBox
              label={t('metrics.competitor1')}
              subtitle={chartData.competitor1.id}
              // indicator="dash"
              // valueType="latest"
              // value={chartData.competitor1.legendVal}
              // valueStyle="currency"
              color="var(--lavender)"
              // valueSmall={chartData.competitor1.diff}
              // valueSmallStyle="percentage"
              secondary
              onClick={() => handleLegendClick(chartData.competitor1?.id as string, 1)}
              style={{
                opacity: hiddenTrends.includes(chartData.competitor1.id) ? 0.5 : 1,
              }}
              // smallSuffix={t('metrics.vya')}
              allNeutral
            />
          </InfoTooltip>
        )}
        {chartData.competitor2?.id && (
          <InfoTooltip
            text={chartData.competitor2.name}
            isHidden={hiddenTrends.includes(chartData.competitor2.id)}
            leaveOpenOnHover
          >
            <ValueBox
              label={t('metrics.competitor2')}
              subtitle={chartData.competitor2.id}
              // indicator="shortDash"
              // valueType="latest"
              // value={chartData.competitor2.legendVal}
              // valueStyle="currency"
              color="var(--tickle-me-pink)"
              // valueSmall={chartData.competitor2.diff}
              // valueSmallStyle="percentage"
              secondary
              // smallSuffix={t('metrics.vya')}
              allNeutral
              onClick={() => handleLegendClick(chartData.competitor2?.id as string, 2)}
              style={{
                opacity: hiddenTrends.includes(chartData.competitor2?.id) ? 0.5 : 1,
              }}
            />
          </InfoTooltip>
        )}
        {!chartData.competitor1?.id && (
          <ValueBox
            label={t('metrics.noCompetitorsFound')}
            valueStyle="currency"
            color="inherit"
            secondary
          />
        )}
        {chartData.competitor1?.id && !chartData.competitor2?.id && (
          <ValueBox
            label={t('metrics.no2ndCompetitorFound')}
            valueStyle="currency"
            color="inherit"
            secondary
          />
        )}
      </SlideCarousel>
      <HighchartsContainer options={options} ref={chartRef} />
    </Flex>
  );
}
