import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

interface SolutionHeaderProps {
  title: string;
  description?: string;
}

export function SolutionHeader({ title, description }: SolutionHeaderProps) {
  return (
    <Flex
      column
      width="100%"
      padding="0 6.5%"
      gap="0.5rem"
      style={{ gridRow: '1', gridColumn: '1' }}
    >
      <Typography as="h1">{title}</Typography>
      {description && (
        <Typography variant="p-14-r" secondary>
          {description}
        </Typography>
      )}
    </Flex>
  );
}
