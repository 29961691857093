import { PropsWithChildren } from 'react';
import { Grid } from '../Core/Grid';
import { Typography } from '../Core/Typography';

interface SidePanelItemProps {
  as: 'button' | 'div';
  onClick?: () => void;
  title: string;
}

export function SidePanelItem({
  as,
  onClick,
  title,
  children,
}: PropsWithChildren<SidePanelItemProps>) {
  return (
    <Grid
      as={as}
      templateColumns="0.9fr 0.1fr"
      width="100%"
      padding="0.7rem 0.2rem"
      onClick={onClick}
      height="38px"
      style={{ maxWidth: '100%' }}
    >
      <Typography
        as="h2"
        variant="p-14-b"
        nowrap
        truncate
        title={title}
        style={{ textAlign: 'start' }}
      >
        {title}
      </Typography>
      {children}
    </Grid>
  );
}
