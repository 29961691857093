import { createContext } from 'react';
import { Author } from '../../Core/AuthorAvatar';

export interface Notification {
  alertName?: string;
  author: Author | 'chatCpg';
  authorColor?: string;
  fakeTimestamp: string;
  id: string;
  isNew: boolean;
  isPrediction?: boolean;
  pageId: number;
  text: string;
  timestamp: number;
  to: string;
}

export const NotificationsContext = createContext<Notification[]>([]);
