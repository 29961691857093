import { useUpdateTabMosaic } from '../../hooks/useUpdateTabMosaic';
import { ClearIcon } from '../../Icons/ClearIcon';
import { useMosaic } from '../useMosaic';
import { useMosaicWindow } from '../useMosaicWindow';
import { toolbarButtonSharedProps } from './toolbarButtonSharedProps';

export function RemoveButton() {
  const { mosaicActions } = useMosaic();
  const { mosaicWindowActions } = useMosaicWindow();
  const updateTabMosaic = useUpdateTabMosaic();

  return (
    <ClearIcon
      as="button"
      {...toolbarButtonSharedProps}
      onClick={() => {
        const root = mosaicActions.getRoot();
        if (root === 'chatCpg') return;

        if (typeof root === 'string') {
          updateTabMosaic({ node: 'chatCpg' });
          return;
        }

        const path = mosaicWindowActions.getPath();
        mosaicActions.remove(path);
      }}
    />
  );
}
