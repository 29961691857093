import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const InformationIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 9.99 20C4.47 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10ZM9 14V10H8V9H9H10H11H12V10H11V13V14H12V15H11H10H9H8V14H9ZM11 7V5H9V7H11Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
