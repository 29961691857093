import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { Typography } from '../../Core/Typography';

export function HeaderCell(props: GridColumnHeaderParams) {
  return (
    <Typography
      secondary
      variant="p-12-r"
      style={{ maxWidth: '150px', whiteSpace: 'normal', lineHeight: '18px' }}
    >
      {props.colDef.headerName}
    </Typography>
  );
}
