import { useLocation, useParams } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';

export const useCurrentPage = () => {
  const { pathname } = useLocation();
  const { pageId } = useParams();
  const { currentProject } = useCurrentProject();

  const isScorecard = pathname === '/smart-scorecard';

  const currentPage = isScorecard
    ? currentProject?.pages.find((page) => page.settings.isScorecard)
    : currentProject?.pages.find((page) => String(page.id) === pageId);

  return currentPage;
};
