import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, useContext } from 'react';
import { Flex } from '../Core/Flex';
import { TabContext } from './TabsContext';

const StyledHighlight = styled(motion.div)<{ width: number; padding: string }>`
  position: absolute;
  background-color: var(--space);
  width: ${({ width }) => width}px;
  height: calc(100% - 2 * ${({ padding }) => padding});
  border-radius: var(--corner);
  top: ${({ padding }) => padding};
`;

const StyledFlex = styled(Flex)<{ padding: string }>`
  background-color: var(--clay);
  width: 100%;
  border-radius: 8px;
  padding: ${({ padding }) => padding}px;
  box-sizing: border-box;
  border: 1px solid var(--black-30);
`;

interface TabListProps {
  listName: string;
}

export function TabList({ listName, children }: PropsWithChildren<TabListProps>) {
  const padding = 3;

  const { activeTab, tabWidth } = useContext(TabContext);

  const left = padding + activeTab * tabWidth;

  return (
    <StyledFlex padding={`${padding}px`} role="tablist" aria-label={`${listName} Tabs`}>
      <AnimatePresence>
        <StyledHighlight
          padding={`${padding}px`}
          width={tabWidth}
          initial={{ left: `${padding}px` }}
          animate={{ left: `${left}px` }}
          transition={{ bounce: 0 }}
        />
      </AnimatePresence>
      {children}
    </StyledFlex>
  );
}
