import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { PickerDownIcon } from '../Icons/PickerDownIcon';
import { PickerUpIcon } from '../Icons/PickerUpIcon';
import { useHandleOutsideClick } from '../hooks/useHandleOutsideClick';
import { SelectDropdownProps } from './SelectDropdown';

type SelectDropdownContentProps = Omit<SelectDropdownProps, 'title' | 'description' | 'error'>;

export function SelectDropdownContent({
  disabled = false,
  onChange,
  placeholder,
  selected = '',
  values,
  width = '100%',
}: SelectDropdownContentProps) {
  const [isOpen, setIsOpen] = useState(false);

  const selectedValue = values.find(({ value }) => value === selected);
  const selectedTitle = selectedValue?.title;

  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  useHandleOutsideClick({
    ref: optionsWrapperRef,
    ignoreEls: [selectRef],
    onClose: () => setIsOpen(false),
  });

  return (
    <Flex>
      <StyledSelect
        as="button"
        width={width}
        align="center"
        justify="space-between"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        disabled={disabled}
        ref={selectRef}
      >
        <Typography variant={selectedTitle ? 'h-12-b' : 'p-12-i'}>
          {selectedTitle ?? placeholder}
        </Typography>
        {isOpen ? <PickerUpIcon size="12px" /> : <PickerDownIcon size="12px" />}
      </StyledSelect>
      {isOpen && (
        <StyledOptionsWrapper
          ref={optionsWrapperRef}
          direction="column"
          width={width}
          padding="0.5rem"
        >
          {values.map(({ title, value }) => (
            <StyledOption
              key={value}
              as="button"
              role="option"
              value={value}
              onClick={() => {
                onChange?.(value);
                setIsOpen(false);
              }}
            >
              <Typography
                variant="h-12-b"
                color={value === selected ? 'var(--brand-secondary)' : 'var(--text)'}
              >
                {title}
              </Typography>
            </StyledOption>
          ))}
        </StyledOptionsWrapper>
      )}
    </Flex>
  );
}

const StyledSelect = styled(Flex)<{ width: string }>`
  width: ${({ width }) => width};
  height: 30px;

  background-color: var(--black-30);
  color: var(--white);
  padding: 12px;
  border: 1px solid var(--black-30);
  border-radius: var(--corner);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 11px;

  :focus {
    outline: 1px solid var(--trout);
  }

  select > option {
    background-color: var(--clay);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const StyledOptionsWrapper = styled(Flex)`
  top: 35px;
  position: absolute;
  background: var(--clay);
  border: 1px solid var(--black-30);
  border-radius: var(--corner);
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledOption = styled.button`
  display: flex;
  gap: 0.6rem;
  text-align: left;
  width: 100%;
  padding: 0.5rem;
  border-radius: var(--corner);
  :hover {
    background: var(--black-30);
  }
`;
