import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductData } from '../ChatCpg/types/substitutionsTypes';
import { NumberFormatter } from '../Core/NumberFormatter';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { ScoreCell } from '../Table/Cells/ScoreCell';
import { SimpleProductCell } from '../Table/Cells/SimpleProductCell';
import { ClickableTable } from '../Table/ClickableTable';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useChatCpgStore } from '../hooks/useChatCpgStore';

interface Substitute {
  id: string;
  name: string;
  substitutionScore: number;
  averagePrice: number;
  totalSales: number;
  changeVsPreviousPeriod: number;
}

export function SubstitutesTable() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const updateStore = useChatCpgStore((state) => state.updateStore);
  const activeGtin = useChatCpgData<string>(['active-substitution-gtin', workflowId]);
  const productData = useChatCpgData<ProductData>(['substitution-data', workflowId]);

  useEffect(() => {
    if (!activeGtin && productData?.secondaryProducts?.[0]?.gtin) {
      updateStore(['active-substitution-gtin', workflowId], productData.secondaryProducts[0].gtin);
    }
  }, [updateStore, activeGtin, productData, workflowId]);

  const substitutes: Substitute[] = useMemo(() => {
    return (
      productData?.secondaryProducts?.map(
        ({ gtin, itemDescription, score, L12WPriceAvg, L12WRevenue, changeVsPreviousPeriod }) => ({
          id: gtin,
          name: itemDescription,
          substitutionScore: score,
          averagePrice: L12WPriceAvg,
          totalSales: L12WRevenue,
          changeVsPreviousPeriod,
        })
      ) ?? []
    );
  }, [productData]);

  return (
    <>
      {activeGtin && (
        <ClickableTable
          width="100%"
          initActiveCol="substitutionScore"
          columns={[
            {
              title: t('chatCpg.substitutions.substitute'),
              field: 'id',
              cell: ({ name, id }) => <SimpleProductCell name={name} id={id} />,
            },
            {
              title: t('chatCpg.substitutions.substitutionScore'),
              field: 'substitutionScore',
              cell: ({ substitutionScore }) => <ScoreCell score={substitutionScore} />,
              align: 'right',
            },
            {
              title: t('chatCpg.substitutions.l12wAveragePrice'),
              field: 'averagePrice',
              cell: ({ averagePrice }) => (
                <NumberFormatter
                  as="p"
                  variant="h-16-b"
                  numberStyle="currency"
                  val={averagePrice}
                  style={{ textAlign: 'right' }}
                />
              ),
              align: 'right',
            },
            {
              title: t('chatCpg.substitutions.l12wTotalSales'),
              field: 'totalSales',
              cell: ({ totalSales }) => (
                <NumberFormatter
                  as="p"
                  variant="h-16-b"
                  numberStyle="currencyEstimate"
                  val={totalSales}
                  style={{ textAlign: 'right' }}
                />
              ),
              align: 'right',
            },
            {
              title: t('chatCpg.substitutions.changeVsPreviousPeriod'),
              field: 'changeVsPreviousPeriod',
              cell: ({ changeVsPreviousPeriod }) => (
                <NumberFormatter
                  as="p"
                  variant="h-16-b"
                  numberStyle="percentage"
                  val={changeVsPreviousPeriod}
                  style={{ textAlign: 'right' }}
                />
              ),
              align: 'right',
            },
          ]}
          data={substitutes}
          onRowClick={({ id }) => updateStore(['active-substitution-gtin', workflowId], id)}
        />
      )}
    </>
  );
}
