import { useQuery } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, useMemo, useReducer, useState } from 'react';
import { useApi } from '../../Providers/ApiProvider/useApi';
import { Augmentations } from '../../Providers/AuthProvider/AuthContext';
import { useAuth } from '../../Providers/AuthProvider/useAuth';
import { ProductAttributesTable } from '../StagedPAFiltersProvider/StagedPAFiltersContext';
import { ActivePAFiltersContext, ProductAttributeFilters } from './ActivePAFiltersContext';
import {
  ActivePAFiltersAction,
  ActivePAFiltersDispatchContext,
} from './ActivePAFiltersDispatchContext';

export interface ProductAttribute {
  id: number;
  ms_id: number;
  gtin: string;
  attributes: Augmentations;
}

interface ActivePAFiltersProviderProps {
  filterScope: ProductAttributeFilters;
}

export function ActivePAFiltersProvider({
  filterScope,
  children,
}: PropsWithChildren<ActivePAFiltersProviderProps>) {
  const [fileId, setFileId] = useState(1);

  const api = useApi();
  const { msId } = useAuth();

  const [activePAFilters, dispatchActivePAFilters] = useReducer(
    activePAFiltersReducer,
    filterScope
  );

  const { data: myProductsTable = null } = useQuery<ProductAttributesTable | null>(
    ['active-product-attributes', msId, fileId, activePAFilters],
    async () => {
      // if (!msNameMapping || !msAugmentations) return null;
      const { data } = await api.get<ProductAttribute[]>(`/v2/poplar/product-attributes`, {
        params: {
          size: 9999,
        },
      });

      const columnNames = await api.get(`/v2/poplar/product-attributes`, {
        params: {
          columns_only: true,
        },
      });

      const msNameMapping: string[] = columnNames.data;
      const msAugmentations: Record<string, Augmentations> = {};

      data.forEach((item: ProductAttribute) => {
        msAugmentations[item.gtin] = item.attributes;
      });

      const myProductsTable = {
        fileId: 1,
        columns: ['gtin', '_is_owned', ...msNameMapping],
        rows: Object.keys(msAugmentations)
          .sort((a, b) => a.localeCompare(b))
          .map((gtin) => ({
            gtin,
            _is_owned: msAugmentations[gtin]._is_owned.toString(),
            ...msNameMapping.reduce((acc, col) => {
              acc[col as Exclude<keyof Augmentations, '_is_owned'>] = msAugmentations[gtin][
                col as keyof Augmentations
              ]?.toString() as string;

              return acc;
            }, {} as Record<Exclude<keyof Augmentations, '_is_owned'>, string>),
          }))
          .filter(({ _is_owned }) => _is_owned.toLowerCase().trim() === 'true'),
      };

      return myProductsTable;
    }
  );

  const { data: competitorsTable = null } = useQuery<ProductAttributesTable | null>(
    ['active-competitor-attributes', msId, fileId, activePAFilters],
    async () => {
      // if (!msNameMapping || !msAugmentations) return null;
      const { data } = await api.get(`/v2/poplar/product-attributes`, {
        params: {
          size: 9999,
        },
      });

      const columnNames = await api.get(`/v2/poplar/product-attributes`, {
        params: {
          columns_only: true,
        },
      });

      const msNameMapping: string[] = columnNames.data;
      const msAugmentations: Record<string, Augmentations> = {};

      data.forEach((item: ProductAttribute) => {
        msAugmentations[item.gtin] = item.attributes;
      });

      const competitorsTable = {
        fileId: 1,
        columns: ['gtin', '_is_owned', ...msNameMapping],
        rows: Object.keys(msAugmentations)
          .sort((a, b) => a.localeCompare(b))
          .map((gtin) => ({
            gtin,
            _is_owned: msAugmentations[gtin]._is_owned.toString(),
            ...msNameMapping.reduce((acc, col) => {
              acc[col as Exclude<keyof Augmentations, '_is_owned'>] = msAugmentations[gtin][
                col as keyof Augmentations
              ]?.toString() as string;

              return acc;
            }, {} as Record<Exclude<keyof Augmentations, '_is_owned'>, string>),
          }))
          .filter(({ _is_owned }) => _is_owned.toLowerCase().trim() === 'false'),
      };

      return competitorsTable;
    }
  );

  const myProductsValues = useMemo(() => {
    if (!myProductsTable) return [];

    return myProductsTable.rows.map(({ gtin, description }) => ({
      value: gtin,
      title: description,
    }));
  }, [myProductsTable]);

  const competitorsValues = useMemo(() => {
    if (!competitorsTable) return [];
    return competitorsTable.rows.map(({ gtin, description }) => ({
      value: gtin,
      title: description,
    }));
  }, [competitorsTable]);

  const memoizedContextValue = useMemo(
    () => ({
      activePAFilters,
      competitorsValues,
      myProductsValues,
    }),
    [competitorsValues, myProductsValues, activePAFilters]
  );

  return (
    <ActivePAFiltersContext.Provider value={memoizedContextValue}>
      <ActivePAFiltersDispatchContext.Provider value={dispatchActivePAFilters}>
        {children}
      </ActivePAFiltersDispatchContext.Provider>
    </ActivePAFiltersContext.Provider>
  );
}

function activePAFiltersReducer(
  activePAFilters: ProductAttributeFilters,
  activePAFiltersAction: ActivePAFiltersAction
) {
  switch (activePAFiltersAction.type) {
    case 'add':
      return { ...activePAFilters, ...activePAFiltersAction.payload };
    case 'replace':
      return { ...activePAFiltersAction.payload };

    case 'remove': {
      const newActivePAFilters = cloneDeep(activePAFilters);
      delete newActivePAFilters[activePAFiltersAction.payload];
      return newActivePAFilters;
    }
  }
}
