import HighchartsReact from 'highcharts-react-official';
import { useRef, useState } from 'react';

export function useLegendClick() {
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [hiddenTrends, setHiddenTrends] = useState<string[]>([]);

  const handleLegendClick = (id: string, seriesIndex: number) => {
    setHiddenTrends((hiddenTrends) =>
      hiddenTrends.includes(id)
        ? hiddenTrends.filter((trend) => trend !== id)
        : [...hiddenTrends, id]
    );

    chartRef.current?.chart.series[seriesIndex].setVisible(
      chartRef.current?.chart.series[seriesIndex].visible ? false : true
    );
  };

  return { chartRef, hiddenTrends, handleLegendClick };
}
