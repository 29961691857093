import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductData } from '../ChatCpg/types/substitutionsTypes';
import { NumberFormatter } from '../Core/NumberFormatter';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { ScoreCell } from '../Table/Cells/ScoreCell';
import { SimpleProductCell } from '../Table/Cells/SimpleProductCell';
import { ClickableTable } from '../Table/ClickableTable';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useChatCpgStore } from '../hooks/useChatCpgStore';

interface Complement {
  id: string;
  name: string;
  complementScore: number;
  totalSales: number;
}

export function ComplementsTable() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const updateStore = useChatCpgStore((state) => state.updateStore);
  const activeGtin = useChatCpgData<string>(['active-complement-gtin', workflowId]);
  const productData = useChatCpgData<ProductData>(['complement-data', workflowId]);

  useEffect(() => {
    if (!activeGtin && productData?.secondaryProducts?.[0]?.gtin) {
      updateStore(['active-complement-gtin', workflowId], productData.secondaryProducts[0].gtin);
    }
  }, [updateStore, activeGtin, productData, workflowId]);

  const complements: Complement[] = useMemo(() => {
    return (
      productData?.secondaryProducts?.map(({ gtin, itemDescription, score, L12WRevenue }) => ({
        id: gtin,
        name: itemDescription,
        complementScore: score,
        totalSales: L12WRevenue,
      })) ?? []
    );
  }, [productData]);

  return (
    <>
      {activeGtin && (
        <ClickableTable
          width="100%"
          initActiveCol="complementScore"
          highlightColor="var(--brand-secondary)"
          columns={[
            {
              title: t('chatCpg.substitutions.substitute'),
              field: 'id',
              cell: ({ name, id }) => <SimpleProductCell name={name} id={id} />,
            },
            {
              title: t('chatCpg.substitutions.complementScore'),
              field: 'complementScore',
              cell: ({ complementScore }) => <ScoreCell score={complementScore} />,
              align: 'right',
            },
            {
              title: t('chatCpg.substitutions.l12wTotalSales'),
              field: 'totalSales',
              cell: ({ totalSales }) => (
                <NumberFormatter
                  as="p"
                  variant="h-16-b"
                  numberStyle="currencyEstimate"
                  val={totalSales}
                  style={{ textAlign: 'right' }}
                />
              ),
              align: 'right',
            },
          ]}
          data={complements}
          onRowClick={({ id }) => updateStore(['active-complement-gtin', workflowId], id)}
        />
      )}
    </>
  );
}
