import { Mosaic, MosaicNode, MosaicWindow } from 'react-mosaic-component';
import 'react-mosaic-component/react-mosaic-component.css';
import { ElementToolbar } from './ElementToolbar';
import { ToolbarControls } from './ToolbarControls';
import { ZeroStateMosaic } from './ZeroStateMosaic';

import {
  ELEMENT_BORDER_COLOR,
  ELEMENT_BORDER_RADIUS,
  ELEMENT_BORDER_WIDTH,
} from '../Element/elementConstants';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import './MosaicLayout.css'; // Keep this below the import of react-mosaic-component.css

interface MosaicLayoutProps {
  initialNode: MosaicNode<string> | null;
  elementMap: Record<string, JSX.Element>;
  onRelease: (currentNode: MosaicNode<string> | null) => void;
}

export function MosaicLayout({ initialNode, elementMap, onRelease }: MosaicLayoutProps) {
  const { presentationMode } = useAuth();

  return (
    <Mosaic<string>
      renderTile={(id, path) => {
        return (
          <MosaicWindow<string>
            className={id}
            title={elementMap[id]?.props?.element?.title}
            path={path}
            // renderPreview={() => <div>{elementMap[id]}</div>}
            toolbarControls={<ToolbarControls />}
            renderToolbar={(props) => {
              const isChatBox = id === 'chatCpg';

              const border = !isChatBox
                ? `${ELEMENT_BORDER_WIDTH} solid ${ELEMENT_BORDER_COLOR}`
                : `${ELEMENT_BORDER_WIDTH} solid transparent`;

              return (
                <div
                  onMouseDown={(e) => {
                    if (presentationMode) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '4px',
                    width: '100%',
                    background:
                      elementMap[id]?.props?.element?.elementDef?.payload?.background ?? 'unset',
                    borderTop: border,
                    borderRight: border,
                    borderLeft: border,
                    borderRadius: `${ELEMENT_BORDER_RADIUS} ${ELEMENT_BORDER_RADIUS} 0 0`,
                    padding: '2px 8px 0 8px',
                    cursor: presentationMode ? 'default' : 'move',
                  }}
                >
                  <ElementToolbar
                    elementMap={elementMap}
                    id={id}
                    title={props.title}
                    toolbarControls={props.toolbarControls}
                  />
                </div>
              );
            }}
          >
            {elementMap[id]}
          </MosaicWindow>
        );
      }}
      initialValue={removeNodeIfNotInElementMap(initialNode, elementMap)}
      onRelease={onRelease}
      resize={
        presentationMode
          ? 'DISABLED'
          : {
              minimumPaneSizePercentage: 20,
            }
      }
      zeroStateView={<ZeroStateMosaic />}
    />
  );
}

const removeNodeIfNotInElementMap = (
  currentNode: MosaicNode<string> | null,
  elementMap: Record<string, JSX.Element>
): MosaicNode<string> | null => {
  if (!currentNode) return null;

  if (typeof currentNode === 'string') {
    if (elementMap[currentNode]) return currentNode;
    return null;
  }

  removeNodeIfNotInElementMap(currentNode.first, elementMap);
  removeNodeIfNotInElementMap(currentNode.second, elementMap);

  return currentNode;
};
