import { captureException } from '@sentry/react';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/src/Core/Button';
import { Flex } from 'ui/src/Core/Flex';
import { SelectDropdown } from 'ui/src/FormElements/SelectDropdown';
import { TextInput } from 'ui/src/FormElements/TextInput';
import { useToaster } from 'ui/src/Providers/ToasterProvider/useToaster';
import { useUsers } from 'ui/src/Providers/UsersProvider/useUsers';
import { useYupValidation } from 'ui/src/hooks/useYupValidation';
import * as Yup from 'yup';

interface UserCreateFormProps {
  setShowForm: Dispatch<SetStateAction<boolean>>;
}

export function InviteUserForm({ setShowForm }: UserCreateFormProps) {
  const { t } = useTranslation();
  const { sendAlert } = useToaster();
  const { roles, inviteUser } = useUsers();
  const { email } = useYupValidation();
  const [isFirst, setIsFirst] = useState(true);

  return (
    <Formik
      initialValues={{ recipientEmail: '', roleId: 2 }}
      onSubmit={async ({ recipientEmail, roleId }) => {
        try {
          await inviteUser({ recipientEmail, roleId });

          sendAlert({
            text: t('success.inviteUser', { user: recipientEmail }),
            severity: 'success',
          });
        } catch (e) {
          captureException(e);

          sendAlert({
            text: t('error.inviteUser'),
            severity: 'error',
          });
        }

        setShowForm(false);
      }}
      onReset={() => {
        setShowForm(false);
      }}
      validationSchema={Yup.object().shape({
        recipientEmail: email.required(t('error.required')),
        roleId: Yup.number().required(t('error.required')),
      })}
      validateOnChange={!isFirst}
    >
      {({ errors, values, handleChange, handleSubmit, handleReset, setFieldValue, isValid }) => (
        <Flex direction="column" width="400px" gap="2rem">
          <TextInput
            required
            title={t('util.recipientEmail')}
            name="recipientEmail"
            type="email"
            id="recipientEmail"
            onChange={handleChange}
            value={values.recipientEmail}
            error={errors.recipientEmail}
          />
          <SelectDropdown
            title={t('util.user.role')}
            values={roles.map((role) => ({ title: role.name, value: role.id }))}
            selected={values.roleId ?? roles[0].id}
            onChange={(value) => setFieldValue('roleId', value)}
          />
          <Flex gap="1rem" width="100%" justify="center">
            <Button type="reset" onClick={() => handleReset()} color="var(--secondary-button)">
              {t('util.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setIsFirst(false);
                handleSubmit();
              }}
              disabled={!isValid}
            >
              {t('util.inviteUser')}
            </Button>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
}
