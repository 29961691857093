import styled from '@emotion/styled';
import { CSSProperties, ElementType, FC, useMemo } from 'react';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { NumberStyle, numberStyleHelper } from '../helpers/numberStyleHelper';
import { Typography, TypographyProps, Variant } from './Typography';

interface NumberFormatterProps {
  val?: number;
  as: ElementType;
  variant?: Variant;
  numberStyle?: NumberStyle;
  showChange?: boolean;
  prefixStyle?: CSSProperties;
  valueStyle?: CSSProperties;
  suffixStyle?: CSSProperties;
  color?: string;
  customSuffix?: string;
  customSuffixStyle?: CSSProperties;
  style?: CSSProperties;
  className?: string;
}

export function NumberFormatter({
  val: rawValue = NaN,
  as = 'p',
  numberStyle = 'real',
  prefixStyle,
  valueStyle,
  suffixStyle,
  showChange,
  variant,
  color = 'var(--text)',
  customSuffix = '',
  customSuffixStyle,
  style,
  className,
}: NumberFormatterProps) {
  const t = useLocalization();

  const displayValue = useMemo<string>(() => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const parts = currencyFormatter.formatToParts(showChange ? Math.abs(rawValue) : rawValue);

    switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
      case 'currency':
        return parts
          .filter((part) => part.type !== 'currency')
          .map((part) => part.value)
          .join('');
      case 'currencyEstimate':
        return numberStyleHelper(Math.abs(rawValue), numberStyle).display();
      default:
        return numberStyleHelper(showChange ? Math.abs(rawValue) : rawValue, numberStyle).display();
    }
  }, [rawValue, numberStyle, showChange]);

  const prefix = useMemo<string>(() => {
    if (!isFinite(rawValue)) return '';

    switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
      case 'currency':
      case 'currencyEstimate':
        if (!showChange && Number(displayValue) * rawValue < 0 ? -1 : 1 < 0) return '-$';
        return '$';
      default:
        return '';
    }
  }, [numberStyle, rawValue, showChange, displayValue]);

  const suffixScale = useMemo(() => {
    if (!isFinite(rawValue)) return '';

    switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
      case 'hh:mm:ss':
        return '';
      case 'mm:ss':
        return '';
    }

    switch (numberStyleHelper(rawValue, numberStyle).scale()) {
      case 't':
        return t('numbers.thousand');
      case 'm':
        return t('numbers.million');
      case 'b':
        return t('numbers.billion');
      case 'tr':
        return t('numbers.trillion');
      case 'p':
        return t('numbers.peta');
      case 'ex':
        return t('numbers.exa');
      default:
        return '';
    }
  }, [rawValue, numberStyle, t]);

  const suffixSymbol = useMemo(() => {
    if (!isFinite(rawValue)) return '';

    switch (numberStyleHelper(rawValue, numberStyle).numberFormat) {
      case 'percentage':
        return '%';
      case 'percentagePoint':
        return t('metrics.percentagePoints');
    }
  }, [rawValue, numberStyle, t]);

  return as === 'tspan' || as === 'span' ? (
    <NumberParts
      as={as}
      variant={variant}
      prefixStyle={{ color, ...style, ...prefixStyle }}
      showChange={showChange}
      rawValue={rawValue}
      displayValue={displayValue}
      prefix={prefix}
      valueStyle={{ color, ...style, ...valueStyle }}
      suffixStyle={{ color, ...style, ...suffixStyle }}
      numberStyle={numberStyle}
      suffixScale={suffixScale}
      suffixSymbol={suffixSymbol}
      customSuffixStyle={{ color, ...style, ...customSuffixStyle }}
      customSuffix={customSuffix}
      className={className}
    />
  ) : (
    <Typography as={as} variant={variant} color={color} style={style}>
      <NumberParts
        as="span"
        prefixStyle={{ color, ...style, ...prefixStyle }}
        showChange={showChange}
        rawValue={rawValue}
        displayValue={displayValue}
        prefix={prefix}
        valueStyle={{ color, ...style, ...valueStyle }}
        suffixStyle={{ color, ...style, ...suffixStyle }}
        numberStyle={numberStyle}
        suffixScale={suffixScale}
        suffixSymbol={suffixSymbol}
        customSuffixStyle={{ color, ...style, ...customSuffixStyle }}
        customSuffix={customSuffix}
        className={className}
      />
    </Typography>
  );
}

interface PartProps extends TypographyProps {
  as: 'span' | 'tspan';
}

const Prefix = styled(Typography)<PartProps>`
  padding-right: 0.1em;
`;

const Value = styled(Typography)<PartProps>``;

const Suffix = styled(Typography)<PartProps>`
  font-size: max(12px, 0.5em);
  padding-left: 0.1em;
`;

const getSign = (rawValue: number, displayValue: string) =>
  (isFinite(rawValue) &&
    Number(displayValue) !== 0 &&
    (rawValue > 0 ? '+' : rawValue < 0 ? '-' : '')) ||
  '';

interface NumberPartsProps {
  as: 'span' | 'tspan';
  variant?: Variant;
  prefixStyle: CSSProperties;
  rawValue: number;
  displayValue: string;
  prefix: string;
  showChange?: boolean;
  valueStyle: CSSProperties;
  suffixStyle: CSSProperties;
  numberStyle: NumberStyle;
  suffixScale: string;
  suffixSymbol?: string;
  customSuffixStyle: CSSProperties;
  customSuffix: string;
  className?: string;
}

const NumberParts: FC<NumberPartsProps> = ({
  as,
  variant,
  prefixStyle,
  showChange,
  rawValue,
  displayValue,
  prefix = '',
  valueStyle,
  suffixStyle,
  numberStyle,
  suffixScale,
  suffixSymbol = '',
  customSuffixStyle,
  customSuffix,
  className,
}) => {
  const sign = showChange ? getSign(rawValue, displayValue) : '';
  const fullPrefix = sign + prefix;

  return (
    <>
      {fullPrefix && (
        <Prefix as={as} style={prefixStyle} className={variant}>
          {fullPrefix}
        </Prefix>
      )}
      {displayValue && (
        <Value
          as={as}
          style={valueStyle}
          className={[variant, className].filter((val) => val).join(' ')}
        >
          {displayValue}
        </Value>
      )}
      {(suffixSymbol || suffixScale) && (
        <Suffix as={as} style={suffixStyle} className={variant}>
          {numberStyle !== 'currency' && suffixScale}
          {suffixSymbol}
        </Suffix>
      )}
      {customSuffix && (
        <Suffix as={as} style={customSuffixStyle} className={variant}>
          {(suffixScale || suffixSymbol) && ' '}
          {` ${customSuffix}`}
        </Suffix>
      )}
    </>
  );
};
