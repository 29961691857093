import styled from '@emotion/styled';

interface DividerProps {
  color?: string;
  solid?: boolean;
  vertical?: boolean;
  stroke?: string;
}

export const Divider = styled.div<DividerProps>`
  border-bottom: ${({ vertical, solid, color, stroke }) =>
    `${vertical ? '0px' : stroke ?? '1px'} ${solid ? 'solid' : 'dashed'} ${
      color ?? 'var(--space)'
    }`};
  border-right: ${({ vertical, solid, color, stroke }) =>
    `${vertical ? stroke ?? '1px' : '0px'} ${solid ? 'solid' : 'dashed'} ${
      color ?? 'var(--space)'
    }`};
  width: ${({ vertical }) => (vertical ? '3px' : '100%')};
  height: ${({ vertical }) => (vertical ? '100%' : '1px')};
`;
