import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';

export const retailerFilter: FilterDefinition = {
  filterId: 'retailer',
  displayName: 'Retailer',
  isLoading: false,
  type: 'radio',
  values: [
    {
      title: 'Albertsons',
      value: 'Albertsons',
    },
    {
      title: 'Kroger',
      value: 'Kroger',
    },
    {
      title: 'Target',
      value: 'Target',
    },
    {
      title: 'Walmart',
      value: 'Walmart',
    },
  ],
  defaultValue: 'Albertsons',
};
