import styled from '@emotion/styled';
import { PropsWithChildren, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { gradientWrapStyles } from '../helpers/gradientWrapStyles';
import { InnerSolutionContent } from './InnerSolutionContent';

interface SolutionLinkProps {
  isActive?: boolean;
  backLink?: string;
  to: string;
}

export const SolutionLink = forwardRef<HTMLAnchorElement, PropsWithChildren<SolutionLinkProps>>(
  ({ backLink = '/solutions', isActive, to, children }, ref) => {
    return (
      <LinkGradientWrap to={to} state={{ backLink }} ref={ref}>
        <InnerSolutionContent isActive={isActive}>{children}</InnerSolutionContent>
      </LinkGradientWrap>
    );
  }
);

const LinkGradientWrap = styled(Link)(gradientWrapStyles);
