import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useYupValidation() {
  const { t } = useTranslation();
  const password = Yup.string().min(6, t('error.password.short')).required(t('error.required'));

  return {
    commaSeparatedEmails: Yup.string().test('email', t('error.invalidEmail'), (email) => {
      const emailRegex =
        /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(\s*,\s*[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/;

      return emailRegex.test(email ?? '') || email === undefined;
    }),
    email: Yup.string().email(t('error.invalidEmail')),
    string: Yup.string(),
    string255: Yup.string().max(255, t('form.error.max', { max: 255 })),
    stringRequired: Yup.string().required(t('error.required')),
    boolean: Yup.boolean(),
    rating: Yup.number().typeError(t('collections.ratings.pleaseSelectAStarRating')),
    password,
  };
}
