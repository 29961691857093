import dayjs, { Dayjs, extend } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

extend(relativeTime);
extend(updateLocale);
extend(isoWeek);
extend(utc);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

export type DateStyle =
  | 'full'
  | 'full-abbreviated'
  | 'full-date-time'
  | 'full-date-time-preview'
  | 'day'
  | 'month'
  | 'month-year'
  | 'year'
  | 'year-abbreviated'
  | 'month-day'
  | 'fromNow'
  | 'mm-dd-yy';

export function useDateFormatter(): (
  period: string | number | Date | Dayjs,
  dateStyle: DateStyle
) => string {
  const format = (period: string | number | Date | Dayjs, dateStyle: DateStyle) => {
    let formatted: string;

    const date = dayjs(period);

    switch (dateStyle) {
      case 'full':
        formatted = date.format('MMM D, YYYY');
        break;
      case 'full-date-time':
        formatted = date.format('MMM D, YYYY, hh:mm a');
        break;
      case 'full-date-time-preview':
        formatted = date.format('MM/DD/YY • hh:mm A');
        break;
      case 'full-abbreviated':
        formatted = date.format("MMM D, 'YY");
        break;
      case 'day':
        formatted = date.format('DD');
        break;
      case 'month':
        formatted = date.format('MMM');
        break;
      case 'month-year':
        formatted = date.format('MMMM YYYY');
        break;
      case 'year':
        formatted = date.format('YYYY');
        break;
      case 'year-abbreviated':
        formatted = date.format('YY');
        break;
      case 'month-day':
        formatted = date.format('MMM D');
        break;
      case 'fromNow':
        formatted = date.fromNow();
        break;
      case 'mm-dd-yy':
        formatted = date.format('MM-DD-YY');
        break;
    }

    return formatted;
  };

  return format;
}
