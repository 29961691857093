import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InfluencerData,
  PriceChartData,
  PriceRevenueVolumeData,
} from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { formatTrend } from '../helpers/chartHelpers';
import { initialComparisonChartData } from '../helpers/comparisonChartHelpers';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { HighchartsContainer } from './HighchartsContainer';

export function CompetitorsPriceVsMyVolume() {
  const { t } = useTranslation();
  const dateTimeLabelFormats = useDateTimeLabelFormats();
  const { workflowId } = useTalkingPointsPage();
  const influencerData = useChatCpgData<InfluencerData>(['influencers', workflowId]);
  const priceChartData = useChatCpgData<PriceChartData>(['price-chart', workflowId]);
  const pvsChartData = useChatCpgData<PriceRevenueVolumeData>(['pvs-chart', workflowId]);

  const chartData = useMemo(() => {
    return influencerData && priceChartData
      ? {
          myProduct: {
            id: influencerData.id,
            name: influencerData.name,
            trend: formatTrend({
              trend: pvsChartData?.volume,
              daysToAdd: 6,
            }),
          },
          competitor1: {
            id: influencerData.influencers[0].id,
            name: influencerData.influencers[0].name,
            trend: formatTrend({
              trend: priceChartData[influencerData.influencers[0].id]?.price,
              daysToAdd: 6,
            }),
          },
          competitor2: {
            id: influencerData.influencers[1].id,
            name: influencerData.influencers[1].name,
            trend: formatTrend({
              trend: priceChartData[influencerData.influencers[1].id]?.price,
              daysToAdd: 6,
            }),
          },
        }
      : initialComparisonChartData;
  }, [influencerData, priceChartData, pvsChartData]);

  const options = useMemo(() => {
    const series: Highcharts.Options['series'] = [
      {
        name: t('metrics.myVolume'),
        type: 'column',
        color: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
          },
          stops: [
            [0, 'var(--salmon)'],
            [0.05, 'var(--salmon-70)'],
            [0.2, 'var(--salmon-50)'],
            [1, 'var(--transparent)'],
          ],
        },
        borderWidth: 0,
        yAxis: 0,
        data: chartData.myProduct.trend,
        tooltip: {
          valueDecimals: 0,
        },
      },
    ];

    if (chartData.competitor1) {
      series.push({
        name: t('metrics.competitor1'),
        type: 'line',
        color: 'var(--lavender)',
        dashStyle: 'Solid',
        lineWidth: 2,
        yAxis: 1,
        data: chartData.competitor1?.trend ?? [],
        tooltip: {
          valuePrefix: '$',
        },
      });
    }

    if ('competitor2' in chartData) {
      series.push({
        name: t('metrics.competitor2'),
        type: 'line',
        color: 'var(--tickle-me-pink)',
        dashStyle: 'Solid',
        lineWidth: 2,
        yAxis: 1,
        data: chartData.competitor2?.trend ?? [],
        tooltip: {
          valuePrefix: '$',
        },
      });
    }

    const highchartsOptions: Highcharts.Options = {
      tooltip: {
        shared: true,
        dateTimeLabelFormats,
        borderColor: 'var(--geyser)',
      },
      chart: {
        animation: false,
      },
      xAxis: {
        type: 'datetime',
        startOfWeek: 6,
        crosshair: true,
      },
      legend: {
        enabled: false,
      },
      yAxis: [
        {
          type: 'linear',
          opposite: true,
          labels: {
            style: {
              color: 'var(--salmon)',
            },
          },
        },
        {
          type: 'linear',
          labels: {
            formatter: function () {
              return `$${Highcharts.numberFormat(this.value as number, 2)}`;
            },
          },
        },
      ],
      series,
    };

    return highchartsOptions;
  }, [t, chartData, dateTimeLabelFormats]);

  const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick();

  return (
    <Flex
      width="100%"
      height="100%"
      justify="stretch"
      align="stretch"
      direction="column"
      style={{ minHeight: 0, minWidth: 0 }}
    >
      <SlideCarousel width="100%" alignItems="flex-start" gap="0.2rem">
        <InfoTooltip
          text={chartData.myProduct.name}
          isHidden={hiddenTrends.includes('myVolume')}
          leaveOpenOnHover
        >
          <ValueBox
            label={t('metrics.myVolume')}
            subtitle={chartData.myProduct.id}
            indicator="column"
            color="var(--salmon)"
            secondary
            allNeutral
            onClick={() => handleLegendClick('myVolume', 0)}
            style={{
              opacity: hiddenTrends.includes('myVolume') ? 0.5 : 1,
            }}
          />
        </InfoTooltip>
        {'competitor1' in chartData && (
          <InfoTooltip
            text={chartData.competitor1?.name}
            isHidden={hiddenTrends.includes('competitor1Price')}
            leaveOpenOnHover
          >
            <ValueBox
              label={t('metrics.competitor1')}
              subtitle={chartData.competitor1?.id}
              color="var(--lavender)"
              secondary
              allNeutral
              onClick={() => handleLegendClick('competitor1Price', 1)}
              style={{
                opacity: hiddenTrends.includes('competitor1Price') ? 0.5 : 1,
              }}
            />
          </InfoTooltip>
        )}
        {'competitor2' in chartData && (
          <InfoTooltip
            text={chartData.competitor2?.name}
            isHidden={hiddenTrends.includes('competitor2Price')}
            leaveOpenOnHover
          >
            <ValueBox
              label={t('metrics.competitor2')}
              subtitle={chartData.competitor2?.id}
              color="var(--tickle-me-pink)"
              secondary
              allNeutral
              onClick={() => handleLegendClick('competitor2Price', 1)}
              style={{
                opacity: hiddenTrends.includes('competitor2Price') ? 0.5 : 1,
              }}
            />
          </InfoTooltip>
        )}
      </SlideCarousel>
      <HighchartsContainer options={options} ref={chartRef} />
    </Flex>
  );
}
