import styled from '@emotion/styled';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { forwardRef } from 'react';
import { useMeasure } from 'react-use';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Flex } from '../Core/Flex';

const StyledFlex = styled(Flex)`
  max-height: 100%;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

interface HighchartsContainerProps {
  options: Highcharts.Options;
}

export const HighchartsContainer = forwardRef<HighchartsReact.RefObject, HighchartsContainerProps>(
  ({ options }, ref) => {
    const [containerRef, { width, height }] = useMeasure<HTMLDivElement>();

    const chartOptions = useDeepCompareMemo(() => {
      return {
        ...options,
        chart: {
          ...(options.chart ?? {}),
          width,
          height,
        },
      };
    }, [width, height, options]);

    return (
      <StyledFlex ref={containerRef}>
        {height > 0 && <HighchartsReact ref={ref} options={chartOptions} highcharts={Highcharts} />}
      </StyledFlex>
    );
  }
);
