import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { PropsWithChildren, useMemo, useReducer } from 'react';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { AppFilters } from '../filterTypes';
import { ActiveFiltersContext } from './ActiveFiltersContext';
import { ActiveFiltersAction, ActiveFiltersDispatchContext } from './ActiveFiltersDispatchContext';
import { useActiveFilters } from './useActiveFilters';

interface ActiveFiltersProviderProps {
  filterScope: AppFilters;
  typeOverrides?: Record<string, FilterDefinition['type']>;
}

export function ActiveFiltersProvider({
  filterScope,
  typeOverrides = {},
  children,
}: PropsWithChildren<ActiveFiltersProviderProps>) {
  const { activeFilters: outerFilters, typeOverrides: outerTypeOverrides = {} } =
    useActiveFilters();

  const [activeFilters, dispatchActiveFilters] = useReducer(activeFiltersReducer, filterScope);

  const clashingFilters: string[] = useMemo(() => {
    const clashingFilters: string[] = [];

    Object.keys(outerTypeOverrides).forEach((filterId) => {
      if (outerTypeOverrides?.[filterId] !== typeOverrides?.[filterId]) {
        clashingFilters.push(filterId);
      }
    });

    Object.keys(typeOverrides).forEach((filterId) => {
      if (outerTypeOverrides?.[filterId] !== typeOverrides?.[filterId]) {
        clashingFilters.push(filterId);
      }
    });

    return clashingFilters;
  }, [outerTypeOverrides, typeOverrides]);

  const combinedFilters = useMemo(
    () => ({ ...omit(outerFilters, clashingFilters), ...activeFilters }),
    [outerFilters, activeFilters, clashingFilters]
  );

  return (
    <ActiveFiltersContext.Provider
      value={{
        activeFilters,
        combinedFilters,
        outerFilters,
        typeOverrides,
      }}
    >
      <ActiveFiltersDispatchContext.Provider value={dispatchActiveFilters}>
        {children}
      </ActiveFiltersDispatchContext.Provider>
    </ActiveFiltersContext.Provider>
  );
}

function activeFiltersReducer(activeFilters: AppFilters, activeFiltersAction: ActiveFiltersAction) {
  const newActiveFilters = cloneDeep(activeFilters);

  switch (activeFiltersAction.type) {
    case 'add':
      return { ...activeFilters, ...activeFiltersAction.payload };
    case 'replace':
      return { ...activeFiltersAction.payload };
    case 'remove':
      delete newActiveFilters[activeFiltersAction.payload];
      return newActiveFilters;
  }
}
