import { shallow } from 'zustand/shallow';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { ChatCpgBubble } from './ChatCpgBubble';

export function ChatStreamBubble() {
  const { workflowId } = useTalkingPointsPage();

  const { chatStreamId } = useChatBox();

  const chatStream = useChatCpgData<StreamingText>([chatStreamId, workflowId], shallow) ?? {
    text: '',
    isStreaming: false,
  };

  return <ChatCpgBubble id="chat-stream-bubble" isStreaming text={chatStream.text} />;
}
