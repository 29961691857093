import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function Mp4Icon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="50" height="60" viewBox="0 0 50 60" fill="none">
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15381 24.9997V3.23047H31.2968V13.9338C31.2968 15.5906 32.6399 16.9338 34.2968 16.9338H45V54.6481H7.15381V41.9997H32C33.6569 41.9997 35 40.6565 35 38.9997V27.9997C35 26.3428 33.6569 24.9997 32 24.9997H7.15381ZM4.15381 24.9997V3.23047C4.15381 1.57361 5.49696 0.230469 7.15381 0.230469H31.7278C32.7886 0.230469 33.806 0.651896 34.5562 1.40204L34.6978 1.54368L34.711 1.55675L46.6738 13.5196L46.6868 13.5326L46.8284 13.6743C47.5786 14.4244 48 15.4419 48 16.5027V54.6481C48 56.305 46.6569 57.6481 45 57.6481H7.15381C5.49696 57.6481 4.15381 56.305 4.15381 54.6481V41.9997H3C1.34315 41.9997 0 40.6565 0 38.9997V27.9997C0 26.3428 1.34315 24.9997 3 24.9997H4.15381ZM34.2968 13.9338V5.38529L42.8453 13.9338H34.2968Z"
          fill="#45DDCF"
        />
        <path
          d="M14.2402 29.5997H12.5362L10.1362 33.5717L7.72423 29.5997H6.02023V37.9997H7.67623V32.5637L10.0402 36.4517H10.2322L12.5962 32.5517V37.9997H14.2402V29.5997ZM18.9374 29.5997H15.8054V37.9997H17.4614V35.2397H18.9374C20.5574 35.2397 21.8054 33.9917 21.8054 32.4197C21.8054 30.8477 20.5574 29.5997 18.9374 29.5997ZM18.9374 33.6917H17.4614V31.1477H18.9374C19.6454 31.1477 20.1614 31.6877 20.1614 32.4197C20.1614 33.1397 19.6454 33.6917 18.9374 33.6917ZM28.5908 35.0357H27.6908V33.0437H26.0228V35.0357H23.8388L26.6228 29.5997H24.8468L22.0508 35.0357V36.5717H26.0228V37.9997H27.6908V36.5717H28.5908V35.0357Z"
          fill="#15161D"
        />
      </svg>
    </IconWrapper>
  );
}
