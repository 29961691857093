import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { ContextMenu } from '../Core/ContextMenu';
import { ContextMenuItem } from '../Core/ContextMenuItem';
import { VisibilityOffIcon } from '../Icons/VisibilityOffIcon';
import { PopoverContent } from '../Popover/PopoverContent';
import { usePopoverContext } from '../Popover/usePopoverContext';
import { useApi } from '../Providers/ApiProvider/useApi';

interface PostPopoverContentProps {
  id: string;
}

export function PostPopoverContent({ id }: PostPopoverContentProps) {
  const { t } = useTranslation();
  const { post } = useApi();
  const { setOpen } = usePopoverContext();
  const [irrelevantCards, setIrrelevantCards] = useLocalStorage<string[]>('irrelevantCards', []);

  const tagIrrelevant = useMutation(
    async (id: string) => {
      post(
        `cards/newsroom/${id}/tag`,
        {
          406: true,
        },
        {
          params: {
            _method: 'PUT',
          },
        }
      );
    },
    {
      onMutate(id) {
        setIrrelevantCards([...irrelevantCards, id]);
      },
    }
  );

  return (
    <PopoverContent>
      <ContextMenu direction="column" gap="0.5rem" width="200px" padding="0.4rem">
        <ContextMenuItem
          textColor="var(--danger)"
          text={t('util.irrelevant')}
          onClick={() => {
            setOpen(false);
            tagIrrelevant.mutateAsync(id);
          }}
        >
          <VisibilityOffIcon color="var(--danger)" />
        </ContextMenuItem>
      </ContextMenu>
    </PopoverContent>
  );
}
