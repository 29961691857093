import styled from '@emotion/styled';
import { captureException } from '@sentry/react';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { DetailedHTMLProps, FC, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { useDialogContext } from '../../DialogFloating/useDialogContext';
import { FormCheckboxes } from '../../FormElements/FormCheckboxes';
import { FormElementWrapper } from '../../FormElements/FormElementWrapper';
import { InformationIcon } from '../../Icons/InformationIcon';
import { FeedbackType } from '../../Providers/ChatCpgMutationsProvider/ChatCpgMutationsContext';
import { useChatCpgMutations } from '../../Providers/ChatCpgMutationsProvider/useChatCpgMutations';
import { useTalkingPointsPage } from '../../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { InfoTooltip } from '../../Tooltips/InfoTooltip';
import { useYupValidation } from '../../hooks/useYupValidation';

export interface FeedbackDialogContentProps {
  chatMessageId?: string;
  feedbackType?: FeedbackType;
  talkingPointId?: string;
  text: string;
}

export const FeedbackDialogContent: FC<FeedbackDialogContentProps> = ({
  chatMessageId,
  feedbackType,
  talkingPointId,
  text,
}) => {
  const { t } = useTranslation();
  const { string } = useYupValidation();
  const { sendAlert } = useToaster();
  const { setOpen } = useDialogContext();
  const { workflowId } = useTalkingPointsPage();

  const { postChatFeedback, postSummaryFeedback } = useChatCpgMutations();
  const [isFirst, setIsFirst] = useState(true);

  return (
    <Formik
      initialValues={{
        correctedText: text,
        errorType: [] as string[],
        explanation: '',
      }}
      onSubmit={async (values) => {
        try {
          if (chatMessageId) {
            await postChatFeedback({
              category: values.errorType.join(','),
              chatMessageId,
              feedback: values.correctedText,
              explanation: values.explanation,
              label: 'bad',
            });
          } else if (talkingPointId && feedbackType) {
            await postSummaryFeedback({
              category: values.errorType.join(','),
              explanation: values.explanation,
              feedback: values.correctedText,
              talkingPointId,
              label: 'bad',
              type: feedbackType,
              workflowId,
            });
          }

          sendAlert({
            severity: 'success',
            text: t('feedback.thankYou'),
          });

          setOpen(false);
        } catch (e) {
          captureException(e);

          sendAlert({
            severity: 'error',
            text: t('feedback.error'),
          });
        }
      }}
      validationSchema={Yup.object().shape({
        correctedText: string
          .required()
          .test('is changed', t('feedback.correctedText.error'), (value) => value !== text),
        errorType: Yup.array()
          .of(Yup.string())
          .test('is not empty', t('feedback.typeOfError.required'), (value) => !isEmpty(value)),
      })}
      validateOnChange={!isFirst}
    >
      {({ errors, values, handleChange, handleSubmit, setFieldValue, isValid, isSubmitting }) => (
        <>
          <DialogTitle title={t('feedback.title')} showX />
          <DialogBody style={{ paddingBottom: '2rem' }}>
            <Flex column width="100%" gap="2rem" style={{ minHeight: 0, overflowY: 'auto' }}>
              <Flex column gap="0.8rem">
                <Typography variant="h-12-b" style={{ paddingLeft: '8px' }}>
                  {t('feedback.originalText')}
                </Typography>
                <Typography
                  variant="h-12-b"
                  multiline
                  style={{
                    padding: '8px',
                    backgroundColor: 'var(--clay-70)',
                    borderRadius: 'var(--corner)',
                    whiteSpace: 'pre-line',
                    lineHeight: '18px',
                  }}
                >
                  {text}
                </Typography>
              </Flex>

              <FormElementWrapper
                title={t('feedback.pleaseProvideCorrectedText')}
                error={errors.correctedText}
              >
                <ControlledTextArea
                  autoFocus
                  className="h-12-b"
                  id="correctedText"
                  name="correctedText"
                  onChange={handleChange}
                  onFocus={function (e) {
                    const val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                  }}
                  value={values.correctedText}
                />
              </FormElementWrapper>
              <Flex width="100%">
                <FormCheckboxes
                  title={t('feedback.errorsPresent')}
                  error={errors.errorType as string}
                  values={[
                    { title: t('feedback.factualErrors'), value: 'factual' },
                    { title: t('feedback.mathematicalErrors'), value: 'math' },
                    { title: t('feedback.logicalErrors'), value: 'logical' },
                    { title: t('feedback.contextualErrors'), value: 'contextual' },
                    { title: t('feedback.other'), value: 'other' },
                  ]}
                  selected={values.errorType}
                  onChange={(e) => {
                    setFieldValue(
                      'errorType',
                      values.errorType.includes(e.target.value)
                        ? values.errorType.filter((v) => v !== e.target.value)
                        : [...values.errorType, e.target.value]
                    );
                  }}
                />
                <InfoTooltip
                  containerStyle={{
                    flexDirection: 'column',
                    borderRadius: '32px',
                    padding: '20px',
                    gap: '1rem',
                  }}
                  maxWidth="600px"
                  zindex={1000}
                  leaveOpenOnHover
                  component={() => (
                    <>
                      <Flex column gap="0.4rem">
                        <Typography
                          width="100%"
                          as="h3"
                          variant="p-14-b"
                          style={{ textAlign: 'left' }}
                        >
                          {t('feedback.factualErrors')}
                        </Typography>
                        <Typography variant="h-12-b" multiline>
                          {t('feedback.factualErrors.description')}
                        </Typography>
                      </Flex>
                      <Flex column gap="0.4rem">
                        <Typography
                          width="100%"
                          as="h3"
                          variant="p-14-b"
                          style={{ textAlign: 'left' }}
                        >
                          {t('feedback.mathematicalErrors')}
                        </Typography>
                        <Typography variant="h-12-b" multiline>
                          {t('feedback.mathematicalErrors.description')}
                        </Typography>
                      </Flex>
                      <Flex column gap="0.4rem">
                        <Typography
                          width="100%"
                          as="h3"
                          variant="p-14-b"
                          style={{ textAlign: 'left' }}
                        >
                          {t('feedback.logicalErrors')}
                        </Typography>
                        <Typography variant="h-12-b" multiline>
                          {t('feedback.logicalErrors.description')}
                        </Typography>
                      </Flex>
                      <Flex column gap="0.4rem">
                        <Typography
                          width="100%"
                          as="h3"
                          variant="p-14-b"
                          style={{ textAlign: 'left' }}
                        >
                          {t('feedback.contextualErrors')}
                        </Typography>
                        <Typography variant="h-12-b" multiline>
                          {t('feedback.contextualErrors.description')}
                        </Typography>
                      </Flex>
                    </>
                  )}
                >
                  <InformationIcon style={{ cursor: 'pointer' }} />
                </InfoTooltip>
              </Flex>
              <FormElementWrapper title={t('feedback.explanation')} error={errors.explanation}>
                <ControlledTextArea
                  className="h-12-b"
                  id="explanation"
                  name="explanation"
                  onChange={handleChange}
                  value={values.explanation}
                />
              </FormElementWrapper>
            </Flex>
          </DialogBody>
          <DialogFooter>
            <DialogTrigger>
              <Button width="200px" color="var(--trout)">
                {t('util.cancel')}
              </Button>
            </DialogTrigger>
            <Button
              disabled={!isValid || isSubmitting}
              width="200px"
              onClick={async () => {
                setIsFirst(false);

                if (isEmpty(errors)) {
                  handleSubmit();
                }
              }}
              type="submit"
            >
              {t('util.submit')}
            </Button>
          </DialogFooter>
        </>
      )}
    </Formik>
  );
};

const ControlledTextArea: FC<
  DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
> = (props) => {
  const correctTextRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (correctTextRef.current) {
      correctTextRef.current.focus();

      correctTextRef.current.style.height = '0px';
      const scrollHeight = correctTextRef.current.scrollHeight;
      correctTextRef.current.style.height = `${scrollHeight}px`;
    }
  }, [props.value]);

  return <StyledTextArea {...props} ref={correctTextRef} />;
};

const StyledTextArea = styled.textarea`
  display: flex;
  flex-grow: 1;
  color: var(--text);
  background: var(--black-50);
  width: 100%;
  border-radius: var(--corner);
  padding: 8px;
  border: none;
  line-height: 18px;
  resize: none;

  :focus {
    outline: none;
  }
`;
