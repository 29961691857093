import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';
import { AppFilters } from '../filterTypes';
import { CheckboxFilterDisplay, CheckboxFilterItem } from './CheckboxFilterDisplay';

interface CheckboxFilterProps {
  inputBorderColor?: string;
  filterId: keyof AppFilters;
  values?: CheckboxFilterItem[];
  style?: React.CSSProperties;
}

export function CheckboxFilter({
  inputBorderColor,
  filterId,
  values = [],
  style,
}: CheckboxFilterProps) {
  const { stagedFilters } = useStagedFilters();
  const dispatchStagedFilters = useStagedFiltersDispatch();

  return (
    <CheckboxFilterDisplay
      filterId={filterId}
      inputBorderColor={inputBorderColor}
      onSelectAll={() => {
        dispatchStagedFilters({
          type: 'add',
          payload: {
            [filterId]: values
              .filter(({ disabled }) => !disabled)
              .map(({ value }) => value)
              .join(','),
          },
        });
      }}
      onDeselectAll={() => {
        dispatchStagedFilters({
          type: 'deselectAll',
          payload: filterId,
        });
      }}
      values={values}
      onChange={(value, checked) => {
        dispatchStagedFilters({
          type: checked ? 'selectCheckbox' : 'deselectCheckbox',
          payload: {
            filterId,
            value,
          },
        });
      }}
      selected={stagedFilters[filterId]}
      style={style}
    />
  );
}
