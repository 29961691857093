import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../Core/Button';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { Header } from '../PageLayout/Header';
import { TilePageLayout } from '../PageLayout/TilePageLayout';

export function Welcome() {
  const { t } = useTranslation();

  return (
    <TilePageLayout pageTitle={t('chatCpg.page.welcome')}>
      <Header pageTitle={t('chatCpg.page.welcome')} />
      <Flex
        column
        gap="5rem"
        padding="0 1rem 3rem max(20%, 1rem)"
        style={{ margin: 'auto 0', overflow: 'auto' }}
      >
        <Flex column gap="2rem">
          <ChatCpgIcon size="150px" />
          <Flex column gap="1rem">
            <Flex column>
              <Typography as="h1">{t('chatCpg.newUser.welcome')}</Typography>
              <Typography as="h2" variant="h-22-b">
                {t('chatCpg.newUser.tickrMostAdvancedAISystem')}
              </Typography>
            </Flex>
            <Typography variant="p-14-r" style={{ maxWidth: '350px' }}>
              {t('chatCpg.newUser.revolutionizing')}
            </Typography>
            <Link to="/solutions">
              <Button width="170px">{t('util.letsGo')}</Button>
            </Link>
          </Flex>
        </Flex>
        <Typography variant="p-14-r" multiline style={{ maxWidth: '350px' }}>
          {t('chatCpg.newUser.description')}
        </Typography>
      </Flex>
    </TilePageLayout>
  );
}
