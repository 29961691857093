import { CSSProperties, PropsWithChildren } from 'react';
import { Flex } from '../../Core/Flex';

interface SolutionContentProps {
  style?: CSSProperties;
}

export function SolutionContent({ children, style }: PropsWithChildren<SolutionContentProps>) {
  return (
    <Flex
      column
      width="100%"
      padding="0 6.5% 1rem 6.5%"
      height="100%"
      gap="2rem"
      style={{ gridRow: '2', gridColumn: '1', overflow: 'auto', ...style }}
    >
      {children}
    </Flex>
  );
}
