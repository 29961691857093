import { useTranslation } from 'react-i18next';
import { Button } from '../../Core/Button';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';

interface ReportDialogContentProps {
  report(): JSX.Element;
}

export function ReportDialogContent({ report }: ReportDialogContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle title={t('cardReport.cardReport')} showX />
      <DialogBody style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {report?.()}
      </DialogBody>
      <DialogFooter>
        <DialogTrigger>
          <Button color="var(--trout)" width="250px">
            {t('util.close')}
          </Button>
        </DialogTrigger>
      </DialogFooter>
    </>
  );
}
