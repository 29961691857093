import { CSSProperties, ReactNode } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { PickerDownIcon } from '../Icons/PickerDownIcon';

interface TableHeadCellProps {
  isLoading?: boolean;
  isActive?: boolean;
  isDescending?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  children?: ReactNode;
}

export function TableHeadCell({
  isLoading,
  isActive,
  isDescending,
  onClick,
  style,
  children,
}: TableHeadCellProps) {
  return !!onClick && !isLoading ? (
    <th style={style}>
      <button onClick={onClick}>
        <Flex gap="0.4em" align="center" width="100%">
          <Typography
            variant={isActive ? 't-13-b' : 't-13-r'}
            color={isActive ? 'var(--text)' : 'var(--text-secondary)'}
          >
            {children}
          </Typography>
          {isActive && (
            <PickerDownIcon
              transform={isDescending ? '' : 'rotate(-180deg)'}
              transition="transform 0.3s"
            />
          )}
        </Flex>
      </button>
    </th>
  ) : (
    <Typography as="th" color="var(--text-secondary)" style={style}>
      {children}
    </Typography>
  );
}
