import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Core/Button';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { EmailIcon } from '../../Icons/EmailIcon';
import { SlackIcon } from '../../Icons/SlackIcon';
import { TeamsIcon } from '../../Icons/TeamsIcon';
import { PopoverContent } from '../../Popover/PopoverContent';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { ShareInputAndDropdown } from './ShareInputAndDropdown';

export function SharingPopoverContent() {
  const { t } = useTranslation();
  const { setOpen } = usePopoverContext();
  const { sendAlert } = useToaster();

  return (
    <PopoverContent>
      <ContextMenu
        direction="column"
        gap="1.5rem"
        width="400px"
        padding="1rem"
        style={{ overflow: 'visible' }}
      >
        <Flex direction="column" gap="0.5rem">
          <Typography as="h2">{t('sharingPopover.shareThisAnalysis')}</Typography>
        </Flex>

        <ShareInputAndDropdown width="100%" />

        <Flex gap="8px">
          <ShareLink shareType={t('sharingPopover.shareType.email')}>
            <EmailIcon
              size="64px"
              style={{
                backgroundColor: 'var(--white)',
                padding: '',
                borderRadius: '15px',
              }}
            />
          </ShareLink>
          <ShareLink shareType={t('sharingPopover.shareType.slack')}>
            <SlackIcon
              size="48px"
              style={{
                backgroundColor: 'var(--white)',
                padding: '8px',
                borderRadius: '15px',
              }}
            />
          </ShareLink>
          <ShareLink shareType={t('sharingPopover.shareType.teams')}>
            <TeamsIcon
              size="48px"
              style={{
                backgroundColor: 'var(--white)',
                padding: '8px',
                borderRadius: '15px',
              }}
            />
          </ShareLink>
        </Flex>

        <Flex gap="1rem" width="100%">
          <StyledLinkButton
            as="button"
            color="var(--secondary-button)"
            width="30%"
            onClick={() => {
              sendAlert({
                text: t('sharingPopover.linkCopied'),
                severity: 'success',
              });
            }}
          >
            {t('sharingPopover.exportFormat.copyLink')}
          </StyledLinkButton>
        </Flex>

        <Flex gap="1rem" width="100%">
          <Button color="var(--secondary-button)" width="100%" onClick={() => setOpen(false)}>
            {t('util.close')}
          </Button>
        </Flex>
      </ContextMenu>
    </PopoverContent>
  );
}

interface ShareLinkProps {
  shareType: string;
  children: ReactNode;
}

function ShareLink({ shareType, children }: ShareLinkProps) {
  return (
    <StyledFlex as="button" direction="column" gap="0.4rem" align="center" isActive>
      {children}
      <Typography variant="h-12-b">{shareType}</Typography>
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};

  :hover {
    transform: scale(1.05);
    opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
  }

  transition: all 0.2s ease-in-out;
`;

const StyledLinkButton = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  height: 26px;

  text-align: center;
  justify: center;
  margin-left: 4px;
  border-radius: var(--corner);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 1px solid var(--trout);

  color: var(--white);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 10px;
  transition: opacity 0.3s;

  :focus {
    outline: 1px solid var(--trout);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }

  :active {
    padding: none;
    border: 1px hidden ${({ color }) => color};
  }
`;
