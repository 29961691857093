import { forwardRef, PropsWithChildren } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const FilterIcon = forwardRef<HTMLDivElement, PropsWithChildren<IconProps>>(
  ({ children, ...props }, ref) => {
    return (
      <IconWrapper {...props} ref={ref}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_2003_11958)">
            <line
              x1="4"
              y1="12"
              x2="20"
              y2="12"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="4"
              y1="19"
              x2="20"
              y2="19"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="4"
              y1="5"
              x2="20"
              y2="5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle cx="7" cy="5" r="2.5" fill="black" stroke="white" />
            <circle cx="16" cy="12" r="2.5" fill="black" stroke="white" />
            <circle cx="11" cy="19" r="2.5" fill="black" stroke="white" />
          </g>
          <defs>
            <clipPath id="clip0_2003_11958">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {children}
      </IconWrapper>
    );
  }
);
