import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../Core/Button';
import { Typography } from '../Core/Typography';
import { TextInput } from '../FormElements/TextInput';
import { LoginLayout } from './LoginLayout';

export function PasswordReset() {
  const { t } = useTranslation();

  const password = Yup.string().min(6, t('error.password.short')).required(t('error.required'));

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit() {
      return;
    },
    validationSchema: Yup.object().shape({
      password,
    }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoginLayout>
        <Typography variant="h-16-b">{t('auth.welcome.title')}</Typography>
        <TextInput
          required
          id="password"
          name="password"
          type="password"
          error={formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <Button type="submit">{t('util.reset')}</Button>
      </LoginLayout>
    </form>
  );
}
