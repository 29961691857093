import { captureException } from '@sentry/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button } from '../Core/Button';
import { Typography } from '../Core/Typography';
import { TextInput } from '../FormElements/TextInput';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useYupValidation } from '../hooks/useYupValidation';
import { LoginLayout } from './LoginLayout';

const LOGIN_WIDTH = '300px';

export function Login() {
  const { t } = useTranslation();
  const [isFirst, setIsFirst] = useState(true);
  const { sendAlert } = useToaster();
  const { login } = useAuth();
  const { email } = useYupValidation();
  const navigate = useNavigate();

  const titleText =
    window.location.host === 'chatcpg.ai'
      ? t('helmet.title.chatCPG', { page: t('pages.login') })
      : t('helmet.title', { page: t('pages.login') });

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async ({ email, password }) => {
          if (navigator.onLine === false) {
            sendAlert({
              severity: 'error',
              text: t('error.offline'),
            });
          }

          try {
            await login({
              email,
              password,
            });

            sendAlert({
              severity: 'success',
              text: t('success.auth'),
            });

            const params = new URLSearchParams(window.location.search);

            if (params.get('forward')) {
              navigate(params.get('forward') as string);
            }
          } catch (e) {
            captureException(e);

            sendAlert({
              severity: 'error',
              text: t('error.auth.badUsernameOrPassword'),
            });
          }
        }}
        validationSchema={Yup.object().shape({
          email: email.required(t('error.required')),
          password: Yup.string().required(t('error.required')),
        })}
        validateOnChange={!isFirst}
      >
        {({ errors, values, handleChange, handleSubmit, isValid, isSubmitting }) => (
          <LoginLayout>
            <Typography as="h1">TICKR</Typography>
            <TextInput
              required
              width={LOGIN_WIDTH}
              id="email"
              name="email"
              title={t('auth.email')}
              autoComplete="username"
              type="email"
              error={errors.email}
              value={values.email}
              onChange={handleChange}
              onSubmit={() => {
                setIsFirst(false);
                handleSubmit();
              }}
              background="var(--space)"
            />
            <TextInput
              required
              width={LOGIN_WIDTH}
              id="password"
              name="password"
              title={t('auth.password')}
              autoComplete="current-password"
              type="password"
              error={errors.password}
              value={values.password}
              onChange={handleChange}
              onSubmit={() => {
                setIsFirst(false);
                handleSubmit();
              }}
              background="var(--space)"
            />
            <Button
              type="submit"
              width={LOGIN_WIDTH}
              disabled={!isValid || isSubmitting}
              onClick={async () => {
                setIsFirst(false);
                handleSubmit();
              }}
            >
              {t('auth.login')}
            </Button>
          </LoginLayout>
        )}
      </Formik>
    </>
  );
}
