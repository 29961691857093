import { useEffect } from 'react';
import { MosaicNode, getLeaves } from 'react-mosaic-component';
import { UpdateMosaicProps } from './useUpdateTabMosaic';

interface useNoDuplicateNodesProps {
  defaultNode?: MosaicNode<string> | null;
  currentNode?: MosaicNode<string> | null;
  update(props: UpdateMosaicProps): void;
}

export const useNoDuplicateNodes = ({
  defaultNode = null,
  currentNode = null,
  update,
}: useNoDuplicateNodesProps) => {
  useEffect(() => {
    if (!currentNode) return;

    const currentLeaves = getLeaves(currentNode);

    if (currentLeaves.length !== [...new Set(currentLeaves)].length) {
      update({ node: defaultNode });
    }
  }, [currentNode, defaultNode, update]);
};
