import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const ClearIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M13.295 2.115C13.6844 1.72564 13.6844 1.09436 13.295 0.705C12.9056 0.315639 12.2744 0.315639 11.885 0.705L7 5.59L2.115 0.705C1.72564 0.315639 1.09436 0.315639 0.705 0.705C0.315639 1.09436 0.315639 1.72564 0.705 2.115L5.59 7L0.705 11.885C0.315639 12.2744 0.315639 12.9056 0.705 13.295C1.09436 13.6844 1.72564 13.6844 2.115 13.295L7 8.41L11.885 13.295C12.2744 13.6844 12.9056 13.6844 13.295 13.295C13.6844 12.9056 13.6844 12.2744 13.295 11.885L8.41 7L13.295 2.115Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
