import { useQuery } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, useMemo, useReducer, useState } from 'react';
import { Augmentations } from '../../Providers/AuthProvider/AuthContext';
import { useAuth } from '../../Providers/AuthProvider/useAuth';
import { ProductAttributeFilters } from '../ActivePAFiltersProvider/ActivePAFiltersContext';
import { useActivePAFilters } from '../ActivePAFiltersProvider/useActivePAFilters';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { AvailablePAFiltersContext } from './AvailablePAFiltersContext';
import {
  ProductAttributesTable,
  StagedPAFiltersContext,
  initialTable,
} from './StagedPAFiltersContext';
import {
  StagedPAFiltersAction,
  StagedPAFiltersDispatchContext,
} from './StagedPAFiltersDispatchContext';

export function StagedPAFiltersProvider({ children }: PropsWithChildren) {
  const { msAugmentations, msNameMapping, msId } = useAuth();
  // const { get } = useApi();
  const [fileId, setFileId] = useState(1);

  const [myProductsTablePage, setMyProductsTablePage] = useState(1);
  const [myProductsTablePageSize, setMyProductsTablePageSize] = useState(10);

  const [myProductsTableSort, setMyProductsTableSort] = useState<
    [string, 'ascending' | 'descending']
  >(['gtin', 'ascending']);

  const [competitorsTablePage, setCompetitorsTablePage] = useState(1);
  const [competitorsTablePageSize, setCompetitorsTablePageSize] = useState(10);

  const [competitorsTableSort, setCompetitorsTableSort] = useState<
    [string, 'ascending' | 'descending']
  >(['gtin', 'ascending']);

  const { activePAFilters } = useActivePAFilters();

  const [stagedPAFilters, dispatchStagedPAFilters] = useReducer(
    stagedPAFiltersReducer,
    activePAFilters
  );

  const {
    data: availablePAFilters = {
      filters: {},
      columns: [],
    },
  } = useQuery<{
    filters: Record<string, FilterDefinition>;
    columns: string[];
  }>(['available-PA-filters', msNameMapping, msAugmentations], async () => {
    // const response = await get(`/product_attributes/${msId}/${fileId}/filters`);
    if (!msNameMapping || !msAugmentations)
      return {
        filters: {},
        columns: [],
      };

    const table: ProductAttributesTable = {
      fileId: 1,
      columns: ['gtin', '_is_owned', ...Object.keys(msNameMapping)],
      rows: Object.keys(msAugmentations)
        .sort((a, b) => a.localeCompare(b))
        .map((gtin) => ({
          gtin,
          _is_owned: msAugmentations[gtin]._is_owned.toString(),
          ...Object.keys(msNameMapping).reduce((acc, col) => {
            acc[col as Exclude<keyof Augmentations, '_is_owned'>] = msAugmentations[gtin][
              col as keyof Augmentations
            ]?.toString() as string;

            return acc;
          }, {} as Record<Exclude<keyof Augmentations, '_is_owned'>, string>),
        })),
    };

    const filters = table.columns.reduce((acc: Record<string, FilterDefinition>, col) => {
      const uniqueValues = new Set(table.rows.map((row) => row[col]));

      const filterType = uniqueValues.size > 50 ? 'search' : 'checkbox';

      acc[col] = {
        type: filterType,
        filterId: col,
        displayName: col,
        ...(filterType === 'checkbox' && {
          values: [...uniqueValues]
            .sort((a, b) => a.localeCompare(b))
            .map((value) => ({ value, title: value })),
        }),
      } as FilterDefinition;

      return acc;
    }, {});

    return { filters, columns: table.columns };
  });

  const { data: myProductsTable = initialTable } = useQuery<ProductAttributesTable>(
    [
      'staged-product-attributes',
      msId,
      fileId,
      stagedPAFilters,
      myProductsTablePage,
      myProductsTablePageSize,
      myProductsTableSort,
      msNameMapping,
      msAugmentations, // TODO: remove when fetching from API
    ],
    async () => {
      if (!msNameMapping || !msAugmentations) return initialTable;

      // const response = await get(`/product_attributes/${msId}/${fileId}`);
      const myProductsTable = {
        fileId: 1,
        columns: ['gtin', '_is_owned', ...Object.keys(msNameMapping)],
        rows: Object.keys(msAugmentations)
          .sort((a, b) => a.localeCompare(b))
          .map((gtin) => ({
            gtin,
            _is_owned: msAugmentations[gtin]._is_owned.toString(),
            ...Object.keys(msNameMapping).reduce((acc, col) => {
              acc[col as Exclude<keyof Augmentations, '_is_owned'>] = msAugmentations[gtin][
                col as keyof Augmentations
              ]?.toString() as string;

              return acc;
            }, {} as Record<Exclude<keyof Augmentations, '_is_owned'>, string>),
          }))
          .filter(({ _is_owned }) => _is_owned.toLowerCase().trim() === 'true')
          .filter((row: Record<string, string>) => {
            return Object.keys(stagedPAFilters).every((filterId) => {
              const values = stagedPAFilters[filterId].split(',');

              return values.includes(row[filterId]);
            });
          }),
        // .slice(
        //   (myProductsTablePage - 1) * myProductsTablePageSize,
        //   myProductsTablePage * myProductsTablePageSize
        // ),
      };

      return myProductsTable;
    }
  );

  const { data: competitorsTable = initialTable } = useQuery<ProductAttributesTable>(
    [
      'staged-competitor-attributes',
      msId,
      fileId,
      stagedPAFilters,
      competitorsTablePage,
      competitorsTablePageSize,
      competitorsTableSort,
      msNameMapping,
      msAugmentations, // TODO: remove when fetching from API
    ],
    async () => {
      // const response = await get(`/product_attributes/${msId}/${fileId}`);
      if (!msNameMapping || !msAugmentations) return initialTable;

      const competitorsTable = {
        fileId: 1,
        columns: ['gtin', '_is_owned', ...Object.keys(msNameMapping)],
        rows: Object.keys(msAugmentations)
          .sort((a, b) => a.localeCompare(b))
          .map((gtin) => ({
            gtin,
            _is_owned: msAugmentations[gtin]._is_owned.toString(),
            ...Object.keys(msNameMapping).reduce((acc, col) => {
              acc[col as Exclude<keyof Augmentations, '_is_owned'>] = msAugmentations[gtin][
                col as keyof Augmentations
              ]?.toString() as string;

              return acc;
            }, {} as Record<Exclude<keyof Augmentations, '_is_owned'>, string>),
          }))
          .filter(({ _is_owned }) => _is_owned.toLowerCase().trim() === 'false')
          .filter((row: Record<string, string>) => {
            return Object.keys(stagedPAFilters).every((filterId) => {
              const values = stagedPAFilters[filterId].split(',');

              return values.includes(row[filterId]);
            });
          }),
        // .slice(
        //   (competitorsTablePage - 1) * competitorsTablePageSize,
        //   competitorsTablePage * competitorsTablePageSize
        // ),
      };

      return competitorsTable;
    }
  );

  const myProductsValues = useMemo(() => {
    if (!myProductsTable) return [];

    return myProductsTable.rows.map(({ gtin, description }) => ({
      value: gtin,
      title: description,
    }));
  }, [myProductsTable]);

  const competitorsValues = useMemo(() => {
    if (!competitorsTable) return [];
    return competitorsTable.rows.map(({ gtin, description }) => ({
      value: gtin,
      title: description,
    }));
  }, [competitorsTable]);

  const memoizedContextValue = useMemo(
    () => ({
      competitorsValues,
      competitorsTable,
      myProductsValues,
      myProductsTable,
      stagedPAFilters,
    }),
    [competitorsTable, competitorsValues, myProductsTable, myProductsValues, stagedPAFilters]
  );

  return (
    <AvailablePAFiltersContext.Provider value={availablePAFilters}>
      <StagedPAFiltersContext.Provider value={memoizedContextValue}>
        <StagedPAFiltersDispatchContext.Provider value={dispatchStagedPAFilters}>
          {children}
        </StagedPAFiltersDispatchContext.Provider>
      </StagedPAFiltersContext.Provider>
    </AvailablePAFiltersContext.Provider>
  );
}

function stagedPAFiltersReducer(
  stagedPAFilters: ProductAttributeFilters,
  stagedPAFiltersAction: StagedPAFiltersAction
) {
  switch (stagedPAFiltersAction.type) {
    case 'add':
      return { ...stagedPAFilters, ...stagedPAFiltersAction.payload };
    case 'deselectAll':
      return { ...stagedPAFilters, [stagedPAFiltersAction.payload]: '' };

    case 'selectCheckbox': {
      const { filterId: selectedId, value: selectedValue } = stagedPAFiltersAction.payload;
      const selectedValues = (stagedPAFilters[selectedId] ?? '').split(',').filter((v) => v);
      const newSelectedValues = [...selectedValues, selectedValue];
      return {
        ...stagedPAFilters,
        [selectedId]: newSelectedValues.join(','),
      };
    }

    case 'deselectCheckbox': {
      const { filterId: deselectedId, value: deselectedValue } = stagedPAFiltersAction.payload;
      const deselectedValues = stagedPAFilters[deselectedId].split(',').filter((v) => v);
      const newDeselectedValues = deselectedValues.filter((value) => value !== deselectedValue);
      return {
        ...stagedPAFilters,
        [deselectedId]: newDeselectedValues.join(','),
      };
    }

    case 'remove': {
      const newStagedPAFilters = cloneDeep(stagedPAFilters);
      delete newStagedPAFilters[stagedPAFiltersAction.payload];
      return newStagedPAFilters;
    }

    default:
      return stagedPAFilters;
  }
}
