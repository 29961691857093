import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Divider } from '../Core/Divider';
import { Flex } from '../Core/Flex';
import { SimpleTextEditor } from '../Core/SimpleTextEditor';
import { Typography } from '../Core/Typography';
import { ElementProps } from '../Element/Element';
import { ELEMENT_BORDER_COLOR, ELEMENT_BORDER_WIDTH } from '../Element/elementConstants';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { ErrorIcon } from '../Icons/ErrorIcon';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { KnowledgeBaseButton } from './ToolbarButtons/KnowledgeBaseButton';

interface ElementToolbarProps {
  elementMap: Record<string, JSX.Element>;
  id: string;
  title?: string;
  toolbarControls: ReactNode;
}

export function ElementToolbar({ elementMap, id, title, toolbarControls }: ElementToolbarProps) {
  const { t } = useTranslation();
  const { pageId, tabId } = useParams();
  const { updateElement } = useCollectionMutations();
  const { presentationMode } = useAuth();
  const { isStreaming } = useTalkingPointsPage();

  const isChatBox = id === 'chatCpg';

  const element = (elementMap[id]?.props as ElementProps)?.element;
  let isLargeToolbar = false;
  let isChatCpgElement = false;

  if (element) {
    isLargeToolbar = element.elementDef.key === 'insightCard';
    isChatCpgElement = !!element.elementDef.settings?.isChatCpg;
  }

  return (
    <>
      <Flex align="center" justify="space-between" width="100%">
        <Flex
          grow="1"
          align="center"
          gap={isLargeToolbar ? '1rem' : '0.6rem'}
          style={{ minHeight: '22px', maxWidth: 'calc(100% - 20px)' }}
        >
          {(isChatCpgElement || isChatBox) && (
            <ChatCpgIcon size={isLargeToolbar ? '28px' : '18px'} />
          )}
          {isChatBox ? (
            <Typography
              as="h2"
              variant={isLargeToolbar ? 'h-22-b' : 'h-14-b'}
              style={{
                borderRadius: '6px',
                cursor: presentationMode || isChatBox ? 'unset' : 'text',
                maxWidth: 'calc(100% - 40px)',
              }}
            >
              {t('chatCpg')}
            </Typography>
          ) : (
            <SimpleTextEditor
              as="h2"
              initialValue={title ?? t('util.untitled')}
              onSuccess={(value) => {
                updateElement({
                  element: {
                    ...element,
                    title: value,
                  },
                  pageId,
                  tabId,
                });
              }}
              disabled={isStreaming || presentationMode}
              variant={isLargeToolbar ? 'h-22-b' : 'h-14-b'}
              style={{ cursor: 'text' }}
            />
          )}
          {id === 'chatCpg' && <KnowledgeBaseButton />}
        </Flex>
        {!presentationMode && toolbarControls}
      </Flex>
      {title && title?.length > 250 && (
        <Flex width="100%" align="center" gap="0.4rem">
          <ErrorIcon color="var(--danger)" size="18px" />
          <Typography color="var(--danger)">{title.length}/250</Typography>
        </Flex>
      )}

      <Divider solid stroke={ELEMENT_BORDER_WIDTH} color={ELEMENT_BORDER_COLOR} />
    </>
  );
}
