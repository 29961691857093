import { MultiCard } from '../helpers/biCardHelpers';
import { NewsPost } from './NewsPost';

interface BiCardProps {
  card: MultiCard;
  report?(): JSX.Element;
}

export function BiPost({ card, report }: BiCardProps) {
  switch (card.key) {
    case 'news':
      return <NewsPost news={card.payload} report={report} />;
    default:
      return <></>;
  }
}
