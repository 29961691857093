import { Typography } from '../../Core/Typography';

interface ScoreCellProps {
  className?: string;
  score: number;
}

export function ScoreCell({ className, score }: ScoreCellProps) {
  return (
    <Typography as="p" variant="h-16-b" className={className} textAlign="right">
      {score.toFixed()}
      <Typography as="span" variant="p-12-r" color="var(--text-secondary)">
        /100
      </Typography>
    </Typography>
  );
}
