import { createContext } from 'react';
import { SolutionType } from '../../ChatCpg/types/chatCpgTypes';

export interface IdResponse {
  id: string;
}

type Model = 'davinci' | 'ada' | 'gpt-4';

export interface CreateAnalysisProps {
  gtins: string[];
  summaryName: SolutionType;
  banner?: string;
  channel?: string;
  model?: Model;
  weeks?: number;
  dataset?: string;
}

export interface PostChatProps {
  workflowId: string;
  message: string;
  model?: Model;
  weeks?: number;
}

export type FeedbackLabel = 'good' | 'bad';
export type FeedbackType = 'headline' | 'summary';

interface SharedFeedbackProps {
  category: string;
  explanation: string;
  feedback: string;
  label: FeedbackLabel;
}

export interface ChatFeedbackProps extends SharedFeedbackProps {
  chatMessageId: string;
}

export interface TextFeedbackProps extends SharedFeedbackProps {
  talkingPointId: string;
  type: FeedbackType;
  workflowId: string;
}

export interface PostFileProps {
  file: File;
  workflowId: string;
}

export type FeedbackResponse = unknown;

export interface ChatCpgMutationsContextProps {
  createAnalysis(props: CreateAnalysisProps): Promise<IdResponse>;
  postChat(props: PostChatProps): Promise<IdResponse>;
  postChatFeedback(props: ChatFeedbackProps): Promise<FeedbackResponse>;
  postSummaryFeedback(props: TextFeedbackProps): Promise<FeedbackResponse>;
  postFile(props: PostFileProps): Promise<IdResponse>;
}

export const ChatCpgMutationsContext = createContext<ChatCpgMutationsContextProps>({
  createAnalysis: async () => ({ id: '' }),
  postChat: async () => ({ id: '' }),
  postChatFeedback: async () => ({} as FeedbackResponse),
  postSummaryFeedback: async () => ({} as FeedbackResponse),
  postFile: async () => ({ id: '' }),
});
