import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { ProductCellProps } from './ProductCell';

export function SimpleProductCell({ name, id, style }: ProductCellProps) {
  return (
    <Flex direction="column" gap="0.2rem" style={style}>
      <Typography variant="p-14-b">GTIN {id}</Typography>
      <Typography variant="p-12-r" color="var(--text-secondary)">
        {name}
      </Typography>
    </Flex>
  );
}
