import styled from '@emotion/styled';

interface MediaImageProps {
  src: string;
  alt: string;
  className?: string;
}

const StyledImage = styled.img`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

export function MediaImage({ src, alt }: MediaImageProps) {
  return <StyledImage src={src} alt={alt} />;
}
