import { fetchEventSource } from '@microsoft/fetch-event-source';
import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ChatCpgEvent } from '../../ChatCpg/types/chatCpgEventType';
import {
  OptimizePricingTalkingPoints,
  SubstitutionsTalkingPoints,
} from '../../ChatCpg/types/chatCpgTypes';
import {
  CompleteCompetitors,
  CompleteHistoric,
  CompleteInflation,
  CompleteRecommendations,
} from '../../ChatCpg/types/optimizePricingTypes';
import { CompleteComplements, CompleteSubstitutions } from '../../ChatCpg/types/substitutionsTypes';
import { TickrPage } from '../../Element/types/elementTypes';
import { replaceNewlineWithBr, shortenGtin } from '../../helpers/stringHelpers';
import { useChatCpgStore } from '../../hooks/useChatCpgStore';
import { useReplaceReportingWeek } from '../../hooks/useReplaceReportingWeek';
import { useReplaceTimestamp } from '../../hooks/useReplaceTimestamp';
import { useApp } from '../AppProvider/useApp';
import { useCollectionMutations } from '../CollectionMutationsProvider/useCollectionMutations';
import { TalkingPointsPageContext } from './TalkingPointsContext';

interface TalkingPointsPageProviderProps {
  page: TickrPage;
}

export function TalkingPointsPageProvider({
  page,
  children,
}: PropsWithChildren<TalkingPointsPageProviderProps>) {
  const { chatCpgUrl: chatCpgPath } = useApp();
  const replaceTimestamp = useReplaceTimestamp();
  const replaceReportingWeek = useReplaceReportingWeek();
  const { updatePage } = useCollectionMutations();

  const { workflowId = '', updatedAt } = page.settings;

  const [isStreaming, setIsStreaming] = useState(!updatedAt);

  const updateStore = useChatCpgStore((state) => state.updateStore);
  const updateStreamText = useChatCpgStore((state) => state.updateStreamText);
  const clearStreamText = useChatCpgStore((state) => state.clearStreamText);

  const formatText = (text?: string) =>
    replaceNewlineWithBr(replaceTimestamp(replaceReportingWeek(shortenGtin(text))));

  useQuery(
    ['workflow-stream', workflowId],
    async () => {
      const controller = new AbortController();

      const fetchData = async () => {
        await fetchEventSource(`${chatCpgPath}/summary/${workflowId}`, {
          method: 'GET',
          openWhenHidden: true,
          onopen: async () => {
            console.log('OPEN CONNECTION');
          },
          onclose: () => {
            console.log('CLOSE CONNECTION');
          },
          onerror: (error) => {
            captureException(error);
            clearStreamText(workflowId);
            // controller.abort();
          },
          signal: controller.signal,
          onmessage(event) {
            if (event.event === 'ping' || event.data === '') return;

            let eventData: ChatCpgEvent;

            try {
              eventData = JSON.parse(event.data);
            } catch (error) {
              captureException(error);
              return;
            }

            if ('meta' in eventData) {
              switch (eventData.meta) {
                case 'ACK':
                  break;
                case 'DONE':
                  if ('type' in eventData) {
                    switch (eventData.type) {
                      case 'headline':
                        updateStreamText([`${eventData.talking_point}-headline`, workflowId], {
                          text: '',
                          isStreaming: false,
                        });

                        break;
                      case 'text':
                        updateStreamText([`${eventData.talking_point}-insight`, workflowId], {
                          text: '',
                          isStreaming: false,
                        });

                        break;
                    }
                  } else {
                    setIsStreaming(false);
                  }

                  break;
                case 'COMPLETE':
                  if ('competitors' in eventData.data) {
                    const { data } = eventData;

                    const gtin = Object.keys(eventData.data.competitors).filter(
                      (key) => key !== 'headline'
                    )[0];

                    (Object.keys(data) as Exclude<OptimizePricingTalkingPoints, 'intro'>[]).forEach(
                      (talkingPoint) => {
                        updateStore([`${talkingPoint}-headline`, workflowId], {
                          isStreaming: false,
                          text: formatText(data[talkingPoint].headline),
                        });
                      }
                    );

                    updateStore(['historic-insight', workflowId], {
                      isStreaming: false,
                      text: formatText((data.historic[gtin] as CompleteHistoric)?.summary),
                    });

                    updateStore(['recommendations-insight', workflowId], {
                      isStreaming: false,
                      text: formatText(
                        (data.recommendations[gtin] as CompleteRecommendations)?.recommendations
                      ),
                    });

                    updateStore(
                      ['pvs-chart', workflowId],
                      (data.historic[gtin] as CompleteHistoric)?.['pvs-chart']
                    );

                    updateStore(
                      ['price-chart', workflowId],
                      (data.competitors[gtin] as CompleteCompetitors)?.['price-chart']
                    );

                    updateStore(
                      ['influencers', workflowId],
                      (data.competitors[gtin] as CompleteCompetitors)?.influencers
                    );

                    updateStore(
                      ['inflation-chart', workflowId],
                      (data.inflation[gtin] as CompleteInflation)?.['inflation-chart']
                    );

                    updateStore(
                      ['range-data', workflowId],
                      (data.recommendations[gtin] as CompleteRecommendations)?.['range-data']
                    );

                    updateStore(
                      ['recommended-price', workflowId],
                      (data.recommendations[gtin] as CompleteRecommendations)?.['recommended-price']
                    );

                    updateStore(
                      ['price-data', workflowId],
                      (data.recommendations[gtin] as CompleteRecommendations)?.['price-data']
                    );

                    setIsStreaming(false);
                  } else if ('substitutions' in eventData.data) {
                    const { data } = eventData;

                    const gtin = Object.keys(eventData.data.substitutions).filter(
                      (key) => key !== 'headline'
                    )[0];

                    (Object.keys(data) as Exclude<SubstitutionsTalkingPoints, 'intro'>[]).forEach(
                      (talkingPoint) => {
                        updateStore([`${talkingPoint}-headline`, workflowId], {
                          isStreaming: false,
                          text: data[talkingPoint].headline,
                        });
                      }
                    );

                    updateStore(['substitution-data', workflowId], {
                      myProduct: (data.substitutions[gtin] as CompleteSubstitutions)?.[
                        'substitutions-data'
                      ].my_product,
                      secondaryProducts: (data.substitutions[gtin] as CompleteSubstitutions)?.[
                        'substitutions-data'
                      ].top_substitutions,
                    });

                    updateStore(['complement-data', workflowId], {
                      myProduct: (data.complements[gtin] as CompleteComplements)?.[
                        'complements-data'
                      ].my_product,
                      secondaryProducts: (data.complements[gtin] as CompleteComplements)?.[
                        'complements-data'
                      ].top_complements,
                    });
                  }

                  break;

                case 'ERROR':
                  // TODO: handle error
                  console.log('ERROR', { eventData });
                  captureException(eventData);
                  break;
              }
            } else {
              switch (eventData.type) {
                case 'headline':
                  updateStreamText(
                    [`${eventData.talking_point}-headline`, workflowId],
                    {
                      text: eventData.text,
                      isStreaming: true,
                    },
                    formatText
                  );

                  break;
                case 'text':
                  updateStreamText(
                    [`${eventData.talking_point}-insight`, workflowId],
                    {
                      text: eventData.text,
                      isStreaming: true,
                    },
                    formatText
                  );

                  break;
                case 'inflation-chart':
                  updateStore(['inflation-chart', workflowId], eventData.data);
                  break;
                case 'influencers':
                  updateStore(['influencers', workflowId], eventData.data);
                  break;
                case 'price-data':
                  updateStore(['price-data', workflowId], eventData.data);
                  break;
                case 'price-chart':
                  updateStore(['price-chart', workflowId], eventData.data);
                  break;
                case 'pvs-chart':
                  updateStore(['pvs-chart', workflowId], eventData.data);
                  break;
                case 'range-data':
                  updateStore(['range-data', workflowId], eventData.data);
                  break;
                case 'recommended-price':
                  updateStore(['recommended-price', workflowId], eventData.data);
                  break;
                case 'substitutions-data':
                  updateStore(['substitution-data', workflowId], {
                    myProduct: eventData.data.my_product,
                    secondaryProducts: eventData.data.top_substitutions,
                  });

                  break;
                case 'complements-data':
                  updateStore(['complement-data', workflowId], {
                    myProduct: eventData.data.my_product,
                    secondaryProducts: eventData.data.top_complements,
                  });

                  break;
                default:
                  console.log({ unknown: eventData });
                  break;
              }
            }
          },
        });
      };

      fetchData();
      return true;
    },
    {
      staleTime: Infinity,
      enabled: !!workflowId && workflowId !== 'test',
    }
  );

  useEffect(() => {
    if (!isStreaming && !page?.settings.updatedAt) {
      const updatedAt = Date.now();

      const pageToUpdate: TickrPage = {
        ...page,
        settings: {
          ...page.settings,
          updatedAt,
        },
      };

      updatePage({ page: pageToUpdate });
    }
  }, [isStreaming, updatePage, page]);

  const chatCpgPageValues = useMemo(
    () => ({
      isStreaming,
      settings: page.settings,
      workflowId,
    }),
    [isStreaming, workflowId, page.settings]
  );

  return (
    <TalkingPointsPageContext.Provider value={chatCpgPageValues}>
      {children}
    </TalkingPointsPageContext.Provider>
  );
}
