import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function LogoutIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
        <path
          d="M9 18C12.866 18 16 14.866 16 11C16 8.2076 14.3649 5.79709 12 4.67363V2.51212C15.4956 3.74762 18 7.08134 18 11C18 15.9706 13.9706 20 9 20C4.02944 20 0 15.9706 0 11C0 7.08134 2.50442 3.74762 6 2.51212V4.67363C3.63505 5.79709 2 8.2076 2 11C2 14.866 5.13401 18 9 18Z"
          fill="white"
        />
        <rect x="8" width="2" height="9" fill="white" />
      </svg>
    </IconWrapper>
  );
}
