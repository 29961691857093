import dayjs, { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useCallback, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import '../../../styles/DatePicker.css';
import { Flex } from '../../Core/Flex';
import { RadioButton } from '../../Core/RadioButton';
import { Typography } from '../../Core/Typography';
import { useAvailableFilters } from '../AvailableFiltersProvider/useAvailableFilters';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';
import { RadioFilterDisplay, RadioFilterItem } from './RadioFilterDisplay';

extend(customParseFormat);
extend(utc);

export interface CustomDateValue {
  from: string;
  to: string;
}

function formatFrom(date: Date) {
  return date != undefined && dayjs(date).isValid()
    ? dayjs(date).startOf('day').toISOString()
    : dayjs().subtract(1, 'd').startOf('day').toISOString();
}

function formatTo(date: Date) {
  return date != undefined && dayjs(date).isValid()
    ? dayjs(date).endOf('day').toISOString()
    : dayjs().endOf('day').toISOString();
}

const today = new Date();
const yesterday = new Date(new Date().setDate(today.getDate() - 1));

export function DatesFilter() {
  const { t } = useTranslation();
  const { stagedFilters } = useStagedFilters();
  const dispatchStagedFilters = useStagedFiltersDispatch();
  const { universalFilters } = useAvailableFilters();

  const selected = stagedFilters.dates;

  const setFilter = useCallback(
    (value: string | CustomDateValue) => {
      dispatchStagedFilters({ type: 'add', payload: { dates: value } });
    },
    [dispatchStagedFilters]
  );

  const [startDate, setStartDate] = useState(
    typeof selected !== 'string' && selected?.from
      ? dayjs(selected?.from).startOf('day').toDate()
      : yesterday
  );

  const [endDate, setEndDate] = useState(
    typeof selected !== 'string' && selected?.to
      ? dayjs(selected?.to).startOf('day').toDate()
      : today
  );

  const customDateValue = useMemo(
    () => ({
      from: formatFrom(startDate),
      to: formatTo(endDate),
    }),
    [startDate, endDate]
  );

  return (
    <RadioFilterDisplay
      selected={selected as string}
      values={universalFilters.dates.values as RadioFilterItem[]}
      onChange={setFilter}
    >
      <Flex direction="column" gap="1rem">
        <RadioButton
          key={t('filters.dates.custom')}
          title={t('filters.dates.custom')}
          description={t('filters.dates.custom.select')}
          checked={Boolean(
            (selected as CustomDateValue)?.to && (selected as CustomDateValue)?.from
          )}
          onChange={() => setFilter?.(customDateValue)}
        />
        <Flex direction="column" padding="0 0 0 calc(16px + 0.8rem)">
          <Typography variant="c-10" style={{ paddingBottom: '0.2rem' }}>
            {t('util.startDate')}
          </Typography>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => {
              setFilter({ from: formatFrom(date), to: formatTo(endDate) });
              setStartDate(date);
            }}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            minDate={new Date('2020-01-01')}
            maxDate={endDate}
            showPopperArrow={false}
            popperModifiers={[
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  altAxis: true,
                },
              },
            ]}
          />
          <Typography variant="c-10" style={{ paddingBottom: '0.2rem' }}>
            {t('util.endDate')}
          </Typography>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => {
              setFilter({ from: formatFrom(startDate), to: formatTo(date) });
              setEndDate(date);
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            showPopperArrow={false}
            popperModifiers={[
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  altAxis: true,
                },
              },
            ]}
          />
        </Flex>
      </Flex>
    </RadioFilterDisplay>
  );
}
