import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface MediaVideoProps {
  src: string;
  className?: string;
}

const StyledVideo = styled.video`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

export function MediaVideo({ src }: MediaVideoProps) {
  const { t } = useTranslation();

  return (
    <StyledVideo controls>
      <source src={src} />
      {t('video.fallback')}
    </StyledVideo>
  );
}
