import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { HundredQuestionsDialogContent } from './HundredQuestionsDialogContent';

interface HundredQuestionsDialogProps {
  onClose: () => void;
}

export function HundredQuestionsDialog({
  onClose,
  children,
}: PropsWithChildren<HundredQuestionsDialogProps>) {
  return (
    <DialogProvider dismissOptions={{ outsidePress: false }}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent onClickOverlay={onClose}>
        <HundredQuestionsDialogContent onClose={onClose} />
      </DialogContent>
    </DialogProvider>
  );
}
