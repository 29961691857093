import styled from '@emotion/styled';
import isFinite from 'lodash/isFinite';
import { CSSProperties } from 'react';
import { NumberStyle, numberStyleHelper } from '../helpers/numberStyleHelper';
import { roundToTenths } from '../helpers/roundToTenths';
import { NumberFormatter } from './NumberFormatter';
import { Typography, TypographyProps } from './Typography';

interface ChangeIndicatorProps {
  allNeutral?: boolean;
  borderColor?: string;
  customSuffix?: string;
  invertColor?: boolean;
  minWidth?: string;
  numberStyle: NumberStyle;
  nowrap?: boolean;
  style?: CSSProperties;
  suffix?: string;
  textColor?: string;
  val?: number;
}

export function ChangeIndicator({
  allNeutral,
  borderColor: borderColorProp,
  customSuffix,
  invertColor = false,
  minWidth,
  nowrap = true,
  numberStyle,
  style,
  suffix,
  textColor = 'var(--text)',
  val = NaN,
}: ChangeIndicatorProps) {
  const roundedVal = roundToTenths(val * 100);
  const isPositive = roundedVal != 0 ? (invertColor ? val < 0 : val > 0) : false;
  const isNegative = roundedVal != 0 ? (invertColor ? val > 0 : val < 0) : false;

  let borderColor: string;

  if (isFinite(roundedVal) && isNegative) {
    borderColor = 'var(--negative)';
  } else if (isFinite(roundedVal) && isPositive) {
    borderColor = 'var(--positive)';
  } else {
    borderColor = 'var(--space)';
  }

  if (allNeutral) {
    borderColor = 'var(--space)';
  }

  return (
    <StyledTypography
      data-testid="change-value"
      variant="n-12-b"
      borderColor={!isNaN(val) ? borderColorProp ?? borderColor : 'transparent'}
      minWidth={minWidth}
      nowrap={nowrap}
      style={style}
    >
      <NumberFormatter
        as="span"
        color={textColor}
        customSuffix={customSuffix}
        numberStyle={numberStyleHelper(val, numberStyle).addNumberOptions({ showChange: true })}
        showChange
        val={val}
      />
      {suffix && (
        <Typography as="span" color={textColor} variant="p-12-i" style={{ paddingLeft: '.5rem' }}>
          {suffix}
        </Typography>
      )}
    </StyledTypography>
  );
}

interface StyledTypographyProps extends TypographyProps {
  borderColor: string;
  minWidth?: string;
}

const StyledTypography = styled(Typography)<StyledTypographyProps>`
  min-width: ${({ minWidth }) => minWidth};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 6px;
  padding: 2px 8px;
  width: fit-content;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
