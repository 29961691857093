import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { useAvailableFilters } from '../../Filters/AvailableFiltersProvider/useAvailableFilters';
import { CheckboxFilterItem } from '../../Filters/Components/CheckboxFilterDisplay';
import { FormElementWrapper } from '../../FormElements/FormElementWrapper';
import { SelectDropdown } from '../../FormElements/SelectDropdown';
import { SelectDropdownContent } from '../../FormElements/SelectDropdownContent';
import { TextInput } from '../../FormElements/TextInput';
import { AddIcon } from '../../Icons/AddIcon';
import { useNotificationsDispatch } from '../../Providers/NotificationsProvider/useNotificationsDispatch';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';
import { useYupValidation } from '../../hooks/useYupValidation';

interface NewAlertDialogContentProps {
  onClose: () => void;
}

type AlertConditionType = 'above' | 'below' | 'outsideOfNormalRange' | 'salesOpportunityOrThreat';

export function NewAlertDialogContent({ onClose }: NewAlertDialogContentProps) {
  const { t } = useTranslation();
  const { string255 } = useYupValidation();
  const dispatchNotification = useNotificationsDispatch();
  const { sendAlert } = useToaster();

  const { datasourceFilters } = useAvailableFilters();

  const alertConditionPlaceholderDict: Record<AlertConditionType, string> = {
    above: t('alerts.condition.above.placeholder'),
    below: t('alerts.condition.below.placeholder'),
    outsideOfNormalRange: t('alerts.condition.outsideOfNormalRange.placeholder'),
    salesOpportunityOrThreat: t('alerts.condition.salesOpportunityOrThreat.placeholder'),
  };

  return (
    <Formik
      initialValues={{
        metric: '$sales',
        scopeCategory: '',
        scopeChannel: '',
        conditionType: 'above' as AlertConditionType,
        conditionValue: '',
        alertChannel: 'email',
        frequency: 'sendImmediately',
        alertName: '',
      }}
      onSubmit={async (values) => {
        onClose();

        sendAlert({
          severity: 'success',
          text: t('alerts.alertCreated'),
        });

        setTimeout(() => {
          dispatchNotification((notifications) => [
            {
              author: 'chatCpg',
              alertName: values.alertName,
              pageId: 123,
              timestamp: Date.now(),
              id: '69',
              to: '/home',
              text: 'GTIN ...5346’s price of $5.24 on the West Region for Kroger, is forecasted to become suboptimal over the next 12 weeks. Decrease the price by $0.15 to increase market share .3% and increase sales by 5%',
              isNew: true,
              fakeTimestamp: '0 sec ago',
            },
            ...notifications,
          ]);
        }, 5000);
      }}
      validationSchema={Yup.object().shape({
        alertName: string255,
      })}
    >
      {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
        <>
          <DialogTitle title={t('alerts.createNewAlert')} showX onClose={onClose} />
          <DialogBody style={{ overflow: 'visible', paddingBottom: '2rem' }}>
            <Flex column width="100%" gap="2rem">
              <SelectDropdown
                title={t('alerts.selectMetric')}
                values={[
                  { title: t('alerts.metrics.$sales'), value: '$sales' },
                  { title: t('alerts.metrics.volume'), value: 'volume' },
                  { title: t('alerts.metrics.$share'), value: '$share' },
                  { title: t('alerts.metrics.avgPrice'), value: 'avgPrice' },
                  { title: t('alerts.metrics.recommendedPrice'), value: 'recommendedPrice' },
                ]}
                onChange={(value) => setFieldValue('metric', value)}
                selected={values.metric}
                width="200px"
              />

              <FormElementWrapper title={t('alerts.scope')}>
                <Flex gap="0.5rem" align="center" width="100%">
                  <SelectDropdownContent
                    values={[
                      { value: 'all', title: 'All Categories' },
                      { value: 'cereal', title: 'Cereal' },
                      { value: 'flavoredDrinks', title: 'Flavored Drinks' },
                      { value: 'functionalDrinks', title: 'Functional Drinks' },
                      { value: 'organicFood', title: 'Organic Food' },
                      { value: 'personalCare', title: 'Personal Care' },
                      { value: 'nonSeasonalCandy', title: 'Non Seasonal Candy' },
                    ]}
                    placeholder={t('alerts.scope.selectCategory')}
                    selected={values.scopeCategory}
                    onChange={(value) => setFieldValue('scopeCategory', value)}
                    width="250px"
                  />
                  {datasourceFilters.numerator?.cpg_banner && (
                    <SelectDropdownContent
                      values={
                        datasourceFilters.numerator?.cpg_banner.values as CheckboxFilterItem[]
                      }
                      placeholder={t('alerts.scope.selectChannel')}
                      selected={values.scopeChannel}
                      onChange={(value) => setFieldValue('scopeChannel', value)}
                      width="250px"
                    />
                  )}
                  <Flex as="button" gap="0.5rem" align="center" padding="0 0 0 12px">
                    <AddIcon color="var(--action)" />
                    <Typography variant="button-10" color="var(--action)" nowrap>
                      {t('alerts.addFilter')}
                    </Typography>
                  </Flex>
                </Flex>
              </FormElementWrapper>

              <Flex gap="0.5rem">
                <SelectDropdown
                  title={t('alerts.conditionType')}
                  error={errors.conditionType}
                  values={[
                    {
                      title: t('alerts.condition.above'),
                      value: 'above',
                      // 'iconId: 'above'
                    },
                    {
                      title: t('alerts.condition.below'),
                      value: 'below',
                      // iconId: 'above',
                      // iconTransform: 'rotateX(180deg)',
                    },
                    {
                      title: t('alerts.condition.outsideOfNormalRange'),
                      value: 'outsideOfNormalRange',
                      // iconId: 'outsideOfNormalRange',
                    },
                    {
                      title: t('alerts.condition.salesOpportunityOrThreat'),
                      value: 'salesOpportunityOrThreat',
                      // iconId: 'salesOpportunity',
                    },
                  ]}
                  selected={values.conditionType}
                  onChange={(value) => setFieldValue('conditionType', value)}
                  width="250px"
                />
                <TextInput
                  title={t('alerts.conditionValue')}
                  id="conditionValue"
                  name="conditionValue"
                  error={errors.conditionValue}
                  value={values.conditionValue}
                  onChange={handleChange}
                  width="250px"
                  placeholder={alertConditionPlaceholderDict[values.conditionType]}
                />
              </Flex>
              <Flex gap="0.5rem" height="100%">
                <SelectDropdown
                  title={t('alerts.alertChannel')}
                  error={errors.alertChannel}
                  values={[
                    {
                      title: t('util.email'),
                      value: 'email',
                      //  iconId: 'mail'
                    },
                  ]}
                  selected={values.alertChannel}
                  onChange={(value) => setFieldValue('alertChannel', value)}
                  width="250px"
                />
                <SelectDropdown
                  title={t('alerts.alertFrequency')}
                  error={errors.frequency}
                  values={[
                    { title: t('alerts.frequency.sendImmediately'), value: 'sendImmediately' },
                    { title: t('alerts.frequency.dailySummary'), value: 'dailySummary' },
                    { title: t('alerts.frequency.weeklySummary'), value: 'weeklySummary' },
                    { title: t('alerts.frequency.earlyWarnings'), value: 'earlyWarnings' },
                  ]}
                  selected={values.frequency}
                  onChange={(value) => setFieldValue('frequency', value)}
                  width="250px"
                />
              </Flex>
              <TextInput
                id="alertName"
                name="alertName"
                title={t('alerts.alertName')}
                error={errors.alertName}
                value={values.alertName}
                onChange={handleChange}
                width="508px"
                max={255}
                placeholder={t('alerts.alertName.placeholder')}
              />
            </Flex>
          </DialogBody>
          <DialogFooter>
            <DialogTrigger>
              <Button
                width="200px"
                color="var(--trout)"
                onClick={() => {
                  onClose();
                }}
              >
                {t('util.cancel')}
              </Button>
            </DialogTrigger>
            <Button width="200px" onClick={() => handleSubmit()} type="submit">
              {t('util.create')}
            </Button>
          </DialogFooter>
        </>
      )}
    </Formik>
  );
}
