import { PropsWithChildren, useState } from 'react';
import { TabContext } from './TabsContext';

export function TabsProvider({ children }: PropsWithChildren) {
  const [activeTab, setActiveTab] = useState(0);

  const [tabWidth, setTabWidth] = useState(0);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab, tabWidth, setTabWidth }}>
      {children}
    </TabContext.Provider>
  );
}
