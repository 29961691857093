import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { TickrPage } from '../../Element/types/elementTypes';
import { PageSettingsDialogContent } from './PageSettingsDialogContent';

interface PageSettingsDialogProps {
  page: TickrPage;
  isNested?: boolean;
  onClose?: () => void;
}

export function PageSettingsDialog({
  page,
  onClose,
  isNested,
  children,
}: PropsWithChildren<PageSettingsDialogProps>) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent
        variant="sm"
        overlayStyles={isNested ? { background: 'transparent' } : {}}
        onClickOverlay={onClose}
      >
        <PageSettingsDialogContent page={page} onClose={onClose} />
      </DialogContent>
    </DialogProvider>
  );
}
