import { PropsWithChildren, forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const NotificationIcon = forwardRef<HTMLDivElement, PropsWithChildren<IconProps>>(
  ({ children, ...props }, ref) => {
    return (
      <IconWrapper ref={ref} {...props}>
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
          <path
            d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20ZM14 14V9C14 5.93 12.37 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.64 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14ZM12 15H4V9C4 6.52 5.51 4.5 8 4.5C10.49 4.5 12 6.52 12 9V15Z"
            fill="white"
          />
        </svg>
        {children}
      </IconWrapper>
    );
  }
);
