import styled from '@emotion/styled';
import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { FeedbackControls } from '../ChatCpg/FeedbackControls';
import { StreamCursor } from '../ChatCpg/StreamCursor';
import { Flex } from '../Core/Flex';
import { RichTextEditor } from '../Core/RichTextEditor';
import { Typography } from '../Core/Typography';
import { InsightCardPayload } from '../Element/types/elementTypes';
import { useElement } from '../Element/useElement';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { useCurrentPage } from '../hooks/useCurrentPage';

type InsightCardProps = InsightCardPayload;

export function InsightCard({
  background = 'var(--clay)',
  focusBackground = 'var(--black-50)',
  iconBackground = 'inherit',
  iconHoverBackground = 'var(--trout)',
  updatedText,
  talkingPoint,
}: InsightCardProps) {
  const element = useElement();
  const { pageId, tabId } = useParams();
  const currentPage = useCurrentPage();

  const { updateElement } = useCollectionMutations();

  const { settings, isStreaming, workflowId } = useTalkingPointsPage();

  const insightStream = useChatCpgData<StreamingText>(
    [`${talkingPoint}-insight`, workflowId],
    shallow
  ) ?? {
    text: '',
    isStreaming: false,
  };

  if (!settings || !currentPage) return <></>;

  const { updatedAt: originalTimestamp } = settings;

  const initialText = updatedText?.text || `<p>${insightStream.text}</p>`;

  return (
    <StyledFlex column padding="8px" gap="12px" width="100%" height="100%" background={background}>
      {insightStream.isStreaming ? (
        <Flex grow={1} overflow="auto">
          <Typography>
            <span className="p-14-b" dangerouslySetInnerHTML={{ __html: insightStream.text }} />
            <StreamCursor />
          </Typography>
        </Flex>
      ) : (
        <RichTextEditor
          disabled={isStreaming}
          focusBackground={focusBackground}
          initialValue={initialText}
          onSuccess={async (text) => {
            try {
              await updateElement({
                element: {
                  ...element,
                  elementDef: {
                    ...element.elementDef,
                    key: 'insightCard',
                    payload: {
                      ...(element.elementDef.payload as InsightCardProps),
                      updatedText: {
                        text,
                        updatedAt: Date.now(),
                      },
                    },
                  },
                },
                tabId,
                pageId,
              });
            } catch (e) {
              captureException(e);
            }
          }}
          style={{ overflow: 'scroll', flexGrow: 1 }}
          variant="p-14-b"
        />
      )}
      <FeedbackControls
        feedbackType="summary"
        iconBackground={iconBackground}
        iconHoverBackground={iconHoverBackground}
        isEdited={!!updatedText?.updatedAt}
        isStreaming={isStreaming}
        onRestore={
          (element?.elementDef.payload as InsightCardProps)?.updatedText?.updatedAt
            ? () => {
                if (!tabId) return;

                const elementToUpdate = cloneDeep(element);

                delete (elementToUpdate.elementDef.payload as InsightCardProps).updatedText;

                updateElement({
                  element: elementToUpdate,
                  tabId,
                  pageId,
                });
              }
            : undefined
        }
        style={{
          padding: '8px',
        }}
        talkingPointId={tabId}
        text={initialText}
        timestamp={updatedText?.updatedAt || originalTimestamp}
      />
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{ background?: string }>`
  min-height: 0;
  overflow: auto;
  background: ${({ background }) => background};
  border-radius: 0 0 6px 6px;
`;
