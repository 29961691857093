import styled from '@emotion/styled';
import { Formik } from 'formik';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { LanguageIcon } from '../../Icons/LanguageIcon';
import { PickerDownIcon } from '../../Icons/PickerDownIcon';
import { PickerUpIcon } from '../../Icons/PickerUpIcon';
import { useApi } from '../../Providers/ApiProvider/useApi';
import { useToaster } from '../../Providers/ToasterProvider/useToaster';

export interface InputValue {
  value: string | number;
}

export interface DropdownValue {
  title: string;
  value: string | number;
}

interface ShareInputAndDropdownProps {
  selected?: string | number;
  description?: string;
  error?: string;
  width?: string;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
}

export function ShareInputAndDropdown({
  selected = '',
  width = '100%',
  onChange,
  disabled = false,
}: ShareInputAndDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const api = useApi();
  const { sendAlert } = useToaster();

  interface SharedPageProps {
    recipientEmail: string;
    pageId: number;
  }

  interface PermOption {
    title: string;
    value: string;
  }

  interface SharedWithProps {
    email: string;
    perm: string;
  }

  const sharePage = async (share: SharedPageProps) => {
    await api.post('v2/poplar/tshares', {
      ...share,
    });
  };

  const permissionOptions: PermOption[] = [
    {
      title: t('sharingPopover.permissions.canEdit'),
      value: 'canEdit',
    },
    {
      title: t('sharingPopover.permissions.canView'),
      value: 'canView',
    },
  ];

  // Temporary... Use local storage.
  const [sharedWith, setSharedWith] = useLocalStorage<SharedWithProps[]>('sharedWith', [
    {
      email: 'marosi@tickr.com',
      perm: 'canEdit',
    },
  ]);

  const optionsWrapperRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  return (
    <Flex
      width={width}
      height="100%"
      direction="column"
      padding="0.5rem"
      style={{
        backgroundColor: 'var(--clay)',
        borderRadius: 'var(--corner)',
      }}
    >
      <Flex width="100%" height="100%" margin="4px">
        <Typography as="h3" variant="c-14">
          {t('sharingPopover.exportFormat.shareInTickr')}
        </Typography>
      </Flex>

      <Formik
        initialValues={{
          sharedWith,
          email: '',
          selectedPerm: { ...permissionOptions[0] },
        }}
        onSubmit={() => {
          setIsOpen(false);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Flex width="100%" style={{ marginTop: '5px', marginBottom: '5px' }}>
              <StyledInput
                as="input"
                width="50%"
                value={values.email}
                placeholder={t('sharingPopover.emailField')}
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                }}
                disabled={disabled}
              />
              <StyledSelect
                as="button"
                width="25%"
                align="center"
                justify="space-between"
                onClick={() => setIsOpen((isOpen) => !isOpen)}
                disabled={disabled}
                ref={selectRef}
              >
                <Typography
                  variant={values.selectedPerm.title ? 'h-12-b' : 'p-12-i'}
                  style={{ fontSize: '10px', whiteSpace: 'nowrap' }}
                >
                  {values.selectedPerm.title ?? t('sharingPopover.permissions.canEdit')}
                </Typography>
                {isOpen ? <PickerUpIcon size="12px" /> : <PickerDownIcon size="12px" />}
              </StyledSelect>
              {isOpen && (
                <StyledOptionsWrapper
                  ref={optionsWrapperRef}
                  direction="column"
                  width="100%"
                  padding="0.5rem"
                >
                  {permissionOptions.map(({ title, value }) => (
                    <StyledOption
                      key={value}
                      as="button"
                      role="option"
                      value={value}
                      onClick={() => {
                        onChange?.(value);
                        setIsOpen(false);

                        setFieldValue(
                          'selectedPerm',
                          permissionOptions.find((optVal) => {
                            return optVal.value === value;
                          })
                        );
                      }}
                    >
                      <Typography
                        variant="h-12-b"
                        style={{ marginTop: '5px', marginBottom: '5px', whiteSpace: 'nowrap' }}
                        color={value === selected ? 'var(--brand-secondary)' : 'var(--text)'}
                      >
                        {title}
                      </Typography>
                    </StyledOption>
                  ))}
                </StyledOptionsWrapper>
              )}

              <StyledButton
                as="button"
                width="25%"
                onClick={() => {
                  const splitPath = window.location.pathname.split('/');

                  // Only allow tickr addresses for now lol
                  if (!values.email || !values.email.includes('@tickr.com')) {
                    sendAlert({
                      text: t('error.invalidEmail'),
                      severity: 'error',
                    });

                    return false;
                  }

                  // Already exists
                  if (
                    sharedWith?.find((share) => {
                      return share.email === values.email.trim();
                    })
                  ) {
                    return false;
                  }

                  const pageId = parseInt(splitPath[splitPath.length - 1]);

                  sharePage({
                    recipientEmail: values.email,
                    pageId,
                  });

                  setFieldValue('email', '');

                  setSharedWith(
                    sharedWith?.concat([
                      { email: values.email.trim(), perm: values.selectedPerm.value },
                    ])
                  );
                }}
              >
                {t('sharingPopover.button.sendInvite')}
              </StyledButton>
            </Flex>
          );
        }}
      </Formik>

      <Flex width="100%" height="100%" margin="4px">
        <Typography as="h3" variant="c-14">
          {t('sharingPopover.access.access')}
        </Typography>
      </Flex>

      <Flex margin="4px" width="100%" justify="space-between">
        <Flex width="90%" style={{ cursor: 'pointer' }}>
          <LanguageIcon style={{ marginRight: '4px' }} />
          <Typography>{t('sharingPopover.access.invitedOnly')}</Typography>
          <PickerDownIcon size="10px" style={{ marginLeft: '4px', padding: '3px 3px 0px 0px' }} />
        </Flex>
        <Flex width="20%" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
          <Typography>{t('sharingPopover.permissions.canView')}</Typography>{' '}
          <PickerDownIcon size="10px" style={{ marginLeft: '4px', padding: '3px 3px 0px 0px' }} />
        </Flex>
      </Flex>
      {sharedWith?.map((share) => {
        return (
          <Flex
            margin="4px"
            width="100%"
            key={share.email}
            style={{ textAlign: 'right', justifyContent: 'space-between' }}
          >
            <Flex>
              <Typography>{share.email}</Typography>
            </Flex>
            <Flex style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
              <Typography style={{ whiteSpace: 'nowrap' }}>
                {
                  permissionOptions.find((optVal) => {
                    return optVal.value === share.perm;
                  })?.title
                }
              </Typography>
              <PickerDownIcon
                size="10px"
                style={{ marginLeft: '4px', padding: '3px 3px 0px 0px' }}
              />
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}

const StyledInput = styled.input<{ width: string; value: string }>`
  width: ${({ width }) => width};
  height: 26px;

  display: flex;
  background-color: var(--black-30);
  color: var(--white);
  padding: 12px;
  border: 1px solid var(--black-30);
  border-right: none;
  border-top-left-radius: var(--corner);
  border-bottom-left-radius: var(--corner);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 10px;

  :focus {
    outline: 1px hidden var(--trout);
  }

  select > option {
    background-color: var(--clay);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const StyledSelect = styled(Flex)<{ width: string }>`
  width: ${({ width }) => width};
  height: 26px;

  background-color: var(--black-30);
  color: var(--white);
  padding: 12px;
  border: 1px solid var(--black-30);
  border-left: none;
  border-top-right-radius: var(--corner);
  border-bottom-right-radius: var(--corner);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 10px;

  :focus {
    outline: 1px solid var(--trout);
  }

  select > option {
    background-color: var(--clay);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const StyledButton = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  min-width: 100px;
  height: 26px;

  text-align: center;
  justify: center;
  margin-left: 4px;
  border-radius: var(--corner);
  background-color: var(--action);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: var(--white);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 10px;
  transition: opacity 0.3s;

  :focus {
    outline: 1px solid var(--trout);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }

  :hover {
    filter: brightness(0.6);
  }
  :active {
    padding: none;
    .__button-content {
      opacity: 0.3;
    }
    border: 1px hidden ${({ color }) => color};
  }
`;

const StyledOptionsWrapper = styled(Flex)`
  top: 35px;
  position: absolute;
  min-width: 80px;
  background: var(--clay);
  border: 1px solid var(--black-30);
  border-radius: var(--corner);
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledOption = styled.button`
  text-align: left;
  width: 100%;
  padding: 0.5rem;
  border-radius: var(--corner);
  :hover {
    background: var(--black-30);
  }
`;
