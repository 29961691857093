import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PickerRightIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
        <path
          d="M6.41992 8L0.697654 13.883C0.316343 14.2751 0.323413 14.9034 0.710118 15.2901C1.10064 15.6806 1.73658 15.6834 2.12711 15.2929L8.71289 8.70711C9.10342 8.31658 9.10342 7.68342 8.71289 7.29289L2.12711 0.707107C1.73658 0.316582 1.10342 0.316583 0.712893 0.707107L0.698453 0.721547C0.311325 1.10868 0.307469 1.73514 0.689801 2.127L6.41992 8Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
