import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const ThumbDownIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
        <path
          d="M14 0H5C4.17 0 3.46 0.5 3.16 1.22L0.139999 8.27C0.0499992 8.5 0 8.74 0 9V11C0 12.1 0.9 13 2 13H8.31L7.36 17.57L7.33 17.89C7.33 18.3 7.5 18.68 7.77 18.95L8.83 20L15.42 13.41C15.78 13.05 16 12.55 16 12V2C16 0.9 15.1 0 14 0ZM14 12L9.66 16.34L11 11H2V9L5 2H14V12ZM22 12H18V0H22V12Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
