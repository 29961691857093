import { captureException } from '@sentry/react';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParam } from 'react-use';
import * as Yup from 'yup';
import { Button } from '../Core/Button';
import { Typography } from '../Core/Typography';
import { TextInput } from '../FormElements/TextInput';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useYupValidation } from '../hooks/useYupValidation';
import { LoginLayout } from './LoginLayout';

export function ActivateAccount() {
  const { t } = useTranslation();
  const { sendAlert } = useToaster();
  const { resetPassword, login } = useAuth();
  const { email, password } = useYupValidation();

  const token = useSearchParam('token');
  const userEmail = useSearchParam('email') ?? '';
  const decodedEmail = decodeURIComponent(userEmail);

  const titleText =
    window.location.host === 'chatcpg.ai'
      ? t('helmet.title.chatCPG', { page: t('activateAccount.pageTitle') })
      : t('helmet.title', { page: t('activateAccount.pageTitle') });

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <Formik
        initialValues={{ email: decodedEmail, password: '' }}
        onSubmit={async ({ email, password }) => {
          try {
            if (!token || !email) throw new Error('No token provided');

            await resetPassword({
              email,
              newPassword: password,
              resetToken: token,
            });

            login({ email, password });

            sendAlert({
              text: t('success.setPassword', { email }),
              severity: 'success',
            });
          } catch (e) {
            captureException(e);

            sendAlert({
              text: t('error.setPassword'),
              severity: 'error',
            });
          }
        }}
        validationSchema={Yup.object().shape({
          email: email.required(t('error.required')),
          password,
        })}
        validateOnChange
      >
        {({ errors, values, handleChange, handleSubmit, isValid }) => (
          <LoginLayout>
            <Typography as="h1">{t('activateAccount.welcomeToTickr')}</Typography>
            <Typography as="h2">{t('activateAccount.setYourPassword')}</Typography>
            <TextInput
              disabled
              width="300px"
              required
              id="email"
              name="email"
              title={t('auth.email')}
              type="email"
              error={errors.email}
              value={values.email}
              background="var(--space)"
            />
            <TextInput
              width="300px"
              required
              id="password"
              name="password"
              title={t('activateAccount.setPassword')}
              type="password"
              error={errors.password}
              value={values.password}
              onChange={handleChange}
              background="var(--space)"
            />
            <Button width="300px" type="submit" onClick={() => handleSubmit()} disabled={!isValid}>
              {t('auth.login')}
            </Button>
          </LoginLayout>
        )}
      </Formik>
    </>
  );
}
