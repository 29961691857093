import { useMutation, useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { PropsWithChildren, useMemo } from 'react';
import { TickrElement, TickrPage, TickrTab } from '../../Element/types/elementTypes';
import { useAvailableFilters } from '../../Filters/AvailableFiltersProvider/useAvailableFilters';
import { PopulatedProject, Project, useCurrentProject } from '../../hooks/useCurrentProject';
import { useApi } from '../ApiProvider/useApi';
import { useAuth } from '../AuthProvider/useAuth';
import {
  AddProjectItemProps,
  CollectionMutationsContext,
  CreateElementsProps,
  CreateProjectProps,
  CreateTabProps,
  DeleteElementProps,
  DeleteElementsProps,
  DeletePageProps,
  DeleteProjectProps,
  DeleteTabProps,
  DuplicateElementProps,
  RemoveProjectItemProps,
  UpdateElementProps,
  UpdateElementsProps,
  UpdatePageProps,
  UpdateTabsProps,
} from './CollectionMutationsContext';

export const OPTIMISTIC_PAGE_ID = 123456789;

export function CollectionMutationsProvider({ children }: PropsWithChildren) {
  const { msId, userId } = useAuth();
  const { post, delete: deleteItem, put } = useApi();
  const { datasourceFilters } = useAvailableFilters();
  const { currentProject } = useCurrentProject();
  const queryClient = useQueryClient();

  const availableDatasources = Object.keys(datasourceFilters).filter((ds) => ds !== 'universal');

  /* PROJECTS */
  const createProject = useMutation(
    async ({
      isActive = true,
      description = '',
      isTemplate = false,
      isTickr = false,
      title,
    }: CreateProjectProps) => {
      const { data } = await post<Project>('v2/poplar/projects', {
        isActive,
        description,
        isTemplate,
        isTickr,
        msId,
        title,
        userId,
      });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  const updateProject = useMutation(
    async ({ id, ...props }: Project) => {
      const { data } = await put<Project>(`v2/poplar/projects/${id}`, props);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  const deleteProject = useMutation(
    async ({ id }: DeleteProjectProps) => {
      await deleteItem(`v2/poplar/projects/${id}`);
      return;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  const addProjectItem = useMutation(
    async ({ projectId, pageId, type }: AddProjectItemProps) => {
      const { data } = await post(`v2/poplar/projects/${projectId}/add-item`, {
        projectId,
        pageId,
        type,
      });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  const removeProjectItem = useMutation(
    async ({ projectId, itemId }: RemoveProjectItemProps) => {
      await deleteItem(`v2/poplar/projects/${projectId}/remove-item/${itemId}`);
      return;
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  /* PAGES */
  const createPage = useMutation(
    async (page: Partial<TickrPage>) => {
      const pageToCreate = { ...page, datasources: availableDatasources };
      const { data } = await post<TickrPage>('v2/poplar/pages', pageToCreate);
      return data;
    },
    {
      onMutate: (page) => {
        queryClient.cancelQueries(['current-project']);

        const currentProject = queryClient.getQueryData<PopulatedProject>([
          'current-project',
          userId,
        ]);

        if (!currentProject) return;
        const currentPages = currentProject?.pages ?? [];

        queryClient.setQueryData<PopulatedProject>(['current-project', userId], () => ({
          ...currentProject,
          // this is necessary to prevent an automatic navigation to home when project pages are empty and a new page is created
          pages: [...currentPages, { ...(page as TickrPage), id: OPTIMISTIC_PAGE_ID }],
        }));
      },
      onSuccess: (page) => {
        if ('id' in currentProject) {
          addProjectItem.mutateAsync({
            projectId: currentProject.id,
            pageId: page.id,
            type: 'page',
          });
        }
      },
    }
  );

  const updatePage = useMutation(
    async ({ page }: UpdatePageProps) => {
      const { data } = await put(`v2/poplar/pages/${page.id}`, page);
      return data;
    },
    {
      onMutate({ page }) {
        queryClient.cancelQueries(['current-project']);

        const currentProject = queryClient.getQueryData<PopulatedProject>([
          'current-project',
          userId,
        ]);

        if (currentProject) {
          const updatedPages = currentProject.pages.map((p) => (p.id === page.id ? page : p));

          queryClient.setQueryData<PopulatedProject>(['current-project', userId], () => ({
            ...currentProject,
            pages: updatedPages,
          }));
        }
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-project']);
      },
    }
  );

  const deletePage = useMutation(
    async ({ id }: DeletePageProps) => {
      await deleteItem(`v2/poplar/pages/${id}`);
    },
    {
      onMutate({ id }) {
        queryClient.cancelQueries(['current-project']);
        queryClient.cancelQueries(['recent-pages']);

        const currentProject = queryClient.getQueryData<PopulatedProject>([
          'current-project',
          userId,
        ]);

        const recentPages = queryClient.getQueryData<TickrPage[]>(['recent-pages', '', userId]);

        if (recentPages) {
          queryClient.setQueryData<TickrPage[]>(['recent-pages', '', userId], () =>
            recentPages.filter((page) => page.id !== id)
          );
        }

        if (currentProject) {
          const updatedPages = currentProject.pages.filter((page) => page.id !== id);

          queryClient.setQueryData<PopulatedProject>(['current-project', userId], () => ({
            ...currentProject,
            pages: updatedPages,
          }));
        }
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-project']);
        queryClient.invalidateQueries(['recent-pages']);
      },
    }
  );

  const duplicatePage = useMutation(async (page: TickrPage) => {
    const newPage = await createPage.mutateAsync({
      ...omit(page, 'id'),
      title: getDuplicateTitle(page.title),
    });

    return newPage;
  });

  /* TABS */
  const createTab = useMutation(
    async ({ tab: tab, pageId }: CreateTabProps) => {
      const { data: tabs } = await post<TickrTab[]>('v2/poplar/tabs', tab);
      return { tabs, pageId };
    },
    {
      onSuccess: ({ tabs, pageId }) => {
        const currentTabs =
          queryClient.getQueryData<TickrTab[]>(['current-tabs', pageId, userId]) ?? [];

        const updatedTabs = [...currentTabs, ...tabs];
        queryClient.setQueryData(['current-tabs', pageId, userId], updatedTabs);
        queryClient.invalidateQueries(['current-tabs']);
      },
    }
  );

  const updateTabs = useMutation(
    async ({ tabs }: UpdateTabsProps) => {
      const { data } = await put<TickrTab[]>(`v2/poplar/tabs`, { tabs });
      return data;
    },
    {
      onMutate: ({ tabs, pageId }) => {
        queryClient.cancelQueries(['current-tabs']);

        const currentTabs =
          queryClient.getQueryData<TickrTab[]>(['current-tabs', pageId, userId]) ?? [];

        const updatedTabs = currentTabs.map(
          (tab) => tabs.find((updatedTab) => updatedTab.id === tab.id) ?? tab
        );

        queryClient.setQueryData(['current-tabs', pageId, userId], updatedTabs);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['current-tabs']);
      },
      onError: () => {
        queryClient.invalidateQueries(['current-tabs']);
      },
    }
  );

  const deleteTab = useMutation(
    async ({ id }: DeleteTabProps) => {
      await deleteItem(`v2/poplar/tabs/${id}`);

      return id;
    },
    {
      onMutate({ id, pageId, navToNearbyTab }) {
        navToNearbyTab?.();

        queryClient.cancelQueries(['current-tabs']);

        const currentTabs =
          queryClient.getQueryData<TickrTab[]>(['current-tabs', pageId, userId]) ?? [];

        const updatedTabs = currentTabs.filter((tab) => tab.id !== id);
        queryClient.setQueryData(['current-tabs', pageId, userId], updatedTabs);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['current-tabs']);
      },
      onError: () => {
        queryClient.invalidateQueries(['current-tabs']);
      },
    }
  );

  /* ELEMENTS */
  const createElements = useMutation(
    async ({ elementsToCreate }: CreateElementsProps) => {
      const { data } = await post<TickrElement[]>('v2/poplar/elements', {
        elements: elementsToCreate,
      });

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['current-elements']);
      },
    }
  );

  const duplicateElement = useMutation(
    async ({ element, pageId, tabId }: DuplicateElementProps) => {
      return await createElements.mutateAsync({
        elementsToCreate: [
          {
            ...omit(element, 'id'),
            title: getDuplicateTitle(element.title),
          },
        ],
        pageId,
        tabId,
      });
    }
  );

  const updateElement = useMutation(
    async ({ element }: UpdateElementProps) => {
      const { data } = await put(`v2/poplar/elements/${element.id}`, element);
      return data;
    },
    {
      onMutate({ element: updatedElement, pageId, tabId }) {
        queryClient.cancelQueries(['current-elements']);

        const currentPageElements =
          queryClient.getQueryData<TickrElement[]>(['current-elements', tabId ?? pageId, userId]) ??
          [];

        queryClient.setQueryData<TickrElement[]>(
          ['current-elements', tabId ?? pageId, userId],
          () =>
            currentPageElements.map((element) =>
              element.id === updatedElement.id ? updatedElement : element
            )
        );
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-elements']);
        queryClient.invalidateQueries(['template-element']);
      },
      onError() {
        queryClient.invalidateQueries(['current-elements']);
      },
    }
  );

  const updateElements = useMutation(
    async ({ elements }: UpdateElementsProps) => {
      if (isEmpty(elements)) return;

      const { data } = await put(`v2/poplar/elements`, {
        elements,
      });

      return data;
    },
    {
      onMutate({ elements: updatedElements, pageId, tabId }) {
        queryClient.cancelQueries(['current-elements']);

        const currentPageElements =
          queryClient.getQueryData<TickrElement[]>(['current-elements', tabId ?? pageId, userId]) ??
          [];

        queryClient.setQueryData<TickrElement[]>(
          ['current-elements', tabId ?? pageId, userId],
          () =>
            currentPageElements.map(
              (element) => updatedElements.find((el) => el.id === element.id) ?? element
            )
        );
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-elements']);
        queryClient.invalidateQueries(['template-elements']);
      },
      onError() {
        queryClient.invalidateQueries(['current-elements']);
      },
    }
  );

  const deleteElement = useMutation(
    async ({ id }: DeleteElementProps) => {
      await deleteItem(`v2/poplar/elements/${id}`);
    },
    {
      onMutate({ id, pageId, tabId }) {
        queryClient.cancelQueries(['current-elements']);

        const currentPageElements =
          queryClient.getQueryData<TickrElement[]>(['current-elements', tabId ?? pageId, userId]) ??
          [];

        queryClient.setQueryData<TickrElement[]>(
          ['current-elements', tabId ?? pageId, userId],
          () => currentPageElements.filter((el) => el.id !== id)
        );
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-elements']);
      },
      onError() {
        queryClient.invalidateQueries(['current-elements']);
      },
    }
  );

  const deleteElements = useMutation(
    async ({ ids }: DeleteElementsProps) => {
      await deleteItem('v2/poplar/elements', {
        data: { elements: ids },
      });
    },
    {
      onMutate({ ids, pageId, tabId }) {
        queryClient.cancelQueries(['current-elements']);

        const currentPageElements =
          queryClient.getQueryData<TickrElement[]>(['current-elements', tabId ?? pageId, userId]) ??
          [];

        queryClient.setQueryData<TickrElement[]>(
          ['current-elements', tabId ?? pageId, userId],
          () => currentPageElements.filter((el) => !ids.includes(el.id))
        );
      },
      onSuccess() {
        queryClient.invalidateQueries(['current-elements']);
      },
      onError() {
        queryClient.invalidateQueries(['current-elements']);
      },
    }
  );

  const collectionMutations = useMemo(
    () => ({
      createProject: createProject.mutateAsync,
      updateProject: updateProject.mutateAsync,
      deleteProject: deleteProject.mutateAsync,
      addProjectItem: addProjectItem.mutateAsync,
      removeProjectItem: removeProjectItem.mutateAsync,

      createPage: createPage.mutateAsync,
      updatePage: updatePage.mutateAsync,
      deletePage: deletePage.mutateAsync,
      duplicatePage: duplicatePage.mutateAsync,

      createTab: createTab.mutateAsync,
      updateTabs: updateTabs.mutateAsync,
      deleteTab: deleteTab.mutateAsync,

      createElements: createElements.mutateAsync,
      updateElement: updateElement.mutateAsync,
      updateElements: updateElements.mutateAsync,
      deleteElement: deleteElement.mutateAsync,
      deleteElements: deleteElements.mutateAsync,
      duplicateElement: duplicateElement.mutateAsync,
    }),
    [
      createProject.mutateAsync,
      updateProject.mutateAsync,
      deleteProject.mutateAsync,
      addProjectItem.mutateAsync,
      removeProjectItem.mutateAsync,

      createPage.mutateAsync,
      updatePage.mutateAsync,
      deletePage.mutateAsync,
      duplicatePage.mutateAsync,

      createTab.mutateAsync,
      updateTabs.mutateAsync,
      deleteTab.mutateAsync,

      createElements.mutateAsync,
      updateElement.mutateAsync,
      updateElements.mutateAsync,
      deleteElement.mutateAsync,
      deleteElements.mutateAsync,
      duplicateElement.mutateAsync,
    ]
  );

  return (
    <CollectionMutationsContext.Provider value={collectionMutations}>
      {children}
    </CollectionMutationsContext.Provider>
  );
}

const duplicateRegex = /\(Copy (\d+)\)/;

function getDuplicateTitle(title: string) {
  const copyMatch = title.match(duplicateRegex);

  let newTitle = '';

  if (copyMatch) {
    const copyNumber = parseInt(copyMatch[1]);
    newTitle = `${title.replace(duplicateRegex, `(Copy ${copyNumber + 1})`)}`;
  } else {
    newTitle = `${title} (Copy 1)`;
  }

  return newTitle;
}
