import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { StreamingIcon } from './StreamingIcon';
import { SuggestionChip } from './SuggestionChip';

export function ZeroStateChat() {
  const { t } = useTranslation();
  const { isStreaming } = useTalkingPointsPage();
  const { conversation } = useChatBox();
  const { setViewConversation } = useChatBoxForm();

  const suggestionsDetails = [
    t('chatCpg.suggestion.demographicMakeup'),
    t('chatCpg.suggestion.marketShare'),
  ];

  const suggestionsDataSources = [
    t('chatCpg.suggestion.dataAccess'),
    t('chatCpg.suggestion.whereMarketShare'),
  ];

  return (
    <Flex
      column
      width="100%"
      height="100%"
      gap="2rem"
      align="center"
      justify="center"
      style={{ overflow: 'auto' }}
    >
      <StreamingIcon size="36px" isStreaming={isStreaming} />
      <Flex column gap="0.5rem" align="center" width="100%">
        <Typography variant="p-14-b">{t('chatCpg.youCanAskForMoreDetails')}</Typography>
        {suggestionsDetails.map((suggestion) => (
          <SuggestionChip key={suggestion} style={{ maxWidth: '350px' }} text={suggestion} />
        ))}
      </Flex>
      <Flex column gap="0.5rem" align="center" width="100%">
        <Typography variant="p-14-b">{t('chatCpg.orAskAboutDataSources')}</Typography>
        {suggestionsDataSources.map((suggestion) => (
          <SuggestionChip key={suggestion} style={{ maxWidth: '350px' }} text={suggestion} />
        ))}
      </Flex>
      {conversation.length > 0 && (
        <Flex gap="0.5rem" align="center" as="button" onClick={() => setViewConversation(true)}>
          <ArrowLeftIcon size="14px" color="var(--text-secondary)" />
          <Typography variant="h-14-b">Back to Conversation</Typography>
        </Flex>
      )}
    </Flex>
  );
}
