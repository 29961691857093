import { AppFilters } from '../../Filters/filterTypes';
import { FilterIcon } from '../../Icons/FilterIcon';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { headerIconSharedProps } from '../../helpers/headerIconSharedProps';
import { FilterMenuPopoverContent } from './FilterMenuPopoverContent';

interface FilterMenuPopoverProps {
  onSave(filters: AppFilters): Promise<void>;
}

export function FilterMenuPopover({ onSave }: FilterMenuPopoverProps) {
  return (
    <PopoverProvider placement="bottom-start" fallbackPlacements={[]}>
      <PopoverTrigger asChild>
        <FilterIcon as="button" {...headerIconSharedProps} />
      </PopoverTrigger>
      <FilterMenuPopoverContent onSave={onSave} />
    </PopoverProvider>
  );
}
