import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ArrowUpIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M0.705315 7.29468C0.31578 7.68422 0.315781 8.31578 0.705316 8.70531C1.0946 9.0946 1.72568 9.09489 2.11532 8.70595L7 3.83L7 15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15L9 3.83L13.8729 8.71163C14.2633 9.10275 14.8995 9.10045 15.2903 8.70968C15.6808 8.31916 15.6834 7.68342 15.2929 7.29289L8.70711 0.707105C8.31658 0.316581 7.68342 0.316582 7.29289 0.707106L0.705315 7.29468Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
