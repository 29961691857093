import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../Core/ContextMenu';
import { ContextMenuItem } from '../../Core/ContextMenuItem';
import { HundredQuestionsDialog } from '../../Dialogs/HundredQuestionsDialog/HundredQuestionsDialog';
import { PopoverContent } from '../../Popover/PopoverContent';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useChatBoxForm } from '../../Providers/ChatBoxProvider/useChatBoxForm';

export function KnowledgeBasePopoverContent() {
  const { t } = useTranslation();
  const { setOpen } = usePopoverContext();
  const { setViewConversation } = useChatBoxForm();

  return (
    <PopoverContent>
      <ContextMenu column padding="0.5rem" width="240px">
        <HundredQuestionsDialog onClose={() => setOpen(false)}>
          <ContextMenuItem text={t('chatCpg.resourceCenter')} showPicker />
        </HundredQuestionsDialog>
        <ContextMenuItem text={t('chatCpg.privateDataRepository')} showPicker />
        <ContextMenuItem text={t('chatCpg.customTuning')} showPicker />
        <ContextMenuItem
          text={t('chatCpg.exampleQuestions')}
          showPicker
          onClick={() => {
            setViewConversation(false);
            setOpen(false);
          }}
        />
      </ContextMenu>
    </PopoverContent>
  );
}
