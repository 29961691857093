import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MosaicNode } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { SaveIcon } from '../Icons/SaveIcon';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';

interface SaveDefaultButtonProps {
  mosaicNode?: MosaicNode<string> | null;
}

export function SaveDefaultButton({ mosaicNode }: SaveDefaultButtonProps) {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const currentTab = useCurrentTab();
  const currentPage = useCurrentPage();
  const updateTabMosaic = useUpdateTabMosaic();
  const updatePageMosaic = useUpdatePageMosaic();

  const defaultMosaic = tabId
    ? currentTab?.settings?.defaultMosaic
    : currentPage?.settings?.defaultMosaic;

  const isRootEqualToDefault = useMemo(
    () => isEqual(mosaicNode, defaultMosaic),
    [mosaicNode, defaultMosaic]
  );

  return (
    <InfoTooltip text={t('dashboard.controls.saveNewDefault')}>
      <SaveIcon
        as="button"
        disabled={isRootEqualToDefault}
        onClick={() => {
          if (!mosaicNode) return;

          tabId
            ? updateTabMosaic({ node: mosaicNode, updateDefaultMosaic: true })
            : updatePageMosaic({ node: mosaicNode, updateDefaultMosaic: true });
        }}
      />
    </InfoTooltip>
  );
}
