import { fetchEventSource } from '@microsoft/fetch-event-source';
import { captureException } from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaStream } from '../../ChatCpg/types/chatCpgTypes';
import { replaceDoubleEscape, shortenGtin } from '../../helpers/stringHelpers';
import { useChatCpgStore } from '../../hooks/useChatCpgStore';
import { useReplaceReportingWeek } from '../../hooks/useReplaceReportingWeek';
import { useReplaceTimestamp } from '../../hooks/useReplaceTimestamp';
import { useApi } from '../ApiProvider/useApi';
import { useApp } from '../AppProvider/useApp';
import { useChatCpgMutations } from '../ChatCpgMutationsProvider/useChatCpgMutations';
import { useTalkingPointsPage } from '../TalkingPointsPageProvider/useTalkingPointsPage';
import {
  ChatBoxContext,
  Conversation,
  FileItem,
  FileStatus,
  FileSummary,
  MessageItem,
  MessageStatus,
  RawFile,
  RawMessage,
} from './ChatBoxContext';
import { ChatBoxFormContext } from './ChatBoxFormContext';
import { useChatBox } from './useChatBox';

type ChatStreamEvent =
  | MetaStream
  | {
      data: {
        document: RawFile;
      };
      type: 'document';
    }
  | {
      status: MessageStatus | FileStatus;
      type: 'status';
    }
  | {
      text: string;
      type: 'summary';
    }
  | {
      talking_point: 'chat';
      text: string;
      type: 'answer';
    };

interface ChatHistoryData {
  id: string;
  messages: RawMessage[];
  files: RawFile[];
}

export function ChatBoxProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const { chatCpgUrl: chatCpgPath } = useApp();
  const { get } = useApi();
  const { isStreaming, workflowId } = useTalkingPointsPage();
  const queryClient = useQueryClient();

  const replaceTimestamp = useReplaceTimestamp();
  const replaceReportingWeek = useReplaceReportingWeek();

  const clearStreamText = useChatCpgStore((state) => state.clearStreamText);
  const updateStreamText = useChatCpgStore((state) => state.updateStreamText);

  const formatText = (text?: string) =>
    replaceReportingWeek(replaceTimestamp(replaceDoubleEscape(shortenGtin(text))));

  const [chatInput, setChatInput] = useState('');

  const [chatStreamId, setChatStreamId] = useState('');
  const [fileStreamId, setFileStreamId] = useState('');

  const [chatScrollPosition, setChatScrollPosition] = useState(0);

  useQuery(
    ['chat-stream', workflowId, chatStreamId],
    async () => {
      const fetchData = async () => {
        await fetchEventSource(`${chatCpgPath}/summary/${workflowId}/chat/${chatStreamId}`, {
          method: 'GET',
          openWhenHidden: true,
          onmessage(event) {
            if (event.event === 'ping' || event.data === '') return;

            const streamData: ChatStreamEvent = JSON.parse(event.data);

            if ('meta' in streamData) {
              if (streamData.meta === 'ACK') {
                const conversation =
                  queryClient.getQueryData<Conversation>(['chat-history', workflowId]) ?? [];

                if (conversation[0]?.id !== 'new-chat-stream') {
                  queryClient.setQueryData<Conversation>(['chat-history', workflowId], () => {
                    return [
                      {
                        id: 'new-chat-stream',
                        sender: 'AI',
                        status: 'generating',
                        text: '',
                        type: 'message',
                        timestamp: Date.now(),
                      },
                      ...conversation,
                    ];
                  });
                }
              }
            } else {
              if ('text' in streamData) {
                const { text } = streamData;

                updateStreamText(
                  [chatStreamId, workflowId],
                  { text, isStreaming: true },
                  formatText
                );
              }
            }
          },
          onopen: async () => {
            console.log('CHAT STREAM OPEN');
          },
          onerror(error) {
            console.log('CHAT STREAM ERROR', { error });
            captureException(error);
            clearStreamText(chatStreamId);
            setChatStreamId('');
            queryClient.invalidateQueries(['chat-history', workflowId]);
          },
          onclose() {
            console.log('CHAT STREAM CLOSED');
            queryClient.invalidateQueries(['chat-history', workflowId]);
          },
        });
      };

      fetchData();
      return true;
    },
    {
      enabled: !!chatStreamId && !!workflowId,
      staleTime: Infinity,
    }
  );

  useQuery(
    ['file-stream', workflowId, fileStreamId],
    async () => {
      const fetchData = async () => {
        await fetchEventSource(`${chatCpgPath}/document/${fileStreamId}`, {
          method: 'GET',
          openWhenHidden: true,
          onmessage(event) {
            if (event.event === 'ping' || event.data === '') return;

            let eventData: ChatStreamEvent;

            try {
              eventData = JSON.parse(event.data);
            } catch (error) {
              captureException(error);
              return;
            }

            if ('meta' in eventData) {
              switch (eventData.meta) {
                case 'ACK':
                  break;
                // case 'COMPLETE':
                //   break;
                case 'ERROR':
                  setFileStreamId('');
                  break;
                default:
                  break;
              }
            } else {
              if (eventData.type === 'document') {
                const { document } = eventData.data;

                const conversation =
                  queryClient.getQueryData<Conversation>(['chat-history', workflowId]) ?? [];

                const updatedConversation: Conversation = conversation?.map((item) =>
                  item.id === 'new-file'
                    ? {
                        ...item,
                        mime: document.mime,
                        name: document.name,
                        id: document.id,
                        status: 'uploading',
                        summary: null,
                        timestamp: document.timestamp * 1000,
                        type: 'file',
                      }
                    : item
                );

                queryClient.setQueryData<Conversation>(
                  ['chat-history', workflowId],
                  () => updatedConversation
                );
              } else if ('text' in eventData) {
                const { text } = eventData;

                updateStreamText(
                  [fileStreamId, workflowId],
                  { text, isStreaming: true },
                  formatText
                );
              }

              if (eventData.type === 'status') {
                const { status } = eventData;

                const conversation =
                  queryClient.getQueryData<Conversation>(['chat-history', workflowId]) ?? [];

                const updatedConversation: Conversation = conversation.map((item) =>
                  item.id === fileStreamId
                    ? {
                        ...(item as FileItem),
                        status: status as FileStatus,
                      }
                    : item
                );

                queryClient.setQueryData<Conversation>(
                  ['chat-history', workflowId],
                  () => updatedConversation
                );
              }
            }
          },
          onopen: async () => {
            console.log('FILE STREAM OPEN');
          },
          onerror(error) {
            console.log('FILE STREAM ERROR', { error });
            captureException(error);
            setFileStreamId('');
            clearStreamText(fileStreamId);
            queryClient.invalidateQueries(['chat-history', workflowId]);
          },
          onclose() {
            console.log('FILE STREAM CLOSED');
            queryClient.invalidateQueries(['chat-history', workflowId]);
          },
        });
      };

      fetchData();
      return true;
    },
    {
      enabled: !!fileStreamId && !!workflowId,
      staleTime: Infinity,
    }
  );

  const { data: conversation = [] } = useQuery<Conversation>(
    ['chat-history', workflowId],
    async () => {
      const { data } = await get<ChatHistoryData>(`${chatCpgPath}/summary/${workflowId}/chat`);

      const messages: MessageItem[] = data.messages.map(({ timestamp, text, ...message }) => ({
        text: formatText(text),
        timestamp: timestamp * 1000,
        type: 'message',
        ...message,
      }));

      const files: FileItem[] = data.files.map(({ timestamp, ...file }) => {
        let parsedSummary: FileSummary | null;

        try {
          parsedSummary = file.summary ? (JSON.parse(file.summary) as FileSummary) : null;
        } catch (error) {
          parsedSummary = { summary: file.summary ?? t('chatCpg.chat.error'), questions: [] };
        }

        return {
          ...file,
          timestamp: timestamp * 1000,
          type: 'file',
          summary: parsedSummary,
        };
      });

      if (
        files[files.length - 1] &&
        files[files.length - 1]?.status !== 'complete' &&
        files[files.length - 1]?.status !== 'error'
      ) {
        setFileStreamId(files[files.length - 1].id);
      }

      const chatHistory = [...messages, ...files].sort((a, b) => b.timestamp - a.timestamp);

      // console.log({ chatHistory });
      return chatHistory;
    },
    {
      enabled: !!workflowId && workflowId !== 'test' && !isStreaming,
      staleTime: Infinity,
      onSuccess() {
        setFileStreamId('');
        setChatStreamId('');
      },
    }
  );

  const fileList = useMemo(() => {
    return conversation.filter((item) => item.type === 'file') as FileItem[];
  }, [conversation]);

  const chatCpgValues = useMemo(
    () => ({
      chatInput,
      chatScrollPosition,
      chatStreamId,
      conversation,
      fileList,
      fileStreamId,
      setChatInput,
      setChatScrollPosition,
      setChatStreamId,
      setFileStreamId,
    }),
    [chatInput, chatStreamId, conversation, fileList, fileStreamId, chatScrollPosition]
  );

  return (
    <ChatBoxContext.Provider value={chatCpgValues}>
      <ChatBoxFormProvider>{children}</ChatBoxFormProvider>
    </ChatBoxContext.Provider>
  );
}

function ChatBoxFormProvider({ children }: PropsWithChildren) {
  const { workflowId } = useTalkingPointsPage();

  const { postChat } = useChatCpgMutations();

  const { chatInput, setChatStreamId, setChatInput, chatScrollPosition, setChatScrollPosition } =
    useChatBox();

  const [viewConversation, setViewConversation] = useState(true);
  const knowledgeBaseChatInputRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);
  const [highlightChat, setHighlightChat] = useState(false);

  useEffect(() => {
    if (highlightChat) {
      setTimeout(() => {
        setHighlightChat(false);
      }, 1000);
    }
  }, [highlightChat]);

  const { handleChange, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      message: chatInput,
    },
    onSubmit: async (values, { resetForm }) => {
      if (!values.message) return;

      resetForm();

      setViewConversation(true);

      if (chatScrollPosition <= 0) {
        // Need to set this to reset state for
        // the overflowAnchor property to work.
        setChatScrollPosition(0);
        // Then scroll to the bottom (top, lol) of the chat window.
        historyRef.current?.scrollTo(0, 0);
      }

      try {
        const { id } = await postChat({
          message: values.message,
          workflowId,
        });

        setChatStreamId(id);
      } catch (e) {
        captureException(e);
      }

      inputRef.current?.focus();
    },
    onReset: () => {
      setChatInput('');
    },
  });

  const setMessage = useCallback(
    (message: string) => {
      setFieldValue('message', message);
    },
    [setFieldValue]
  );

  const onClickSuggestion = useCallback(
    (message: string) => {
      inputRef.current?.focus();
      setMessage(message);
    },
    [setMessage]
  );

  const onClickSuggestionKnowledgeBase = useCallback(
    (message: string) => {
      knowledgeBaseChatInputRef.current?.focus();
      setHighlightChat(true);
      setMessage(message);
    },
    [setMessage]
  );

  const memoizedValue = useMemo(
    () => ({
      handleChange,
      handleSubmit,
      highlightChat,
      historyRef,
      inputRef,
      knowledgeBaseChatInputRef,
      onClickSuggestion,
      onClickSuggestionKnowledgeBase,
      setMessage,
      setViewConversation,
      values,
      viewConversation,
    }),
    [
      handleChange,
      handleSubmit,
      highlightChat,
      onClickSuggestion,
      onClickSuggestionKnowledgeBase,
      setMessage,
      values,
      viewConversation,
    ]
  );

  return (
    <ChatBoxFormContext.Provider value={memoizedValue}>{children}</ChatBoxFormContext.Provider>
  );
}
