import { Circle } from './Circle';
import { Typography } from './Typography';

export interface Author {
  id: number;
  fullname?: string;
  username?: string;
}

interface AuthorAvatarProps {
  author?: Partial<Author>;
  imageSrc?: string;
  size?: string;
  background?: string;
  style?: React.CSSProperties;
}

export function AuthorAvatar({
  author,
  imageSrc,
  background = 'var(--background',
  size = '32px',
  style,
}: AuthorAvatarProps) {
  const initials = getInitials(author);

  return (
    <Circle
      size={size}
      color={background}
      style={{
        ...(imageSrc
          ? {
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundSize: size,
            }
          : {}),
        ...style,
      }}
    >
      {!imageSrc && (
        <Typography variant="h-12-b" color="var(--black)" style={{ textTransform: 'uppercase' }}>
          {initials}
        </Typography>
      )}
    </Circle>
  );
}

function getInitials(author?: Partial<Author>): string {
  const name = author?.fullname ?? author?.username ?? 'U';
  const names = name.split(' ');
  const firstInitial = names[0].charAt(0);
  const lastInitial = names.length > 1 ? names[names.length - 1].charAt(0) : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
}
