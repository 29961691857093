import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { NavPopover } from '../Popovers/NavPopover/NavPopover';
import { NotificationsPopover } from '../Popovers/NotificationsPopover/NotificationsPopover';
import { SidePanelDialog } from '../SidePanel/SidePanelDialog';
import { DASHBOARD_X_PADDING } from '../helpers/constants';
import { getFirstTabId } from '../helpers/getFirstTabId';
import { useCurrentProject } from '../hooks/useCurrentProject';

interface HeaderProps {
  endChildren?: ReactNode;
  pageTitle?: string;
}

interface PageLink {
  name: string;
  pageId: string;
  to: string;
}

const HEADER_ROOT_ID = 'header-root';

export function Header({ endChildren, pageTitle, children }: PropsWithChildren<HeaderProps>) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { pageId } = useParams();
  const { currentProject } = useCurrentProject();

  const pageLinks = [
    {
      name: t('page.home'),
      pageId: 'home',
      to: '/',
    },
    {
      name: t('page.myWork'),
      pageId: 'my-work',
      to: '/my-work',
    },
    {
      name: t('page.smartScorecard'),
      pageId: 'smart-scorecard',
      to: '/smart-scorecard',
    },
    ...(currentProject.pages ?? []).reduce((pages, { id, tabs, title, settings }) => {
      if (settings.isScorecard) {
        return pages;
      }

      pages.push({
        name: title,
        pageId: id.toString(),
        to: `/page/${id}${!isEmpty(tabs) ? `/tab/${getFirstTabId(tabs ?? [])}` : ''}`,
      });

      return pages;
    }, [] as PageLink[]),
  ];

  const activePageId = pageId ?? pathname.slice(1);

  return (
    <HeaderContainer id={HEADER_ROOT_ID}>
      <Flex align="center" width="100%" justify="space-between" style={{ minHeight: '32px' }}>
        <Flex gap="0.6rem" align="center" style={{ minWidth: 0 }}>
          <SidePanelDialog rootId={HEADER_ROOT_ID} />
          {pageTitle ? (
            <Typography as="h1" nowrap style={{ zIndex: 3, paddingRight: '2rem' }}>
              {pageTitle}
            </Typography>
          ) : (
            <NavPopover activePageId={activePageId} pageLinks={pageLinks} />
          )}
          <NotificationsPopover />
          {children}
        </Flex>
        <Flex gap="1rem" align="center">
          {endChildren}
          <Typography variant="h-22-b" nowrap>
            {t('tickr').toUpperCase()}
          </Typography>
        </Flex>
      </Flex>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  overflow: hidden;
  min-width: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: inherit;
  width: 100%;
  padding: 12px ${DASHBOARD_X_PADDING}px
    ${
      DASHBOARD_X_PADDING - 6 // makes space for invalid filter indicator element three dot menu
    }px
    ${DASHBOARD_X_PADDING}px;
`;
