import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthorAvatar } from '../../Core/AuthorAvatar';
import { ContextMenu } from '../../Core/ContextMenu';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { NewAlertDialog } from '../../Dialogs/NewAlertDialog/NewAlertDialog';
import { AddIcon } from '../../Icons/AddIcon';
import { ChatCpgIcon } from '../../Icons/ChatCpgIcon';
import { ClearIcon } from '../../Icons/ClearIcon';
import { PickerRightIcon } from '../../Icons/PickerRightIcon';
import { PopoverContent } from '../../Popover/PopoverContent';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { Notification } from '../../Providers/NotificationsProvider/NotificationsContext';
import { useNotifications } from '../../Providers/NotificationsProvider/useNotifications';
import { useNotificationsDispatch } from '../../Providers/NotificationsProvider/useNotificationsDispatch';
import { Tab } from '../../Tabs/Tab';
import { TabList } from '../../Tabs/TabList';
import { TabPanel } from '../../Tabs/TabPanel';
import { TabsProvider } from '../../Tabs/TabsProvider';

export function NotificationsPopoverContent() {
  const { t } = useTranslation();
  const notifications = useNotifications();
  const setNotifications = useNotificationsDispatch();

  const { setOpen } = usePopoverContext();

  return (
    <PopoverContent>
      <ContextMenu
        direction="column"
        gap="1.5rem"
        width="550px"
        padding="0.5rem"
        style={{ overflow: 'visible' }}
      >
        <TabsProvider>
          <TabList listName="tabs">
            <Tab name={t('alerts.showAll')} index={0}>
              {t('alerts.showAll')}
            </Tab>
            <Tab name={t('alerts.collaboration')} index={1}>
              {t('alerts.collaboration')}
            </Tab>
            <Tab name={t('alerts.insights')} index={2}>
              {t('alerts.insights')}
            </Tab>
          </TabList>
          <TabPanel name={t('alerts.showAll')} index={0} style={tabPanelStyle}>
            <Flex width="100%" align="center" justify="space-between">
              <Typography
                as="button"
                onClick={() => {
                  setNotifications([]);
                }}
                variant="button-12"
              >
                {t('alerts.clearAll')}
              </Typography>
              <NewAlertDialog onClose={() => setOpen(false)}>
                <Flex as="button" gap="0.5rem" align="center">
                  <AddIcon color="var(--action)" />
                  <Typography color="var(--action)" variant="button-12">
                    {t('alerts.createNewAlert')}
                  </Typography>
                </Flex>
              </NewAlertDialog>
            </Flex>
            <NotificationsList notifications={notifications} />
          </TabPanel>
          <TabPanel name={t('alerts.collaboration')} index={1} style={tabPanelStyle}>
            <NotificationsList
              notifications={notifications.filter(({ author }) => author !== 'chatCpg')}
            />
          </TabPanel>
          <TabPanel name={t('alerts.insights')} index={2} style={tabPanelStyle}>
            <NotificationsList
              notifications={notifications.filter(({ author }) => author === 'chatCpg')}
            />
          </TabPanel>
        </TabsProvider>
      </ContextMenu>
    </PopoverContent>
  );
}

const tabPanelStyle: CSSProperties = {
  width: '100%',
  padding: '0 1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
};

interface NotificationsListProps {
  notifications: Notification[];
}

function NotificationsList({ notifications }: NotificationsListProps) {
  const { t } = useTranslation();
  const setNotifications = useNotificationsDispatch();

  const handleLink = (notificationId: string) => {
    setNotifications((notifications) =>
      notifications.map((n) => (n.id === notificationId ? { ...n, isNew: false } : n))
    );
  };

  return (
    <Flex column as="ul" gap="1rem" width="100%">
      {notifications.map(
        ({ author, authorColor, id, isNew, isPrediction, text, to, fakeTimestamp }) => (
          <Flex
            as="li"
            key={`notification-${id}`}
            align="center"
            justify="space-between"
            width="100%"
          >
            <Flex gap="0.2rem" align="center">
              <StyledBar color={isNew ? 'var(--brand-secondary)' : 'var(--space)'} />
              <ClearIcon
                as="button"
                size="10px"
                hoverFill="var(--white-70)"
                onClick={() => {
                  setNotifications((prev) => prev.filter((n) => n.id !== id));
                }}
              />
            </Flex>
            <StyledLink
              onClick={() => {
                handleLink(id);
              }}
              to={to}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLink(id);
                }
              }}
            >
              {author === 'chatCpg' ? (
                <ChatCpgIcon size="24px" />
              ) : (
                <AuthorAvatar author={author} background={authorColor} size="24px" />
              )}
              <Typography multiline style={{ maxWidth: '300px' }}>
                {isPrediction && <span className="h-12-b">{t('alerts.prediction')}</span>}
                {text}
              </Typography>
              <Typography secondary nowrap style={{ minWidth: '100px' }}>
                {fakeTimestamp}
                {/* {formatDate(timestamp, 'full')} */}
              </Typography>
              <PickerRightIcon />
            </StyledLink>
          </Flex>
        )
      )}
    </Flex>
  );
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  justify-content: flex-start;

  cursor: pointer;
`;

const StyledBar = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  min-height: 30px;
  min-width: 2px;
`;
