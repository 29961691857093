import { forwardRef, PropsWithChildren } from 'react';

interface TableHeadProps {
  style?: React.CSSProperties;
}

export const TableHead = forwardRef<HTMLTableSectionElement, PropsWithChildren<TableHeadProps>>(
  ({ style, children }, ref) => {
    return (
      <thead style={style} ref={ref}>
        {children}
      </thead>
    );
  }
);
