import { useContext } from 'react';
import { ContextType, DialogContext } from './DialogContext';

export function useDialogContext(): Exclude<ContextType, null> {
  const context = useContext(DialogContext);

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
}
