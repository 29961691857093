import styled from '@emotion/styled';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { ErrorIcon } from '../Icons/ErrorIcon';
import { useApp } from '../Providers/AppProvider/useApp';
import { multilineCSS } from '../helpers/multilineCSS';
import { Flex } from './Flex';
import { Paragraph, Typography, Variant } from './Typography';

type SupportedTags = 'p' | 'h2' | 'h3';

interface SimpleTextEditorProps {
  as?: SupportedTags;
  color?: string;
  focusBackground?: string;
  initialValue: string;
  maxLength?: number;
  onSuccess: (value: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  variant?: Variant;
}

export function SimpleTextEditor({
  as = 'p',
  color = 'var(--text)',
  disabled = false,
  focusBackground = 'var(--black-50)',
  initialValue,
  maxLength = 255,
  onSuccess,
  style,
  variant,
}: SimpleTextEditorProps) {
  const { tinyMceApiKey } = useApp();
  const [text, setText] = useState(initialValue);

  return (
    <StyledFlex
      column
      className={variant}
      focusBackground={focusBackground}
      gap="0.4rem"
      error={!!(text && text?.length > maxLength)}
      style={{ color, ...style }}
      variant={variant}
    >
      <Editor
        apiKey={tinyMceApiKey}
        disabled={disabled}
        init={{
          setup: (editor) => {
            editor.on('keydown', (e) => {
              if (e.key === 'Escape' || e.key === 'Enter') {
                e.preventDefault();
                editor.getElement().blur();
              }
            });
          },
          width: '100%',
          contextmenu: false,
          inline: true,
          menubar: false,
          toolbar: false,
          skin: 'oxide-dark',
          content_css: 'dark',
          branding: false,
        }}
        initialValue={initialValue}
        onEditorChange={(_, editor) => setText(editor.getContent({ format: 'text' }))}
        onFocusOut={(_, editor) => {
          const newText = editor.getContent({ format: 'text' }).trim();

          if (!newText) {
            setText(initialValue);
            return;
          }

          if (newText.length > maxLength) {
            return;
          }

          if (newText && newText !== initialValue) {
            onSuccess(newText);
          }
        }}
        value={`<${as}>${text}</${as}>`}
      />
      {text && text?.length > maxLength && (
        <Flex width="100%" align="center" gap="0.4rem">
          <ErrorIcon color="var(--danger)" size="18px" />
          <Typography color="var(--danger)">
            {text.length}/{maxLength}
          </Typography>
        </Flex>
      )}
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{
  focusBackground: string;
  error?: boolean;
  variant?: Variant;
}>`
  overflow: auto;
  padding: 2px;
  text-align: left;
  word-break: break-word;
  line-height: ${({ variant }) => multilineCSS[variant as Paragraph] ?? 'unset'};

  ${({ error }) => error && `div:focus { border: 1px solid var(--danger); }`}

  ${({ focusBackground }) =>
    `div:focus {
      background: ${focusBackground};
      border-radius: 6px;
    }`}
`;
