import { SerializedStyles, css } from '@emotion/react';

export const gradientWrapStyles = (): SerializedStyles => css`
  position: relative;
  justify-content: center;
  display: flex;
  bottom: 0;
  border-radius: var(--corner);
  padding: 1px;
  background: var(--heat-map-gradient);
`;
