import { useMemo } from 'react';
import { ChatElement } from '../ChatCpg/ChatElement';
import { Element } from '../Element/Element';
import { TickrElement } from '../Element/types/elementTypes';

export function useElementMap(elements: TickrElement[] = []) {
  return useMemo(() => {
    return {
      chatCpg: <ChatElement />,
      ...elements.reduce((acc, element) => {
        acc[String(element.id)] = <Element element={element} />;
        return acc;
      }, {} as Record<string, JSX.Element>),
    };
  }, [elements]);
}
