import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Flex } from '../../Core/Flex';
import { PickerLeftIcon } from '../../Icons/PickerLeftIcon';
import { PickerRightIcon } from '../../Icons/PickerRightIcon';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { NavPopoverContent, NavPopoverContentProps } from './NavPopoverContent';
import { NavPopoverTrigger } from './NavPopoverTrigger';

type NavPopoverProps = NavPopoverContentProps;

export function NavPopover({ pageLinks, activePageId }: NavPopoverProps) {
  const activePageIndex = pageLinks.findIndex((page) => page.pageId === activePageId);
  const pageTitle = pageLinks.find(({ pageId }) => pageId === activePageId)?.name ?? '';

  return (
    <Flex gap="1rem" style={{ zIndex: 3 }}>
      <Flex gap="2px" align="center">
        <StyledLink
          disabled={
            activePageIndex === -1 || activePageIndex === 0 || !pageLinks?.[activePageIndex - 1]?.to
          }
          orientation="left"
          to={pageLinks?.[activePageIndex - 1]?.to}
        >
          <StyledPickerLeftIcon color="var(--white)" />
        </StyledLink>
        <StyledLink
          orientation="right"
          disabled={activePageIndex === -1 || !pageLinks?.[activePageIndex + 1]?.to}
          to={pageLinks?.[activePageIndex + 1]?.to ?? '#'}
        >
          <StyledPickerRightIcon color="var(--white)" />
        </StyledLink>
      </Flex>

      <PopoverProvider placement="bottom-start">
        <NavPopoverTrigger pageTitle={pageTitle} />
        <NavPopoverContent pageLinks={pageLinks} activePageId={activePageId} />
      </PopoverProvider>
    </Flex>
  );
}

const StyledLink = styled(Link)<{ disabled: boolean; orientation: 'left' | 'right' }>`
  :hover {
    div {
      filter: brightness(0.8);

      :active {
        filter: brightness(0.6);
      }
    }
  }

  border-radius: ${({ orientation }) => {
    if (orientation === 'left') return 'var(--corner) 0px 0px var(--corner)';
    if (orientation === 'right') return '0px var(--corner) var(--corner) 0px';
  }};

  ${({ disabled }) =>
    disabled
      ? `
    pointer-events: none;
    opacity: 0.5;
    cursor: default;
    tab-index: -1;
  `
      : ''}
})}
`;

const StyledPickerLeftIcon = styled(PickerLeftIcon)`
  border-radius: var(--corner) 0px 0px var(--corner);

  min-width: 32px;
  min-height: 28px;
  background: var(--space);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPickerRightIcon = styled(PickerRightIcon)`
  border-radius: 0px var(--corner) var(--corner) 0px;

  min-width: 32px;
  min-height: 28px;
  background: var(--space);
  display: flex;
  justify-content: center;
  align-items: center;
`;
