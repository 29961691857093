import { createContext } from 'react';

export interface ProductAttributesFile {
  id: number;
  displayName: string;
  fileName: string;
  fileSize: number;
  rowCount: number;
  hiddenColumns: string[];
}

export const PAFilesContext = createContext<ProductAttributesFile[]>([]);
