import styled from '@emotion/styled';
import { CodeIcon } from '../Icons/CodeIcon';
import { Button } from './Button';
import { Flex } from './Flex';
import { Typography } from './Typography';

const BrokenBox = styled.div`
  border: 1px solid var(--danger);
  height: 100%;
  width: 100%;
  border-radius: var(--corner);
`;

interface ErrorBoxProps {
  text: string;
  onRetry?(): void;
}

export function ErrorBox({ text, onRetry }: ErrorBoxProps) {
  return (
    <BrokenBox>
      <Flex align="center" justify="center" height="100%" direction="column" gap="1rem">
        <CodeIcon color="var(--danger)" />
        <Typography color="var(--danger)" as="h3" variant="c-14" style={{ textAlign: 'center' }}>
          {text}
        </Typography>
        {onRetry && (
          <Button onClick={onRetry} color="var(--danger)">
            Retry
          </Button>
        )}
      </Flex>
    </BrokenBox>
  );
}
