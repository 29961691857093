import { Dispatch, SetStateAction, createContext } from 'react';

export type AlertLevel = 'info' | 'warning' | 'success' | 'error';

export interface AlertProps {
  title?: string;
  text: string;
  severity?: AlertLevel;
  onDismiss?(): void;
}

export interface AlertItem extends AlertProps {
  id?: string;
  timeout?: number;
}

export interface ToasterContextState {
  sendAlert(props: AlertItem): void;
  setAlerts: Dispatch<SetStateAction<AlertItem[]>>;
  removeAlert(id: string): void;
}

export const ToasterContext = createContext<ToasterContextState>({
  sendAlert: () => null,
  setAlerts: () => null,
  removeAlert: () => null,
});
