import { Children, PropsWithChildren } from 'react';
import { useMeasure } from 'react-use';
import { Grid } from '../Core/Grid';

const MIN_CHILD_WIDTH = 190;
const GRID_GAP = 15;

export function CollectionTileGrid({ children }: PropsWithChildren) {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  /* Makes children the same width whether the grid wraps or not */
  const fitChildren = Math.floor((width + GRID_GAP) / (MIN_CHILD_WIDTH + GRID_GAP));
  const childWidth = (width - GRID_GAP * (fitChildren - 1)) / fitChildren;
  const childrenCount = Children.count(children);
  const isWrapping = width < childrenCount * MIN_CHILD_WIDTH + (childrenCount - 1) * GRID_GAP;

  return (
    <Grid
      ref={ref}
      width="100%"
      gap={`${GRID_GAP}px`}
      templateColumns={`repeat(auto-fit, minmax(${MIN_CHILD_WIDTH}px,  ${
        isWrapping ? '1fr' : `${childWidth}px`
      }))`}
      autoRows="140px"
      height="100%"
      style={{ overflowY: 'scroll', flex: '1 1 auto' }}
    >
      {width > 0 && children}
    </Grid>
  );
}
