import some from 'lodash/some';
import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { RadioButton } from '../../Core/RadioButton';
import { SearchBar } from '../../Core/SearchBar';
import { Typography, Variant } from '../../Core/Typography';

export interface RadioFilterItem {
  value: string;
  title: string;
  subtitle?: string;
}

interface RadioFilterDisplayProps {
  autofocus?: boolean;
  filterId?: string;
  hideUntilSearch?: boolean;
  inputBorderColor?: string;
  onChange(value: string): void;
  searchPlaceholder?: string;
  searchWidth?: string;
  selected: string;
  style?: React.CSSProperties;
  values: RadioFilterItem[];
  variant?: Variant;
}

export function RadioFilterDisplay({
  autofocus,
  filterId,
  hideUntilSearch,
  inputBorderColor,
  onChange,
  selected,
  searchPlaceholder,
  searchWidth = '100%',
  style,
  values,
  variant,
  children,
}: PropsWithChildren<RadioFilterDisplayProps>) {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [showList, setShowList] = useState(false);

  return (
    <Flex direction="column" gap="1rem" padding="1rem" width="100%" style={style}>
      {values.length > 10 && (
        <Flex column gap="0.5rem" align="flex-end" width={searchWidth}>
          <SearchBar
            autofocus={autofocus}
            inputId={`${filterId}-search`}
            search={search}
            placeholder={searchPlaceholder ?? t('util.search')}
            onSearch={setSearch}
            inputWidth={searchWidth}
            poll
          />
          {hideUntilSearch && !showList && !search && (
            <Typography
              as="button"
              variant="p-14-r"
              color="var(--action)"
              onClick={() => setShowList(true)}
            >
              {t('util.orSelectFromAList')}
            </Typography>
          )}
        </Flex>
      )}
      {(!hideUntilSearch || showList || !!search) && (
        <Flex
          column
          as="ul"
          width="100%"
          height="100%"
          gap="1rem"
          padding="0 0 0 3px" // for focus ring
          style={{ flex: '1 1 auto', overflowY: 'scroll' }}
        >
          {values
            .filter(({ title, subtitle }) =>
              some(search)
                ? title.toLowerCase().includes(search.toLowerCase()) ||
                  subtitle?.toLowerCase().includes(search.toLowerCase())
                : true
            )
            .map(({ value, title, subtitle }) => (
              <RadioButton
                key={title}
                title={title}
                subtitle={subtitle}
                checked={selected === value}
                search={search}
                onChange={() => {
                  onChange(value);
                }}
                inputBorderColor={inputBorderColor}
                {...(filterId && { className: `${filterId}-${value}` })}
                variant={variant}
              />
            ))}
          {children}
        </Flex>
      )}
    </Flex>
  );
}
