import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import { useDebounce } from 'usehooks-ts';
import { SolutionButton } from '../ChatCpg/SolutionButton';
import { SolutionLink } from '../ChatCpg/SolutionLink';
import { Flex } from '../Core/Flex';
import { Grid } from '../Core/Grid';
import { SearchBar } from '../Core/SearchBar';
import { Typography } from '../Core/Typography';
import { CollectionTile } from '../Element/CollectionTile';
import { TickrPage } from '../Element/types/elementTypes';
import { Header } from '../PageLayout/Header';
import { TilePageLayout } from '../PageLayout/TilePageLayout';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { SortOrder } from '../helpers/sortTableData';
import { useCreateCustomAnalysis } from '../hooks/useCreateCustomAnalysis';

export interface SortOption {
  title: string;
  value: string;
  defaultOrder: SortOrder;
}

export function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { msId, userId } = useAuth();
  const { get } = useApi();
  const createCustomAnalysis = useCreateCustomAnalysis();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { data: recentPages = [] } = useQuery(
    ['recent-pages', debouncedSearch, userId],
    async () => {
      const { data: recentPages } = await get<TickrPage[]>('/v2/poplar/search-recent', {
        params: {
          search: debouncedSearch,
          populate: ['elements', 'author'],
        },
      });

      return recentPages.filter((page) => !page.settings.isScorecard);
    }
  );

  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <TilePageLayout pageTitle={t('page.home')} style={{ paddingBottom: '1rem' }}>
      <Header />
      <Flex
        column
        gap="3rem"
        width="100%"
        height="calc(100% - 60px)"
        padding="min(2%, 50px) 100px 0px 100px"
        style={{ overflowY: 'scroll', minHeight: '0px', maxWidth: '1500px', alignSelf: 'center' }}
      >
        <Flex direction="column" gap="1rem" width="100%">
          <Typography as="h2" variant="h-22-b">
            {t('chatCpg.chatCpgAiSolutions')}
          </Typography>
          <Flex direction="column" gap="1rem" width="100%">
            <Typography as="h2" variant="p-18-r" secondary style={{ letterSpacing: '1px' }}>
              {t('chatCpg.solutionSection.distribution')}
            </Typography>
            <Flex wrap="wrap" gap="1rem">
              <SolutionLink to="/">{t('chatCpg.analysis.benchmarkCompetitors')}</SolutionLink>
              <SolutionLink to="/">{t('chatCpg.analysis.identifyExpansion')}</SolutionLink>
            </Flex>
            <Typography as="h2" variant="p-18-r" secondary style={{ letterSpacing: '1px' }}>
              {t('chatCpg.solutionSection.pricing')}
            </Typography>
            <Flex wrap="wrap" gap="1rem">
              <SolutionLink to="/solutions/optimize-pricing" backLink="/home">
                {t('chatCpg.analysis.optimizePricing')}
              </SolutionLink>
              <SolutionLink to="/">{t('chatCpg.analysis.optimizePromotionTiming')}</SolutionLink>
              <SolutionLink to="/">{t('chatCpg.analysis.optimizeTradeFunds')}</SolutionLink>
              <SolutionLink to={msId === 24202 ? '/solutions/substitutions' : '/'}>
                {t('chatCpg.analysis.optimizeSubstitutions')}
              </SolutionLink>
            </Flex>
            <Typography as="h2" variant="p-18-r" secondary style={{ letterSpacing: '1px' }}>
              {t('chatCpg.solutionSection.createYourOwn')}
            </Typography>
            <Flex wrap="wrap" gap="1rem">
              <SolutionButton showPlus onClick={createCustomAnalysis}>
                {t('chatCpg.analysis.createNewAnalysis')}
              </SolutionButton>
            </Flex>
          </Flex>
        </Flex>
        {recentPages.length > 0 && (
          <Flex direction="column" gap="2rem" width="100%">
            <Flex gap="5rem" align="center">
              <Typography as="h2" variant="h-22-b">
                {t('home.recentAnalyses')}
              </Typography>
              <SearchBar
                inputId="my-work-search"
                search={search}
                placeholder={t('util.search')}
                onSearch={setSearch}
                inputWidth="300px"
                poll
              />
            </Flex>
            <Grid
              ref={ref}
              width="100%"
              gap="15px"
              templateColumns="repeat(auto-fit, 190px)"
              templateRows="140px"
              padding="2px 0 2px 6px"
              style={{
                maxWidth: '100%',
                maxHeight: '144px',
                gridAutoFlow: 'column',
                overflowX: 'hidden',
              }}
            >
              {recentPages.slice(0, Math.floor(width / 205)).map((page) => (
                <CollectionTile
                  key={page.id}
                  item={page}
                  baseZindex={5}
                  onClick={() => {
                    navigate(`/page/${page.id}`);
                  }}
                />
              ))}
            </Grid>
          </Flex>
        )}
      </Flex>
    </TilePageLayout>
  );
}
