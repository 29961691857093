import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider } from '../Core/Divider';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useDialogContext } from '../DialogFloating/useDialogContext';
import { PageSettingsDialog } from '../Dialogs/PageSettingsDialog/PageSettingsDialog';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { LogoutIcon } from '../Icons/LogoutIcon';
import { PickerRightIcon } from '../Icons/PickerRightIcon';
import { SettingsIcon } from '../Icons/SettingsIcon';
import { useApp } from '../Providers/AppProvider/useApp';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { OPTIMISTIC_PAGE_ID } from '../Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { getFirstTabId } from '../helpers/getFirstTabId';
import { useCollectionHelpers } from '../hooks/useCollectionHelpers';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { SidePanelButton } from './SidePanelButton';
import { SidePanelLink } from './SidePanelLink';
import { SidePanelPageLink } from './SidePanelPageLink';
import { SidePanelToggle } from './SidePanelToggle';
import { SidePanelWorkspaceLink } from './SidePanelWorkspaceLink';

interface SidePanelDialogContentProps {
  setSidePanelHidden: Dispatch<SetStateAction<boolean>>;
}

export function SidePanelDialogContent({ setSidePanelHidden }: SidePanelDialogContentProps) {
  const { setOpen } = useDialogContext();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createPage } = useCollectionMutations();
  const { isTemplateEditor } = useApp();
  const { logout, presentationMode, setPresentationMode } = useAuth();
  const { currentProject } = useCurrentProject();
  const currentPage = useCurrentPage();

  const { formatNewPage } = useCollectionHelpers();

  const onOpenChildDialog = () => {
    setSidePanelHidden(true);
  };

  const onCloseChildDialog = () => {
    setOpen(false);
    setSidePanelHidden(false);
  };

  const isScorecard = currentPage?.settings?.isScorecard;

  return (
    <Flex direction="column" height="100%" width="100%" gap="1rem">
      <Flex as="ul" direction="column" width="100%">
        {!isTemplateEditor ? (
          <>
            <SidePanelLink to="/home" title={t('page.home')} onClick={() => setOpen(false)}>
              <PickerRightIcon />
            </SidePanelLink>
            <SidePanelLink to="/my-work" title={t('page.myWork')} onClick={() => setOpen(false)}>
              <PickerRightIcon />
            </SidePanelLink>
            <SidePanelLink
              to="/smart-scorecard"
              title={t('page.smartScorecard')}
              onClick={() => setOpen(false)}
            >
              <PickerRightIcon />
            </SidePanelLink>
            <SidePanelLink
              to="/welcome"
              title={t('chatCpg.chatCpgAiSolutions')}
              onClick={() => setOpen(false)}
            >
              <ChatCpgIcon />
            </SidePanelLink>
          </>
        ) : (
          <SidePanelLink title="Template List" to="/templates" onClick={() => setOpen(false)}>
            <PickerRightIcon />
          </SidePanelLink>
        )}
      </Flex>
      {!!currentPage && !isTemplateEditor && (
        <>
          <Divider solid color="var(--white-10)" />
          <Flex as="ul" direction="column" width="100%">
            <SidePanelToggle
              title={t('sidePanel.presentationMode')}
              checked={presentationMode}
              onClick={() => setPresentationMode?.((presentationMode) => !presentationMode)}
            />
            {!isScorecard && (
              <PageSettingsDialog page={currentPage} onClose={onCloseChildDialog} isNested>
                <SidePanelButton title={t('sidePanel.pageSettings')} onClick={onOpenChildDialog}>
                  <PickerRightIcon />
                </SidePanelButton>
              </PageSettingsDialog>
            )}
            {/* Duplicate is not configured for pages with tabs yet */}
            {/* <SidePanelButton
                      title={t('sidePanel.duplicateCurrentPage')}
                      iconId="pickerRight"
                      onClick={async () => {
                        setOpen(false);
        
                        try {
                          const newPage = await duplicatePage({
                            ...currentPage,
                            elements: currentPageElements,
                          });
        
                          navigate(`/page/${newPage.id}`);
                        } catch (e) {
                          captureException(e);
        
                          sendAlert({
                            severity: 'error',
                            text: t('error.duplicatePage'),
                          });
                        }
        
                      }}
                    /> */}
          </Flex>
        </>
      )}
      <Divider solid color="var(--white-10)" />

      {!isTemplateEditor && (
        <Flex
          direction="column"
          width="100%"
          gap="0.4rem"
          style={{
            overflow: 'hidden',
            minHeight: '0px',
          }}
        >
          <Typography
            as="h2"
            variant="p-14-b"
            style={{ paddingLeft: '0.2rem', paddingBottom: '0.6rem' }}
          >
            {t('home.recentAnalyses')}
          </Typography>
          <Flex
            as="ul"
            direction="column"
            width="100%"
            height="100%"
            padding="2px"
            style={{
              flex: '1 1 auto',
              overflowY: 'scroll',
            }}
          >
            {currentProject.pages.reduce((acc, { title, id, tabs, settings }) => {
              if (id !== OPTIMISTIC_PAGE_ID && !settings.isScorecard) {
                acc.push(
                  <SidePanelPageLink
                    key={id}
                    title={title}
                    pageId={id}
                    tabId={getFirstTabId(tabs ?? [])}
                    onClick={() => setOpen(false)}
                  />
                );
              }

              return acc;
            }, [] as JSX.Element[])}
            <SidePanelWorkspaceLink
              title={t('sidePanel.createNewPage')}
              onClick={async () => {
                setOpen(false);

                const newPage = await createPage(
                  formatNewPage({
                    settings: {
                      layoutType: 'standard',
                      mosaicNode: 'chatCpg',
                    },
                  })
                );

                navigate(`/page/${newPage.id}`);
              }}
            />
          </Flex>
        </Flex>
      )}
      <Flex direction="column" width="100%">
        {!isTemplateEditor && (
          <SidePanelLink
            to="/settings"
            title={t('preferences.accountSettings')}
            onClick={() => setOpen(false)}
          >
            <SettingsIcon />
          </SidePanelLink>
        )}
        <SidePanelButton title={t('auth.logout')} onClick={logout}>
          <LogoutIcon />
        </SidePanelButton>
      </Flex>
    </Flex>
  );
}
