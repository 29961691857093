import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AlertsContext } from './AlertsContext';
import { AlertItem, ToasterContext } from './ToasterContext';

export function ToasterProvider({ children }: PropsWithChildren) {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);

  const sendAlert = useCallback((props: AlertItem) => {
    setAlerts((items) => [...items, { id: uuid(), ...props }]);
    return;
  }, []);

  const removeAlert = useCallback((id: string) => {
    setAlerts((items) => items.filter((item) => item.id !== id));
    return;
  }, []);

  const toaster = useMemo(
    () => ({ sendAlert, setAlerts, removeAlert }),
    [sendAlert, setAlerts, removeAlert]
  );

  return (
    <ToasterContext.Provider value={toaster}>
      <AlertsContext.Provider value={alerts}>{children}</AlertsContext.Provider>
    </ToasterContext.Provider>
  );
}
