import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { Grid } from '../../Core/Grid';
import { Typography } from '../../Core/Typography';
import { AppFilters } from '../../Filters/filterTypes';
import { SentimentDonut } from '../../Graphs/SentimentDonut';
import { ProgressBar } from './ProgressBar';
import { SentimentBar } from './SentimentBar';

interface ReportLayoutProps {
  currentStory?: number;
  isLoadingStories?: boolean;
  metrics: [string, number][];
  post: () => JSX.Element;
  sentimentAmount: number;
  sentimentFilters: AppFilters;
  sentimentTopic: string;
  sentimentType: string;
  totalStories?: number;
  type: string;
  setCurrentStoryIdx?: Dispatch<SetStateAction<number>>;
}

export function ReportLayout({
  metrics,
  post,
  sentimentAmount,
  sentimentFilters,
  sentimentTopic,
  sentimentType,
  type,
}: ReportLayoutProps) {
  const { t } = useTranslation();

  return (
    <Grid templateColumns="1fr 1fr 1fr" colGap="3rem" style={{ overflowY: 'auto' }}>
      <Flex column gap="1.5rem" style={{ overflowY: 'auto' }}>
        <Typography as="h2">{type}</Typography>
        {post()}
      </Flex>
      <Flex column gap="1.5rem" height="100%" style={{ overflowY: 'auto' }}>
        <Typography as="h2">{t('cardReport.metricBreakdown')}</Typography>
        <Flex
          direction="column"
          width="100%"
          gap="2rem"
          height="calc(100% + 20px)"
          style={{ overflowY: 'auto' }}
        >
          {metrics.map(([name, amount]) => (
            <ProgressBar key={name} amount={amount} name={name} />
          ))}
          <SentimentBar amount={sentimentAmount} />
        </Flex>
      </Flex>
      <Flex column gap="1.5rem" style={{ overflowY: 'auto' }}>
        <Typography as="h2">
          {sentimentType} - {sentimentTopic}
        </Typography>
        <SentimentDonut filters={sentimentFilters} />
      </Flex>
    </Grid>
  );
}
