import { useContext } from 'react';
import { PopoverContext, PopoverContextProps } from './PopoverContext';

export function usePopoverContext(): Exclude<PopoverContextProps, null> {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
}
