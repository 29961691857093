import { Indicator } from '../../Core/Indicator';
import { NotificationIcon } from '../../Icons/NotificationIcon';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { useNotifications } from '../../Providers/NotificationsProvider/useNotifications';
import { headerIconSharedProps } from '../../helpers/headerIconSharedProps';
import { NotificationsPopoverContent } from './NotificationsPopoverContent';

export function NotificationsPopover() {
  const notifications = useNotifications();
  const unreadNotificationCount = notifications.filter(({ isNew }) => isNew).length;

  return (
    <PopoverProvider placement="bottom-start">
      <PopoverTrigger asChild>
        <NotificationIcon as="button" {...headerIconSharedProps}>
          {unreadNotificationCount > 0 && (
            <Indicator top="3px" right="3px">
              {unreadNotificationCount}
            </Indicator>
          )}
        </NotificationIcon>
      </PopoverTrigger>
      <NotificationsPopoverContent />
    </PopoverProvider>
  );
}
