import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { SettingsPage } from './SettingsPage';
import { UsersPageContent } from './UsersPageContent';

export const UsersRoutes: FC = () => {
  const { t } = useTranslation();

  return (
    <SettingsPage
      // icon="verifiedUser"
      title={t('preferences.accountSettings.users')}
    >
      <Routes>
        <Route path="/" element={<UsersPageContent />} />
      </Routes>
    </SettingsPage>
  );
};
