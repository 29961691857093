import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PinIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1317 3.98959L14.0104 1.86827H12.5962L11.5356 0.807614C11.145 0.41709 10.5119 0.41709 10.1213 0.807614C9.73081 1.19814 9.73081 1.8313 10.1213 2.22183L10.2446 2.34506L4.56153 6.56151L3.75738 5.75736C3.36685 5.36684 2.73369 5.36684 2.34316 5.75736C1.95264 6.14789 1.95264 6.78105 2.34316 7.17158L5.8787 10.7071L0.92895 15.6569V17.0711H2.34316L7.29291 12.1213L10.8284 15.6569C11.219 16.0474 11.8521 16.0474 12.2427 15.6569C12.6332 15.2663 12.6332 14.6332 12.2427 14.2426L11.4385 13.4385L15.655 7.75546L15.7782 7.87868C16.1687 8.26921 16.8019 8.26921 17.1924 7.87868C17.5829 7.48816 17.5829 6.85499 17.1924 6.46447L16.1317 5.40381V3.98959ZM14.2253 6.32581L11.6742 3.77471L5.99118 7.99116L10.0089 12.0089L14.2253 6.32581Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
