import { createContext, Dispatch } from 'react';
import { ProductAttributeFilters } from '../ActivePAFiltersProvider/ActivePAFiltersContext';

export type StagedPAFiltersAction =
  | {
      type: 'add';
      payload: ProductAttributeFilters;
    }
  | {
      type: 'remove';
      payload: string;
    }
  | {
      type: 'selectCheckbox';
      payload: {
        filterId: string;
        value: string;
      };
    }
  | {
      type: 'deselectCheckbox';
      payload: {
        filterId: string;
        value: string;
      };
    }
  | {
      type: 'deselectAll';
      payload: string;
    };

export const StagedPAFiltersDispatchContext = createContext<Dispatch<StagedPAFiltersAction>>(
  () => null
);
