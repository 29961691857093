import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PptxIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="50" height="60" viewBox="0 0 50 60" fill="none">
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15381 24.9997V3.23047H31.2968V13.9338C31.2968 15.5906 32.6399 16.9338 34.2968 16.9338H45V54.6481H7.15381V41.9997H32C33.6569 41.9997 35 40.6565 35 38.9997V27.9997C35 26.3428 33.6569 24.9997 32 24.9997H7.15381ZM4.15381 24.9997V3.23047C4.15381 1.57361 5.49696 0.230469 7.15381 0.230469H31.7278C32.7886 0.230469 33.806 0.651896 34.5562 1.40204L34.6978 1.54368L34.711 1.55675L46.6738 13.5196L46.6868 13.5326L46.8284 13.6743C47.5786 14.4244 48 15.4419 48 16.5027V54.6481C48 56.305 46.6569 57.6481 45 57.6481H7.15381C5.49696 57.6481 4.15381 56.305 4.15381 54.6481V41.9997H3C1.34315 41.9997 0 40.6565 0 38.9997V27.9997C0 26.3428 1.34315 24.9997 3 24.9997H4.15381ZM34.2968 13.9338V5.38529L42.8453 13.9338H34.2968Z"
          fill="#FF584B"
        />
        <path
          d="M7.0077 29.5997H3.8757V37.9997H5.5317V35.2397H7.0077C8.6277 35.2397 9.8757 33.9917 9.8757 32.4197C9.8757 30.8477 8.6277 29.5997 7.0077 29.5997ZM7.0077 33.6917H5.5317V31.1477H7.0077C7.7157 31.1477 8.2317 31.6877 8.2317 32.4197C8.2317 33.1397 7.7157 33.6917 7.0077 33.6917ZM14.0858 29.5997H10.9538V37.9997H12.6098V35.2397H14.0858C15.7058 35.2397 16.9538 33.9917 16.9538 32.4197C16.9538 30.8477 15.7058 29.5997 14.0858 29.5997ZM14.0858 33.6917H12.6098V31.1477H14.0858C14.7938 31.1477 15.3098 31.6877 15.3098 32.4197C15.3098 33.1397 14.7938 33.6917 14.0858 33.6917ZM23.4356 29.5997H17.2556V31.1837H19.5116V37.9997H21.1676V31.1837H23.4356V29.5997ZM30.7561 37.9997L28.1761 33.6797L30.6121 29.5997H28.7401L27.2401 32.1197L25.7401 29.5997H23.8681L26.3041 33.6917L23.7361 37.9997H25.6081L27.2401 35.2637L28.8721 37.9997H30.7561Z"
          fill="#15161D"
        />
      </svg>
    </IconWrapper>
  );
}
