import { createContext, Dispatch } from 'react';
import { AppFilters } from '../filterTypes';

export type ActiveFiltersAction =
  | {
      type: 'add';
      payload: AppFilters;
    }
  | {
      type: 'replace';
      payload: AppFilters;
    }
  | {
      type: 'remove';
      payload: keyof AppFilters;
    };

export const ActiveFiltersDispatchContext = createContext<Dispatch<ActiveFiltersAction>>(
  () => null
);
