import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';

More(Highcharts);

Highcharts.setOptions({
  accessibility: {
    enabled: false,
  },
  lang: {
    thousandsSep: ',',
  },
  chart: {
    backgroundColor: 'var(--background)',
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  title: {
    text: '',
    style: {
      color: 'var(--text)',
    },
  },
  subtitle: {
    style: {
      color: 'var(--text)',
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--text)',
    },
    itemHoverStyle: {
      color: 'var(--text-secondary)',
    },
  },
  xAxis: {
    title: {
      text: '',
      style: { color: 'var(--text)' },
    },
    labels: {
      style: {
        color: 'var(--text)',
        fontSize: '12px',
      },
    },
    gridLineColor: 'var(--clay)',
    className: 'h-12-b',
  },
  yAxis: {
    title: {
      text: '',
      style: { color: 'var(--text)' },
    },
    labels: {
      style: {
        color: 'var(--text)',
        fontSize: '12px',
      },
    },
    gridLineColor: 'var(--clay)',
    opposite: true,
    className: 'h-12-b',
  },
  tooltip: {
    backgroundColor: 'var(--background)',
    valueDecimals: 2,
    style: {
      color: 'var(--text)',
    },
    dateTimeLabelFormats: {
      day: '%a, %b %e, %Y',
      hour: '%a, %b %e, %Y',
    },
  },
  credits: {
    enabled: false,
  },
});
