import { GridColumnGroupHeaderParams } from '@mui/x-data-grid-pro';
import { Typography } from '../../Core/Typography';

interface HeaderGroupCellProps extends GridColumnGroupHeaderParams {
  secondary?: boolean;
}

export function HeaderGroupCell({ secondary, groupId }: HeaderGroupCellProps) {
  return (
    <Typography secondary={secondary} variant="h-16-b">
      {groupId}
    </Typography>
  );
}
