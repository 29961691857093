import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InflationChartData } from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { getUnixFromIsoWeek } from '../helpers/getUnixFromIsoWeek';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useDateTimeLabelFormats } from '../hooks/useDateTimeLabelFormats';
import { useLegendClick } from '../hooks/useLegendClick';
import { HighchartsContainer } from './HighchartsContainer';

export function CategoryVsCpi() {
  const { t } = useTranslation();
  const dateTimeLabelFormats = useDateTimeLabelFormats();
  const { workflowId } = useTalkingPointsPage();
  const inflationChartData = useChatCpgData<InflationChartData>(['inflation-chart', workflowId]);

  const chartData = useMemo(() => {
    return {
      category: {
        trend: inflationChartData?.category.map(([x, y]) => [getUnixFromIsoWeek(x), y]) ?? [],
      },
      cpilfens: {
        trend: inflationChartData?.cpilfens.map(([x, y]) => [getUnixFromIsoWeek(x), y]) ?? [],
      },
    };
  }, [inflationChartData]);

  const options = useMemo(() => {
    const highchartsOptions: Highcharts.Options = {
      tooltip: {
        shared: true,
        dateTimeLabelFormats,
        borderColor: 'var(--geyser)',
      },
      chart: {
        animation: false,
      },
      xAxis: {
        type: 'datetime',
        startOfWeek: 6,
        crosshair: true,
      },
      legend: {
        enabled: false,
      },
      yAxis: [
        {
          type: 'linear',
          opposite: false,
          labels: {
            style: {
              color: 'var(--turquoise)',
            },
          },
        },
        {
          type: 'linear',
          opposite: true,
        },
      ],
      series: [
        {
          name: t('metrics.categoryPriceIndex'),
          type: 'line',
          color: 'var(--turquoise)',
          dashStyle: 'Solid',
          lineWidth: 2,
          data: chartData.category.trend,
          yAxis: 0,
          tooltip: {
            valueDecimals: 2,
          },
        },
        {
          name: t('metrics.cpilfens'),
          type: 'line',
          color: 'var(--cornflower-blue)',
          dashStyle: 'Solid',
          lineWidth: 2,
          data: chartData.cpilfens.trend,
          yAxis: 1,
          tooltip: {
            valueDecimals: 1,
          },
        },
      ],
    };

    return highchartsOptions;
  }, [t, chartData, dateTimeLabelFormats]);

  const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick();

  return (
    <Flex
      width="100%"
      height="100%"
      justify="stretch"
      align="stretch"
      direction="column"
      style={{ minHeight: 0, minWidth: 0 }}
    >
      <SlideCarousel width="100%">
        <ValueBox
          label={t('metrics.categoryPriceIndex')}
          color="var(--turquoise)"
          secondary
          allNeutral
          onClick={() => handleLegendClick('categoryPriceIndex', 0)}
          style={{
            opacity: hiddenTrends.includes('categoryPriceIndex') ? 0.5 : 1,
          }}
        />
        <ValueBox
          label={t('metrics.cpilfens')}
          color="var(--cornflower-blue)"
          secondary
          allNeutral
          onClick={() => handleLegendClick('cpilfens', 1)}
          style={{
            opacity: hiddenTrends.includes('cpilfens') ? 0.5 : 1,
          }}
        />
      </SlideCarousel>
      <HighchartsContainer options={options} ref={chartRef} />
    </Flex>
  );
}
