import { NewsCard, ParsedObject } from '../types/libBiTypes';

export type MultiCard = { key: 'news'; payload: NewsCard };

export function labelCards(data: ParsedObject<unknown[]>): MultiCard[] {
  return Object.entries(data)
    .map(([key, value]) => {
      if (!value.primary) return [];
      return value.primary.map((payload) => ({ key, payload } as MultiCard));
    })
    .flat();
}

export function sortCards(cards: MultiCard[]): MultiCard[] {
  return cards.sort((a, b) => b.payload.metrics.impact - a.payload.metrics.impact);
}
