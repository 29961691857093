import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

const StyledBar = styled(Flex)`
  background: linear-gradient(90deg, var(--negative) 0%, transparent 50%, var(--positive) 100%);
  border-radius: var(--corner);
`;

const Indicator = styled.div<{ left: number }>`
  position: absolute;
  width: 8px;
  height: 20px;
  background-color: var(--geyser);
  top: -8px;
  left: calc(${({ left }) => left}px - 4px);
  border-radius: var(--corner);
  border: 1px solid var(--clay);
`;

interface SentimentBarProps {
  amount: number;
}

export function SentimentBar({ amount }: SentimentBarProps) {
  const { t } = useTranslation();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Flex width="100%" direction="column" gap="0.8rem">
      <Flex align="center" justify="space-between" width="100%">
        <Typography variant="p-14-b">{t('metrics.sentiment')}</Typography>
        <Typography color="var(--action)" variant="n-14-b">
          {amount}
        </Typography>
      </Flex>

      <StyledBar width="100%" height="4px" ref={ref}>
        {width !== 0 && <Indicator left={((amount + 100) / 200) * width} />}
      </StyledBar>
    </Flex>
  );
}
