import styled from '@emotion/styled';
import { TableRow } from './TableRow';

const HIGHLIGHT_THICKNESS = '1px';

interface HighlightRowProps {
  active: boolean;
  color: string;
  onClick?: () => void;
}

export const HighlightRow = styled(TableRow)<HighlightRowProps>`
  box-shadow: ${({ active, color, onClick }) =>
    active && onClick ? `inset 0px 0px 0px ${HIGHLIGHT_THICKNESS} ${color}` : 'none'};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  border-radius: var(--corner);
`;
