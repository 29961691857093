import { captureException } from '@sentry/react';
import { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { FeedbackDialog } from '../Dialogs/FeedbackDialog/FeedbackDialog';
import { BackupIcon } from '../Icons/BackupIcon';
import { ThumbDownIcon } from '../Icons/ThumbDownIcon';
import { ThumbUpIcon } from '../Icons/ThumbUpIcon';
import { FeedbackType } from '../Providers/ChatCpgMutationsProvider/ChatCpgMutationsContext';
import { useChatCpgMutations } from '../Providers/ChatCpgMutationsProvider/useChatCpgMutations';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useDateFormatter } from '../hooks/useDateFormatter';

interface FeedbackControlsProps extends Omit<FeedbackButtonsProps, 'chatMessageId'> {
  style?: React.CSSProperties;
  timestamp?: number;
}

export const FeedbackControls = forwardRef<HTMLDivElement, FeedbackControlsProps>(
  (
    {
      iconBackground = 'inherit',
      iconHoverBackground = 'var(--space)',
      feedbackType,
      isEdited,
      isStreaming,
      onRestore,
      style,
      talkingPointId,
      text,
      timestamp,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const format = useDateFormatter();

    return (
      <Flex
        align="center"
        width="100%"
        gap="0.2rem"
        justify="space-between"
        style={{ minHeight: '24px', ...style }}
        ref={ref}
      >
        <Flex gap="0.3rem" align="center">
          <Typography secondary>
            {isEdited
              ? t('chatCpg.editedByUser')
              : isStreaming
              ? t('chatCpg.generating')
              : t('chatCpg.byChatCpg')}
          </Typography>
          <Typography secondary>·</Typography>
          <Typography secondary>
            {isStreaming || !timestamp ? t('util.now') : format(timestamp, 'fromNow')}
          </Typography>
        </Flex>
        <FeedbackButtons
          iconBackground={iconBackground}
          iconHoverBackground={iconHoverBackground}
          isEdited={isEdited}
          isStreaming={isStreaming}
          onRestore={onRestore}
          talkingPointId={talkingPointId}
          text={text}
          feedbackType={feedbackType}
        />
      </Flex>
    );
  }
);

interface FeedbackButtonsProps {
  chatMessageId?: string;
  feedbackType?: FeedbackType;
  iconBackground?: string;
  iconHoverBackground?: string;
  isEdited: boolean;
  isStreaming: boolean;
  onRestore?: () => void;
  talkingPointId?: string;
  text: string;
}

export const FeedbackButtons: FC<FeedbackButtonsProps> = ({
  chatMessageId,
  feedbackType,
  iconBackground,
  iconHoverBackground,
  isEdited,
  isStreaming,
  onRestore,
  talkingPointId,
  text,
}) => {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const { postChatFeedback, postSummaryFeedback } = useChatCpgMutations();
  const { sendAlert } = useToaster();

  return (
    <Flex gap="0.2rem">
      {onRestore && (
        <BackupIcon
          as="button"
          color="var(--text-secondary)"
          onClick={onRestore}
          background={iconBackground}
          hoverBackground={iconHoverBackground}
          hoverFill="var(--white)"
          style={{
            ...(isStreaming && { visibility: 'hidden' }),
          }}
        />
      )}

      {!isEdited && !isStreaming && (
        <>
          <ThumbUpIcon
            as="button"
            color="var(--text-secondary)"
            background={iconBackground}
            hoverBackground={iconHoverBackground}
            hoverFill="var(--white)"
            onClick={async () => {
              try {
                if (chatMessageId) {
                  await postChatFeedback({
                    category: '',
                    chatMessageId,
                    feedback: '',
                    explanation: '',
                    label: 'good',
                  });
                } else if (talkingPointId && feedbackType) {
                  await postSummaryFeedback({
                    category: '',
                    feedback: '',
                    explanation: '',
                    talkingPointId,
                    label: 'good',
                    type: feedbackType,
                    workflowId,
                  });
                } else {
                  throw new Error('No talkingPointId or chatMessageId');
                }

                sendAlert({
                  severity: 'success',
                  text: t('feedback.thankYou'),
                });
              } catch (e) {
                captureException(e);

                sendAlert({
                  severity: 'error',
                  text: t('feedback.error'),
                });
              }
            }}
          />
          <FeedbackDialog
            chatMessageId={chatMessageId}
            talkingPointId={talkingPointId}
            text={text}
            feedbackType={feedbackType}
          >
            <ThumbDownIcon
              as="button"
              color="var(--text-secondary)"
              background={iconBackground}
              hoverBackground={iconHoverBackground}
              hoverFill="var(--white)"
            />
          </FeedbackDialog>
        </>
      )}
    </Flex>
  );
};
