import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ChangeIndicator } from '../Core/ChangeIndicator';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { Typography } from '../Core/Typography';
import { useActiveFilters } from '../Filters/ActiveFiltersProvider/useActiveFilters';
import { AppFilters } from '../Filters/filterTypes';
import { useBi } from '../hooks/useBi';
import { DonutGraph } from './DonutGraph';

interface SentimentDonutProps {
  filters?: AppFilters;
}

export function SentimentDonut({ filters = {} }: SentimentDonutProps) {
  const { t } = useTranslation();
  const bi = useBi();
  const { combinedFilters } = useActiveFilters();

  const { data: sentiment } = useQuery(['sentiment', combinedFilters, filters], async () => {
    const { sentimentSocial } = await bi('fieldVolume', {
      filters: {
        ...combinedFilters,
        ...filters,
      },
      options: {
        mixins,
        transforms: [
          {
            type: 'sortlessMerge',
            args: {
              type: 'sentimentSocial',
              types: mixins.map((m) => m.type),
              label: 'socialSentiment',
            },
          },
        ],
      },
    });

    const positive = sentimentSocial.primary?.find((s) => s.term === 'positive')?.value ?? 0;
    const negative = sentimentSocial.primary?.find((s) => s.term === 'negative')?.value ?? 0;
    const neutral = sentimentSocial.primary?.find((s) => s.term === 'neutral')?.value ?? 0;

    const positiveSecondary =
      sentimentSocial.secondary?.find((s) => s.term === 'positive')?.value ?? 0;

    const negativeSecondary =
      sentimentSocial.secondary?.find((s) => s.term === 'negative')?.value ?? 0;

    const neutralSecondary =
      sentimentSocial.secondary?.find((s) => s.term === 'neutral')?.value ?? 0;

    const total = positive + negative + neutral;
    const totalSecondary = positiveSecondary + negativeSecondary + neutralSecondary;

    return {
      primary: {
        percentPositive: Math.round(100 * (positive / total)),
        percentNegative: Math.round(100 * (negative / total)),
        percentNeutral: Math.round(100 * (neutral / total)),
        positive,
        negative,
        neutral,
      },
      secondary: {
        percentPositive: Math.round(100 * (positiveSecondary / totalSecondary)),
        percentNegative: Math.round(100 * (negativeSecondary / totalSecondary)),
        percentNeutral: Math.round(100 * (neutralSecondary / totalSecondary)),
        positive: positiveSecondary,
        negative: negativeSecondary,
        neutral: neutralSecondary,
      },
    };
  });

  return sentiment ? (
    <DonutGraph
      containerPadding="0 10px"
      data={
        sentiment.primary.neutral === 0 && sentiment.secondary.neutral === 0
          ? [
              {
                label: t('metrics.sentiment.positive'),
                color: 'var(--positive)',
                inner: sentiment.primary.positive,
                outer: sentiment.secondary.positive,
                diff:
                  sentiment.primary.percentPositive / 100 -
                  sentiment.secondary.percentPositive / 100,
              },
              {
                label: t('metrics.sentiment.negative'),
                color: 'var(--negative)',
                inner: sentiment.primary.negative,
                outer: sentiment.secondary.negative,
                diff:
                  sentiment.primary.percentNegative / 100 -
                  sentiment.secondary.percentNegative / 100,
              },
            ]
          : [
              {
                label: t('metrics.sentiment.positive'),
                color: 'var(--positive)',
                inner: sentiment.primary.positive,
                outer: sentiment.secondary.positive,
                diff:
                  sentiment.primary.percentPositive / 100 -
                  sentiment.secondary.percentPositive / 100,
              },
              {
                label: t('metrics.sentiment.neutral'),
                color: 'var(--neutral)',
                inner: sentiment.primary.neutral === 0 ? undefined : sentiment.primary.neutral,
                outer: sentiment.secondary.neutral === 0 ? undefined : sentiment.secondary.neutral,
                diff:
                  sentiment.primary.percentNeutral / 100 - sentiment.secondary.percentNeutral / 100,
              },
              {
                label: t('metrics.sentiment.negative'),
                color: 'var(--negative)',
                inner: sentiment.primary.negative,
                outer: sentiment.secondary.negative,
                diff:
                  sentiment.primary.percentNegative / 100 -
                  sentiment.secondary.percentNegative / 100,
              },
            ]
      }
    >
      <Flex direction="column" align="center" justify="space-evenly" width="100%" height="100%">
        <Flex direction="column" justify="center" align="center">
          <NumberFormatter
            as="p"
            variant="n-24-b"
            color="var(--positive)"
            val={sentiment.primary.percentPositive / 100}
            numberStyle={{ numberFormat: 'percentage', showTrailingZeros: false }}
          />
          <Typography color="var(--text-secondary)" variant="c-11">
            {t('metrics.sentiment.positive')}
          </Typography>
        </Flex>

        <ChangeIndicator
          val={sentiment.primary.percentPositive / 100 - sentiment.secondary.percentPositive / 100}
          numberStyle="percentagePoint"
          customSuffix={t('metrics.ppVsPrevious')}
          nowrap={false}
          allNeutral
        />
      </Flex>
    </DonutGraph>
  ) : (
    <></>
  );
}

const mixins = [
  {
    type: 'sentimentCrimsonSocial',
    comparison: true,
  },
  {
    type: 'sentimentOracleESDFSocial',
    comparison: true,
  },
  {
    type: 'sentimentSysomosListenSocial',
    comparison: true,
  },
  {
    type: 'sentimentTwitter',
    comparison: true,
  },
  {
    type: 'sentimentStilingueSocial',
    comparison: true,
  },
  {
    type: 'sentimentNews',
    comparison: true,
  },
];
