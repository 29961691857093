import { CSSProperties, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import { HighlightRow } from './HighlightRow';
import { Table } from './Table';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableHead } from './TableHead';
import { TableHeadCell } from './TableHeadCell';
import { TableRow } from './TableRow';

type SortOrder = 'asc' | 'desc';

interface Column<DataItem> {
  align?: CSSProperties['textAlign'];
  cell?(item: DataItem): JSX.Element;
  cellStyle?: CSSProperties;
  defaultSortOrder?: SortOrder;
  disableSort?: boolean;
  field?: keyof DataItem;
  headCell?(): JSX.Element;
  headCellStyle?: CSSProperties;
  key?: string;
  title?: string;
}

function sortTableData<DataItem>(
  data: DataItem[],
  field: keyof DataItem | null,
  sortOrder: SortOrder
) {
  if (!field) return data;

  return [
    ...data.sort((a, b) => {
      if (sortOrder === 'asc') return a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
      if (sortOrder === 'desc') return a[field] < b[field] ? 1 : a[field] > b[field] ? -1 : 0;
      return 0;
    }),
  ];
}

interface ClickableTableProps<DataItem> {
  className?: string;
  columns: Column<DataItem>[];
  data: DataItem[];
  enableTableHeadHack?: boolean;
  highlightColor?: string;
  initActiveCol?: keyof DataItem;
  initActiveRow?: string | number;
  isLoading?: boolean;
  onRowClick?: (item: DataItem) => void;
  primaryColor?: string;
  secondaryColor?: string;
  style?: CSSProperties;
  tbodyStyle?: CSSProperties;
  theadStyle?: CSSProperties;
  width?: CSSProperties['width'];
}

export function ClickableTable<DataItem extends { id: string | number }>({
  className,
  columns,
  data,
  enableTableHeadHack,
  highlightColor = 'var(--action)',
  initActiveCol,
  initActiveRow,
  isLoading,
  onRowClick,
  primaryColor = 'var(--background)',
  secondaryColor = 'var(--clay)',
  tbodyStyle,
  theadStyle,
  width,
  style,
}: ClickableTableProps<DataItem>) {
  const [activeRow, setActiveRow] = useState<string | number>(initActiveRow ?? data?.[0]?.id ?? '');
  const [activeCol, setActiveCol] = useState<keyof DataItem | null>(initActiveCol ?? null);

  const [sortOrder, setSortOrder] = useState<SortOrder>(
    columns.find(({ field }) => field === initActiveCol)?.defaultSortOrder ?? 'desc'
  );

  const sortedData = useMemo(
    () => sortTableData(data, activeCol, sortOrder),
    [data, activeCol, sortOrder]
  );

  const [ref, { height }] = useMeasure<HTMLTableSectionElement>();

  return (
    <>
      {/* Hack to hide the body boxShadow (border) on scroll */}
      {enableTableHeadHack && (
        <div
          style={{
            position: 'fixed',
            width: 'calc(50% - 1.2rem)',
            height: `${height}px`,
            backgroundColor: 'var(--woodsmoke)',
            left: '50%',
          }}
        />
      )}
      <Table
        className={className}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        width={width}
        style={style}
      >
        <TableHead style={theadStyle} ref={ref}>
          <TableRow>
            {columns.map(
              ({
                align,
                defaultSortOrder,
                disableSort,
                field,
                headCell,
                headCellStyle,
                key,
                title,
              }) => {
                const isActive = field === activeCol;

                return (
                  <TableHeadCell
                    key={`header-cell-${field ? String(field) : title ?? key}`}
                    isLoading={isLoading}
                    isActive={isActive}
                    isDescending={sortOrder === 'desc'}
                    onClick={
                      disableSort || !field
                        ? undefined
                        : () => {
                            if (isActive) {
                              setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
                            } else {
                              setActiveCol(field);

                              setSortOrder(
                                defaultSortOrder ??
                                  (typeof data[0][field] === 'number' ? 'desc' : 'asc')
                              );
                            }
                          }
                    }
                    style={{
                      ...headCellStyle,
                      ...(align && { textAlign: align }),
                    }}
                  >
                    {headCell?.() ?? title ?? <></>}
                  </TableHeadCell>
                );
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody style={tbodyStyle}>
          {sortedData.map((item) => {
            return (
              <HighlightRow
                key={`table-row-${item.id}`}
                active={item.id === activeRow}
                color={highlightColor}
                onClick={
                  onRowClick
                    ? () => {
                        setActiveRow(item.id);
                        onRowClick?.(item);
                      }
                    : undefined
                }
              >
                {columns.map(({ align, field, cell, cellStyle, key, title }) => {
                  return (
                    <TableCell
                      key={`cell-${field ? String(field) : title ?? key}-${item.id}`}
                      style={{
                        ...cellStyle,
                        textAlign: align,
                      }}
                    >
                      {cell ? cell(item) : field ? <>{item[field]}</> : ''}
                    </TableCell>
                  );
                })}
              </HighlightRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
