import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { ChangeEvent, HTMLProps } from 'react';

type ToggleVariant = 'off-on' | 'on-on';
interface ToggleProps extends HTMLProps<HTMLInputElement> {
  checked: boolean;
  onSwitch(e?: ChangeEvent<HTMLInputElement>): void;
  label?: string;
  variant?: ToggleVariant;
}

const StyledLabel = styled.label<{ checked: boolean; variant: ToggleVariant }>`
  :focus-within {
    outline: 1px solid var(--geyser);
  }

  position: relative;
  min-width: 40px;
  max-width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ checked, variant }) =>
    variant === 'on-on'
      ? 'var(--neutral-shading)'
      : checked
      ? 'var(--action)'
      : 'var(--woodsmoke)'};
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0;

  .switchRound {
    position: absolute;
    top: 2px;
    right: ${({ checked }) => (checked ? '2px' : '22px')};
    width: 15px;
    height: 15px;
    border-radius: 100%;
    ${({ checked, variant }) =>
      variant === 'on-on'
        ? checked
          ? 'filter: drop-shadow(-3px 0 3px rgba(0, 0, 0, 0.35))'
          : 'filter: drop-shadow(3px 0 3px rgba(0, 0, 0, 0.35))'
        : ''};
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  cursor: pointer;
`;

export function Toggle({
  checked = false,
  onSwitch,
  label,
  id,
  name,
  variant = 'off-on',
}: ToggleProps) {
  return (
    <StyledLabel
      checked={checked}
      variant={variant}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onSwitch();
      }}
    >
      {label}
      <StyledInput type="checkbox" id={id} name={name} onChange={onSwitch} checked={checked} />
      <motion.div
        className="switchRound"
        animate={{
          backgroundColor: checked || variant === 'on-on' ? 'var(--white)' : 'var(--space)',
          right: checked ? 2 : 22,
        }}
      />
    </StyledLabel>
  );
}
