import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatBubbleHeader } from './ChatBubbleHeader';
import { DotLoader } from './DotLoader';
import { StreamCursor } from './StreamCursor';
import { SuggestionChip } from './SuggestionChip';

interface ChatCpgBubbleProps {
  chips?: string[];
  error?: boolean;
  fileName?: string;
  id: string;
  isStreaming?: boolean;
  text?: string;
  timestamp?: number;
}

export function ChatCpgBubble({
  chips,
  error,
  fileName,
  id,
  isStreaming,
  text,
  timestamp,
}: ChatCpgBubbleProps) {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      gap="1rem"
      padding="1rem"
      style={{
        borderRadius: '8px',
        backgroundColor: 'var(--clay)',
        maxWidth: '90%',
        border: `1px solid ${error ? 'var(--danger)' : 'transparent'}`,
      }}
    >
      <ChatBubbleHeader
        date={timestamp}
        hideFeedback={!!fileName}
        id={id}
        isStreaming={isStreaming}
        text={text}
      />
      {!error && fileName && !isStreaming && (
        <Typography variant="p-14-b" style={{ whiteSpace: 'pre-line' }}>
          {t('chatCpg.fileSummary.hereIsAFileSummary', { fileName })}
        </Typography>
      )}
      <Typography variant="p-14-b" style={{ whiteSpace: 'pre-line' }}>
        {!error ? text : t('chatCpg.chat.error')}
        {!error && isStreaming && !fileName && <StreamCursor />}
        {!error && isStreaming && fileName && (
          <Flex align="baseline" gap="4px">
            <Typography variant="p-14-b">{t('chatCpg.fileUpload.analyzing')}</Typography>
            <DotLoader />
          </Flex>
        )}
      </Typography>
      {chips && chips?.length > 0 && (
        <Typography variant="p-14-b">{t('chatCpg.fileSummary.youCanAskQuestions')}</Typography>
      )}
      <Flex column gap="0.5rem">
        {chips?.map((chip) => (
          <SuggestionChip key={chip} textAlign="left" text={chip} />
        ))}
      </Flex>
    </Flex>
  );
}
