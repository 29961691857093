import { useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { SimpleTextEditor } from '../Core/SimpleTextEditor';
import { Typography } from '../Core/Typography';
import { IntroCardPayload } from '../Element/types/elementTypes';
import { useElement } from '../Element/useElement';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useDateFormatter } from '../hooks/useDateFormatter';

type IntroCardProps = IntroCardPayload;

export function IntroCard({
  title,
  subtitle,
  text,
  footnote,
  background = 'var(--clay)',
}: IntroCardProps) {
  const { presentationMode } = useAuth();
  const { pageId, tabId } = useParams();
  const formatDate = useDateFormatter();
  const page = useCurrentPage();
  const element = useElement();
  const { updateElement } = useCollectionMutations();

  if (!page) return null;

  return (
    <Flex
      column
      gap="24px"
      padding="32px 48px"
      width="100%"
      height="100%"
      style={{
        background,
        borderRadius: '0 0 6px 6px',
        overflow: 'auto',
      }}
    >
      <Flex column gap="10px">
        <Typography as="h2" variant="h-30-b">
          {title}
        </Typography>
        <Typography variant="h-16-b">{subtitle}</Typography>
      </Flex>
      <Typography variant="h-16-b" secondary>
        {formatDate(page?.updatedAt ?? '', 'full')}
      </Typography>
      <SimpleTextEditor
        initialValue={text}
        maxLength={9999999}
        focusBackground="var(--woodsmoke)"
        onSuccess={(text) => {
          updateElement({
            element: {
              ...element,
              elementDef: {
                ...element.elementDef,
                key: 'introCard',
                payload: {
                  ...(element.elementDef.payload as IntroCardProps),
                  text,
                },
              },
            },
            tabId,
            pageId,
          });
        }}
        style={{ flexGrow: 0 }}
        variant="p-18-r"
        disabled={presentationMode}
      />
      <Typography variant="h-16-b" secondary>
        {footnote}
      </Typography>
    </Flex>
  );
}
