import { captureException } from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useActiveFiltersDispatch } from '../ActiveFiltersProvider/useActiveFiltersDispatch';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { AppFilters } from '../filterTypes';

interface FilterMenuFooterProps {
  onSave(filters: AppFilters): Promise<void>;
  filterId?: keyof AppFilters;
}

export function FilterMenuFooter({ filterId, onSave }: FilterMenuFooterProps) {
  const { t } = useTranslation();
  const { setOpen } = usePopoverContext();
  const dispatchActiveFilters = useActiveFiltersDispatch();

  const { stagedFilters, invalidFilters } = useStagedFilters();

  const isApplyDisabled = filterId
    ? invalidFilters.includes(filterId) || !stagedFilters[filterId]
    : Object.keys(stagedFilters).some((filterId) => !stagedFilters[filterId as keyof AppFilters]) ||
      !isEmpty(invalidFilters);

  return (
    <Flex
      width="100%"
      align="center"
      gap="1rem"
      padding="0.8rem"
      style={{
        boxShadow: '0px -1px 0.5rem var(--black-20)',
      }}
    >
      <Button
        color="var(--secondary-button)"
        onClick={() => setOpen(false)}
        width="50%"
        className="e2e-filter-menu-cancel"
      >
        {t('util.cancel')}
      </Button>
      <Button
        color="var(--action)"
        onClick={async () => {
          try {
            await onSave(stagedFilters);
            dispatchActiveFilters({ type: 'replace', payload: stagedFilters });
          } catch (e) {
            captureException(e);
          }

          setOpen(false);
        }}
        width="50%"
        disabled={isApplyDisabled}
        className="e2e-filter-menu-apply"
      >
        {t('util.apply')}
      </Button>
    </Flex>
  );
}
