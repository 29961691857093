import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ArrowTopRightIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M1.34061 0.343143C0.789725 0.343143 0.343144 0.789724 0.343145 1.34061V1.34061C0.343145 1.89115 0.789182 2.33758 1.33972 2.33808L8.24153 2.34425L0.343144 10.2426C-0.0473798 10.6332 -0.0473789 11.2663 0.343145 11.6569V11.6569C0.73367 12.0474 1.36683 12.0474 1.75736 11.6569L9.65574 3.75847L9.64957 10.656C9.64907 11.2086 10.1006 11.6569 10.6532 11.6569V11.6569C11.2055 11.6569 11.6569 11.2091 11.6569 10.6569L11.6569 1.34314C11.6569 0.790857 11.2091 0.343143 10.6569 0.343143L1.34061 0.343143Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
