import { useQuery } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../Providers/ApiProvider/useApi';
import { useAuth } from '../../Providers/AuthProvider/useAuth';
import {
  AvailableFiltersContext,
  AvailableFiltersProviderContextProps,
  DatasourceFilters,
  ProductAttributes,
  UniversalFilters,
} from './AvailableFiltersContext';

interface EntitySearchGroup {
  createdAt: string;
  deletedAt?: string;
  updatedAt?: string;
  entity_id: number;
  entity_searches_ids: number[];
  id: string;
  name: string;
}

// const FILTER_ORDER: Record<string, string[]> = {
//   datasources: ['ibotta', 'newsroom', 'numerator', 'iri', 'synth'],
//   universal: [
//     'dates',
//     'cpg_owned_products',
//     'cpg_competitor_products',
//     // 'brand',
//     // 'unit_price',
//   ],
//   newsroom: ['brand_entity_search_groups', 'nsfw'],
//   numerator: ['cpg_banner', 'category_id'],
//   synth: ['cpg_banner', 'category_id'],
//   ibotta: ['cpg_channel', 'age', 'registered_zip', 'ibotta_incentivised_purchase'], // retailer_channel
//   iri: [],
// };

const SAMPLE_AVAILABLE: DatasourceFilters = {
  universal: {
    // brand: {
    //   filterId: 'brand',
    //   displayName: 'Brand',
    //   values: [
    //     { title: 'Brand 1', value: 'brand_1' },
    //     { title: 'Brand 2', value: 'brand_2' },
    //     { title: 'Brand 3', value: 'brand_3' },
    //   ],
    //   type: 'checkbox',
    // },
    // unit_price: {
    //   filterId: 'unit_price',
    //   displayName: 'Unit Price',
    //   values: {
    //     min: 0,
    //     max: 100,
    //   },
    //   type: 'range',
    // },
  },
  newsroom: {
    brand_entity_search_groups: {
      filterId: 'brand_entity_search_groups',
      displayName: 'Topics',
      values: [],
      type: 'checkbox',
      isLoading: true,
    },
    nsfw: {
      filterId: 'nsfw',
      displayName: 'NSFW',
      values: [
        { title: 'Hide Unsafe Content', value: 'remove' },
        { title: 'Show Unsafe Content', value: 'include' },
      ],
      type: 'radio',
      defaultValue: 'remove',
      isLoading: false,
    },
  },
  numerator: {
    cpg_banner: {
      filterId: 'cpg_banner',
      displayName: 'Retailer',
      values: [
        { title: 'Albertsons', value: 'albertsons' },
        { title: "Fry's Marketplace", value: 'frys_marketplace' },
        { title: 'Jewel-Osco', value: 'jewel_osco' },
        { title: 'Kroger', value: 'kroger' },
        { title: 'Publix', value: 'publix' },
        { title: 'Target', value: 'target' },
        { title: 'Vons', value: 'vons' },
        { title: 'Walmart', value: 'walmart' },
        // { title: 'HEB', value: 'heb' },
        // { title: 'Wegmans', value: 'wegmans' },
      ],
      type: 'radio',
      defaultValue: 'walmart',
      isLoading: false,
    },
    // category_id: {
    //   filterId: 'category_id',
    //   displayName: 'Category',
    //   values: [
    //     { title: 'Category 1', value: 'category_1' },
    //     { title: 'Category 2', value: 'category_2' },
    //     { title: 'Category 3', value: 'category_3' },
    //   ],
    //   type: 'checkbox',
    // },
  },
  synth: {
    cpg_banner: {
      filterId: 'cpg_banner',
      displayName: 'Retailer',
      values: [
        { title: 'Albertsons', value: 'albertsons' },
        { title: "Fry's Marketplace", value: 'frys_marketplace' },
        { title: 'Jewel-Osco', value: 'jewel_osco' },
        { title: 'Kroger', value: 'kroger' },
        { title: 'Publix', value: 'publix' },
        { title: 'Target', value: 'target' },
        { title: 'Vons', value: 'vons' },
        { title: 'Walmart', value: 'walmart' },
        // { title: 'HEB', value: 'heb' },
        // { title: 'Wegmans', value: 'wegmans' },
      ],
      type: 'radio',
      defaultValue: 'walmart',
      isLoading: false,
    },
  },
  ibotta: {
    cpg_channel: {
      filterId: 'cpg_channel', // retailer_channel
      displayName: 'Channel',
      values: [
        { title: 'Big Box', value: 'Big Box' },
        { title: 'Club', value: 'Club' },
        { title: 'Convenience', value: 'Convenience' },
        { title: 'Dollar', value: 'Dollar' },
        { title: 'Grocery', value: 'Grocery' },
        { title: 'Liquor', value: 'Liquor' },
        { title: 'Online', value: 'Online' },
        { title: 'Other', value: 'Other' },
        { title: 'Pharmacy', value: 'Pharmacy' },
      ],
      type: 'radio',
      isLoading: false,
    },
    // ibotta_incentivised_purchase: {
    //   filterId: 'ibotta_incentivised_purchase',
    //   displayName: 'Incentivised Purchase',
    //   values: [
    //     { title: 'True', value: 'true' },
    //     { title: 'False', value: 'false' },
    //   ],
    //   type: 'checkbox',
    // },
    // registered_zip: {
    //   filterId: 'registered_zip',
    //   displayName: 'Registered ZIP Code',
    //   type: 'search',
    // },
    // age: {
    //   filterId: 'age',
    //   displayName: 'Age',
    //   values: {
    //     min: 0,
    //     max: 100,
    //   },
    //   type: 'range',
    // },
  },
};

/**
 * Going to put a note in here that the datasources should be coming
 * through the user obj now for all users on qa. The datasources are
 * now added as integrations during admin user creation BUT to make
 * it work on prod with existing demo users we'll have to add the
 * datasources as integrations manually to all of our demo accounts.
 * Then we can map marketspace.integrations into the format below.
 */
const AVAILABLE_DATASOURCES_BY_MS_ID: Record<number, string[]> = {
  25411: ['universal', 'newsroom', 'numerator', 'ibotta', 'iri'], // admin
  1805: ['universal', 'newsroom', 'numerator', 'ibotta'], // unilever
  24201: ['universal', 'newsroom', 'ibotta'], // clorox
  25027: ['universal', 'newsroom', 'numerator'], // kelloggs
  25417: ['universal', 'newsroom', 'numerator'], // frito lay
  24096: ['universal', 'newsroom', 'ibotta'], // reynolds
  25926: ['universal', 'newsroom', 'ibotta'], // general mills
  25028: ['universal', 'newsroom', 'ibotta'], // duke cannon
  26023: ['universal', 'newsroom', 'numerator', 'iri'], // ChatCPG Demo
  24202: ['universal', 'newsroom', 'numerator'], // Hershey
  26024: ['universal', 'newsroom', 'synth'], // Synth
};

export function AvailableFiltersProvider({ children }: PropsWithChildren) {
  const { msId, isFuzzy } = useAuth();
  const { get } = useApi();
  const { t } = useTranslation();

  const availableUserDatasources = AVAILABLE_DATASOURCES_BY_MS_ID[msId as number];

  const datasourceDisplayNames: Record<string, string> = useMemo(
    () => ({
      universal: t('filters.cpg_dataset.universal'),
      numerator: isFuzzy ? t('filters.cpg_dataset.synthetic') : t('filters.cpg_dataset.numerator'),
      ibotta: t('filters.cpg_dataset.ibotta'),
      iri: t('filters.cpg_dataset.iri'),
      nielsen: t('filters.cpg_dataset.nielsen'),
      spins: t('filters.cpg_dataset.spins'),
      '84.51': t('filters.cpg_dataset.84.51'),
      walmart: t('filters.cpg_dataset.walmartLuminate'),
      newsroom: t('filters.cpg_dataset.newsroom'),
      productAttributes: t('filters.cpg_dataset.productAttributes'),
      synth: t('filters.cpg_dataset.synth'),
    }),
    [t, isFuzzy]
  );

  const staticFilters: UniversalFilters = useMemo(
    () => ({
      dates: {
        filterId: 'dates',
        displayName: t('filters.dates'),
        type: 'dates',
        values: [
          {
            value: '30d/d',
            title: t('dates.30d/d'),
          },
          {
            value: '90d/d',
            title: t('dates.90d/d'),
          },
          {
            value: '12w/w',
            title: t('dates.12w/w'),
          },
          {
            value: '26w/w',
            title: t('dates.26w/w'),
          },
          {
            value: '52w/w',
            title: t('dates.52w/w'),
          },
        ],
        defaultValue: '52w/w',
        isLoading: false,
      },
      cpg_owned_products: {
        filterId: 'cpg_owned_products',
        displayName: t('filters.cpg_owned_products'),
        type: 'gtin',
        isLoading: false,
      },
      cpg_competitor_products: {
        filterId: 'cpg_competitor_products',
        displayName: t('filters.cpg_competitor_products'),
        type: 'gtin',
        isLoading: false,
      },
    }),
    [t]
  );

  const { data: datasourceFiltersData = {} } = useQuery(
    ['datasource-filters-query', msId, availableUserDatasources],
    async () => {
      // const response = await get(`/filters/${msId}/datasource`);
      return pick(SAMPLE_AVAILABLE, ...availableUserDatasources);
    }
  );

  const { data: topics = [] } = useQuery(['topics-filter'], async () => {
    const { data } = await get<EntitySearchGroup[]>(`/entity_searches/get_search_groups/`);

    const topicsValues = data.map(({ id, name }) => ({
      title: name,
      value: id.toString(),
    }));

    return topicsValues;
  });

  const { data: productAttributes = {} } = useQuery(
    ['productAttributesFilters', msId],
    async () => {
      const res = await get<{ productAttributes: ProductAttributes }>(
        `/v2/poplar/product-attributes/filters`
      );

      // I'm not sure if we want to allow this later. Going
      // to keep returning it from the api just in case so
      // it's a matter of deleting this delete.
      delete res?.data?.productAttributes['pa:_is_owned'];

      return res?.data?.productAttributes ?? {};
    },
    {
      enabled: !!msId,
    }
  );

  const datasourceFilters = useMemo(() => {
    const filtersWithoutUniversal = { ...datasourceFiltersData };
    delete filtersWithoutUniversal.universal;

    if (availableUserDatasources.includes('newsroom')) {
      filtersWithoutUniversal.newsroom = {
        ...filtersWithoutUniversal.newsroom,
        brand_entity_search_groups: {
          filterId: 'brand_entity_search_groups',
          displayName: t('filters.brand_entity_search_groups'),
          values: topics,
          isLoading: false,
          type: 'checkbox',
        },
      };
    }

    return filtersWithoutUniversal;
  }, [datasourceFiltersData, topics, availableUserDatasources, t]);

  const universalFilters = useMemo(
    () => ({
      ...datasourceFiltersData.universal,
      ...staticFilters,
    }),
    [datasourceFiltersData, staticFilters]
  );

  const userDatasources = useMemo(
    () =>
      Object.keys(datasourceFilters)
        .filter((key) => key !== 'universal')
        .map((value) => ({
          title: datasourceDisplayNames[value],
          value,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [datasourceFilters, datasourceDisplayNames]
  );

  const allUserDatasources = useMemo(
    () =>
      availableUserDatasources
        .filter((key) => key !== 'universal')
        .map((value) => ({
          title: datasourceDisplayNames[value],
          value,
        })),
    [availableUserDatasources, datasourceDisplayNames]
  );

  const memoizedContextValue = useMemo<AvailableFiltersProviderContextProps>(
    () => ({
      allUserDatasources,
      datasourceDisplayNames,
      datasourceFilters,
      universalFilters,
      userDatasources,
      productAttributes,
    }),
    [
      allUserDatasources,
      datasourceDisplayNames,
      datasourceFilters,
      userDatasources,
      universalFilters,
      productAttributes,
    ]
  );

  return (
    <AvailableFiltersContext.Provider value={memoizedContextValue}>
      {children}
    </AvailableFiltersContext.Provider>
  );
}
