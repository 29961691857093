import { AppFilters } from '../Filters/filterTypes';
import { useApi } from '../Providers/ApiProvider/useApi';
import { BiOptions, KeyedTrend, ParsedObject, TermValue, Trend } from '../types/libBiTypes';

type BiResource =
  | 'filters'
  | 'trends'
  | 'keyValues'
  | 'cards'
  | 'range'
  | 'fieldVolume'
  | 'agg'
  | 'keyedTrends'
  | 'volume'
  | 'pivot';

interface BiPayload {
  filters?: AppFilters;
  isErndc?: boolean;
  options: BiOptions;
}

export function useBi() {
  const api = useApi();

  function fetch<T = TermValue>(resource: 'filters', payload: BiPayload): Promise<ParsedObject<T>>;
  function fetch(resource: 'trends', payload: BiPayload): Promise<ParsedObject<Trend>>;
  function fetch(resource: 'range', payload: BiPayload): Promise<ParsedObject<unknown>>;
  function fetch<T = TermValue>(
    resource: 'fieldVolume',
    payload: BiPayload
  ): Promise<ParsedObject<T[]>>;
  function fetch(resource: 'agg', payload: BiPayload): Promise<ParsedObject<number>>;
  function fetch(resource: 'keyedTrends', payload: BiPayload): Promise<ParsedObject<KeyedTrend[]>>;
  function fetch(resource: 'keyValues', payload: BiPayload): Promise<ParsedObject<KeyedTrend[]>>;
  function fetch(resource: 'volume', payload: BiPayload): Promise<ParsedObject<number>>;
  function fetch<T>(resource: 'cards', payload: BiPayload): Promise<ParsedObject<T>>;
  function fetch<T>(resource: 'pivot', payload: BiPayload): Promise<T>;

  async function fetch(resource: BiResource, payload: BiPayload) {
    const { filters = {}, options } = payload;

    const res = await api.post<unknown>(
      `/bi/${resource}`,
      {
        filters,
        options: {
          obj: true,
          interval: (filters.dates as string)?.includes?.('w/w') ? '1w' : '1d',
          ...options,
        },
      },
      {
        headers: {
          'x-utc-offset': '+00:00',
        },
      }
    );

    return res?.data;
  }

  return fetch;
}
