import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { NewAlertDialogContent } from './NewAlertDialogContent';

interface NewAlertDialogProps {
  onClose: () => void;
}

export function NewAlertDialog({ onClose, children }: PropsWithChildren<NewAlertDialogProps>) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent variant="md" onClickOverlay={onClose}>
        <NewAlertDialogContent onClose={onClose} />
      </DialogContent>
    </DialogProvider>
  );
}
