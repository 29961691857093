import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PickerDownIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
        <path
          d="M7.70996 6.70992L13.593 0.987654C13.985 0.606343 14.6134 0.613414 15.0001 1.00012C15.3906 1.39064 15.3934 2.02658 15.0029 2.41711L8.41707 9.00289C8.02654 9.39342 7.39338 9.39342 7.00285 9.0029L0.417068 2.41711C0.0265435 2.02658 0.0265437 1.39342 0.417068 1.00289L0.431508 0.988454C0.818636 0.601326 1.4451 0.59747 1.83696 0.979802L7.70996 6.70992Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
