export function replaceNewlineWithBr(text?: string) {
  return text?.replace(/\n/g, '<br>') ?? '';
}

export function shortenGtin(text?: string) {
  return text?.replace(/\d{9}(\d{5})/gm, '...$1') ?? '';
}

export function replaceDoubleEscape(text?: string) {
  return text?.replace(/\\n/g, '\n') ?? '';
}
