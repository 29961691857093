import { MosaicNode } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { useCurrentElements } from '../hooks/useCurrentElements';
import { useCurrentTabs } from '../hooks/useCurrentTabs';
import { useElementMap } from '../hooks/useElementMap';
import { useNoDuplicateNodes } from '../hooks/useNoDuplicateNodes';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';
import { MosaicLayout } from './MosaicLayout';
import { doesNodeContainZero } from './doesNodeContainZero';

export function TabMosaic() {
  const { tabId } = useParams();
  const { tabs } = useCurrentTabs();
  const currentTabElements = useCurrentElements();
  const updateTabMosaic = useUpdateTabMosaic();

  const currentTab = tabs?.find(({ id }) => id === tabId);

  useNoDuplicateNodes({
    defaultNode: currentTab?.settings?.defaultMosaic ?? null,
    currentNode: currentTab?.settings?.mosaicNode ?? null,
    update: updateTabMosaic,
  });

  const elementMap = useElementMap(currentTabElements);

  if (!currentTab || !currentTabElements) return null;

  return (
    <MosaicLayout
      elementMap={elementMap}
      initialNode={currentTab.settings?.mosaicNode as MosaicNode<string>}
      onRelease={(currentNode) => {
        if (doesNodeContainZero(currentNode)) return;
        updateTabMosaic({ node: currentNode });
      }}
    />
  );
}
