/* eslint-disable no-case-declarations */
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { FilterDefinition } from '../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { CheckboxFilterItem } from '../Filters/Components/CheckboxFilterDisplay';
import { CustomDate, RangeFilter } from '../Filters/filterTypes';
import { useDateFormatter } from './useDateFormatter';

export function useFilterSummary() {
  const { t } = useTranslation();
  const dateFormatter = useDateFormatter();

  const getFilterSummary = ({
    filterDefinition,
    filterValue,
  }: {
    filterDefinition: FilterDefinition;
    filterValue: unknown;
  }): string => {
    if (!filterValue) return t('filters.noneSelected');

    switch (filterDefinition?.type) {
      case 'dates': {
        if (typeof filterValue === 'string') {
          return (
            (filterDefinition?.values as CheckboxFilterItem[])?.find(
              ({ value }) => value === filterValue
            )?.title ?? ''
          );
        }

        const from = dateFormatter((filterValue as CustomDate).from, 'full');
        const to = dateFormatter((filterValue as CustomDate).to, 'full');
        return t('dates.fromTo', { dateFrom: from, dateTo: to });
      }

      case 'checkbox':
      case 'gtin':
        const selected = (filterValue as string).split(',').filter((filter) => filter);
        const numSelected = t('filters.selected', { amount: selected.length });
        if (isEmpty(selected)) return numSelected;

        const selectedDisplayNames = (filterDefinition.values as CheckboxFilterItem[])
          .filter(({ value }) => selected.includes(value))
          .map(({ title }) => title);

        const joinedSelected = selectedDisplayNames.join(' • ');
        if (selected.length === 1 || joinedSelected.length < 100) return joinedSelected;
        return numSelected;

      case 'range':
        return t('filters.range.summary', {
          min: (filterValue as RangeFilter).min,
          max: (filterValue as RangeFilter).max,
        });

      case 'radio':
        return filterDefinition?.values?.find(({ value }) => value === filterValue)?.title ?? '';

      default:
        return '';
    }
  };

  return getFilterSummary;
}
