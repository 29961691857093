import omit from 'lodash/omit';
import { TickrElement, TickrPage } from '../Element/types/elementTypes';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { ElementToCreate } from '../Providers/CollectionMutationsProvider/CollectionMutationsContext';

export function useCollectionHelpers() {
  const { userId } = useAuth();

  const formatNewElement = (
    element: TickrElement,
    props: Partial<ElementToCreate> = {}
  ): ElementToCreate => ({
    ...omit(element, 'id'),
    authorId: userId,
    isPrivate: true,
    isTemplate: false,
    isTickr: false,
    templateId: element.id,
    version: 1,
    ...props,
  });

  const formatNewElementTemplate = (
    element: TickrElement,
    props: Partial<ElementToCreate> = {}
  ): ElementToCreate => ({
    ...omit(element, 'id'),
    authorId: userId,
    isTemplate: true,
    isTickr: false,
    pageId: null,
    templateId: null,
    version: 1,
    ...props,
  });

  const formatNewPage = (
    page: Partial<TickrPage> = {},
    props: Partial<TickrPage> = {}
  ): Partial<TickrPage> => ({
    filterDef: { activeFilters: {} },
    title: 'New Page',
    ...omit(page, 'id'),
    authorId: userId,
    isPrivate: true,
    isTemplate: false,
    isTickr: false,
    isOrgDefault: false,
    version: 1,
    ...props,
  });

  const formatNewPageTemplate = (
    page: TickrPage,
    props: Partial<TickrPage> = {}
  ): Partial<TickrPage> => ({
    ...omit(page, 'id'),
    authorId: userId,
    isTemplate: true,
    isOrgDefault: false,
    isTickr: false,
    templateId: null,
    version: 1,
    ...props,
  });

  return {
    formatNewElement,
    formatNewElementTemplate,
    formatNewPage,
    formatNewPageTemplate,
  };
}
