import { ThemeProvider, createTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-grid-layout/css/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import {
  AppProvider,
  AuthProvider,
  ErrorBox,
  ToasterDisplay,
  ToasterProvider,
  WebAppLocalizationProvider,
  i18n,
} from 'ui';
import 'ui/src/Charts/HighchartsConfig';
import { Pages } from './Pages';
import { queryClient } from './queryClient';

// NOTE: Don't move this import above the react-grid-layout imports
import 'ui/styles/App.css';

/*
init({
  enabled: import.meta.env.MODE !== 'development',
  dsn: 'https://f1f7fb0ef08746d3bc97f53912d68b2d@o4505036936839168.ingest.sentry.io/4505347760979968',
  integrations: [
    new BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // 'localhost',
        /^https:\/\/qa\.tids\.tickr\.com/,
        /^https:\/\/tids\.tickr\.com/,
        /^https:\/\/apiqa\.tickr\.com/,
        /^https:\/\/api2\.tickr\.com/,
      ],
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
*/

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export function App() {
  const [err, setError] = useState<string>('');

  return (
    <ErrorBoundary
      onError={(err) => {
        captureException(err);
        setError('Please try refreshing the page');
      }}
      fallback={<ErrorBox text={err} />}
    >
      <div style={{ background: 'inherit' }}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <AppProvider
              apiUrl={import.meta.env.VITE_BASE_API_URL}
              chatCpgUrl={import.meta.env.VITE_CHATCPG_PATH}
              dsUrl={import.meta.env.VITE_BASE_DS_URL}
              envMode={import.meta.env.MODE === 'production' ? 'prod' : 'qa'}
              tinyMceApiKey={import.meta.env.VITE_TINY_MCE_API_KEY}
            >
              <I18nextProvider i18n={i18n}>
                <WebAppLocalizationProvider>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <ToasterProvider>
                        <AuthProvider>
                          <Pages />
                          <ToasterDisplay />
                        </AuthProvider>
                      </ToasterProvider>
                    </BrowserRouter>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                  </QueryClientProvider>
                </WebAppLocalizationProvider>
              </I18nextProvider>
            </AppProvider>
          </ThemeProvider>
        </HelmetProvider>
        <div id="modal-portal" />
      </div>
    </ErrorBoundary>
  );
}
