import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  MosaicBranch,
  MosaicNode,
  createRemoveUpdate,
  getLeaves,
  updateTree,
} from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';

export function ChatButton() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const currentPage = useCurrentPage();
  const currentTab = useCurrentTab();
  const updateTabMosaic = useUpdateTabMosaic();
  const updatePageMosaic = useUpdatePageMosaic();

  return (
    <GradientWrap
      as="button"
      onClick={() => {
        const existingNode = tabId
          ? currentTab?.settings?.mosaicNode
          : currentPage?.settings?.mosaicNode;

        if (!existingNode) return;
        const leaves = getLeaves(existingNode);

        if (!leaves.includes('chatCpg')) {
          const newNode: MosaicNode<string> = {
            direction: 'row',
            first: existingNode,
            second: 'chatCpg',
          };

          tabId ? updateTabMosaic({ node: newNode }) : updatePageMosaic({ node: newNode });
        } else {
          const path = getPathById(existingNode, 'chatCpg');
          if (!path) return;
          const updatedTree = updateTree(existingNode, [createRemoveUpdate(existingNode, path)]);
          tabId ? updateTabMosaic({ node: updatedTree }) : updatePageMosaic({ node: updatedTree });
        }
      }}
    >
      <StyledFlex align="center" gap="8px" padding="2px 8px">
        <ChatCpgIcon size="20px" />
        <Typography variant="button-12">{t('chatCpg.chat')}</Typography>
      </StyledFlex>
    </GradientWrap>
  );
}

const StyledFlex = styled(Flex)`
  background: var(--woodsmoke);
  border-radius: var(--corner);
  min-height: 30px;
`;

const GradientWrap = styled.div`
  padding: 1px;
  background: var(--heat-map-gradient);
  border-radius: var(--corner);
`;

const getPathById = (
  node: MosaicNode<string>,
  id: string,
  path: MosaicBranch[] = []
): MosaicBranch[] | null => {
  if (typeof node === 'string') return node === id ? path : null;

  const firstPath = getPathById(node.first, id, [...path, 'first']);

  if (firstPath) return firstPath;

  const secondPath = getPathById(node.second, id, [...path, 'second']);

  if (secondPath) return secondPath;

  return null;
};
