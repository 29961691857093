import styled from '@emotion/styled';

type JustifyItems = 'start' | 'end' | 'center' | 'stretch';
type JustifyContent = JustifyItems | 'space-around' | 'space-between' | 'space-evenly';

export interface GridProps {
  width?: string;
  height?: string;
  padding?: string;
  templateColumns?: string;
  templateRows?: string;
  gap?: string;
  colGap?: string;
  rowGap?: string;
  flow?: 'row' | 'column';
  justifyItems?: JustifyItems;
  alignItems?: JustifyItems | 'baseline';
  justifyContent?: JustifyContent;
  alignContent?: JustifyContent;
  autoRows?: string;
  autoColumns?: string;
  zindex?: number;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  width: ${({ width }) => width ?? 'unset'};
  height: ${({ height }) => height ?? 'unset'};
  padding: ${({ padding }) => padding ?? '0'};
  grid-template-columns: ${({ templateColumns }) => templateColumns ?? 'unset'};
  grid-template-rows: ${({ templateRows }) => templateRows ?? 'unset'};
  row-gap: ${({ rowGap, gap }) => rowGap ?? gap ?? 'unset'};
  column-gap: ${({ colGap, gap }) => colGap ?? gap ?? 'unset'};
  grid-auto-flow: ${({ flow }) => flow ?? 'unset'};
  justify-items: ${({ justifyItems }) => justifyItems ?? 'unset'};
  align-items: ${({ alignItems }) => alignItems ?? 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'unset'};
  grid-auto-rows: ${({ autoRows }) => autoRows ?? 'unset'};
  grid-auto-columns: ${({ autoColumns }) => autoColumns ?? 'unset'};
  z-index: ${({ zindex }) => zindex ?? 'unset'};
`;
