import { createContext } from 'react';
import { TickrPageSettings } from '../../Element/types/elementTypes';

export interface TalkingPointsPageContextProps {
  isStreaming: boolean;
  settings: TickrPageSettings | null;
  workflowId: string;
}

export const TalkingPointsPageContext = createContext<TalkingPointsPageContextProps>({
  isStreaming: false,
  settings: null,
  workflowId: '',
});
