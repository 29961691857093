import { FC } from 'react';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { CheckboxFilter } from './CheckboxFilter';
import { DatesFilter } from './DatesFilter';
import { GTINFilter } from './GTINFilter';
import { RadioFilter } from './RadioFilter';

interface FilterSwitchProps {
  filterDefinition: FilterDefinition;
}

export const FilterSwitch: FC<FilterSwitchProps> = ({ filterDefinition }) => {
  const { type, filterId, values } = filterDefinition;

  switch (type) {
    case 'dates':
      return <DatesFilter />;
    case 'gtin':
      return <GTINFilter filterId={filterId} values={values} />;
    case 'checkbox':
      return <CheckboxFilter filterId={filterId} values={values} />;
    case 'radio':
      return <RadioFilter filterId={filterId} values={values} />;
    case 'range':
      return <p>Range Filter Here</p>;
    case 'search':
      return <p>Search Filter Here</p>;
    default:
      return <p>Unknown Filter Type</p>;
  }
};
