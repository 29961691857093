import { useId } from '@react-aria/utils';
import { arc, pie } from 'd3';
import isUndefined from 'lodash/isUndefined';
import { PropsWithChildren } from 'react';
import { ChangeIndicator } from '../Core/ChangeIndicator';
import { Circle } from '../Core/Circle';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { SlideCarousel } from '../Core/SlideCarousel';
import { Typography } from '../Core/Typography';
import { GraphProvider, NewGraphProps } from '../Graph/GraphProvider';
import { useGraph } from '../Graph/useGraph';
import { NumberStyle } from '../helpers/numberStyleHelper';

type DonutGraphProps = InnerDonutGraphProps & NewGraphProps;

export function DonutGraph({
  containerPadding,
  data,
  children,
}: PropsWithChildren<DonutGraphProps>) {
  return (
    <GraphProvider containerPadding={containerPadding}>
      <InnerDonutGraph data={data}>{children}</InnerDonutGraph>
    </GraphProvider>
  );
}

interface InnerDonutGraphProps {
  data: {
    inner?: number;
    outer?: number;
    numberStyle?: NumberStyle;
    color: string;
    label: string;
    legend?: DonutLegend;
    diff?: number;
  }[];
}

function InnerDonutGraph({ data, children }: PropsWithChildren<InnerDonutGraphProps>) {
  const { width, height } = useGraph();

  const id = useId();

  const innerValues = data
    .filter(({ inner }) => !isUndefined(inner))
    .map(({ inner }) => inner) as number[];

  const colors = data.map(({ color }) => color);
  const LEGEND_SPACE = 85;
  const centerWidth = Math.min(width, height - LEGEND_SPACE) / 2;
  const minGraphDim = Math.min(width, height - LEGEND_SPACE);

  const createPie = pie().sort(null);

  const donutThickness = minGraphDim / 15 > 16 ? 16 : minGraphDim / 15;

  const innerStart = centerWidth - donutThickness;
  const innerEnd = centerWidth;
  const innerData = createPie(innerValues);

  return (
    <g className="donut-graph">
      <g transform={`translate(${centerWidth}, ${centerWidth})`}>
        <g className="inner-arc">
          {innerData.map(({ value, startAngle, endAngle, index }, i) => (
            <Arc
              key={value + index}
              startAngle={startAngle}
              endAngle={endAngle}
              start={innerStart}
              end={innerEnd}
              fill={`url(#${id}-inner-${i})`}
            />
          ))}
        </g>
        <g className="inner-text">
          <foreignObject
            width={centerWidth + 20}
            height={centerWidth}
            x={-centerWidth / 2 - 10}
            y={-centerWidth / 2}
          >
            {children}
          </foreignObject>
        </g>
      </g>

      <g className="donut-graph-axis">
        <foreignObject width={width} height="80px" x={0} y={centerWidth * 2 + 20}>
          <SlideCarousel height="75px" width={`${width}px`}>
            {data.map(
              ({ label, numberStyle, color, inner, outer, legend, diff }) =>
                !isUndefined(inner) &&
                !isUndefined(outer) && (
                  <LegendItem
                    key={label}
                    numberStyle={numberStyle}
                    color={color}
                    inner={inner}
                    outer={outer}
                    label={label}
                    legend={legend}
                    diff={diff}
                  />
                )
            )}
          </SlideCarousel>
        </foreignObject>
      </g>

      {colors.map((color, i) => (
        <radialGradient
          key={color}
          gradientUnits="userSpaceOnUse"
          cx="0"
          cy="0"
          fr={Math.abs(innerStart)}
          r={Math.abs(innerEnd)}
          id={`${id}-inner-${i}`}
        >
          <stop offset="40%" stopColor={color} stopOpacity=".5" />
          <stop offset="60%" stopColor={color} stopOpacity="1" />
        </radialGradient>
      ))}
    </g>
  );
}

interface ArcProps {
  startAngle: number;
  endAngle: number;
  start: number;
  end: number;
  fill?: string;
  opacity?: number;
}

function Arc({ startAngle, endAngle, start, end, fill, opacity }: ArcProps) {
  const innerArc = arc().cornerRadius(3);
  return (
    <path
      d={
        innerArc({
          innerRadius: start,
          outerRadius: end,
          startAngle,
          endAngle,
          padAngle: 0.02,
        }) ?? ''
      }
      fill={fill}
      opacity={opacity}
    />
  );
}

interface LegendItem {
  val?: number;
  numberStyle?: NumberStyle;
}

interface DonutLegend {
  inner?: LegendItem;
  outer?: LegendItem;
  change?: LegendItem;
}

interface LegendItemProps {
  label: string;
  inner: number;
  outer: number;
  color: string;
  numberStyle?: NumberStyle;
  legend?: DonutLegend;
  diff?: number;
}

function LegendItem({ label, inner, outer, color, legend = {}, diff }: LegendItemProps) {
  return (
    <Flex direction="column" gap="4px" width="100%">
      <Flex align="flex-start" gap="4px">
        <Circle color={color} size="10px" border="2px solid var(--background)" />
        <Typography color="var(--text-secondary)" variant="c-11">
          {label}
        </Typography>
      </Flex>
      <Flex direction="column" align="flex-start" padding="0 0 0 14px">
        <Flex gap="10px">
          <NumberFormatter
            as="p"
            variant="n-20-b"
            val={legend?.inner?.val || inner}
            numberStyle="int"
          />
          <ChangeIndicator val={diff || NaN} numberStyle="percentagePoint" allNeutral />
        </Flex>

        <NumberFormatter
          as="p"
          variant="h-12-b"
          color="var(--text-secondary)"
          val={legend?.outer?.val || outer}
          numberStyle="int"
        />
      </Flex>
    </Flex>
  );
}
