import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const FlipIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          d="M0 10H2V8H0V10ZM0 14H2V12H0V14ZM2 18V16H0C0 17.1 0.89 18 2 18ZM0 6H2V4H0V6ZM12 18H14V16H12V18ZM16 0H6C4.89 0 4 0.9 4 2V12C4 13.1 4.89 14 6 14H16C17.1 14 18 13.1 18 12V2C18 0.9 17.1 0 16 0ZM16 12H6V2H16V12ZM8 18H10V16H8V18ZM4 18H6V16H4V18Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
