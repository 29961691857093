import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { Header } from '../../PageLayout/Header';
import { TilePageLayout } from '../../PageLayout/TilePageLayout';

export function SolutionLayout() {
  const { t } = useTranslation();
  const outletContext = useOutletContext();
  const { pathname } = useLocation();

  const tabTitles: Record<string, string> = {
    '/solutions/optimize-pricing': t('chatCpg.analysis.optimizePricing'),
  };

  const pageNames: Record<string, string> = {
    '/solutions': t('chatCpg.page.solutions'),
    '/solutions/optimize-pricing': t('chatCpg.page.solutions.optimizePricing'),
    '/solutions/substitutions': t('chatCpg.page.solutions.optimizeSubstitutions'),
  };

  return (
    <TilePageLayout
      hideOutlet
      pageTitle={tabTitles[pathname] ?? pageNames[pathname] ?? t('chatCpg.page.solutions')}
      style={{
        background: 'linear-gradient(to right, var(--woodsmoke) 63%, var(--black) 63% 100%)',
      }}
    >
      <Header pageTitle={pageNames[pathname] ?? t('chatCpg.page.solutions')} />
      <Outlet context={outletContext} />
    </TilePageLayout>
  );
}
