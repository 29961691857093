import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';
import { Typography } from '../Core/Typography';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';

interface SuggestionChipProps {
  onClick?: (message: string) => void;
  style?: React.CSSProperties;
  text: string;
  textAlign?: CSSProperties['textAlign'];
}

export function SuggestionChip({
  children,
  onClick,
  text,
  textAlign = 'center',
  style,
}: PropsWithChildren<SuggestionChipProps>) {
  const { onClickSuggestion } = useChatBoxForm();

  return (
    <StyledTypography
      as="button"
      variant="h-12-b"
      onClick={() => (onClick ? onClick(text) : onClickSuggestion(text))}
      multiline
      textAlign={textAlign}
      style={style}
    >
      {children ?? text}
    </StyledTypography>
  );
}

const StyledTypography = styled(Typography)`
  border-radius: 8px;
  padding: 4px 12px;
  border: 1px solid var(--lavender-70);

  :focus-visible {
    outline: 1px solid var(--geyser);
  }
`;
