import { createContext } from 'react';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';

export interface AvailablePAFiltersContextProps {
  filters: Record<string, FilterDefinition>;
  columns: string[];
}

export const AvailablePAFiltersContext = createContext<AvailablePAFiltersContextProps>({
  filters: {},
  columns: [],
});
