import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useApp } from '../Providers/AppProvider/useApp';

const StyledLayout = styled.div<{ showBackgroundDetail?: boolean; scroll?: boolean }>`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'hidden')};
  background: ${({ showBackgroundDetail }) =>
    showBackgroundDetail ? 'var(--talking-point-background)' : 'inherit'};
`;

interface PageLayoutProps {
  pageTitle?: string;
  showBackgroundDetail?: boolean;
  scroll?: boolean;
  style?: CSSProperties;
}

export function PageLayout({
  pageTitle,
  showBackgroundDetail,
  scroll = true,
  style,
  children,
}: PropsWithChildren<PageLayoutProps>) {
  const { t } = useTranslation();
  const { isTemplateEditor } = useApp();
  const outletContext = useOutletContext();

  let titleText = t('helmet.title', { page: pageTitle ?? t('page.home') });

  if (window.location.host === 'chatcpg.ai')
    titleText = t('helmet.title.chatCPG', { page: t('page.home') });

  if (isTemplateEditor)
    titleText = t('helmet.title.templateEditor', { page: pageTitle ?? t('page.home') });

  return (
    <StyledLayout showBackgroundDetail={showBackgroundDetail} scroll={scroll} style={style}>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <Outlet context={outletContext} />
      {children}
    </StyledLayout>
  );
}
