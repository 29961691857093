import styled from '@emotion/styled';
import { FC } from 'react';
import { Circle } from './Circle';
import { Flex } from './Flex';
import { Typography } from './Typography';

export type LegendIndicator = 'circle' | 'shortDash' | 'dash' | 'area' | 'line' | 'column';

interface LegendSeriesProps {
  circleBorder?: string;
  color: string;
  indicator?: LegendIndicator;
  indicatorBackground?: string;
  label: string;
  selected?: string;
}

export function LegendSeries({
  circleBorder,
  color,
  indicator,
  indicatorBackground,
  label,
  selected,
}: LegendSeriesProps) {
  return (
    <Flex gap="0.625rem" align="center">
      {indicator && (
        <Flex
          gap="3px"
          style={{ background: indicatorBackground }}
          height="20px"
          width="20px"
          align="center"
        >
          {indicator === 'circle' && (
            <Circle size="7px" color={color} border={circleBorder || '1px solid var(--black)'} />
          )}
          {indicator === 'shortDash' && <ShortDashIndicator color={color} />}
          {indicator === 'dash' && <DashIndicator color={color} />}
          {indicator === 'area' && <AreaIndicator color={color} />}
          {indicator === 'line' && <StyledRect color={color} width="15px" />}
          {indicator === 'column' && <ColumnIndicator color={color} />}
        </Flex>
      )}
      <Typography
        variant="c-11"
        color={selected === label?.toLowerCase() ? 'var(--black)' : 'var(--text)'}
        nowrap
        style={{ paddingTop: '1px' }}
      >
        {label}
      </Typography>
    </Flex>
  );
}

interface IndicatorProps {
  color: string;
}

const ShortDashIndicator: FC<IndicatorProps> = ({ color }) => (
  <Flex gap="1px">
    <StyledRect color={color} width="6px" />
    <StyledRect color={color} width="6px" />
    <StyledRect color={color} width="6px" />
  </Flex>
);

const DashIndicator: FC<IndicatorProps> = ({ color }) => (
  <Flex gap="5px">
    <StyledRect color={color} width="8px" />
    <StyledRect color={color} width="8px" />
  </Flex>
);

const AreaIndicator: FC<IndicatorProps> = ({ color }) => (
  <Flex column>
    <StyledRect color={color} width="15px" height="3px" borderRadius="0" />
    <StyledRect color={color.replace(')', '-50)')} width="15px" height="10px" borderRadius="0" />
  </Flex>
);

const ColumnIndicator: FC<IndicatorProps> = ({ color }) => (
  <Flex gap="2px" align="flex-end">
    <Flex column align="baseline">
      <StyledRect color={color} width="5px" height="3px" borderRadius="0" />
      <StyledRect color={color.replace(')', '-50)')} width="5px" height="6px" borderRadius="0" />
    </Flex>
    <Flex column align="baseline">
      <StyledRect color={color} width="5px" height="3px" borderRadius="0" />
      <StyledRect color={color.replace(')', '-50)')} width="5px" height="10px" borderRadius="0" />
    </Flex>
    <Flex column align="baseline">
      <StyledRect color={color} width="5px" height="3px" borderRadius="0" />
      <StyledRect color={color.replace(')', '-50)')} width="5px" height="8px" borderRadius="0" />
    </Flex>
  </Flex>
);

const StyledRect = styled.div<{
  color: string;
  width: string;
  height?: string;
  borderRadius?: string;
}>`
  background-color: ${({ color }) => color};
  width: ${({ width }) => width};
  height: ${({ height }) => height ?? '2px'};
  border-radius: ${({ borderRadius }) => borderRadius ?? '3px'};
`;
