import styled from '@emotion/styled';
import { Flex } from 'ui/src/Core/Flex';
import { Typography } from 'ui/src/Core/Typography';

interface SettingsButtonProps {
  name: string;
  // iconId: IconId;
  onClick(): void;
  isActive: boolean;
}

export function SettingsButton({
  name,
  // iconId,
  isActive,
  onClick,
}: SettingsButtonProps) {
  return (
    <StyledWrapper
      as="button"
      gap="1rem"
      width="100%"
      padding="0.8rem"
      isActive={isActive}
      onClick={onClick}
    >
      {/* <Icon iconId={iconId} /> */}
      <Typography variant="p-14-b">{name}</Typography>
    </StyledWrapper>
  );
}

const StyledWrapper = styled(Flex)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? 'var(--space)' : 'var(--background)')};

  :hover {
    opacity: 0.8;
  }
`;
