import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChatButton } from '../ChatCpg/ChatButton';
import { Flex } from '../Core/Flex';
import { AddElementDialog } from '../Dialogs/AddElementDialog/AddElementDialog';
import { ActiveFiltersProvider } from '../Filters/ActiveFiltersProvider/ActiveFiltersProvider';
import { ActivePAFiltersProvider } from '../Filters/ActivePAFiltersProvider/ActivePAFiltersProvider';
import { DataAddIcon } from '../Icons/DataAddIcon';
import { AutoArrangeButton } from '../MosaicLayout/AutoArrangeButton';
import { RestoreDefaultButton } from '../MosaicLayout/RestoreDefaultButton';
import { SaveDefaultButton } from '../MosaicLayout/SaveDefaultButton';
import { PageSwitch } from '../Page/PageSwitch';
import { Header } from '../PageLayout/Header';
import { PageLayout } from '../PageLayout/PageLayout';
import { FilterMenuPopover } from '../Popovers/FilterMenuPopover/FilterMenuPopover';
import { SharingPopover } from '../Popovers/SharingPopover/SharingPopover';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { ChatBoxProvider } from '../Providers/ChatBoxProvider/ChatBoxProvider';
import { OPTIMISTIC_PAGE_ID } from '../Providers/CollectionMutationsProvider/CollectionMutationsProvider';
import { TalkingPointsPageProviderWrapper } from '../Providers/TalkingPointsPageProvider/TalkingPointsPageProviderWrapper';
import { getFirstTabId } from '../helpers/getFirstTabId';
import { headerIconSharedProps } from '../helpers/headerIconSharedProps';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { PopulatedProject, useCurrentProject } from '../hooks/useCurrentProject';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { useSavePageFilter } from '../hooks/useSavePageFilter';

export function Dashboard() {
  const navigate = useNavigate();
  const { presentationMode } = useAuth();
  const { pageId } = useParams();
  const { currentProject } = useCurrentProject();
  const currentTab = useCurrentTab();
  const currentPage = useCurrentPage();
  const onSaveFilter = useSavePageFilter({ page: currentPage });

  useEffect(() => {
    if ((currentProject as PopulatedProject)?.id && currentProject?.pages.length === 0) {
      navigate('/home');
    }

    const optimisticExists = !!currentProject?.pages.find((page) => page.id === OPTIMISTIC_PAGE_ID);

    if (
      pageId &&
      !isEmpty(currentProject?.pages) &&
      !currentPage &&
      !optimisticExists // if optimistic page exists, don't auto nav. wait on route for the real id before navigating
    ) {
      const tabId = getFirstTabId(currentProject?.pages[0]?.tabs ?? []);

      const url = tabId
        ? `/page/${currentProject?.pages[0].id}/tab/${tabId}`
        : `/page/${currentProject?.pages[0].id}`;

      navigate(url);
    }
  });

  const isScorecard = currentPage?.settings?.isScorecard;

  const mosaicNode = currentTab?.settings?.mosaicNode ?? currentPage?.settings.mosaicNode;

  return (
    <ActivePAFiltersProvider
      key={currentPage?.id}
      filterScope={currentPage?.filterDef?.productAttributeFilters ?? {}}
    >
      <ActiveFiltersProvider filterScope={currentPage?.filterDef?.activeFilters ?? {}}>
        <TalkingPointsPageProviderWrapper page={currentPage}>
          <ChatBoxProvider>
            <PageLayout
              pageTitle={currentPage?.title}
              showBackgroundDetail={currentPage?.settings?.layoutType === 'talkingPoints'}
            >
              <Header
                endChildren={
                  <Flex align="center" gap="2rem">
                    {!presentationMode && (
                      <Flex align="center" gap="0.6rem">
                        {!currentPage?.settings?.isScorecard && (
                          <SaveDefaultButton mosaicNode={mosaicNode} />
                        )}
                        <RestoreDefaultButton mosaicNode={mosaicNode} />
                        <AutoArrangeButton mosaicNode={mosaicNode} />
                      </Flex>
                    )}
                    <ChatButton />
                  </Flex>
                }
              >
                <SharingPopover />
                {!isScorecard && (
                  <AddElementDialog>
                    <DataAddIcon as="button" size="14px" {...headerIconSharedProps} />
                  </AddElementDialog>
                )}
                <FilterMenuPopover onSave={onSaveFilter} />
                {/* <ActiveFiltersSummary onSave={onSaveFilter} /> */}
              </Header>
              <PageSwitch />
            </PageLayout>
          </ChatBoxProvider>
        </TalkingPointsPageProviderWrapper>
      </ActiveFiltersProvider>
    </ActivePAFiltersProvider>
  );
}
