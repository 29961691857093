import { useTranslation } from 'react-i18next';
import { InfluencerData } from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { Typography } from '../Core/Typography';
import { InformationIcon } from '../Icons/InformationIcon';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { ScoreCell } from '../Table/Cells/ScoreCell';
import { SimpleProductCell } from '../Table/Cells/SimpleProductCell';
import { ClickableTable } from '../Table/ClickableTable';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useChatCpgData } from '../hooks/useChatCpgData';

export function InfluentialCompetitors() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const influencerData = useChatCpgData<InfluencerData>(['influencers', workflowId]);

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      style={{ minHeight: 0, minWidth: 0, overflow: 'hidden' }}
    >
      <Flex width="100%" justify="space-between" padding="6px">
        <Typography variant="p-14-r" multiline secondary style={{ maxWidth: '400px' }}>
          {t('table.influentialCompetitors.description')}
        </Typography>
        <InfoTooltip
          containerStyle={{
            flexDirection: 'column',
            borderRadius: '32px',
            padding: '20px',
            gap: '1rem',
          }}
          leaveOpenOnHover
          component={() => (
            <>
              <Typography width="100%" as="h2" style={{ textAlign: 'left' }}>
                {t('chatCpg.howAreTheseCompetitorsIdentified')}
              </Typography>
              <Typography variant="h-12-b" multiline>
                {t('chatCpg.howAreTheseCompetitorsIdentified.description1')}
              </Typography>
              <Typography variant="h-12-b" multiline>
                {t('chatCpg.howAreTheseCompetitorsIdentified.description2')}
              </Typography>
            </>
          )}
        >
          <InformationIcon style={{ cursor: 'pointer' }} />
        </InfoTooltip>
      </Flex>
      <Flex width="100%" height="100%" style={{ minWidth: 0, overflowY: 'scroll' }}>
        {influencerData?.influencers && (
          <ClickableTable
            width="100%"
            className="e2e-influential-competitors-table"
            initActiveCol="influence"
            columns={[
              {
                title: t('metrics.product'),
                field: 'id',
                cell: ({ name, id }) => <SimpleProductCell name={name} id={id} width="200px" />,
              },
              {
                title: t('metrics.influenceScore'),
                field: 'influence',
                align: 'right',
                cell: ({ influence }) => (
                  <ScoreCell score={influence} className="e2e-influence-score" />
                ),
              },
              {
                title: t('metrics.priceL12w'),
                field: 'price',
                align: 'right',
                cell: ({ price }) => (
                  <NumberFormatter
                    as="p"
                    variant="h-16-b"
                    numberStyle="currency"
                    val={price}
                    className="e2e-influential-competitor-latest-price"
                    style={{ textAlign: 'right' }}
                  />
                ),
              },
            ]}
            data={influencerData.influencers}
            style={{
              display: 'table',
              minHeight: 0,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}
