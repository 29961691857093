import { useTranslation } from 'react-i18next';
import { MosaicNode, createBalancedTreeFromLeaves, getLeaves } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { AutoArrangeIcon } from '../Icons/AutoArrangeIcon';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';

interface AutoArrangeButtonProps {
  mosaicNode?: MosaicNode<string> | null;
}

export function AutoArrangeButton({ mosaicNode }: AutoArrangeButtonProps) {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const updatePageMosaic = useUpdatePageMosaic();
  const updateTabMosaic = useUpdateTabMosaic();

  if (!mosaicNode) return null;

  return (
    <InfoTooltip text={t('dashboard.controls.autoArrange')}>
      <AutoArrangeIcon
        as="button"
        onClick={async () => {
          const leaves = getLeaves(mosaicNode);
          const balancedTree = createBalancedTreeFromLeaves<string>(leaves as string[]);

          tabId
            ? updateTabMosaic({ node: balancedTree })
            : updatePageMosaic({ node: balancedTree });
        }}
      />
    </InfoTooltip>
  );
}
