import { captureException } from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { MosaicNode } from 'react-mosaic-component';
import { useNavigate } from 'react-router-dom';
import { SolutionType } from '../ChatCpg/types/chatCpgTypes';
import {
  ElementDef,
  InsightCardPayload,
  IntroCardPayload,
  PostsPayload,
  ProjectedRevenuePayload,
  TickrElement,
  TickrPage,
  TickrTab,
} from '../Element/types/elementTypes';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useChatCpgMutations } from '../Providers/ChatCpgMutationsProvider/useChatCpgMutations';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { getFirstTabId } from '../helpers/getFirstTabId';
import { useCollectionHelpers } from './useCollectionHelpers';
import { useTemplateElements } from './useTemplateElements';

interface CreateSolutionProps {
  banner?: string;
  dataset: string;
  datasources: string;
  gtin: string;
  marketSummary: string;
  productDescription: string;
  solutionType: SolutionType;
}

export function useCreateSolution() {
  const { t } = useTranslation();
  const { msId } = useAuth();
  const { createAnalysis } = useChatCpgMutations();
  const { formatNewPage, formatNewElement } = useCollectionHelpers();
  const { createPage, updateTabs } = useCollectionMutations();
  const { sendAlert } = useToaster();
  const navigate = useNavigate();
  const { templateElementsMap } = useTemplateElements();

  if (isEmpty(templateElementsMap)) return;

  const handleError = (e: unknown, message: string) => {
    captureException(e);

    sendAlert({
      severity: 'error',
      text: message,
    });
  };

  const createSolution = async ({
    banner,
    dataset,
    datasources,
    gtin,
    marketSummary,
    productDescription,
    solutionType,
  }: CreateSolutionProps) => {
    try {
      for (const elementKey of RELEVANT_ELEMENTS[solutionType]) {
        if (!templateElementsMap[elementKey]) throw new Error(`Missing element ${elementKey}`);
      }
    } catch (e) {
      handleError(e, t('error.launchAnalysis.missingTemplateElement'));
      return;
    }

    let id: string;

    try {
      id = (
        await createAnalysis({
          summaryName: solutionType,
          gtins: [gtin],
          weeks: 12,
          dataset,
          ...(banner && banner !== 'all' && { banner }),
        })
      ).id;
    } catch (e) {
      handleError(e, t('error.launchAnalysis.createAnalysis'));
      return;
    }

    let newPage: TickrPage;

    try {
      const stagedFilters = {
        cpg_owned_products: gtin,
        cpg_dataset: dataset,
        ...(banner && banner !== 'all' && { banner }),
      };

      const sharedPageConfig: Partial<TickrPage> = {
        settings: {
          layoutType: 'talkingPoints',
          workflowId: id,
          solutionType,
        },
        filterDef: {
          activeFilters: {
            ...stagedFilters,
            dates: '12w/w',
          },
        },
      };

      const introSubtitle = `${marketSummary} · ${gtin} ${productDescription}`;

      const introFootnote = t('chatCpg.intro.dataProvidedBy', {
        datasources,
      });

      let searches = '';

      switch (msId) {
        case 25027:
          searches = '11871040';
          break;
        case 26024:
          searches = '11871049';
          break;
        case 26023:
          searches = '11871047';
          break;
        case 24202:
          searches = '11871048';
          break;
      }

      const solutions: Partial<Record<SolutionType, Partial<TickrPage>>> = {
        optimizePricing: {
          title: `Pricing Analysis ${t('axis.gtin')} ${gtin}`,
          ...sharedPageConfig,
          tabs: [
            {
              text: `Pricing Analysis of ${productDescription}`,
              title: t('chatCpg.abstract'),
              row: 0,
              settings: {
                talkingPoint: 'intro',
              },
              elements: [
                formatNewElement(templateElementsMap.introCard, {
                  title: t('chatCpg.abstract'),
                  elementDef: {
                    ...templateElementsMap.introCard.elementDef,
                    key: 'introCard',
                    payload: {
                      ...(templateElementsMap.introCard.elementDef.payload as IntroCardPayload),
                      title: 'Pricing Analysis',
                      subtitle: introSubtitle,
                      text: t('chatCpg.intro.body'),
                      footnote: introFootnote,
                      background: 'var(--clay)',
                    },
                  },
                }),
              ],
            },
            {
              text: '',
              title: 'Historic Sales',
              row: 1,
              settings: {
                talkingPoint: 'historic',
              },
              elements: [
                formatNewElement(templateElementsMap.insightCard, {
                  title: t('chatCpg.insights'),
                  elementDef: {
                    ...templateElementsMap.insightCard.elementDef,
                    key: 'insightCard',
                    payload: {
                      ...(templateElementsMap.insightCard.elementDef.payload as InsightCardPayload),
                      background: 'var(--clay)',
                      focusBackground: 'var(--black-50)',
                      talkingPoint: 'historic',
                    },
                  },
                }),
                formatNewElement(templateElementsMap.priceRevenueVolume),
              ],
            },
            {
              text: '',
              title: 'Competition',
              row: 2,
              settings: {
                talkingPoint: 'competitors',
              },
              elements: [
                formatNewElement(templateElementsMap.influentialCompetitors),
                formatNewElement(templateElementsMap.priceComparison),
                formatNewElement(templateElementsMap.competitorsPriceVsMyVolume),
              ],
            },
            {
              text: '',
              title: 'Inflation',
              row: 3,
              settings: {
                talkingPoint: 'inflation',
              },
              elements: [
                formatNewElement(templateElementsMap.posts, {
                  title: 'News Regarding Inflation',
                  elementDef: {
                    ...templateElementsMap.posts.elementDef,
                    key: 'posts',
                    settings: {
                      ...templateElementsMap.posts.elementDef.settings,
                      isChatCpg: true,
                    },
                    payload: {
                      ...(templateElementsMap.posts.elementDef.payload as PostsPayload),
                      filters: {
                        searches,
                        text: 'inflation',
                        top_content: '35',
                        text_fields: [
                          'json_obj.newsroom.news.title',
                          'json_obj.newsroom.news.description',
                        ],
                      },
                      options: {
                        types: ['news'],
                      },
                    },
                  },
                }),
                formatNewElement(templateElementsMap.categoryVsCpi),
              ],
            },
            {
              text: '',
              title: 'Recommendations',
              row: 4,
              settings: {
                talkingPoint: 'recommendations',
              },
              elements: [
                formatNewElement(templateElementsMap.insightCard, {
                  title: t('util.summary'),
                  elementDef: {
                    ...templateElementsMap.insightCard.elementDef,
                    key: 'insightCard',
                    payload: {
                      ...(templateElementsMap.insightCard.elementDef.payload as InsightCardPayload),
                      background: 'var(--cornflower-blue-50)',
                      focusBackground: 'var(--black-50)',
                      talkingPoint: 'recommendations',
                    },
                  },
                }),
                formatNewElement(templateElementsMap.priceOptimizedRevenueTids),
              ],
            },
          ],
        },
        substitutions: {
          title: `Substitution Analysis ${t('axis.gtin')} ${gtin}`,
          ...sharedPageConfig,
          tabs: [
            {
              text: `Substitution Analysis of ${productDescription}`,
              title: 'Abstract',
              row: 0,
              settings: {
                talkingPoint: 'intro',
              },
              elements: [
                formatNewElement(templateElementsMap.introCard, {
                  title: t('chatCpg.abstract'),
                  elementDef: {
                    ...templateElementsMap.introCard.elementDef,
                    key: 'introCard',
                    payload: {
                      ...(templateElementsMap.introCard.elementDef.payload as IntroCardPayload),
                      title: 'Substitution Analysis',
                      subtitle: introSubtitle,
                      text: t('chatCpg.intro.body.substitutions'),
                      footnote: introFootnote,
                      background: 'var(--clay)',
                    },
                  },
                }),
              ],
            },
            {
              text: '',
              title: 'Substitutions',
              row: 1,
              settings: {
                talkingPoint: 'substitutions',
              },
              elements: [
                formatNewElement(templateElementsMap.substitutesTable, {
                  elementDef: {
                    ...templateElementsMap.substitutesTable.elementDef,
                    payload: null,
                    key: 'substitutesTable',
                  },
                }),
                formatNewElement(templateElementsMap.projectedRevenue, {
                  elementDef: {
                    ...templateElementsMap.projectedRevenue.elementDef,
                    payload: {
                      ...(templateElementsMap.projectedRevenue.elementDef
                        .payload as ProjectedRevenuePayload),
                      storeKey: 'substitution',
                      secondaryTrendOptions: {
                        forecastLabel: t('chatCpg.substitutions.substituteProductsRevenueForecast'),
                        label: t('chatCpg.substitutions.substituteProductsRevenue'),
                        color: 'var(--malibu)',
                      },
                    },
                    key: 'projectedRevenue',
                  },
                }),
              ],
            },
            {
              text: '',
              title: 'Complements',
              row: 2,
              settings: {
                talkingPoint: 'complements',
              },
              elements: [
                formatNewElement(templateElementsMap.complementsTable, {
                  elementDef: {
                    ...templateElementsMap.complementsTable.elementDef,
                    payload: null,
                    key: 'complementsTable',
                  },
                }),
                formatNewElement(templateElementsMap.projectedRevenue, {
                  elementDef: {
                    ...templateElementsMap.projectedRevenue.elementDef,
                    payload: {
                      ...(templateElementsMap.projectedRevenue.elementDef
                        .payload as ProjectedRevenuePayload),
                      storeKey: 'complement',
                      secondaryTrendOptions: {
                        forecastLabel: t('chatCpg.substitutions.complementProductsRevenueForecast'),
                        label: t('chatCpg.substitutions.complementProductsRevenue'),
                        color: 'var(--brand-secondary)',
                      },
                    },
                    key: 'projectedRevenue',
                  },
                }),
              ],
            },
          ],
        },
      };

      const pageToCreate = formatNewPage(solutions[solutionType]);
      newPage = await createPage(pageToCreate);
      if (!newPage?.tabs) throw new Error('Failed to create page');
    } catch (e) {
      handleError(e, t('error.launchAnalysis.createPage'));
      return;
    }

    try {
      if (!newPage?.tabs) throw new Error('Failed to create page');

      const tabsWithMosaic = newPage.tabs.map((tab) => {
        const talkingPoint = tab.settings?.talkingPoint;
        if (!talkingPoint || !tab.elements) return tab;
        const relevantMap = MOSAIC_MAP[solutionType]?.[talkingPoint];
        if (!relevantMap) return tab;
        const mosaicNode = buildMosaicNodeFromMap(tab.elements, relevantMap);

        return {
          ...tab,
          settings: {
            ...tab.settings,
            mosaicNode,
            defaultMosaic: mosaicNode,
          },
        };
      });

      const updatedTabs = await updateTabs({
        tabs: tabsWithMosaic as TickrTab[],
        pageId: String(newPage.id),
      });

      navigate(`/page/${newPage.id}/${getFirstTabId(updatedTabs)}`);
    } catch (e) {
      handleError(e, t('error.launchAnalysis.updateTabs'));
      return;
    }
  };

  return createSolution;
}

const buildMosaicNodeFromMap = (
  elements: Partial<TickrElement>[],
  nodeMap: MosaicNode<string>
): MosaicNode<string> => {
  if (typeof nodeMap === 'string') {
    return String(elements.find((element) => element.elementDef?.key === nodeMap)?.id) ?? nodeMap;
  }

  return {
    direction: nodeMap.direction,
    first: buildMosaicNodeFromMap(elements, nodeMap.first),
    second: buildMosaicNodeFromMap(elements, nodeMap.second),
    splitPercentage: nodeMap.splitPercentage,
  };
};

const MOSAIC_MAP: Partial<Record<SolutionType, Record<string, MosaicNode<string>>>> = {
  optimizePricing: {
    intro: 'introCard',
    historic: {
      direction: 'row',
      first: 'insightCard',
      second: 'priceRevenueVolume',
      splitPercentage: 40,
    },
    competitors: {
      direction: 'row',
      first: 'influentialCompetitors',
      second: {
        direction: 'column',
        first: 'priceComparison',
        second: 'competitorsPriceVsMyVolume',
      },
    },
    inflation: {
      direction: 'row',
      first: 'posts',
      second: 'categoryVsCpi',
      splitPercentage: 40,
    },
    recommendations: {
      direction: 'row',
      first: 'insightCard',
      second: 'priceOptimizedRevenueTids',
      splitPercentage: 40,
    },
  },
  substitutions: {
    intro: 'introCard',
    substitutions: {
      direction: 'column',
      first: 'substitutesTable',
      second: 'projectedRevenue',
    },
    complements: {
      direction: 'column',
      first: 'complementsTable',
      second: 'projectedRevenue',
    },
  },
};

const RELEVANT_ELEMENTS: Record<SolutionType, ElementDef['key'][]> = {
  custom: [],
  substitutions: ['introCard', 'substitutesTable', 'projectedRevenue', 'complementsTable'],
  optimizePricing: [
    'introCard',
    'insightCard',
    'priceRevenueVolume',
    'influentialCompetitors',
    'priceComparison',
    'competitorsPriceVsMyVolume',
    'posts',
    'categoryVsCpi',
    'priceOptimizedRevenueTids',
  ],
};
