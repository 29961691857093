import styled from '@emotion/styled';

interface PageShadowProps {
  visible?: boolean;
  onClick?: () => void;
  zindex?: number;
}

const PageShadowStyles = styled.div<{ zindex: number; visible: boolean }>`
  opacity: 0.6;
  z-index: ${({ zindex }) => zindex};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--background);
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export function PageShadow({ visible = true, onClick, zindex = 100 }: PageShadowProps) {
  return (
    <PageShadowStyles
      visible={visible}
      zindex={zindex}
      onClick={onClick}
      data-testid="page-shadow"
    />
  );
}
