import styled from '@emotion/styled';
import { forwardRef, PropsWithChildren } from 'react';
import { gradientWrapStyles } from '../helpers/gradientWrapStyles';
import { InnerSolutionContent } from './InnerSolutionContent';

interface SolutionButtonProps {
  isActive?: boolean;
  onClick?: () => void;
  showPlus?: boolean;
}

export const SolutionButton = forwardRef<HTMLButtonElement, PropsWithChildren<SolutionButtonProps>>(
  ({ isActive, onClick, showPlus, children }, ref) => {
    return (
      <ButtonGradientWrap onClick={onClick} ref={ref}>
        <InnerSolutionContent showPlus={showPlus} isActive={isActive}>
          {children}
        </InnerSolutionContent>
      </ButtonGradientWrap>
    );
  }
);

const ButtonGradientWrap = styled.button(gradientWrapStyles);
