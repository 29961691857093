import { ClearIcon } from '../Icons/ClearIcon';
import { DoneIcon } from '../Icons/DoneIcon';
import { ErrorIcon } from '../Icons/ErrorIcon';
import { ErrorWarningIcon } from '../Icons/ErrorWarningIcon';
import { InformationIcon } from '../Icons/InformationIcon';
import { AlertProps } from '../Providers/ToasterProvider/ToasterContext';
import { Card } from './Card';
import { Flex } from './Flex';
import { Typography } from './Typography';

export function Alert({ title, text, severity = 'info', onDismiss }: AlertProps) {
  let color: string;

  switch (severity) {
    case 'info':
      color = 'var(--action)';
      break;
    case 'success':
      color = 'var(--positive)';
      break;
    case 'warning':
      color = 'var(--alert)';
      break;
    case 'error':
      color = 'var(--danger)';
      break;
    default:
      color = `var(--${severity})`;
  }

  return (
    <Card outlined background="var(--background)" style={{ borderColor: color }}>
      <Flex gap="1rem" padding="1rem" align="center" justify="space-between">
        <Flex gap="1rem" align="center">
          {severity === 'info' ? (
            <InformationIcon color={color} />
          ) : severity === 'success' ? (
            <DoneIcon color={color} />
          ) : severity === 'warning' ? (
            <ErrorWarningIcon color={color} />
          ) : (
            <ErrorIcon color={color} />
          )}
          <Flex direction="column" gap="1rem">
            {title && (
              <Typography as="h5" variant="c-11">
                {title}
              </Typography>
            )}
            <Typography>{text}</Typography>
          </Flex>
        </Flex>
        {onDismiss && <ClearIcon as="button" onClick={onDismiss} />}
      </Flex>
    </Card>
  );
}
