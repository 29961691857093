import { PropsWithChildren } from 'react';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { TalkingPointPopoverContent } from './TalkingPointPopoverContent';

export function TalkingPointPopover({ children }: PropsWithChildren) {
  return (
    <PopoverProvider placement="bottom-start">
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <TalkingPointPopoverContent />
    </PopoverProvider>
  );
}
