import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PdfIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="49" height="60" viewBox="0 0 49 60" fill="none">
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15381 25.9997V4.23047H31.2968V14.9338C31.2968 16.5906 32.6399 17.9338 34.2968 17.9338H45V55.6481H7.15381V42.9997H32C33.6569 42.9997 35 41.6565 35 39.9997V28.9997C35 27.3428 33.6569 25.9997 32 25.9997H7.15381ZM4.15381 25.9997V4.23047C4.15381 2.57361 5.49696 1.23047 7.15381 1.23047H31.7278C32.7886 1.23047 33.806 1.6519 34.5562 2.40204L34.6978 2.54368L34.711 2.55675L46.6738 14.5196L46.6868 14.5326L46.8284 14.6743C47.5786 15.4244 48 16.4419 48 17.5027V55.6481C48 57.305 46.6569 58.6481 45 58.6481H7.15381C5.49696 58.6481 4.15381 57.305 4.15381 55.6481V42.9997H3C1.34315 42.9997 0 41.6565 0 39.9997V28.9997C0 27.3428 1.34315 25.9997 3 25.9997H4.15381ZM34.2968 14.9338V6.38529L42.8453 14.9338H34.2968Z"
          fill="#FB7EB1"
        />
        <path
          d="M9.98427 30.5997H6.85227V38.9997H8.50827V36.2397H9.98427C11.6043 36.2397 12.8523 34.9917 12.8523 33.4197C12.8523 31.8477 11.6043 30.5997 9.98427 30.5997ZM9.98427 34.6917H8.50827V32.1477H9.98427C10.6923 32.1477 11.2083 32.6877 11.2083 33.4197C11.2083 34.1397 10.6923 34.6917 9.98427 34.6917ZM17.2664 30.5997H13.9304V38.9997H17.2664C19.5824 38.9997 21.3104 37.1637 21.3104 34.7997C21.3104 32.4357 19.5824 30.5997 17.2664 30.5997ZM17.2664 37.4157H15.5864V32.1837H17.2664C18.7184 32.1837 19.7264 33.2517 19.7264 34.7997C19.7264 36.3477 18.7184 37.4157 17.2664 37.4157ZM27.5011 32.1837V30.5997H22.4851V38.9997H24.1411V35.7597H27.4051V34.1757H24.1411V32.1837H27.5011Z"
          fill="var(--woodsmoke)"
        />
      </svg>
    </IconWrapper>
  );
}
