import { PropsWithChildren, useContext, useEffect } from 'react';
import slugify from 'slugify';
import { SettingsContext, SettingsPageDef } from './SettingsDialog';

export function SettingsPage({
  children,
  title,
}: // icon,
PropsWithChildren<Omit<SettingsPageDef, 'id'>>) {
  const { setPages, activePage } = useContext(SettingsContext);
  const id = slugify(title);

  useEffect(() => {
    setPages((pages) => {
      return [
        ...pages,
        {
          title,
          // icon,
          id,
        },
      ];
    });

    return () => {
      setPages((pages) => pages.filter((page) => page.id !== id));
    };
  }, [
    title,
    // icon,
    setPages,
    id,
  ]);

  return <>{activePage?.id === id && children}</>;
}
