import { createContext } from 'react';
import { ProductAttributeFilters } from '../ActivePAFiltersProvider/ActivePAFiltersContext';
import { CheckboxFilterItem } from '../Components/CheckboxFilterDisplay';

export interface ProductAttributesTable {
  fileId: number;
  columns: string[];
  rows: Record<string, string>[];
}

export const initialTable: ProductAttributesTable = {
  fileId: 0,
  columns: ['gtin', '_is_owned'],
  rows: [],
};

export interface StagedPAFiltersContextProps {
  stagedPAFilters: ProductAttributeFilters;
  myProductsValues: CheckboxFilterItem[];
  myProductsTable: ProductAttributesTable;
  competitorsTable: ProductAttributesTable;
  competitorsValues: CheckboxFilterItem[];
}

export const StagedPAFiltersContext = createContext<StagedPAFiltersContextProps>({
  stagedPAFilters: {},
  myProductsTable: initialTable,
  myProductsValues: [],
  competitorsTable: initialTable,
  competitorsValues: [],
});
