import styled from '@emotion/styled';
import first from 'lodash/first';
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/src/Core/Button';
import { Flex } from 'ui/src/Core/Flex';
import { Typography } from 'ui/src/Core/Typography';
import { DialogBody } from 'ui/src/DialogFloating/DialogBody';
import { DialogFooter } from 'ui/src/DialogFloating/DialogFooter';
import { DialogTitle } from 'ui/src/DialogFloating/DialogTitle';
import { Dialog } from './Dialog';
import { SettingsButton } from './SettingsButton';

export interface SettingsPageDef {
  title: string;
  // icon: IconId;
  id: string;
}

interface SettingsModalProps {
  open: boolean;
  onClose?(): void;
}

interface SettingsContextState {
  activePage?: SettingsPageDef;
  pages: SettingsPageDef[];
  setPages: Dispatch<SetStateAction<SettingsPageDef[]>>;
}

export const SettingsContext = createContext<SettingsContextState>({
  pages: [],
  setPages: () => null,
});

export const SettingsDialog: FC<PropsWithChildren<SettingsModalProps>> = ({
  onClose,
  open,
  children,
}) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState<SettingsPageDef[]>([]);
  const [activePage, setActivePage] = useState<SettingsPageDef>();

  useEffect(() => {
    if (!activePage) setActivePage(first(pages));
  }, [pages, activePage]);

  return (
    <Dialog open={open} style={{ padding: 0 }}>
      <SettingsContext.Provider value={{ pages, setPages, activePage }}>
        <DialogTitle title={t('preferences.accountSettings')} />
        <DialogBody style={{ padding: 0 }}>
          <Flex height="100%" width="100%">
            <StyledFlex direction="column" height="100%" padding="40px 0 0 0">
              {pages.map((page) => (
                <SettingsButton
                  key={page.id}
                  // iconId={page.icon}
                  name={page.title}
                  isActive={activePage?.id === page.id}
                  onClick={() => setActivePage(page)}
                />
              ))}
            </StyledFlex>
            <Flex
              direction="column"
              width="100%"
              height="100%"
              gap="1rem"
              padding="1rem"
              style={{ overflow: 'hidden' }}
            >
              <Typography as="h2">{activePage?.title}</Typography>
              {children}
            </Flex>
          </Flex>
        </DialogBody>
        <DialogFooter>
          <Button color="var(--secondary-button)" onClick={onClose} width="285px">
            {t('util.close')}
          </Button>
        </DialogFooter>
      </SettingsContext.Provider>
    </Dialog>
  );
};

const StyledFlex = styled(Flex)`
  background-color: var(--background);
  min-width: 200px;
  overflow-y: auto;
`;
