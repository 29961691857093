import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Flex } from 'ui/src/Core/Flex';
import { PageShadow } from './PageShadow';
import { useScrollBlock } from './useScrollBlock';

export interface ModalProps {
  level?: number;
  open: boolean;
  style?: CSSProperties;
}

const ModalWrapper = styled.div<{ level: number }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(1000 + ${({ level }) => level});
  width: 100vw;
  height: 100vh;
  padding: 3rem;
  box-sizing: border-box;
`;

export function Modal({ level = 0, open, style, children }: PropsWithChildren<ModalProps>) {
  const [block, allow] = useScrollBlock();

  useEffect(() => {
    open ? block() : allow();
  }, [open, block, allow]);

  return createPortal(
    <>
      {open && <PageShadow zindex={1000 + level} />}
      {open && (
        <>
          <ModalWrapper level={level} style={{ ...style }}>
            <Flex height="100%" justify="center" align="center">
              {children}
            </Flex>
          </ModalWrapper>
        </>
      )}
    </>,
    document.getElementById('modal-portal') ?? document.body
  );
}
