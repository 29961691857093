import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const MenuIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect y="4" width="24" height="2" rx="1" fill="white" />
        <rect y="11" width="24" height="2" rx="1" fill="white" />
        <rect y="18" width="24" height="2" rx="1" fill="white" />
      </svg>
    </IconWrapper>
  );
});
