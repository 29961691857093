import { useLocation, useParams } from 'react-router-dom';
import { useCurrentProject } from './useCurrentProject';
import { useElements } from './useElements';

export function useCurrentElements() {
  const { pathname } = useLocation();
  const { currentProject } = useCurrentProject();
  const { pageId: paramPageId, tabId } = useParams();

  const isScorecard = pathname === '/smart-scorecard';

  const pageId = isScorecard
    ? currentProject.pages.find((page) => page.settings.isScorecard)?.id
    : paramPageId;

  return useElements({
    pageId,
    tabId,
    queryKey: 'current-elements',
  });
}
