import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PickerLeftIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
        <path
          d="M3.00006 8L8.72233 13.883C9.10364 14.2751 9.09657 14.9034 8.70986 15.2901C8.31934 15.6806 7.6834 15.6834 7.29288 15.2929L0.707089 8.70711C0.316565 8.31658 0.316564 7.68342 0.707088 7.29289L7.29288 0.707107C7.6834 0.316582 8.31657 0.316583 8.70709 0.707107L8.72153 0.721547C9.10866 1.10868 9.11251 1.73514 8.73018 2.127L3.00006 8Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
