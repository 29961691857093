import { createContext } from 'react';
import { CheckboxFilterItem } from '../Components/CheckboxFilterDisplay';
import { RadioFilterItem } from '../Components/RadioFilterDisplay';
import { AppFilters, CustomDate, RangeFilter } from '../filterTypes';

export type FilterType = 'checkbox' | 'radio' | 'range' | 'search' | 'dates' | 'gtin';

interface SharedFilterDefinition {
  filterId: keyof AppFilters;
  displayName: string;
  isLoading: boolean;
}

type SpecificFilterDefinition =
  | {
      type: 'checkbox' | 'gtin';
      values?: CheckboxFilterItem[];
      defaultValue?: string;
    }
  | {
      type: 'range';
      values?: RangeFilter;
      defaultValue?: RangeFilter;
    }
  | {
      type: 'radio';
      values?: RadioFilterItem[];
      defaultValue?: string;
    }
  | {
      type: 'search';
      values?: never;
      defaultValue?: never;
    }
  | {
      type: 'dates';
      values?: RadioFilterItem[];
      defaultValue?: string | CustomDate;
    };

export type FilterDefinition = SharedFilterDefinition & SpecificFilterDefinition;

export type UniversalFilters = Record<string, FilterDefinition>;
export type ProductAttributes = Record<string, FilterDefinition>;
export type DatasourceFilters = Partial<
  Record<string, Partial<Record<keyof AppFilters, FilterDefinition>>>
>;

export interface AvailableFiltersProviderContextProps {
  allUserDatasources: CheckboxFilterItem[];
  datasourceDisplayNames: Record<string, string>;
  datasourceFilters: DatasourceFilters;
  userDatasources: CheckboxFilterItem[];
  universalFilters: UniversalFilters;
  productAttributes: ProductAttributes;
}

export const AvailableFiltersContext = createContext<AvailableFiltersProviderContextProps>({
  allUserDatasources: [],
  datasourceDisplayNames: {},
  datasourceFilters: {},
  universalFilters: {},
  userDatasources: [],
  productAttributes: {},
});
