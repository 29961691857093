import { Link, useParams } from 'react-router-dom';
import { Circle } from '../Core/Circle';
import { Flex } from '../Core/Flex';
import { Grid } from '../Core/Grid';
import { Typography } from '../Core/Typography';
import { PickerRightIcon } from '../Icons/PickerRightIcon';

interface SidePanelPageLinkProps {
  title: string;
  pageId: number;
  tabId?: string;
  onClick: () => void;
}

export function SidePanelPageLink({ title, onClick, pageId, tabId }: SidePanelPageLinkProps) {
  const { pageId: currentPageId } = useParams();

  const isActive = String(pageId) === currentPageId;

  return (
    <Flex as="li" width="calc(100% - 0.8rem)" padding="0 0 0 0.8rem">
      {isActive && <Circle size="3px" style={{ position: 'absolute', left: 3, top: 14.75 }} />}
      <Link to={`/page/${pageId}${tabId ? `/tab/${tabId}` : ''}`}>
        <Grid
          alignItems="center"
          templateColumns="89.2fr 10.8fr"
          padding="0.5rem 0.2rem"
          onClick={onClick}
        >
          <Typography
            as="h2"
            variant="p-14-b"
            color={isActive ? 'var(--text)' : 'var(--text-secondary)'}
            nowrap
            truncate
            title={title}
            style={{ justifySelf: 'start', width: '95%' }}
          >
            {title}
          </Typography>
          <PickerRightIcon
            size="12px"
            color={isActive ? 'var(--text)' : 'var(--text-secondary)'}
            style={{ justifySelf: 'center' }}
          />
        </Grid>
      </Link>
    </Flex>
  );
}
