import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { LaunchIcon } from '../Icons/LaunchIcon';
import { MediaImage } from './MediaImage';
import { MediaVideo } from './MediaVideo';

interface PostContentProps {
  hashtags?: JSX.Element[];
  headline?: string;
  imageSrc?: string;
  linkText?: string;
  metrics?: [string, number][];
  postSrc?: string | false;
  text?: string;
  videoSrc?: string;
}

export function PostContent({
  hashtags,
  headline,
  imageSrc,
  linkText,
  metrics,
  postSrc,
  text,
  videoSrc,
}: PostContentProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap="0.5rem" width="100%">
      {headline && <Typography as="h3">{headline}</Typography>}
      <Typography
        color="var(--text-secondary)"
        style={{ width: '100%', overflowWrap: 'break-word' }}
      >
        {text}
      </Typography>
      {!isEmpty(hashtags) && (
        <Flex wrap="wrap" gap=".4rem">
          {hashtags}
        </Flex>
      )}
      {videoSrc && <MediaVideo src={videoSrc} />}
      {imageSrc && !videoSrc && <MediaImage src={imageSrc} alt="media image" />}
      {!isEmpty(metrics) && (
        <Flex gap="1rem">
          {metrics?.map(([name, number]) => (
            <Flex key={name} gap="0.2rem">
              <Typography variant="n-12-b">{number}</Typography>
              <Typography variant="p-12-r">{name}</Typography>
            </Flex>
          ))}
        </Flex>
      )}
      {postSrc && (
        <a href={postSrc} target="_blank">
          <Flex align="center" gap="0.4rem">
            <LaunchIcon size="10px" color="var(--action)" />
            <Typography variant="h-12-b" color="var(--action)">
              {linkText ?? t('util.readMore')}
            </Typography>
          </Flex>
        </a>
      )}
    </Flex>
  );
}
