import { useQuery } from '@tanstack/react-query';
import some from 'lodash/some';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocalStorage } from 'usehooks-ts';
import { Flex } from '../Core/Flex';
import { PostsPayload } from '../Element/types/elementTypes';
import { useActiveFilters } from '../Filters/ActiveFiltersProvider/useActiveFilters';
import { MultiCard, labelCards, sortCards } from '../helpers/biCardHelpers';
import { useBi } from '../hooks/useBi';
import { BiPost } from './BiPost';
import { NewsReport } from './Reports/NewsReport';

interface PostsProps {
  query: PostsPayload;
}

export function Posts({ query }: PostsProps) {
  const bi = useBi();
  const { combinedFilters } = useActiveFilters();
  const [irrelevantCards] = useLocalStorage<string[]>('irrelevantCards', []);

  const { data: cards = [], isLoading } = useQuery(
    ['bi-cards', query, combinedFilters],
    async () => {
      const data = labelCards(
        await bi('cards', {
          filters: {
            ...query.filters,
            ...combinedFilters,
          },
          options: {
            per_page: 30,
            ...query.options,
          },
        })
      );

      return sortCards(data.filter(({ payload }) => payload.metrics.impact > 0));
    },
    { enabled: some(query) }
  );

  const reportGenerator = (card: MultiCard) => {
    switch (card.key) {
      case 'news':
        return () => <NewsReport newsCard={card.payload} />;
      default:
        return () => <></>;
    }
  };

  return (
    <Flex
      direction="column"
      gap="1rem"
      width="100%"
      height={isLoading ? '100%' : 'unset'}
      style={{ overflowX: 'hidden' }}
    >
      {isLoading ? (
        <Skeleton
          baseColor="var(--clay)"
          highlightColor="var(--space)"
          width="100%"
          height="100%"
          containerClassName="flex-skeleton"
        />
      ) : (
        cards
          ?.filter((card) => !irrelevantCards.includes(card.payload.clusterId))
          ?.map((card) => (
            <BiPost
              key={card.payload.id ?? card.payload.clusterId}
              card={card}
              report={reportGenerator(card)}
            />
          ))
      )}
    </Flex>
  );
}
