import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MosaicNode } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { BackupIcon } from '../Icons/BackupIcon';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../hooks/useUpdateTabMosaic';

interface RestoreDefaultButtonProps {
  mosaicNode?: MosaicNode<string> | null;
}

export function RestoreDefaultButton({ mosaicNode }: RestoreDefaultButtonProps) {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const currentTab = useCurrentTab();
  const currentPage = useCurrentPage();
  const updateTabMosaic = useUpdateTabMosaic();
  const updatePageMosaic = useUpdatePageMosaic();

  const defaultMosaic = tabId
    ? currentTab?.settings?.defaultMosaic
    : currentPage?.settings?.defaultMosaic;

  const isRootEqualToDefault = useMemo(
    () => isEqual(mosaicNode, defaultMosaic),
    [mosaicNode, defaultMosaic]
  );

  return (
    <InfoTooltip text={t('dashboard.controls.restoreDefault')}>
      <BackupIcon
        as="button"
        disabled={isRootEqualToDefault}
        onClick={() => {
          if (!defaultMosaic) return;

          tabId
            ? updateTabMosaic({ node: defaultMosaic })
            : updatePageMosaic({ node: defaultMosaic });
        }}
      />
    </InfoTooltip>
  );
}
