import {
  FloatingFocusManager,
  FloatingPortal,
  UseTransitionStylesProps,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import omit from 'lodash/omit';
import { HTMLProps, forwardRef } from 'react';
import { PopoverArrow } from './PopoverArrow';
import { usePopoverContext } from './usePopoverContext';

interface PopoverContentProps extends HTMLProps<HTMLDivElement> {
  hideArrow?: boolean;
  position?: 'fixed' | 'absolute';
  transitionProps?: UseTransitionStylesProps;
  zIndex?: number;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent(
    { hideArrow, transitionProps = { duration: 100 }, position, zIndex = 1000, ...props },
    propRef
  ) {
    const { context: floatingContext, arrowRef, ...context } = usePopoverContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const { isMounted, styles } = useTransitionStyles(floatingContext, transitionProps);

    return (
      <FloatingPortal>
        {isMounted && (
          <FloatingFocusManager context={floatingContext} modal initialFocus={999}>
            <div
              ref={ref}
              style={{
                position: position ?? context.strategy,
                top: context.y ?? 0,
                left: context.x ?? 0,
                width: 'max-content',
                zIndex,
                backgroundColor: 'var(--secondary-background)',
                borderRadius: 'var(--corner)',
                ...props.style,
                ...styles,
              }}
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              {...context.getFloatingProps(omit(props, 'arrowRef'))}
            >
              <>
                {props.children}
                {!hideArrow && <PopoverArrow ref={arrowRef} />}
              </>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    );
  }
);
