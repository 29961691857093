import { PropsWithChildren, useState } from 'react';
import { Notification, NotificationsContext } from './NotificationsContext';
import { NotificationsDispatchContext } from './NotificationsDispatchContext';

export function NotificationsProvider({ children }: PropsWithChildren) {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      author: 'chatCpg',
      authorColor: 'var(--brand-secondary)',
      id: '0',
      isNew: true,
      isPrediction: true,
      pageId: 1704,
      text: '3 products’ prices across several retailers will become sub-optimal in the next 15 days.',
      timestamp: 1670227200000,
      fakeTimestamp: '5 min ago',
      to: `/pricing-alert`,
    },
    {
      author: { id: 123, fullname: 'J L' },
      authorColor: 'var(--brand-secondary)',
      id: '1',
      isNew: false,
      pageId: 1704,
      text: 'We analyzed the demographics for Cereal and found two major growth opportunities. Here is the presentation.',
      timestamp: 1670227200000,
      fakeTimestamp: '10 min ago',
      to: `page/1`,
    },
    {
      author: 'chatCpg',
      id: '2',
      isNew: false,
      isPrediction: true,
      pageId: 2,
      text: 'The Cold Cereal category has 2 new competitors that are expected to reach 3-5% market share in 8 weeks. “New Competitor” analysis completed.',
      timestamp: 1670140800000,
      fakeTimestamp: '15 min ago',
      to: `page/2`,
    },
    {
      author: { id: 123, fullname: 'K K' },
      authorColor: 'var(--cornflower-blue)',
      id: '3',
      isNew: false,
      pageId: 3,
      text: 'The latest projections for Personal Care show a decline on the West Coast. chatCPG recommends 3 price adjustments as shown in this summary.',
      timestamp: 1670054400000,
      fakeTimestamp: '32 min ago',
      to: `page/3`,
    },
    {
      author: 'chatCpg',
      id: '4',
      isNew: false,
      pageId: 4,
      text: 'GTIN ...23456’s is showing signs of regularly being out of stock. “Top Substitutes” analysis completed.',
      timestamp: 1669968000000,
      fakeTimestamp: '2 hr ago',
      to: `page/4`,
    },
    {
      author: 'chatCpg',
      id: '5',
      isNew: false,
      isPrediction: true,
      pageId: 5,
      text: 'GTIN ...23543’s price is predicted to be sub-optimal in 3 weeks. Pricing Optimization analysis completed.',
      timestamp: 1669968000000,
      fakeTimestamp: 'Yesterday morning',
      to: `page/5`,
    },
  ]);

  return (
    <NotificationsContext.Provider value={notifications}>
      <NotificationsDispatchContext.Provider value={setNotifications}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  );
}
