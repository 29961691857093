import { Flex } from '../Core/Flex';
import { Grid } from '../Core/Grid';
import { Typography } from '../Core/Typography';
import { AddIcon } from '../Icons/AddIcon';

interface SidePanelWorkspaceLinkProps {
  title: string;
  onClick: () => void;
}

export function SidePanelWorkspaceLink({ title, onClick }: SidePanelWorkspaceLinkProps) {
  return (
    <Flex as="li" width="calc(100% - 0.8rem)" padding="0 0 0 0.8rem">
      <Grid
        as="button"
        width="100%"
        alignItems="center"
        templateColumns="89.2fr 10.8fr"
        padding="0.5rem 0.2rem"
        onClick={onClick}
        style={{ gridColumn: '2/ span 1', textAlign: 'left' }}
      >
        <Typography
          as="h2"
          variant="p-14-b"
          color="var(--action)"
          nowrap
          truncate
          title={title}
          style={{ justifySelf: 'start', width: '95%' }}
        >
          {title}
        </Typography>
        <AddIcon size="12px" color="var(--action)" style={{ justifySelf: 'center' }} />
      </Grid>
    </Flex>
  );
}
