import { useCurrentPage } from '../hooks/useCurrentPage';
import { StandardPage } from './StandardPage';
import { TalkingPointsPage } from './TalkingPointsPage';

export function PageSwitch() {
  const currentPage = useCurrentPage();

  switch (currentPage?.settings?.layoutType) {
    case 'talkingPoints':
      return <TalkingPointsPage />;
    case 'standard':
      return <StandardPage />;
    default:
      return <></>;
  }
}
