import { ChartSeries } from './chartHelpers';

const initialComparisonItem: ComparisonSeries = {
  id: '',
  name: '',
  trend: [],
  diff: 0,
  legendVal: 0,
};

export const initialComparisonChartData: ComparisonChartData = {
  myProduct: initialComparisonItem,
  competitor1: initialComparisonItem,
  competitor2: initialComparisonItem,
};

interface ComparisonSeries extends ChartSeries {
  id: string;
  name: string;
}

export interface ComparisonChartData {
  myProduct: ComparisonSeries;
  competitor1?: ComparisonSeries;
  competitor2?: ComparisonSeries;
}
