import { CSSProperties, PropsWithChildren } from 'react';
import { useMeasure } from 'react-use';
import { Flex } from '../Core/Flex';
import { GraphContext } from './GraphContext';

export interface NewGraphProps {
  containerPadding?: CSSProperties['padding'];
}

export function GraphProvider({ containerPadding, children }: PropsWithChildren<NewGraphProps>) {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  return (
    <GraphContext.Provider value={{ width, height }}>
      <Flex
        ref={ref}
        style={{ width: '100%', height: '100%', flex: '1 1 auto', padding: containerPadding }}
      >
        <svg viewBox={`0 0 ${width} ${height}`} overflow="hidden">
          {children}
        </svg>
      </Flex>
    </GraphContext.Provider>
  );
}
