import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentProject } from '../../hooks/useCurrentProject';

interface DeletePageDialogContentProps {
  onClose?(): void;
  onDelete?(): void;
  pageId: number;
}

export function DeletePageDialogContent({
  onClose,
  onDelete,
  pageId,
}: DeletePageDialogContentProps) {
  const { pageId: pageIdParam } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deletePage } = useCollectionMutations();
  const { currentProject } = useCurrentProject();

  return (
    <>
      <DialogTitle title={t('deletePage.modal.title')} size="sm" centerTitle />
      <DialogBody style={{ backgroundColor: 'var(--clay)' }}>
        <Flex direction="column" gap="0.5rem" width="100%" align="center">
          <Typography color="var(--text-secondary)" style={{ textAlign: 'center' }}>
            {t('deletePage.areYouSure')}
          </Typography>
          <Typography color="var(--text-secondary)">{t('delete.youCantUndo')}</Typography>
        </Flex>
      </DialogBody>
      <DialogFooter>
        <DialogTrigger>
          <Button width="150px" color="var(--trout)" onClick={onClose}>
            {t('util.cancel')}
          </Button>
        </DialogTrigger>
        <DialogTrigger>
          <Button
            onClick={() => {
              onClose?.();
              onDelete?.();

              if (pageIdParam) {
                const totalPages = currentProject.pages.length;

                const pageToDeleteIdx = currentProject.pages.findIndex(
                  (page) => page.id === pageId
                );

                const nearestPageIdx = pageToDeleteIdx === 0 ? 1 : pageToDeleteIdx - 1;

                navigate(
                  totalPages === 1 ? `/home` : `/page/${currentProject.pages[nearestPageIdx].id}`
                );
              }

              deletePage({
                id: pageId,
              });
            }}
            width="150px"
            color="var(--danger)"
          >
            {t('util.delete')}
          </Button>
        </DialogTrigger>
      </DialogFooter>
    </>
  );
}
