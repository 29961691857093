import { Dispatch, SetStateAction, createContext } from 'react';

export interface Augmentations {
  _is_owned: boolean;
  brand_name: string;
  description: string;
  manufacturer: string;
  gender?: string;
  variant?: string;
}

export interface LoginProps {
  email: string;
  password: string;
}

export interface ResetProps {
  email: string;
  newPassword: string;
  resetToken: string;
}

export interface AuthContextProps {
  fullname?: string;
  isFuzzy: boolean;
  isLoadingToken: boolean;
  isOwner: boolean;
  login(props: LoginProps): Promise<void>;
  logout(): void;
  msAugmentations?: Record<string, Augmentations>;
  msNameMapping?: Record<string, string>;
  msId?: number;
  presentationMode: boolean;
  resetPassword(props: ResetProps): Promise<void>;
  setPresentationMode: Dispatch<SetStateAction<boolean>>;
  token?: string;
  userId?: number;
  username?: string;
}

export const AuthContext = createContext<AuthContextProps>({
  isFuzzy: false,
  isLoadingToken: false,
  isOwner: false,
  login: async () => undefined,
  logout: () => null,
  presentationMode: false,
  resetPassword: async () => undefined,
  setPresentationMode: () => null,
});
