import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function DoneIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path
          d="M5.9999 11.2L1.7999 7.00001L0.399902 8.40001L5.9999 14L17.9999 2.00001L16.5999 0.600006L5.9999 11.2Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
