import styled from '@emotion/styled';

export interface CardProps {
  background?: string;
  outlined?: boolean;
  style?: React.CSSProperties;
}

export const Card = styled.div<CardProps>`
  border-radius: var(--corner);
  background: ${({ background }) => background ?? 'var(--secondary-background)'};
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${({ outlined }) => (outlined ? '1px solid var(--woodsmoke-30)' : 'none')};
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
`;
