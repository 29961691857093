import styled from '@emotion/styled';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { ClearIcon } from '../Icons/ClearIcon';
import { DialogTrigger } from './DialogTrigger';

interface DialogTitleProps {
  title?: string;
  description?: string;
  onClose?: () => void;
  showX?: boolean;
  size?: 'lg' | 'sm';
  centerTitle?: boolean;
}

const StyledDiv = styled.div`
  padding: 0.8rem;
  background-color: var(--clay);
`;

export function DialogTitle({
  centerTitle = false,
  description,
  onClose,
  showX = false,
  size = 'lg',
  title,
}: DialogTitleProps) {
  return (
    <StyledDiv style={{ backgroundColor: 'var(--clay)', padding: '0.8rem' }}>
      {title && (
        <Flex direction="column" gap="0.4rem" width="100%">
          <Flex justify={centerTitle ? 'center' : 'space-between'} align="center" width="100%">
            <Typography as={size === 'lg' ? 'h1' : 'h2'}>{title}</Typography>
            {(onClose || showX) && (
              <DialogTrigger>
                <ClearIcon
                  as="button"
                  background="inherit"
                  onClick={onClose}
                  color="var(--white)"
                  size="12px"
                  height="20px"
                />
              </DialogTrigger>
            )}
          </Flex>
          {description && <Typography color="var(--text-secondary)">{description}</Typography>}
        </Flex>
      )}
    </StyledDiv>
  );
}
