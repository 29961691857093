import { Dispatch, SetStateAction, useState } from 'react';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { Typography } from '../Core/Typography';
import { ArrowBottomRightIcon } from '../Icons/ArrowBottomRightIcon';
import { ArrowTopRightIcon } from '../Icons/ArrowTopRightIcon';
import { ClearIcon } from '../Icons/ClearIcon';
import { PinIcon } from '../Icons/PinIcon';
import { useApp } from '../Providers/AppProvider/useApp';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { NumberStyle } from '../helpers/numberStyleHelper';

export interface SalesPerformanceInsight {
  id: string;
  gtin: string;
  type: 'risk' | 'opportunity';
  value: number;
  numberStyle: NumberStyle;
  description: string;
  retailer: string;
}

interface SalesPerformanceInsightProps {
  insights: SalesPerformanceInsight[];
}

export function SalesPerformanceInsights({ insights }: SalesPerformanceInsightProps) {
  const t = useLocalization();
  const { apiUrl } = useApp();
  const [pinnedInsights, setPinnedInsights] = useState<string[]>([]);
  const [hiddenInsights, setHiddenInsights] = useState<string[]>([]);

  return (
    <Flex
      {...(!apiUrl && { padding: '0.5rem' })}
      column
      gap="0.5rem"
      height="100%"
      width="100%"
      style={{ overflow: 'hidden' }}
    >
      {!apiUrl && <Typography variant="h-16-b">{t('salesPerformance.insights')}</Typography>}
      <Flex column gap="0.5rem" height="100%" width="100%" style={{ overflowY: 'auto' }}>
        {pinnedInsights.map((id) => {
          const insight = insights.find((insight) => insight.id === id) as SalesPerformanceInsight;

          return (
            <SalesPerformanceInsightCard
              key={id}
              {...insight}
              pinned
              setHiddenInsights={setHiddenInsights}
              setPinnedInsights={setPinnedInsights}
            />
          );
        })}
        {insights
          .filter(({ id }) => !pinnedInsights.includes(id) && !hiddenInsights.includes(id))
          .map((insight) => (
            <SalesPerformanceInsightCard
              key={insight.id}
              {...insight}
              setHiddenInsights={setHiddenInsights}
              setPinnedInsights={setPinnedInsights}
            />
          ))}
      </Flex>
    </Flex>
  );
}

interface SalesPerformanceInsightCardProps extends SalesPerformanceInsight {
  setPinnedInsights: Dispatch<SetStateAction<string[]>>;
  setHiddenInsights: Dispatch<SetStateAction<string[]>>;
  pinned?: boolean;
}

function SalesPerformanceInsightCard({
  id,
  gtin,
  pinned,
  value,
  numberStyle,
  description,
  type,
  setPinnedInsights,
  setHiddenInsights,
}: SalesPerformanceInsightCardProps) {
  const t = useLocalization();
  const isRisk = type === 'risk';

  const color = isRisk ? 'var(--negative-trend)' : 'var(--positive-trend)';

  const typeText = isRisk
    ? t('salesPerformance.valueAtStake')
    : t('salesPerformance.forecastedGain');

  return (
    <Flex
      padding="0.6rem 1.2rem"
      width="100%"
      align="center"
      justify="space-between"
      gap="2rem"
      style={{
        background: `linear-gradient(to right, ${color} 0%, ${color} 12px, var(--clay) 12px)`,
        borderRadius: '6px',
      }}
    >
      <Flex column width="100px">
        <Flex align="baseline" gap="0.8rem">
          <NumberFormatter as="p" variant="n-24-b" val={value} numberStyle={numberStyle} />
          {isRisk ? (
            <ArrowBottomRightIcon color={color} size="12px" />
          ) : (
            <ArrowTopRightIcon color={color} size="12px" />
          )}
        </Flex>
        <Typography secondary style={{ whiteSpace: 'pre-line' }}>
          {typeText.toLocaleLowerCase()}
        </Typography>
      </Flex>
      <Flex column grow="1" gap="0.4rem">
        <Typography variant="h-14-b">{`GTIN ${gtin}`}</Typography>
        <Typography variant="p-14-r">{description}</Typography>
      </Flex>
      <Flex gap="1rem">
        <ClearIcon
          as="button"
          color="var(--text-secondary)"
          onClick={() => {
            setHiddenInsights((hiddenInsights) => [...hiddenInsights, id]);

            setPinnedInsights((pinnedInsights) =>
              pinnedInsights.filter((pinnedId) => pinnedId !== id)
            );
          }}
        />
        <PinIcon
          as="button"
          size="20px"
          color={pinned ? 'var(--action)' : 'var(--text-secondary)'}
          onClick={() => {
            setPinnedInsights((pinnedInsights) =>
              pinnedInsights.includes(id)
                ? pinnedInsights.filter((pinnedId) => pinnedId !== id)
                : [...pinnedInsights, id]
            );
          }}
        />
      </Flex>
    </Flex>
  );
}
