import { useTranslation } from 'react-i18next';
import { MosaicNode, createBalancedTreeFromLeaves, getLeaves } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { ContextMenu } from '../Core/ContextMenu';
import { ContextMenuItem } from '../Core/ContextMenuItem';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { AddIcon } from '../Icons/AddIcon';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';
import { ContentIcon } from '../Icons/ContentIcon';
import { PopoverContent } from '../Popover/PopoverContent';
import { PopoverProvider } from '../Popover/PopoverProvider';
import { PopoverTrigger } from '../Popover/PopoverTrigger';
import { usePopoverContext } from '../Popover/usePopoverContext';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { useCollectionHelpers } from '../hooks/useCollectionHelpers';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { useDateFormatter } from '../hooks/useDateFormatter';
import { useTemplateElements } from '../hooks/useTemplateElements';
import { FeedbackButtons } from './FeedbackControls';
import { StreamingIcon } from './StreamingIcon';

interface ChatBubbleHeaderProps {
  date?: string | number | Date;
  hideFeedback?: boolean;
  id: string;
  isEdited?: boolean;
  isStreaming?: boolean;
  style?: React.CSSProperties;
  text?: string;
}

export function ChatBubbleHeader({
  date,
  id,
  hideFeedback,
  isStreaming = false,
  isEdited = false,
  style,
  text,
}: ChatBubbleHeaderProps) {
  const { t } = useTranslation();
  const format = useDateFormatter();

  return (
    <Flex align="center" justify="space-between" width="100%">
      <Flex gap="0.6rem" align="center" style={style}>
        {!isStreaming && text && (
          <PopoverProvider placement="top-start">
            <PopoverTrigger asChild>
              <ChatCpgIcon as="button" />
            </PopoverTrigger>
            <ChatBubblePopoverContent text={text} />
          </PopoverProvider>
        )}
        {!isStreaming && !text && <ChatCpgIcon />}

        {isStreaming && <StreamingIcon isStreaming={isStreaming} />}
        <Flex gap="0.3rem" align="center">
          <Typography secondary>
            {isStreaming || !date ? t('util.now') : format(date, 'fromNow')}
          </Typography>
          <Typography secondary>·</Typography>
          <Typography secondary>
            {isStreaming
              ? id === 'file-stream-bubble'
                ? t('chatCpg.generatingFileSummary')
                : t('chatCpg.generatingResponse')
              : isEdited
              ? t('chatCpg.editedByUser')
              : t('chatCpg.generatedByChatCpg')}
          </Typography>
        </Flex>
      </Flex>
      {!hideFeedback && !isStreaming && text && (
        <Flex>
          <FeedbackButtons
            chatMessageId={id}
            talkingPointId={id}
            isEdited={false}
            isStreaming={isStreaming}
            text={text}
          />
        </Flex>
      )}
    </Flex>
  );
}

interface ChatBubblePopoverContentProps {
  text: string;
}

function ChatBubblePopoverContent({ text }: ChatBubblePopoverContentProps) {
  const { t } = useTranslation();
  const { setOpen } = usePopoverContext();
  const { sendAlert } = useToaster();
  const { pageId, tabId } = useParams();
  const { createElements, updateTabs } = useCollectionMutations();
  const { formatNewElement } = useCollectionHelpers();

  const currentTab = useCurrentTab();
  const { templateElementsMap } = useTemplateElements();

  return (
    <PopoverContent>
      <ContextMenu direction="column" gap="0.5rem" width="200px" padding="0.4rem">
        <ContextMenuItem
          text={t('chatCpg.bubbleMenu.copyText')}
          onClick={() => {
            navigator.clipboard.writeText(
              text
                .split('\n')
                .map((p: string) => {
                  return `${p}
                  `;
                })
                .join('')
            );

            sendAlert({
              text: t('chatCpg.bubbleMenu.textCopied'),
              severity: 'success',
            });

            setOpen(false);
          }}
        >
          <ContentIcon />
        </ContextMenuItem>
        <ContextMenuItem
          text={t('chatCpg.bubbleMenu.saveToAnalysis')}
          onClick={async () => {
            try {
              const annotationTemplate = templateElementsMap.richText;

              if (!annotationTemplate || !pageId || !tabId || !currentTab) {
                throw new Error('Missing required data');
              }

              const elementToCreate = formatNewElement(annotationTemplate, {
                pageId: Number(pageId),
                tabId: tabId,
                elementDef: {
                  ...annotationTemplate.elementDef,
                  key: 'richText',
                  payload: `${text
                    .split('\n')
                    .map((p) => `<p>${p}</p>`)
                    .join('')}`,
                  settings: {
                    ...annotationTemplate.elementDef.settings,
                    isChatCpg: true,
                  },
                },
              });

              const [element] = await createElements({
                elementsToCreate: [elementToCreate],
                tabId,
                pageId,
              });

              const leaves = [
                ...getLeaves(currentTab.settings?.mosaicNode ?? null),
                String(element.id),
              ].filter((leaf) => leaf !== 'chatCpg');

              const newTree = createBalancedTreeFromLeaves(leaves) as MosaicNode<string>;

              await updateTabs({
                tabs: [
                  {
                    ...currentTab,
                    elements: [...(currentTab.elements ?? []), element],
                    settings: {
                      ...currentTab.settings,
                      mosaicNode: {
                        direction: 'row',
                        first: newTree,
                        second: 'chatCpg',
                      },
                    },
                  },
                ],
              });
            } catch (error) {
              sendAlert({
                text: t('chatCpg.bubbleMenu.errorSavingElement'),
                severity: 'error',
              });

              return;
            }

            sendAlert({
              text: t('chatCpg.bubbleMenu.elementSaved'),
              severity: 'success',
            });

            setOpen(false);
          }}
        >
          <AddIcon />
        </ContextMenuItem>
      </ContextMenu>
    </PopoverContent>
  );
}
