import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentPage } from './useCurrentPage';
import { UpdateMosaicProps } from './useUpdateTabMosaic';

export const useUpdatePageMosaic = () => {
  const { updatePage } = useCollectionMutations();
  const currentPage = useCurrentPage();

  return async ({ node, updateDefaultMosaic }: UpdateMosaicProps) => {
    if (!currentPage) return;

    let updatedNode = node;

    if (node === null) {
      updatedNode = 'chatCpg';
    }

    const page = await updatePage({
      page: {
        ...currentPage,
        settings: {
          ...currentPage.settings,
          mosaicNode: updatedNode,
          ...(updateDefaultMosaic && { defaultMosaic: updatedNode }),
        },
      },
    });

    return page;
  };
};
