import {
  Placement,
  UseFloatingReturn,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Dispatch, MutableRefObject, SetStateAction, useMemo, useState } from 'react';
import { UseInteractionsReturn } from '../DialogFloating/types';
import { DismissOptions } from '../DialogFloating/useDialog';

export interface PopoverOptions {
  initialOpen?: boolean;
  placement?: Placement;
  fallbackPlacements?: Placement[];
  modal?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  arrowRef?: MutableRefObject<HTMLDivElement | null>;
  crossAxisOffset?: number;
  mainAxisOffset?: number;
  dismissOptions?: DismissOptions;
}

interface UsePopoverReturn extends UseFloatingReturn, UseInteractionsReturn {
  open: boolean;
  setOpen: (open: boolean) => void;
  modal?: boolean;
  labelId?: string;
  descriptionId?: string;
  setLabelId: Dispatch<SetStateAction<string | undefined>>;
  setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
  arrowRef?: MutableRefObject<HTMLDivElement | null>;
}

export function usePopover({
  initialOpen = false,
  placement = 'right',
  fallbackPlacements = ['right-start', 'left-start', 'bottom', 'top'],
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  arrowRef,
  crossAxisOffset = -5,
  mainAxisOffset = 10,
  dismissOptions = {},
}: PopoverOptions = {}): UsePopoverReturn {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: mainAxisOffset,
        crossAxis: crossAxisOffset,
      }),
      shift({ padding: 10 }),
      flip({
        fallbackAxisSideDirection: 'end',
        fallbackPlacements,
      }),
      arrowRef && arrow({ element: arrowRef }),
    ],
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });

  const dismiss = useDismiss(context, dismissOptions);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      arrowRef,
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId, arrowRef]
  );
}
