import uniqBy from 'lodash/uniqBy';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SolutionType } from '../../ChatCpg/types/chatCpgTypes';
import { FilterDefinition } from '../../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { useAvailableFilters } from '../../Filters/AvailableFiltersProvider/useAvailableFilters';
import { CheckboxFilter } from '../../Filters/Components/CheckboxFilter';
import { CheckboxFilterItem } from '../../Filters/Components/CheckboxFilterDisplay';
import { RadioFilter } from '../../Filters/Components/RadioFilter';
import { useStagedFilters } from '../../Filters/StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../../Filters/StagedFiltersProvider/useStagedFiltersDispatch';
import { useStagedPAFilters } from '../../Filters/StagedPAFiltersProvider/useStagedPAFilters';
import { AppFilters } from '../../Filters/filterTypes';
import { useCreateSolution } from '../../hooks/useCreateSolution';
import { useFilterSummary } from '../../hooks/useFilterSummary';
import { SolutionContent } from '../SolutionsComponents/SolutionContent';
import { SolutionFooter } from '../SolutionsComponents/SolutionFooter';

interface SolutionWizardProps {
  activeFilter: keyof AppFilters;
  filterIndex: number;
  setFilterIndex: Dispatch<SetStateAction<number>>;
  solutionType: SolutionType;
}

export function SolutionWizard({
  activeFilter,
  filterIndex,
  setFilterIndex,
  solutionType,
}: SolutionWizardProps) {
  const { t } = useTranslation();
  const dispatchStagedFilters = useStagedFiltersDispatch();
  const { myProductsValues } = useStagedPAFilters();
  const navigate = useNavigate();
  const { stagedFilters } = useStagedFilters();
  const getFilterSummary = useFilterSummary();

  const {
    datasourceFilters,
    allUserDatasources,
    datasourceDisplayNames,
    userDatasources,
    universalFilters,
  } = useAvailableFilters();

  const location = useLocation();
  const backLink = location?.state?.backLink;

  const [isLoading, setIsLoading] = useState(false);

  // This is a hack for the substitution analysis not all owned products have
  // substitution data so this is slightly better than adding a new filter or
  // data type to the analysis flow.
  const filteredProductValues = getFilteredProductValues(myProductsValues, solutionType);

  const createSolution = useCreateSolution();

  return (
    <>
      <SolutionContent>
        {activeFilter === 'cpg_owned_products' && (
          <RadioFilter
            autofocus
            inputBorderColor="var(--trout)"
            filterId="cpg_owned_products"
            searchPlaceholder="Search for a product"
            searchWidth="400px"
            values={filteredProductValues.map(({ value, title }) => {
              const [gtin, ...description] = title.split(' ');
              return { value, title: gtin, subtitle: description.join(' ') };
            })}
            style={{ overflow: 'hidden', minHeight: '0px', width: '100%' }}
            variant="p-14-b"
          />
        )}
        {activeFilter === 'cpg_dataset' && (
          <CheckboxFilter
            inputBorderColor="var(--trout)"
            filterId="cpg_dataset"
            values={uniqBy(
              [
                ...allUserDatasources,
                { value: 'synth', title: 'Synthetic Weekly Point of Sale', disabled: true },
                { value: 'numerator', title: 'Numerator', disabled: true },
                { value: 'iri', title: 'IRI', disabled: true },
                { value: 'ibotta', title: 'Ibotta', disabled: true },
                { value: 'newsroom', title: 'Tickr News' },
                { value: 'nielsen', title: 'NielsenIQ', disabled: true },
                { value: 'spins', title: 'SPINS', disabled: true },
                { value: '84.51', title: '84.51°', disabled: true },
                { value: 'walmart', title: 'Walmart Luminate', disabled: true },
              ],
              'value'
            )}
          />
        )}
        {activeFilter === 'cpg_banner' && (
          <RadioFilter
            inputBorderColor="var(--trout)"
            filterId="cpg_banner"
            values={[
              { title: t('util.allBanners'), value: 'all' },
              ...((datasourceFilters[
                stagedFilters.cpg_dataset?.includes('synth') ? 'synth' : 'numerator'
              ]?.cpg_banner?.values ?? []) as CheckboxFilterItem[]),
            ]}
          />
        )}
      </SolutionContent>
      <SolutionFooter
        {...(filterIndex === 2 && { nextButtonText: t('util.runAnalysis') })}
        nextDisabled={isLoading || !stagedFilters[activeFilter]}
        onBack={() =>
          filterIndex === 0
            ? navigate(backLink ?? '/solutions')
            : setFilterIndex((filterIndex) => filterIndex - 1)
        }
        onNext={async () => {
          if (filterIndex === 0) {
            setFilterIndex((filterIndex) => filterIndex + 1);

            if (!stagedFilters.cpg_dataset) {
              dispatchStagedFilters({
                type: 'add',
                payload: { cpg_dataset: allUserDatasources.map(({ value }) => value).join(',') },
              });
            }
          }

          if (filterIndex === 1) {
            setFilterIndex((filterIndex) => filterIndex + 1);

            if (!stagedFilters.cpg_banner) {
              dispatchStagedFilters({
                type: 'add',
                payload: { cpg_banner: 'all' },
              });
            }
          }

          if (filterIndex === 2) {
            if (!createSolution) return;
            setIsLoading(true);

            const marketSummary =
              getFilterSummary({
                filterValue: stagedFilters.cpg_banner,
                filterDefinition: datasourceFilters.numerator?.cpg_banner as FilterDefinition,
              }) || t('util.allBanners');

            const productSummary = getFilterSummary({
              filterValue: stagedFilters.cpg_owned_products,
              filterDefinition: {
                ...universalFilters.cpg_owned_products,
                values: myProductsValues,
              } as FilterDefinition,
            });

            await createSolution({
              banner: stagedFilters.cpg_banner,
              dataset: stagedFilters.cpg_dataset?.includes('synth') ? 'synth' : 'numerator',
              datasources:
                solutionType === 'optimizePricing'
                  ? stagedFilters.cpg_dataset
                      ?.split(',')
                      .map((d) => datasourceDisplayNames[d])
                      .join(', ') ?? userDatasources.map(({ title }) => title).join(', ')
                  : 'Numerator',
              gtin: stagedFilters.cpg_owned_products as string,
              marketSummary,
              productDescription: productSummary.split(' ').slice(1).join(' '),
              solutionType,
            });

            setIsLoading(false);
          }
        }}
      />
    </>
  );
}

const getFilteredProductValues = function (
  myProductsValues: CheckboxFilterItem[],
  solutionType: string
) {
  if (solutionType === 'substitutions') {
    return myProductsValues.filter(({ value }) => {
      return [
        '00034000002405',
        '00034000004409',
        '00040000424314',
        '00034000002467',
        '00099900100873',
        '00034000491087',
        '00040000000327',
        '00034000405688',
        '00040000004356',
        '00034000114702',
        '00034000560028',
        '00034000004805',
        '00040000513056',
        '00070346000095',
        '00070462431230',
        '00010700024404',
        '00010700021526',
        '00034000002412',
        '00040000464082',
        '00040000001607',
        '00040000002635',
        '00040000000310',
        '00034000002399',
        '00040000512943',
        '00070970471254',
        '00034000070152',
        '00000980000052',
        '00070970471230',
        '00040000004325',
        '00034000402113',
        '00071720539446',
        '00099900100255',
        '00040000464280',
        '00022000011893',
        '00040000497523',
        '00070462098358',
        '00040000497547',
        '00040000464105',
        '00034000560431',
        '00079200558338',
        '00040000513018',
        '00011110072153',
        '00070970473562',
        '00040000422082',
        '00099900908363',
        '00073390162009',
        '00034000170388',
        '00099900738076',
        '00010700858405',
        '00042238302419',
      ].includes(value);
    });
  }

  return myProductsValues;
};
