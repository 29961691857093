import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ArrowLeftIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M7.29468 15.2947C7.68422 15.6842 8.31578 15.6842 8.70531 15.2947C9.0946 14.9054 9.09489 14.2743 8.70595 13.8847L3.83 9L15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7L3.83 7L8.71163 2.12711C9.10275 1.73668 9.10045 1.10045 8.70968 0.709682C8.31916 0.319158 7.68342 0.316582 7.29289 0.707106L0.707105 7.29289C0.316581 7.68342 0.316582 8.31658 0.707106 8.70711L7.29468 15.2947Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
