import { MosaicNode } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentTab } from './useCurrentTab';

export interface UpdateMosaicProps {
  node: MosaicNode<string> | null;
  updateDefaultMosaic?: boolean;
}

export const useUpdateTabMosaic = () => {
  const { pageId } = useParams();
  const { updateTabs } = useCollectionMutations();
  const currentTab = useCurrentTab();

  return async ({ node, updateDefaultMosaic }: UpdateMosaicProps) => {
    if (!currentTab) return;

    let updatedNode = node;

    if (node === null) {
      updatedNode = 'chatCpg';
    }

    const tabs = await updateTabs({
      tabs: [
        {
          ...currentTab,
          settings: {
            ...currentTab.settings,
            mosaicNode: updatedNode,
            ...(updateDefaultMosaic && { defaultMosaic: updatedNode }),
          },
        },
      ],
      pageId,
    });

    return tabs;
  };
};
