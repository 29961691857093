import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveFilters } from '../../Filters/ActiveFiltersProvider/useActiveFilters';
import { AppFilters } from '../../Filters/filterTypes';
import { useBi } from '../../hooks/useBi';
import { NewsCard } from '../../types/libBiTypes';
import { NewsPost } from '../NewsPost';
import { ReportLayout } from './ReportLayout';

interface NewsReportProps {
  newsCard: NewsCard;
}

export function NewsReport({ newsCard }: NewsReportProps) {
  const { t } = useTranslation();
  const bi = useBi();
  const { combinedFilters } = useActiveFilters();
  const [currentStoryIdx, setCurrentStoryIdx] = useState(0);

  const { data: newsStories = [], isLoading } = useQuery(
    ['news cluster', combinedFilters, newsCard],
    async () => {
      const { news } = await bi<NewsCard[]>('cards', {
        filters: {
          clusterId: newsCard.clusterId,
          searches: String(newsCard.topicId),
          ...combinedFilters,
        },
        options: {
          obj: true,
          types: ['news'],
        },
      });

      return news.primary;
    },
    {
      enabled: newsCard.clusterSize > 1,
    }
  );

  const currentNewsStory = newsStories[currentStoryIdx] ?? newsCard;

  const sentimentFilters: AppFilters = {
    // ...combinedFilters,
    search_platforms: 'only_news',
    searches: String(newsCard.topicId),
  };

  return (
    <ReportLayout
      currentStory={currentStoryIdx + 1}
      isLoadingStories={isLoading}
      metrics={[
        [t('metrics.impact'), currentNewsStory.metrics.impact],
        [t('metrics.relevance'), currentNewsStory.metrics.relevance],
        [t('metrics.influence'), currentNewsStory.metrics.influence],
        [t('metrics.trust'), currentNewsStory.metrics.trust],
        [t('metrics.velocity'), currentNewsStory.metrics.velocity],
      ]}
      post={() => (
        <NewsPost news={currentNewsStory} textLength={2000} currentStory={currentStoryIdx + 1} />
      )}
      sentimentAmount={currentNewsStory.metrics.sentiment}
      sentimentFilters={sentimentFilters}
      sentimentTopic={currentNewsStory.topic}
      sentimentType={t('cardReport.newsSentiment')}
      setCurrentStoryIdx={setCurrentStoryIdx}
      totalStories={newsCard.clusterSize}
      type={t('cardReport.news')}
    />
  );
}
