import styled from '@emotion/styled';
import { ComponentProps, forwardRef, PropsWithChildren } from 'react';

export interface ButtonProps extends ComponentProps<'button'> {
  color?: string;
  disabled?: boolean;
  fontColor?: string;
  fontSize?: string;
  variant?: 'text';
  size?: 'md' | 'sm';
  padding?: string;
  width?: string;
}

const StyledButton = styled.button<ButtonProps>`
  background-color: ${({ color, variant }) => (variant === 'text' ? 'none' : color)};
  border-radius: var(--corner);
  color: ${({ fontColor, variant, color }) =>
    variant === 'text' ? color : fontColor ?? 'var(--text)'};
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize ?? '12px'};
  font-weight: bold;
  letter-spacing: ${({ variant }) => (variant === 'text' ? '0.5px' : '2px')};
  overflow: hidden;
  min-height: 30px;
  padding: ${({ padding, size, variant }) =>
    padding ? padding : variant === 'text' ? '0' : size === 'md' ? '12px 40px' : '5px 40px'};
  text-transform: ${({ variant }) => (variant === 'text' ? 'capitalize' : 'uppercase')};
  transition: opacity 0.3s;
  width: ${({ width }) => width ?? 'unset'};
`;

const fontColors: Record<string, string> = {
  'var(--action)': 'var(--black)',
  'var(--danger)': 'var(--black)',
};

export const Button = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      color = 'var(--action)',
      size,
      disabled,
      variant,
      fontColor: fontColorProp,
      padding,
      ...props
    },
    ref
  ) => {
    const fontColor = fontColorProp ?? fontColors[color] ?? 'var(--text)';

    return (
      <StyledButton
        type="button"
        disabled={disabled}
        color={color}
        size={size}
        variant={variant}
        fontColor={fontColor}
        padding={padding}
        ref={ref}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
);
