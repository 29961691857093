import axios from 'axios';
import pcript from 'lib-auth/lib/pcrypt';
import { PropsWithChildren, useMemo } from 'react';
import { useApp } from '../AppProvider/useApp';
import { useAuth } from '../AuthProvider/useAuth';
import { ApiContext } from './ApiContext';

export function ApiProvider({ children }: PropsWithChildren) {
  const { apiUrl } = useApp();
  const { token, msId } = useAuth();
  let baseURL = apiUrl;
  const host = window.location.host;

  if (host.endsWith('.nip.io') || host.includes('.k8s.')) {
    // Supports running the frontend within kubernetes
    baseURL = `//api.${window.location.host.replace(/.*?\./, '')}`;
  }

  const api = useMemo(() => {
    const api = axios.create({
      baseURL,
      params: {
        ms_id: msId,
      },
      headers: {
        Authorization: (token && pcript.gen_auth(token)) || '',
      },
    });

    return api;
  }, [token, baseURL, msId]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}
