import { PropsWithChildren } from 'react';
import { AppContext } from './AppContext';

interface AppProviderProps {
  apiUrl?: string;
  chatCpgUrl?: string;
  dsUrl?: string;
  envMode: 'prod' | 'qa';
  isTemplateEditor?: boolean;
  tinyMceApiKey: string;
}

export function AppProvider({
  apiUrl,
  chatCpgUrl,
  dsUrl,
  envMode,
  isTemplateEditor,
  tinyMceApiKey,
  children,
}: PropsWithChildren<AppProviderProps>): JSX.Element {
  return (
    <AppContext.Provider
      value={{
        apiUrl,
        chatCpgUrl,
        isTemplateEditor: !!isTemplateEditor,
        dsUrl,
        envMode,
        tinyMceApiKey,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
