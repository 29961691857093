import { PropsWithChildren } from 'react';
import { PopoverProvider } from '../../Popover/PopoverProvider';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { KnowledgeBasePopoverContent } from './KnowledgeBasePopoverContent';

export function KnowledgeBasePopover({ children }: PropsWithChildren) {
  return (
    <PopoverProvider placement="bottom-start">
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <KnowledgeBasePopoverContent />
    </PopoverProvider>
  );
}
