import { FC } from 'react';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { PickerUpIcon } from '../../Icons/PickerUpIcon';
import { PopoverTrigger } from '../../Popover/PopoverTrigger';
import { usePopoverContext } from '../../Popover/usePopoverContext';

interface NavPopoverTriggerProps {
  pageTitle: string;
}

export const NavPopoverTrigger: FC<NavPopoverTriggerProps> = ({ pageTitle }) => {
  const { open } = usePopoverContext();

  return (
    <PopoverTrigger asChild>
      <Flex as="button" align="center" gap="0.6rem" style={{ borderRadius: 'var(--corner)' }}>
        <Typography as="h1" variant="p-22-b" nowrap truncate style={{ maxWidth: '450px' }}>
          {pageTitle}
        </Typography>
        <PickerUpIcon
          color="var(--white)"
          transform={open ? '' : 'rotate(180deg)'}
          transition="0.1s"
        />
      </Flex>
    </PopoverTrigger>
  );
};
