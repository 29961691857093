import styled from '@emotion/styled';
import { PropsWithChildren, useContext, useEffect } from 'react';
import { useMeasure } from 'react-use';
import { Button } from '../Core/Button';
import { TabContext } from './TabsContext';

interface TabProps {
  name: string; // should match respective TabPanel name
  index: number;
  className?: string;
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  overflow-wrap: break-word;
  z-index: 1;
`;

export function Tab({ name, index, className, children }: PropsWithChildren<TabProps>) {
  const { activeTab, setActiveTab, setTabWidth } = useContext(TabContext);

  const [ref, { width }] = useMeasure<HTMLButtonElement>();

  useEffect(() => {
    if (width) setTabWidth(width);
  }, [width, setTabWidth]);

  const isActive = activeTab === index;

  return (
    <StyledButton
      role="tab"
      aria-selected={isActive}
      aria-controls={`panel-${name.toLowerCase().replace(/\s/g, '-')}`}
      id={`tab-${name.toLowerCase().replace(/\s/g, '-')}`}
      color="transparent"
      fontColor={isActive ? 'var(--white)' : 'var(--trout)'}
      padding="5px"
      onClick={() => setActiveTab(index)}
      className={className}
      ref={ref}
    >
      {children}
    </StyledButton>
  );
}
