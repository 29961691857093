import styled from '@emotion/styled';
import { useId } from '@react-aria/utils';
import { highlightSearchTerm } from '../helpers/highlightSearchTerm';
import { Flex } from './Flex';
import { Typography, Variant } from './Typography';

interface RadioButtonProps {
  checked?: boolean;
  className?: string;
  description?: string;
  disabled?: boolean;
  inputBorderColor?: string;
  onChange?(): void;
  readOnly?: boolean;
  search?: string;
  subtitle?: string;
  title?: string;
  variant?: Variant;
}

export function RadioButton({
  checked,
  className,
  description,
  disabled,
  inputBorderColor,
  onChange,
  readOnly,
  search,
  subtitle,
  title,
  variant = 'h-12-b',
}: RadioButtonProps) {
  const id = useId();

  return (
    <Flex as="li" direction="column" gap="0.5rem">
      <Flex
        as="label"
        htmlFor={id}
        gap="0.8rem"
        align="center"
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <StyledInput
          id={id}
          disabled={disabled}
          type="radio"
          checked={checked}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onChange?.();
            }
          }}
          className={className}
          inputBorderColor={inputBorderColor}
          readOnly={readOnly}
        />
        {(title || subtitle) && (
          <Flex justify="flex-start" direction="column" gap=".3rem">
            {title && (
              <Typography variant={variant} color={disabled ? 'var(--white-30)' : 'var(--text)'}>
                {highlightSearchTerm(title, search)}
              </Typography>
            )}
            {subtitle && (
              <Typography
                secondary
                variant={variant}
                color={disabled ? 'var(--white-30)' : 'var(--text)'}
              >
                {highlightSearchTerm(subtitle, search)}
              </Typography>
            )}
          </Flex>
        )}
      </Flex>
      {description && (
        <Typography variant="p-12-r" style={{ opacity: 0.5, paddingLeft: 'calc(16px + 0.8rem)' }}>
          {description}
        </Typography>
      )}
    </Flex>
  );
}

const StyledInput = styled.input<RadioButtonProps>`
  appearance: none;
  background-color: var(--clay);
  border-radius: 100%;
  border: 1px solid ${({ inputBorderColor }) => inputBorderColor ?? 'var(--black)'};

  min-width: 16px;
  min-height: 16px;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  :focus-visible {
    outline: 1px solid var(--geyser);
  }

  :checked {
    background-color: ${({ disabled }) => (disabled ? 'var(--clay)' : 'var(--action)')};
  }
`;
