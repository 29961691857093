import { captureException } from '@sentry/react';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/src/Core/Button';
import { Flex } from 'ui/src/Core/Flex';
import { TextInput } from 'ui/src/FormElements/TextInput';
import { useAuth } from 'ui/src/Providers/AuthProvider/useAuth';
import { useToaster } from 'ui/src/Providers/ToasterProvider/useToaster';
import { useYupValidation } from 'ui/src/hooks/useYupValidation';
import * as Yup from 'yup';

export const ChangePasswordForm: FC = () => {
  const { t } = useTranslation();
  const { username, token, resetPassword } = useAuth();
  const { password } = useYupValidation();
  const { sendAlert } = useToaster();
  const [isFirst, setIsFirst] = useState(true);

  return (
    <Formik
      initialValues={{ newPassword: '' }}
      onSubmit={async ({ newPassword }) => {
        if (!username || !token) return;

        try {
          await resetPassword({ email: username, resetToken: token, newPassword });

          sendAlert({
            text: t('success.changePassword'),
            severity: 'success',
          });
        } catch (e) {
          captureException(e);

          sendAlert({
            text: t('error.changePassword'),
            severity: 'error',
          });
        }
      }}
      validationSchema={Yup.object().shape({
        newPassword: password,
      })}
      validateOnChange={!isFirst}
    >
      {({ errors, values, handleChange, handleSubmit, isValid, resetForm }) => (
        <>
          <Flex direction="column" width="400px" gap="2rem">
            <TextInput
              title={t('util.newPassword')}
              name="newPassword"
              type="password"
              id="newPassword"
              onChange={handleChange}
              value={values.newPassword}
              error={errors.newPassword}
            />
          </Flex>
          <Flex gap="1rem" width="100%" justify="center">
            <Button type="reset" onClick={() => resetForm()} color="var(--secondary-button)">
              {t('util.cancel')}
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setIsFirst(false);
                handleSubmit();
              }}
              disabled={!isValid}
            >
              {t('util.changePassword')}
            </Button>
          </Flex>
        </>
      )}
    </Formik>
  );
};
