import { PropsWithChildren, forwardRef } from 'react';
import { SidePanelItem } from './SidePanelItem';
import { SidePanelLi } from './SidePanelLi';

interface SidePanelButtonProps {
  disabled?: boolean;
  title: string;
  onClick?: () => void;
}

export const SidePanelButton = forwardRef<HTMLLIElement, PropsWithChildren<SidePanelButtonProps>>(
  (props, ref) => {
    return (
      <SidePanelLi ref={ref}>
        <SidePanelItem as="button" {...props} />
      </SidePanelLi>
    );
  }
);
