import styled from '@emotion/styled';
import isNil from 'lodash/isNil';
import { CSSProperties, MouseEventHandler, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberStyle } from '../helpers/numberStyleHelper';
import { ChangeIndicator } from './ChangeIndicator';
import { Flex } from './Flex';
import { LegendIndicator, LegendSeries } from './LegendSeries';
import { NumberFormatter } from './NumberFormatter';
import { Typography } from './Typography';

export type ValueType = 'average' | 'latest' | 'total' | 'current' | 'weeklyAverage';

export interface ValueBoxProps {
  label?: string;
  subtitle?: string;
  value?: number;
  valueStyle?: NumberStyle;
  valueSmall?: number;
  valueSmallStyle?: NumberStyle;
  color?: string;
  circleBorder?: string;
  secondary?: boolean;
  background?: boolean;
  style?: CSSProperties;
  selected?: string;
  onClick?: (val: string) => void;
  border?: boolean;
  valueType?: ValueType;
  smallSuffix?: string;
  indicator?: LegendIndicator;
  indicatorBackground?: string;
  allNeutral?: boolean;
  primaryClassName?: string;
}

export const ValueBox = forwardRef<HTMLDivElement, ValueBoxProps>(
  (
    {
      value,
      valueStyle,
      valueSmall,
      valueSmallStyle,
      label,
      subtitle,
      color = 'var(--white)',
      circleBorder,
      secondary = false,
      background = false,
      style,
      selected,
      onClick,
      border,
      valueType = 'total',
      smallSuffix,
      indicator = 'line',
      indicatorBackground = 'inherit',
      allNeutral = false,
      primaryClassName,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const valueTypeDict: Record<ValueType, string> = {
      average: t('valueType.average'),
      latest: t('valueType.latest'),
      total: t('valueType.total'),
      current: t('valueType.current'),
      weeklyAverage: t('valueType.weeklyAverage'),
    };

    return (
      <FlexContainer
        direction="column"
        gap="0.4rem"
        color={color}
        secondary={secondary}
        background={background}
        style={style}
        className={selected === label?.toLowerCase() ? 'selected' : ''}
        onClick={
          label && onClick
            ? () => {
                const selectedValue = label.toLowerCase();
                onClick(selectedValue);
              }
            : undefined
        }
        border={border}
        data-testid="value-box-container"
        ref={ref}
      >
        {label && (
          <LegendSeries
            circleBorder={circleBorder}
            color={color}
            indicator={indicator}
            indicatorBackground={indicatorBackground}
            label={label}
            selected={selected}
          />
        )}
        {subtitle && (
          <Typography
            variant="c-10"
            color="var(--text-secondary)"
            style={{ paddingLeft: 'calc(20px + 0.625rem)' }}
          >
            {subtitle}
          </Typography>
        )}
        {value !== undefined && (
          <Flex
            gap="1rem"
            align="center"
            style={{ paddingLeft: secondary ? (indicator === 'circle' ? '16px' : '26px') : 0 }}
          >
            <Flex direction="column" gap="2px">
              {valueType && (
                <Typography color={color} nowrap variant="h-12-b">
                  {valueTypeDict[valueType]}
                </Typography>
              )}
              <Flex align="center" gap="1rem">
                <NumberFormatter
                  as="h2"
                  variant={!secondary ? 'n-24-b' : 'n-16-b'}
                  val={value}
                  numberStyle={valueStyle}
                  className={primaryClassName}
                />
                {!isNil(valueSmall) && valueSmallStyle !== undefined && (
                  <ChangeIndicator
                    val={valueSmall}
                    numberStyle={valueSmallStyle}
                    suffix={smallSuffix}
                    allNeutral={allNeutral}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </FlexContainer>
    );
  }
);

const FlexContainer = styled(Flex)<{
  color: string;
  secondary: boolean;
  background: boolean;
  onClick?: MouseEventHandler;
  border?: boolean;
}>`
  background-color: ${({ background }) => (background ? 'var(--clay-50)' : 'none')};
  padding: ${({ secondary }) => (secondary ? '0.5rem' : '0')};
  border-radius: var(--corner);
  .selected {
    background-color: ${({ background }) => (background ? 'var(--white)' : 'none')};
  }
  :hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  }
  border: ${({ border, color }) => (border ? `${color} solid 1px` : 'none')};
`;
