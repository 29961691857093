import styled from '@emotion/styled';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useDateFormatter } from '../hooks/useDateFormatter';
import { ImageWithFallback } from './ImageWithFallback';

interface PostDetailsProps {
  author?: string;
  authorHandle?: string;
  authorImage?: string;
  date?: string | number | Date;
  topic?: string;
}

const StyledImage = styled(ImageWithFallback)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

export function PostDetails({ author, authorImage, authorHandle, date, topic }: PostDetailsProps) {
  const format = useDateFormatter();

  return (
    <Flex gap="10px" width="100%">
      {authorImage && <StyledImage src={authorImage} alt="author avatar" />}
      <Flex direction="column" gap="0.1rem" width="calc(100% - 50px)">
        <Typography nowrap truncate style={{ width: '100%' }}>
          {author}
        </Typography>
        <Flex gap="0.3rem" width="100%">
          <Typography color="var(--text-secondary)" nowrap truncate title={authorHandle}>
            {authorHandle}
          </Typography>
          {date && (
            <>
              <Typography color="var(--text-secondary)">·</Typography>
              <Typography color="var(--text-secondary)" nowrap>
                {format(date.toString(), 'fromNow')}
              </Typography>
            </>
          )}
        </Flex>
        {topic && (
          <Typography
            title={topic}
            color="var(--text-secondary)"
            nowrap
            truncate
            style={{ width: '100%' }}
          >
            {topic}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
}
