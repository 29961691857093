import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ArrowDownIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.2947 8.70531C15.6842 8.31578 15.6842 7.68422 15.2947 7.29468C14.9054 6.9054 14.2743 6.90511 13.8847 7.29405L9 12.17L9 0.999999C9 0.447714 8.55229 0 8 0C7.44772 0 7 0.447715 7 1L7 12.17L2.12711 7.28837C1.73668 6.89725 1.10045 6.89955 0.709682 7.29032C0.319158 7.68084 0.316583 8.31658 0.707107 8.70711L7.29289 15.2929C7.68342 15.6834 8.31658 15.6834 8.70711 15.2929L15.2947 8.70531Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
