import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppFilters } from '../filterTypes';
import { CheckboxFilter } from './CheckboxFilter';
import { CheckboxFilterItem } from './CheckboxFilterDisplay';

interface GTINFilterProps {
  filterId: keyof AppFilters;
  values?: CheckboxFilterItem[];
}

export function GTINFilter({ filterId, values = [] }: GTINFilterProps) {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    // <Flex direction="column" padding="1rem 1rem 0 1rem" width="100%" gap="0.5rem">
    //   <DialogProvider>
    //     <DialogTrigger>
    //       <Flex as="button" gap="0.4rem" align="center" onClick={() => setDialogOpen(true)}>
    //         <Typography variant="h-11-b" color="var(--action)">
    //           {t('filterMenu.filterByProductAttributes')}
    //         </Typography>
    //         <Icon iconId="pickerRight" size="12px" color="var(--action)" />
    //       </Flex>
    //     </DialogTrigger>
    //     <ProductAttributesDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    //   </DialogProvider>
    //   <Divider solid color="var(--clay)" />
    <CheckboxFilter filterId={filterId} values={values} />
    // </Flex>
  );
}
