import styled from '@emotion/styled';
import { Flex } from '../../Core/Flex';
import { KnowledgeRepoIcon } from '../../Icons/KnowledgeRepoIcon';
import { KnowledgeBasePopover } from '../../Popovers/KnowledgeBasePopover/KnowledgeBasePopover';

export function KnowledgeBaseButton() {
  return (
    <KnowledgeBasePopover>
      <StyledFlex
        as="button"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <KnowledgeRepoIcon size="20px" />
      </StyledFlex>
    </KnowledgeBasePopover>
  );
}

const StyledFlex = styled(Flex)`
  border-radius: var(--corner);
  gap: 0.8rem;
  align-items: center;
  transition: filter 0.1s ease-in-out;
`;
