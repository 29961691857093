import styled from '@emotion/styled';
import { captureException } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import ReactGridLayout, { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { TickrTab } from '../Element/types/elementTypes';
import { PlusIcon } from '../Icons/PlusIcon';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentTabs } from '../hooks/useCurrentTabs';
import { DragHandle } from './DragHandle';
import { TalkingPoint } from './TalkingPoint';

export function TalkingPoints() {
  const { t } = useTranslation();
  const { pageId } = useParams();
  const { tabs, isLoading } = useCurrentTabs();
  const { createTab, updateTabs } = useCollectionMutations();
  const navigate = useNavigate();
  const { presentationMode } = useAuth();

  const layout: Layout[] = useMemo(
    () =>
      tabs.map(({ id, row }) => ({
        i: id,
        x: 0,
        y: row,
        w: 1,
        h: 1,
      })),
    [tabs]
  );

  const onLayoutChange = (layouts: Layout[]) => {
    if (presentationMode) return;

    const tabsToUpdate = layouts.reduce((acc: TickrTab[], layout: Layout) => {
      const tab = cloneDeep(tabs.find(({ id }) => id === layout.i));
      const newRow = layout.y;
      if (!tab || newRow === tab.row) return acc;

      return [
        ...acc,
        {
          ...tab,
          row: newRow,
        },
      ];
    }, []);

    if (isEmpty(tabsToUpdate)) return;

    updateTabs({ tabs: tabsToUpdate, pageId });
  };

  const [draggingId, setDraggingId] = useState<string>('');

  return (
    <Flex column overflow="scroll" height="calc(100% - 12px)" gap="16px" align="center">
      <Flex column padding="0 12px" style={{ minWidth: '274px', maxWidth: '274px' }}>
        <ReactGridLayout
          useCSSTransforms={false}
          isBounded
          width={250}
          rowHeight={154}
          draggableHandle=".drag-handle"
          onLayoutChange={onLayoutChange}
          margin={[0, 12]}
          onDragStart={(_, { i }) => setDraggingId(i)}
          onDragStop={() => setDraggingId('')}
          isResizable={false}
          isDraggable={!presentationMode}
          layout={layout}
          cols={1}
          containerPadding={[0, 0]}
          autoSize
        >
          {tabs.map((tab) => (
            <StyledTalkingPointContainer key={tab.id} draggingId={draggingId}>
              <TalkingPoint tab={tab} />
              {/* Keep DragHandle in the same file as ReactGridLayout */}
              <DragHandle isDragging={tab.id === draggingId} />
            </StyledTalkingPointContainer>
          ))}
        </ReactGridLayout>
      </Flex>
      {!presentationMode && !isLoading && (
        <StyledFlex
          column
          as="button"
          justify="center"
          align="center"
          gap="1rem"
          onClick={async () => {
            if (!pageId) return;

            const maxRow = tabs.reduce(
              (row, nextTab) => (nextTab.row > row ? nextTab.row : row),
              0
            );

            try {
              const {
                tabs: [tab],
              } = await createTab({
                tab: {
                  title: 'Untitled',
                  text: 'Your text here',
                  row: maxRow + 1,
                  pageId,
                  updatedAt: Date.now(),
                  settings: {
                    mosaicNode: 'chatCpg',
                  },
                },
                pageId,
              });

              navigate(`/page/${pageId}/tab/${tab.id}`);
            } catch (e) {
              captureException(e);
            }
          }}
        >
          <PlusIcon color="var(--action)" />
          <Typography variant="c-14" color="var(--action)">
            {t('chatCpg.addTalkingPoint')}
          </Typography>
        </StyledFlex>
      )}
    </Flex>
  );
}

const StyledTalkingPointContainer = styled.div<{ draggingId: string }>`
  min-width: 250px;
  max-width: 250px;
  ${({ draggingId }) => !draggingId && ':hover { button { visibility: visible }'}
`;

const StyledFlex = styled(Flex)`
  min-width: 250px;
  max-width: 250px;
  min-height: 150px;
  border-radius: 8px;
  border: 1px solid var(--space);
  background: var(--woodsmoke);
`;
