import styled from '@emotion/styled';
import { DragIcon } from '../Icons/DragIcon';

interface DragHandleProps {
  isDragging: boolean;
}

export function DragHandle({ isDragging }: DragHandleProps) {
  return (
    <StyledDragIcon
      as="button"
      background="transparent"
      className="drag-handle"
      hoverBackground="transparent"
      isDragging={isDragging}
    />
  );
}

const StyledDragIcon = styled(DragIcon)<{ isDragging?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  cursor: move;
  z-index: 2;
  visibility: ${({ isDragging }) => (isDragging ? 'visible' : 'hidden')};
  background: inherit;

  :focus-visible {
    outline: 1px solid var(--geyser);
  }
`;
