import styled from '@emotion/styled';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { PlusIcon } from '../Icons/PlusIcon';
import { fileToData } from '../helpers/fileToData';
import { FormElementWrapper } from './FormElementWrapper';

export interface DropFile extends File {
  preview: string;
}

interface ThumbnailUploaderProps {
  existingUrl?: string;
  setFileData: Dispatch<SetStateAction<string | null>>;
}

export function ThumbnailUploader({ existingUrl, setFileData }: ThumbnailUploaderProps) {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    maxSize: 2097152,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];

      setFilePreview(URL.createObjectURL(file));

      await fileToData(file, async (file, result) => {
        if (result) {
          setFileData(result);
        }
      });
    },
    onDropRejected(fileRejections, event) {
      // TODO: handle image upload failure
    },
  });

  const previewUrl = filePreview || (existingUrl ? `https://${existingUrl}` : '');

  return (
    <FormElementWrapper title={t('thumbnail')} description={t('thumbnail.description')}>
      <OuterFlex width="100%" padding="0.5rem" gap="0.5rem">
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <PlusContainer
            width="90px"
            height="66px"
            justify="center"
            align="center"
            preview={previewUrl}
          >
            {!previewUrl && <PlusIcon color="var(--action)" size="16px" />}
          </PlusContainer>
        </DropzoneContainer>
        <Flex direction="column" gap="0.45rem">
          <Flex gap="4px" wrap="wrap">
            <Typography variant="h-12-b">{t('thumbnail.dragDrop')}</Typography>
            <DropzoneContainer {...getRootProps()}>
              <input {...getInputProps()} />
              <Browse variant="h-12-b" color="var(--action)">
                {t('thumbnail.browse')}
              </Browse>
            </DropzoneContainer>
          </Flex>
          <Flex gap="0.2rem" wrap="wrap">
            <Typography color="var(--text-secondary)">{t('thumbnail.dimensions')}</Typography>
            <Typography color="var(--text-secondary)">{t('thumbnail.size')}</Typography>
          </Flex>
        </Flex>
      </OuterFlex>
    </FormElementWrapper>
  );
}

const Browse = styled(Typography)`
  :hover {
    opacity: 0.8;
  }
`;

const PlusContainer = styled(Flex)<{ preview?: string }>`
  border: 1px ${({ preview }) => (preview ? 'solid var(--space)' : 'dashed var(--action)')};
  border-radius: var(--corner);
  background-image: url(${({ preview }) => preview});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  :hover {
    opacity: 0.8;
  }
`;

const OuterFlex = styled(Flex)`
  background-color: var(--black-30);
  border-radius: var(--corner);
  border: 1px solid var(--black-30);
`;

const DropzoneContainer = styled(Flex)`
  border-radius: var(--corner);
  :hover {
    cursor: pointer;
  }
`;
