import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Flex } from '../Core/Flex';

interface TilePageLayoutProps {
  hideOutlet?: boolean;
  pageTitle?: string;
  style?: React.CSSProperties;
}

export function TilePageLayout({
  hideOutlet,
  pageTitle,
  style,
  children,
}: PropsWithChildren<TilePageLayoutProps>) {
  const { t } = useTranslation();
  const outletContext = useOutletContext();

  const titleText =
    window.location.host === 'chatcpg.ai'
      ? t('helmet.title.chatCPG', { page: pageTitle ?? t('page.home') })
      : t('helmet.title', { page: pageTitle ?? t('page.home') });

  return (
    <Flex
      direction="column"
      width="100%"
      height="100vh"
      style={{
        overflow: 'hidden',
        background: 'inherit',
        ...style,
      }}
    >
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      {children}
      {!hideOutlet && <Outlet context={outletContext} />}
    </Flex>
  );
}
