import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { ButtonProps } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { PickerUpIcon } from '../../Icons/PickerUpIcon';
import { useLocalization } from '../../Providers/LocalizationProvider/useLocalization';
import { useCountDescendants } from './useCountDescendants';

export function ProductGroupCell(props: GridRenderCellParams) {
  const t = useLocalization();
  const { id, field, rowNode, row } = props;
  const isGroup = rowNode.type === 'group';
  const isLeaf = rowNode.type === 'leaf';
  const isOwnedProduct = isLeaf && row.isOwned;
  const apiRef = useGridApiContext();
  const { owned, competitors } = useCountDescendants(rowNode);

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (!isGroup) return;
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Flex align="center" gap="0.6rem" style={{ paddingLeft: `calc(${rowNode.depth} * 15px)` }}>
      {isGroup ? (
        <PickerUpIcon
          as="button"
          size="12px"
          transform={rowNode.childrenExpanded ? 'rotate(0)' : 'rotate(180deg)'}
          transition="transform 0.1s ease-out"
          onClick={handleClick}
        />
      ) : (
        <div style={{ minWidth: '24px' }} />
      )}
      <Flex column>
        {isGroup ? (
          <>
            <Typography variant="h-12-b">{props.value}</Typography>
            <Typography>{t('salesPerformance.ownedProductsCount', { count: owned })}</Typography>
            <Typography secondary>
              {t('salesPerformance.competitorProductsCount', { count: competitors })}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h-12-b" secondary={!isOwnedProduct}>
              {isLeaf && typeof rowNode.groupingKey === 'string'
                ? rowNode.groupingKey.slice(0, 15)
                : ''}
            </Typography>
            <Typography secondary={!isOwnedProduct}>
              {isLeaf && typeof rowNode.groupingKey === 'string'
                ? rowNode.groupingKey.slice(15)
                : ''}
            </Typography>
          </>
        )}
      </Flex>
    </Flex>
  );
}
