import { LicenseInfo } from '@mui/x-license-pro';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';

LicenseInfo.setLicenseKey(
  '4f2c18fdb6fccd8caba89fbe27d69ca0Tz03NjIyNixFPTE3MjgzMTgwMTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
