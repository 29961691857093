import styled from '@emotion/styled';
import { useId } from '@react-aria/utils';
import { ComponentProps, useRef } from 'react';
import { Flex } from './Flex';
import { Typography } from './Typography';

export type CheckboxSymbol = 'checkmark' | 'hyphen';

interface CheckboxProps extends ComponentProps<'input'> {
  inputBorderColor?: string;
  fullyClickable?: boolean;
  symbol?: CheckboxSymbol;
  title?: string;
}

export function Checkbox({
  checked,
  className,
  disabled,
  fullyClickable,
  inputBorderColor,
  onChange,
  symbol = 'checkmark',
  title,
  value,
}: CheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);
  const id = useId();

  return (
    <Flex as="li" gap=".8rem" align="center" width={fullyClickable ? '100%' : 'auto'}>
      <Flex
        as="label"
        htmlFor={id}
        gap="0.8rem"
        align="center"
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <StyledInput
          id={id}
          ref={ref}
          inputBorderColor={inputBorderColor}
          onChange={onChange}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          value={value}
          className={className}
        />
        {!disabled && <Symbol checked={checked ?? ref.current?.checked} className={symbol} />}
        {title && (
          <Typography variant="h-12-b" color={disabled ? 'var(--white-30)' : 'var(--text)'}>
            {title}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
}

const StyledInput = styled.input<CheckboxProps>`
  appearance: none;
  :focus-visible {
    outline: 1px solid var(--geyser);
  }
  background-color: var(--clay);
  border-radius: 2px;
  border: 1px solid ${({ inputBorderColor }) => inputBorderColor ?? 'var(--black)'};
  min-width: 16px;
  min-height: 16px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: 0;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  :checked {
    background-color: ${({ disabled }) => (disabled ? 'var(--clay)' : 'var(--action)')};
  }
`;

const Symbol = styled.span<{ checked?: boolean }>`
  position: absolute;
  top: 50%;
  transform: ;
  :after {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    pointer-events: none;
  }
  &.checkmark:after {
    left: 1px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg) translateY(calc(-50% - 3px));
  }
  &.hyphen:after {
    left: 5px;
    transform: translateY(calc(-50% - 4px));
    width: 6px;
    height: 8px;
    border: solid white;
    border-width: 0 0 2px 0;
  }
`;
