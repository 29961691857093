import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Core/Button';
import { ChangeIndicator } from '../Core/ChangeIndicator';
import { Flex } from '../Core/Flex';
import { NumberFormatter } from '../Core/NumberFormatter';
import { RadioButton } from '../Core/RadioButton';
import { Typography } from '../Core/Typography';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { ArrowUpIcon } from '../Icons/ArrowUpIcon';
import { Header } from '../PageLayout/Header';
import { PageLayout } from '../PageLayout/PageLayout';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useApp } from '../Providers/AppProvider/useApp';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { CurrencyChangeCell } from '../Table/Cells/CurrencyChangeCell';
import { SimpleProductCell } from '../Table/Cells/SimpleProductCell';
import { ClickableTable } from '../Table/ClickableTable';
import { numberStyleHelper } from '../helpers/numberStyleHelper';
import { useCreateSolution } from '../hooks/useCreateSolution';

interface PricingAlertData {
  banner: string;
  categoryLWRevenueTotalForecast: number;
  categoryRevenueChange: number;
  gap: number;
  gtin: string;
  LWRevenueTotalForecast: number;
  pRevenueChange: number;
  price: number;
  productDescription: string;
  recommended_price: number;
}

interface PricingAlertProduct {
  id: string;
  gtin: string;
  name: string;
  banner: string;
  salesForecast: number;
  salesForecastChange: number;
  restOfCategoryForecast: number;
  restOfCategoryForecastChange: number;
  growthGap: number;
  price: number;
  priceChange: number;
  recommendedPrice: number;
}

export function PricingAlert() {
  const { t } = useTranslation();
  const { dsUrl } = useApp();
  const { get } = useApi();
  const navigate = useNavigate();
  const { msId } = useAuth();

  const [selectedProduct, setSelectedProduct] = useState<PricingAlertProduct>();

  const { data: products = [] } = useQuery(['static-pricing-alert'], async () => {
    const { data } = await get<PricingAlertData[]>(`${dsUrl}/v1/pricing/synth/executive-view`, {
      params: {
        ids: ['00016000122543', '00016000123991', '00016000124790'],
      },
    });

    return data
      .map((item) => ({
        id: `${item.gtin}-${item.banner}`,
        gtin: item.gtin,
        name: item.productDescription.split(' ').slice(1).join(' '),
        banner: item.banner,
        salesForecast: item.LWRevenueTotalForecast,
        salesForecastChange: item.pRevenueChange,
        restOfCategoryForecast: item.categoryLWRevenueTotalForecast,
        restOfCategoryForecastChange: item.categoryRevenueChange,
        growthGap: item.pRevenueChange - item.categoryRevenueChange,
        price: item.price,
        priceChange: item.recommended_price - item.price,
        recommendedPrice: item.recommended_price,
      }))
      .filter(({ recommendedPrice }) => recommendedPrice !== 0);
  });

  const createSolution = useCreateSolution();

  return (
    <PageLayout pageTitle="Pricing Alert" style={{ display: 'flex', flexDirection: 'column' }}>
      <Header pageTitle="Pricing Alert" />
      <Flex column width="100%" height="calc(100% - 62px)" padding="3rem 4rem" gap="2rem" grow="1">
        <Flex column gap="1rem">
          <Typography as="h1">{t('executiveView.title')}</Typography>
          <Typography variant="p-16-r" multiline secondary style={{ maxWidth: '495px' }}>
            {t('executiveView.description')}
          </Typography>
        </Flex>

        <ClickableTable
          width="100%"
          initActiveCol="growthGap"
          data={products}
          onRowClick={(item) => setSelectedProduct(item)}
          initActiveRow=""
          style={{ paddingBottom: '0.7rem' }}
          columns={[
            {
              title: '',
              cell: (item) => (
                <RadioButton
                  inputBorderColor="var(--geyser)"
                  checked={selectedProduct?.id === item.id}
                  readOnly
                />
              ),
            },
            {
              title: t('metrics.product'),
              field: 'gtin',
              cell: ({ gtin, name }) => <SimpleProductCell id={gtin} name={name} />,
            },
            {
              title: t('metrics.retailer'),
              field: 'banner',
              cell: ({ banner }) => <Typography variant="p-14-b">{banner}</Typography>,
            },
            {
              title: t('metrics.salesForecast'),
              field: 'salesForecast',
              cell: ({ salesForecast, salesForecastChange }) => (
                <CurrencyChangeCell value={salesForecast} change={salesForecastChange} />
              ),
            },
            {
              title: t('metrics.restOfCategoryForecast'),
              field: 'restOfCategoryForecast',
              cell: ({ restOfCategoryForecast, restOfCategoryForecastChange }) => (
                <CurrencyChangeCell
                  value={restOfCategoryForecast}
                  change={restOfCategoryForecastChange}
                  textColor="var(--text-secondary)"
                  borderColor="var(--text-secondary)"
                />
              ),
            },
            {
              title: t('metrics.growthGap'),
              field: 'growthGap',
              defaultSortOrder: 'asc',
              cell: ({ growthGap }) => (
                <ChangeIndicator
                  val={growthGap}
                  numberStyle="percentagePoint"
                  borderColor="var(--text)"
                />
              ),
            },
            {
              title: t('metrics.currentPrice'),
              field: 'price',
              cell: ({ price }) => (
                <NumberFormatter
                  as="span"
                  variant="p-14-b"
                  color="var(--text-secondary)"
                  val={price}
                  numberStyle="currency"
                />
              ),
            },
            {
              title: t('metrics.recommendedPrice'),
              field: 'priceChange',
              cell: ({ priceChange, recommendedPrice }) => (
                <Flex gap="0.4rem" align="center">
                  {priceChange > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  <Typography variant="p-14-b" color="var(--text)">
                    {priceChange > 0
                      ? t('metrics.recommendedPriceChange.increase', {
                          priceChange: numberStyleHelper(priceChange, 'currency').display(),
                          recommendedPrice: numberStyleHelper(
                            recommendedPrice,
                            'currency'
                          ).display(),
                        })
                      : t('metrics.recommendedPriceChange.decrease', {
                          priceChange: numberStyleHelper(priceChange, 'currency').display(),
                          recommendedPrice: numberStyleHelper(
                            recommendedPrice,
                            'currency'
                          ).display(),
                        })}
                  </Typography>
                </Flex>
              ),
              cellStyle: { paddingRight: '3rem' },
            },
          ]}
        />
        <Flex gap="1rem">
          <Button width="210px" onClick={() => navigate(-1)} color="var(--trout)">
            {t('util.back')}
          </Button>
          <Button
            width="210px"
            onClick={async () => {
              if (!selectedProduct || !createSolution) return;

              try {
                await createSolution({
                  solutionType: 'optimizePricing',
                  gtin: selectedProduct.gtin,
                  banner: selectedProduct.banner,
                  dataset: msId === 26024 ? 'synth' : 'numerator',
                  datasources: msId === 26024 ? 'Synthetic' : 'Numerator',
                  marketSummary: selectedProduct.banner,
                  productDescription: selectedProduct.name,
                });
              } catch (e) {
                captureException(e);
              }
            }}
            disabled={!selectedProduct}
          >
            {t('util.openAnalysis')}
          </Button>
        </Flex>
      </Flex>
    </PageLayout>
  );
}
