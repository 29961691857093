import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import omit from 'lodash/omit';
import { CSSProperties, forwardRef, HTMLProps } from 'react';
import { Card } from '../Core/Card';
import { BREAKPOINTS, DASHBOARD_X_PADDING } from '../helpers/constants';
import { useDialogContext } from './useDialogContext';

interface DialogContentProps extends HTMLProps<HTMLDivElement> {
  variant?: 'xl' | 'lg' | 'md' | 'sm';
  transitionProps?: UseTransitionStylesProps;
  omitFromOverlay?: string[];
  zindex?: number;
  placeItems?: CSSProperties['placeItems'];
  asCard?: boolean;
  width?: string;
  height?: string;
  level?: number;
  rootId?: string;
  overlayStyles?: CSSProperties;
  contentStyles?: CSSProperties;
  onClickOverlay?: () => void;
  style?: CSSProperties;
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(function DialogContent(
  {
    variant = 'lg',
    rootId,
    transitionProps = { initial: { opacity: 0 } },
    omitFromOverlay = [],
    zindex = 1000,
    placeItems = 'center',
    asCard = true,
    overlayStyles = {},
    contentStyles = {},
    onClickOverlay,
    ...props
  },
  propRef
) {
  const { context: floatingContext, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    floatingContext,
    transitionProps
  );

  return (
    <FloatingNode id={floatingContext?.nodeId ?? ''}>
      <FloatingPortal {...(rootId && { id: rootId })}>
        {isMounted && (
          <StyledFloatingOverlay
            lockScroll
            onClick={onClickOverlay}
            style={{
              ...omit(transitionStyles, ...omitFromOverlay),
              zIndex: zindex,
              placeItems,
              ...overlayStyles,
            }}
          >
            <FloatingFocusManager context={floatingContext} modal initialFocus={999}>
              {asCard ? (
                <StyledCard
                  ref={ref}
                  onClick={(e) => e.stopPropagation()} // prevent click from propagating to overlay
                  aria-labelledby={context.labelId}
                  aria-describedby={context.descriptionId}
                  {...context.getFloatingProps(props)}
                  variant={variant}
                  style={{
                    overflow: 'hidden',
                    borderRadius: 'var(--corner)',
                    ...props.style,
                    ...transitionStyles,
                  }}
                >
                  {props.children}
                </StyledCard>
              ) : (
                <StyledNav
                  ref={ref}
                  onClick={(e) => e.stopPropagation()} // prevent click from propagating to overlay
                  aria-labelledby={context.labelId}
                  aria-describedby={context.descriptionId}
                  {...context.getFloatingProps(props)}
                  style={{
                    overflow: 'hidden',
                    ...props.style,
                    ...transitionStyles,
                    ...contentStyles,
                  }}
                >
                  {props.children}
                </StyledNav>
              )}
            </FloatingFocusManager>
          </StyledFloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
});

const StyledFloatingOverlay = styled(FloatingOverlay)`
  background: rgba(0, 0, 0, 0.7);
  display: grid;
  position: absolute;
`;

const StyledCard = styled(Card)<Omit<DialogContentProps, 'open'>>(({ variant, width, height }) => {
  switch (variant) {
    case 'sm':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '50%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '30%'};
        }
      `;
    case 'md':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '75%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '50%'};
        }
      `;
    case 'lg':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '85%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '75%'};
        }
      `;
    case 'xl':
    default:
      return css`
        max-height: 95vh;
        height: ${height};
        width: ${width ?? '95%'};
      `;
  }
});

const StyledNav = styled.nav`
  width: 300px;
  background: var(--main-menu-gradient);
  padding: 60px 1rem 1rem
    ${
      DASHBOARD_X_PADDING + 6 // aligns side panel elements with hamburger menu
    }px;
  li {
    display: block;
  }
`;
