import styled from '@emotion/styled';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '../../Core/Divider';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { PopoverContent } from '../../Popover/PopoverContent';

interface PageLink {
  name: string;
  pageId: string;
  to: string;
}
export interface NavPopoverContentProps {
  pageLinks: PageLink[];
  activePageId?: string;
}

export const NavPopoverContent: FC<NavPopoverContentProps> = ({ pageLinks = [], activePageId }) => {
  const pages = pageLinks.slice(3);

  return (
    <PopoverContent>
      <StyledFlex as="ul" column padding="0.4rem" overflow="auto">
        {pageLinks.slice(0, 3).map(({ pageId, name, to }) => (
          <PageLi key={pageId} activePageId={activePageId} name={name} pageId={pageId} to={to} />
        ))}
        {pages.length > 0 && <Divider solid color="var(--clay)" />}
        {pages.map(({ pageId, name, to }) => (
          <PageLi key={pageId} activePageId={activePageId} name={name} pageId={pageId} to={to} />
        ))}
      </StyledFlex>
    </PopoverContent>
  );
};

interface PageLiProps {
  pageId: string;
  to: string;
  name: string;
  activePageId?: string;
}

function PageLi({ pageId, to, name, activePageId }: PageLiProps) {
  return (
    <Flex as="li" key={`nav-link-${pageId}`} width="100%" style={{ zIndex: 102 }}>
      <StyledLink to={to}>
        <Typography
          variant="h-16-b"
          truncate
          title={name}
          color={pageId === activePageId ? 'var(--brand-secondary)' : 'var(--text)'}
        >
          {name}
        </Typography>
      </StyledLink>
    </Flex>
  );
}

const StyledFlex = styled(Flex)`
  box-shadow: var(--shadow-400);
  max-height: 70vh;
  min-width: 200px;
  max-width: 400px;
`;

const StyledLink = styled(Link)`
  padding: 0.5rem;
  width: calc(100% - 1rem);
  border-radius: var(--corner);

  :hover {
    background-color: rgba(21, 23, 33, 0.3);
    color: var(--brand-secondary);
  }
`;
