import styled from '@emotion/styled';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { Flex } from '../Core/Flex';
import { SimpleTextEditor } from '../Core/SimpleTextEditor';
import { Typography } from '../Core/Typography';
import { TickrTab } from '../Element/types/elementTypes';
import { TalkingPointPopover } from '../Popovers/TalkingPointPopover/TalkingPointPopover';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useCurrentTab } from '../hooks/useCurrentTab';
import { FeedbackControls } from './FeedbackControls';
import { StreamCursor } from './StreamCursor';
import { StreamingIcon } from './StreamingIcon';

interface TalkingPointProps {
  tab: TickrTab;
}

export function TalkingPoint({ tab }: TalkingPointProps) {
  const { presentationMode } = useAuth();
  const { pageId, tabId } = useParams();
  const currentPage = useCurrentPage();
  const currentTab = useCurrentTab();
  const { updateTabs } = useCollectionMutations();
  const { isStreaming, workflowId } = useTalkingPointsPage();
  const navigate = useNavigate();

  const { text: headlineStream, isStreaming: isStreamingHeadline } = useChatCpgData<StreamingText>(
    [`${tab.settings?.talkingPoint}-headline`, workflowId],
    shallow
  ) ?? {
    text: '',
    isStreaming: false,
  };

  if (!currentPage) return <></>;

  const isActive = tabId === tab.id;
  const { updatedAt: originalTimestamp } = currentPage.settings;

  const initialText = (tab.text || headlineStream) ?? '';

  return (
    <StyledFlex
      isActive={isActive}
      as={isActive ? 'div' : 'button'}
      direction="column"
      width="100%"
      padding="8px"
      gap="6px"
      style={{ overflow: 'auto' }}
      onClick={!isActive ? () => navigate(`/page/${pageId}/tab/${tab.id}`) : undefined}
    >
      <Flex gap="0.6rem" align="center" width="100%">
        {isActive ? (
          <TalkingPointPopover>
            <StreamingIcon
              as="button"
              isStreaming={(isStreaming && !initialText) || isStreamingHeadline}
              size="24px"
            />
          </TalkingPointPopover>
        ) : (
          <StreamingIcon
            isStreaming={(isStreaming && !initialText) || isStreamingHeadline}
            size="24px"
          />
        )}
        <SimpleTextEditor
          as="h3"
          color={isActive ? 'var(--white)' : 'var(--text-secondary)'}
          disabled={!isActive || isStreaming || presentationMode}
          variant="c-14"
          initialValue={tab.title}
          focusBackground="var(--black-50)"
          onSuccess={async (title) => {
            try {
              await updateTabs({
                tabs: [
                  {
                    ...tab,
                    title: title,
                    settings: {
                      ...tab.settings,
                      textUpdatedAt: Date.now(),
                    },
                  },
                ],
                pageId,
              });
            } catch (e) {
              captureException(e);
            }
          }}
        />
      </Flex>

      {isStreamingHeadline ? (
        <Flex grow={1} overflow="auto">
          <Typography textAlign="left">
            <span className="p-14-b" dangerouslySetInnerHTML={{ __html: headlineStream }} />
            <StreamCursor />
          </Typography>
        </Flex>
      ) : (
        <SimpleTextEditor
          color={isActive ? 'var(--white)' : 'var(--text-secondary)'}
          disabled={!isActive || isStreaming || presentationMode}
          focusBackground="var(--black-50)"
          initialValue={initialText}
          onSuccess={async (text) => {
            try {
              await updateTabs({
                tabs: [
                  {
                    ...tab,
                    text,
                    settings: {
                      ...tab.settings,
                      textUpdatedAt: Date.now(),
                    },
                  },
                ],
                pageId,
              });
            } catch (e) {
              captureException(e);
            }
          }}
          style={{ flexGrow: 1 }}
          variant="p-14-b"
        />
      )}
      {isActive && (
        <FeedbackControls
          feedbackType="headline"
          isEdited={!!tab.settings?.textUpdatedAt}
          isStreaming={isStreaming}
          onRestore={
            currentTab?.settings?.textUpdatedAt
              ? () => {
                  if (!tabId || !currentTab) return;

                  const tabToUpdate: TickrTab = {
                    ...currentTab,
                    text: '',
                    settings: { ...currentTab?.settings, textUpdatedAt: null },
                  };

                  updateTabs({ tabs: [tabToUpdate], pageId });
                }
              : undefined
          }
          talkingPointId={tab.id}
          text={headlineStream}
          timestamp={tab.settings?.textUpdatedAt ?? originalTimestamp}
        />
      )}
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{ isActive: boolean }>`
  border: ${({ isActive }) => (isActive ? '1px solid var(--white)' : '1px solid var(--space)')};
  border-radius: 8px;
  min-height: 154px;
  max-height: 154px;
  width: 250px;

  background-color: ${({ isActive }) => (isActive ? 'var(--clay)' : 'var(--woodsmoke)')};
  filter: brightness(${({ isActive }) => (isActive ? '1' : '0.8')});

  :hover:enabled {
    filter: brightness(1);
    opacity: 1;
  }

  :active:enabled {
    filter: brightness(0.7);
  }
`;
