import * as Highcharts from 'highcharts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceRevenueVolumeData } from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { SlideCarousel } from '../Core/SlideCarousel';
import { ValueBox } from '../Core/ValueBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { formatTrend } from '../helpers/chartHelpers';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { useLegendClick } from '../hooks/useLegendClick';
import { HighchartsContainer } from './HighchartsContainer';

export function PriceRevenueVolume() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const workflowData = useChatCpgData<PriceRevenueVolumeData>(['pvs-chart', workflowId]);

  const chartData = useMemo(() => {
    return workflowData
      ? {
          price: {
            trend: formatTrend({ trend: workflowData.price, daysToAdd: 6 }),
          },
          volume: {
            trend: formatTrend({ trend: workflowData.volume, daysToAdd: 6 }),
          },
          revenue: {
            trend: formatTrend({ trend: workflowData.sales, daysToAdd: 6 }),
          },
        }
      : {
          price: {
            trend: [],
          },
          volume: {
            trend: [],
          },
          revenue: {
            trend: [],
          },
        };
  }, [workflowData]);

  const options = useMemo(() => {
    const highchartsOptions: Highcharts.Options = {
      tooltip: {
        shared: true,
        dateTimeLabelFormats: {
          day: `${t('util.weekEnding')} %a, %b %e, %Y`,
          hour: `${t('util.weekEnding')} %a, %b %e, %Y`,
          millisecond: `${t('util.weekEnding')} %a, %b %e, %Y`,
        },
        borderColor: 'var(--geyser)',
      },
      chart: {
        animation: false,
      },
      xAxis: {
        type: 'datetime',
        startOfWeek: 6,
        crosshair: true,
      },
      legend: {
        enabled: false,
      },
      yAxis: [
        {
          type: 'linear',
          labels: {
            formatter: function () {
              return `$${Highcharts.numberFormat(this.value as number, 2)}`;
            },
          },
        },
        {
          type: 'linear',
          opposite: true,
          labels: {
            formatter: function () {
              return `$${this.axis.defaultLabelFormatter.call(this)}`;
            },
            style: {
              color: 'var(--positive)',
            },
          },
        },
        {
          type: 'linear',
          opposite: true,
          labels: {
            style: {
              color: 'var(--salmon)',
            },
          },
        },
      ],
      series: [
        {
          name: t('metrics.revenue'),
          type: 'area',
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, 'var(--positive-50)'],
              [1, 'var(--transparent)'],
            ],
          },
          lineWidth: 2,
          lineColor: 'var(--positive)',
          data: chartData.revenue.trend,
          tooltip: {
            valuePrefix: '$',
          },
          yAxis: 1,
          marker: {
            fillColor: 'var(--positive)',
          },
        },
        {
          name: t('metrics.volume'),
          type: 'column',
          animation: false,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, 'var(--salmon)'],
              [0.05, 'var(--salmon-70)'],
              [0.2, 'var(--salmon-50)'],
              [1, 'var(--transparent)'],
            ],
          },
          data: chartData.volume.trend,
          borderWidth: 0,
          yAxis: 2,
          tooltip: {
            valueDecimals: 0,
          },
        },
        {
          name: t('metrics.price'),
          type: 'line',
          color: 'var(--white)',
          dashStyle: 'Solid',
          borderColor: 'var(--woodsmoke)',
          borderWidth: 1,
          lineWidth: 2,
          data: chartData.price.trend,
          tooltip: {
            valuePrefix: '$',
          },
          yAxis: 0,
        },
      ],
    };

    return highchartsOptions;
  }, [t, chartData]);

  const { chartRef, hiddenTrends, handleLegendClick } = useLegendClick();

  return (
    <Flex
      width="100%"
      height="100%"
      justify="stretch"
      align="stretch"
      direction="column"
      style={{ minHeight: 0, minWidth: 0 }}
    >
      <SlideCarousel width="100%">
        <ValueBox
          label={t('metrics.price')}
          color="var(--white)"
          secondary
          allNeutral
          onClick={() => handleLegendClick('price', 2)}
          style={{
            opacity: hiddenTrends.includes('price') ? 0.5 : 1,
          }}
        />
        <ValueBox
          label={t('metrics.revenue')}
          color="var(--positive)"
          secondary
          allNeutral
          indicator="area"
          onClick={() => handleLegendClick('revenue', 0)}
          style={{
            opacity: hiddenTrends.includes('revenue') ? 0.5 : 1,
          }}
        />
        <ValueBox
          label={t('metrics.volume')}
          color="var(--salmon)"
          secondary
          allNeutral
          indicator="column"
          onClick={() => handleLegendClick('volume', 1)}
          style={{
            opacity: hiddenTrends.includes('volume') ? 0.5 : 1,
          }}
        />
      </SlideCarousel>
      <HighchartsContainer options={options} ref={chartRef} />
    </Flex>
  );
}
