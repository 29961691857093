import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PickerUpIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
        <path
          d="M7.70996 3.29008L1.82693 9.01234C1.43491 9.39366 0.806548 9.38659 0.419844 8.99988C0.0293189 8.60936 0.0265428 7.97342 0.417067 7.58289L7.00286 0.997105C7.39338 0.606581 8.02654 0.60658 8.41707 0.997104L15.0029 7.58289C15.3934 7.97342 15.3934 8.60658 15.0029 8.99711L14.9884 9.01155C14.6013 9.39867 13.9748 9.40253 13.583 9.0202L7.70996 3.29008Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
