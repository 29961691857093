import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { NewsIcon } from '../Icons/NewsIcon';
import { PickerRightIcon } from '../Icons/PickerRightIcon';
import { ThreeDotIcon } from '../Icons/ThreeDotIcon';
import { PopoverProvider } from '../Popover/PopoverProvider';
import { PopoverTrigger } from '../Popover/PopoverTrigger';
import { PostPopoverContent } from './PostPopoverContent';
import { ReportDialog } from './ReportDialog/ReportDialog';

interface PostHeaderProps {
  clusterId: string;
  clusterSize?: number;
  currentStory?: number;
  impact?: number;
  report?(): JSX.Element;
}

export function PostHeader({
  clusterId,
  clusterSize,
  currentStory,
  impact,
  report,
}: PostHeaderProps) {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" align="center" width="100%">
      <ReportDialog report={report}>
        <ImpactButton hasReport={!!report}>
          <Flex align="center" gap="0.2rem">
            <ImpactScore align="center" gap="0.6rem" padding="0.3rem 0.5rem">
              <Typography variant="n-32-b">{impact}</Typography>
              <Flex direction="column">
                <Typography variant="n-12-b" color="var(--text-secondary)">
                  /100
                </Typography>
                <Typography variant="n-12-b" color="var(--text-secondary)">
                  {t('metrics.impact')}
                </Typography>
              </Flex>
            </ImpactScore>
            {report && <PickerRightIcon size="12px" />}
          </Flex>
        </ImpactButton>
      </ReportDialog>

      <Flex direction="column" gap="0.4rem" align="center">
        <Flex align="center" width="100%" justify="center" gap="0.2rem">
          <NewsIcon color="var(--white)" />
          <PopoverProvider placement="bottom-start">
            <PopoverTrigger asChild>
              <ThreeDotIcon />
            </PopoverTrigger>
            <PostPopoverContent id={clusterId} />
          </PopoverProvider>
        </Flex>
        {clusterSize ? (
          <Typography variant="h-12-b" width="100%" style={{ textAlign: 'center' }}>
            {t('util.newsCluster', { currentStory, clusterSize })}
          </Typography>
        ) : (
          <div />
        )}
      </Flex>
    </Flex>
  );
}

const ImpactButton = styled.button<{ hasReport: boolean }>`
  display: flex;
  border-radius: var(--corner);
  border: 2px solid var(--space);
  background-color: var(--space);
  ${({ hasReport }) =>
    hasReport
      ? `
        cursor: pointer;
        :hover {
          background-color: var(--space-70);
          border: 2px solid var(--space-70);
        }
        `
      : 'cursor: default;'}
`;

const ImpactScore = styled(Flex)`
  border-radius: var(--corner);
  background-color: var(--black-50);
`;
