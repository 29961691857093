import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { Tooltip } from '../Tooltip/Tooltip';
import { TooltipContent } from '../Tooltip/TooltipContent';
import { TooltipTrigger } from '../Tooltip/TooltipTrigger';

interface InfoTooltipProps {
  text?: string;
  component?(): JSX.Element;
  maxWidth?: string;
  isHidden?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  zindex?: number;
  containerStyle?: React.CSSProperties;
  leaveOpenOnHover?: boolean;
  disabled?: boolean;
}

export function InfoTooltip({
  isHidden,
  maxWidth = '400px',
  text,
  component,
  open,
  onOpenChange,
  zindex,
  containerStyle,
  leaveOpenOnHover,
  disabled,
  children,
}: PropsWithChildren<InfoTooltipProps>) {
  return (
    <Tooltip
      placement="bottom"
      fallbackPlacements={['top']}
      leaveOpenOnHover={leaveOpenOnHover}
      {...(open !== undefined && onOpenChange && { open, onOpenChange })}
      {...(disabled && { open: false })}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent isHidden={isHidden} zindex={zindex}>
        <Flex
          width="100%"
          padding="0.5rem"
          align="center"
          style={{
            minWidth: '30px',
            borderRadius: '100px',
            background: 'var(--info)',
            maxWidth,
            padding: '0.5rem 1.5rem',
            ...containerStyle,
          }}
        >
          {!component && text && <Typography multiline>{text}</Typography>}
          {component && component()}
        </Flex>
      </TooltipContent>
    </Tooltip>
  );
}
