import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Circle } from './Circle';
import { Typography } from './Typography';

const StyledCircle = styled(Circle)`
  position: absolute;
`;

interface IndicatorProps {
  top?: string;
  left?: string;
  right?: string;
  align?: 'right' | 'left';
  color?: string;
  textColor?: string;
  zindex?: string | number;
  onClick?: () => void;
}

export function Indicator({
  top = 'unset',
  left = 'unset',
  right = 'unset',
  color = 'var(--brand-secondary)',
  textColor = 'var(--black)',
  zindex = 'unset',
  onClick,
  children,
}: PropsWithChildren<IndicatorProps>) {
  return (
    <StyledCircle
      size="12px"
      color={color}
      onClick={onClick}
      style={{
        position: 'absolute',
        zIndex: zindex,
        top: top,
        left: left,
        right: right,
        minWidth: '12px',
        minHeight: '12px',
        maxWidth: '12px',
        maxHeight: '12px',
      }}
    >
      <Typography variant="n-12-b" color={textColor}>
        {children}
      </Typography>
    </StyledCircle>
  );
}
