import { createContext } from 'react';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { AppFilters } from '../filterTypes';

export interface ActiveFiltersContextProps {
  activeFilters: AppFilters;
  combinedFilters: AppFilters;
  outerFilters: AppFilters;
  typeOverrides?: Record<string, FilterDefinition['type']>;
}

export const ActiveFiltersContext = createContext<ActiveFiltersContextProps>({
  activeFilters: {},
  combinedFilters: {},
  outerFilters: {},
});
