import styled from '@emotion/styled';
import { useState } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { DataIcon } from '../Icons/DataIcon';
import { PageAddIcon } from '../Icons/PageAddIcon';
import { PageIcon } from '../Icons/PageIcon';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import { TickrElement, TickrPage } from './types/elementTypes';

interface CollectionTileProps {
  item: TickrElement | TickrPage;
  baseZindex?: number;
  onClick: () => void;
}

export function CollectionTile({ item, onClick, baseZindex = 1 }: CollectionTileProps) {
  const { title } = item;
  const isElement = !!(item as TickrElement)?.elementDef;

  const [descriptionTooltipOpen, setDescriptionTooltipOpen] = useState(false);

  return (
    <InfoTooltip
      text={item.description || item.subtitle || item.title}
      open={descriptionTooltipOpen}
      onOpenChange={setDescriptionTooltipOpen}
      zindex={baseZindex + 1}
    >
      <StyledFlex
        as="button"
        className="content"
        direction="column"
        width="100%"
        height="100%"
        gap="0.2rem"
        padding="8px 10px"
        imageUrl={item.imageUrl}
        onClick={onClick}
      >
        <Typography textAlign="left" as="h4" className="title" title={title} lineClamp={2}>
          {title}
        </Typography>
        {!item.imageUrl && isElement && (
          <DataIcon
            size="50px"
            color="var(--lavender-70)"
            style={{
              position: 'absolute',
              left: 'calc(50% - 20px)',
              top: 'calc(50% - 10px)',
              zIndex: 0,
            }}
          />
        )}
        {!item.imageUrl && !isElement && item.isTemplate && (
          <PageAddIcon
            size="50px"
            color="var(--lavender-70)"
            style={{
              position: 'absolute',
              left: 'calc(50% - 20px)',
              top: 'calc(50% - 10px)',
              zIndex: 0,
            }}
          />
        )}
        {!item.imageUrl && !isElement && !item.isTemplate && (
          <PageIcon
            size="50px"
            color="var(--lavender-70)"
            style={{
              position: 'absolute',
              left: 'calc(50% - 20px)',
              top: 'calc(50% - 10px)',
              zIndex: 0,
            }}
          />
        )}
      </StyledFlex>
    </InfoTooltip>
  );
}

const StyledFlex = styled(Flex)<{ imageUrl?: string }>`
  min-width: 190px;
  height: 140px;
  ${({ imageUrl }) =>
    imageUrl
      ? `
        background: linear-gradient(180deg, var(--woodsmoke) 25%, rgba(21, 22, 29, 0) 50%), url(https://${imageUrl}) no-repeat 50% calc(50% + 18px);
        background-size: cover;
        `
      : `
        background: var(--clay);
  `}
  border-radius: var(--corner);

  border: 1px solid var(--space);

  :hover {
    border: 1px solid var(--white-50);
  }
`;
