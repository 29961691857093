import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PlusIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0405 0H10.0052C9.45087 2.66036e-06 9.00232 0.450867 9.00518 1.00516L9.04642 9H1C0.447715 9 0 9.44772 0 10C3.00034e-06 10.5523 0.447718 11 1 11L8.99999 11L8.99999 19C8.99999 19.5523 9.44771 20 9.99999 20C10.5523 20 11 19.5523 11 19L11 11L19 11C19.5523 11 20 10.5523 20 10C20 9.44772 19.5523 9.00001 19 9.00001L11.0405 9.00001L11.0405 1C11.0405 0.447715 10.5927 0 10.0405 0Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
