import {
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
  gridClasses,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ChangeIndicator } from '../../Core/ChangeIndicator';
import { DataGridTree } from '../../Core/DataGridTree';
import { Flex } from '../../Core/Flex';
import { NumberFormatter } from '../../Core/NumberFormatter';
import { Typography } from '../../Core/Typography';
import { Sparkline } from '../../Graphs/Sparkline';
import { InformationIcon } from '../../Icons/InformationIcon';
import { useApp } from '../../Providers/AppProvider/useApp';
import { useLocalization } from '../../Providers/LocalizationProvider/useLocalization';
import { InfoTooltip } from '../../Tooltips/InfoTooltip';
import { HeaderCell } from './HeaderCell';
import { HeaderGroupCell } from './HeaderGroupCell';
import { ProductGroupCell } from './ProductGroupCell';

export interface SalesTrend {
  historicTrend: [string, number][];
  forecastTrend: [string, number][];
}

interface SalesTrends {
  owned: SalesTrend;
  competitors: SalesTrend;
}

export interface SalesPerformanceRow {
  id: number | string;
  retailer?: string;
  path: string[];
  salesTrends?: SalesTrends;
  isOwned?: boolean;
  salesOwnedHistoric?: number;
  vlpOwnedHistoric?: number;
  salesCompetitorHistoric?: number;
  vlpCompetitorHistoric?: number;
  vlpDiffHistoric?: number;
  salesOwnedForecast?: number;
  vlpOwnedForecast?: number;
  salesCompetitorForecast?: number;
  vlpCompetitorForecast?: number;
  vlpDiffForecast?: number;
}
interface SalesPerformanceProps {
  isLoading?: boolean;
  rows?: SalesPerformanceRow[];
}

const GROUP_MIN_WIDTH = 200;
const GROUP_WIDTH = 200;
const TREND_MIN_WIDTH = 100;
const TREND_WIDTH = 135;
const SALES_MIN_WIDTH = 80;
const SALES_WIDTH = 80;
const VYA_MIN_WIDTH = 80;
const VYA_WIDTH = 105;
const GROWTH_DIFF_MIN_WIDTH = 90;
const GROWTH_DIFF_WIDTH = 100;

export function SalesPerformance({ isLoading = false, rows = [] }: SalesPerformanceProps) {
  const t = useLocalization();
  const { apiUrl } = useApp();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'salesTrends',
        headerName: t('salesPerformance.salesTrend'),
        renderCell: (props: GridRenderCellParams<any, SalesTrends>) => {
          const {
            owned = { historicTrend: [], forecastTrend: [] },
            competitors = { historicTrend: [], forecastTrend: [] },
          } = props?.value ?? {};

          if (owned.historicTrend?.[owned.historicTrend.length - 1]) {
            owned.forecastTrend = [
              owned.historicTrend?.[owned.historicTrend.length - 1],
              ...owned.forecastTrend,
            ];
          }

          if (competitors.historicTrend?.[competitors.historicTrend.length - 1]) {
            competitors.forecastTrend = [
              competitors.historicTrend?.[competitors.historicTrend.length - 1],
              ...competitors.forecastTrend,
            ];
          }

          return (
            <Sparkline
              // xDomain={[]}
              sparklines={[
                {
                  id: `${props.id}-owned`,
                  historicData: owned.historicTrend.map(([x, y]) => [dateStringToNumber(x), y]),
                  forecastData: owned.forecastTrend.map(([x, y]) => [dateStringToNumber(x), y]),
                  color: 'var(--white)',
                  strokeWidth: 2.5,
                },
                {
                  id: `${props.id}-competitors`,
                  historicData: competitors.historicTrend.map(([x, y]) => [
                    dateStringToNumber(x),
                    y,
                  ]),
                  forecastData: competitors.forecastTrend.map(([x, y]) => [
                    dateStringToNumber(x),
                    y,
                  ]),
                  color: 'var(--white-70)',
                  strokeWidth: 0.5,
                },
              ]}
              containerPadding="8px 0"
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: TREND_MIN_WIDTH,
        width: TREND_WIDTH,
      },
      {
        field: 'salesOwnedHistoric',
        headerName: t('salesPerformance.owned'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <NumberFormatter as="p" numberStyle="currencyEstimate" variant="n-14-b" val={value} />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: SALES_MIN_WIDTH,
        width: SALES_WIDTH,
      },
      {
        field: 'vlpOwnedHistoric',
        headerName: t('salesPerformance.vya'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <ChangeIndicator
              borderColor="var(--text)"
              textColor="var(--text)"
              numberStyle="percentage"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: VYA_MIN_WIDTH,
        width: VYA_WIDTH,
      },
      {
        field: 'salesCompetitorHistoric',
        headerName: t('salesPerformance.competitors'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <NumberFormatter
              as="p"
              numberStyle="currencyEstimate"
              color="var(--text-secondary)"
              variant="n-14-b"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: SALES_MIN_WIDTH,
        width: SALES_WIDTH,
      },
      {
        field: 'vlpCompetitorHistoric',
        headerName: t('salesPerformance.vya'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <ChangeIndicator
              allNeutral
              textColor="var(--text-secondary)"
              numberStyle="percentage"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: VYA_MIN_WIDTH,
        width: VYA_WIDTH,
      },
      {
        field: 'vlpDiffHistoric',
        headerName: t('salesPerformance.growthDiff'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          const isLeaf = props.rowNode.type === 'leaf';

          return (
            <Flex width="100%" align="center" gap="0.5rem">
              <ChangeIndicator numberStyle="percentagePoint" val={value} />
              {isLeaf && !!value && (
                <InfoTooltip text={t('salesPerformance.growthDiff.tooltip')}>
                  <InformationIcon color="var(--white-70)" size="14px" />
                </InfoTooltip>
              )}
            </Flex>
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: GROWTH_DIFF_MIN_WIDTH,
        width: GROWTH_DIFF_WIDTH,
      },
      {
        field: 'salesOwnedForecast',
        headerName: t('salesPerformance.owned'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <NumberFormatter as="p" numberStyle="currencyEstimate" variant="n-14-b" val={value} />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: SALES_MIN_WIDTH,
        width: SALES_WIDTH,
      },
      {
        field: 'vlpOwnedForecast',
        headerName: t('salesPerformance.vya'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <ChangeIndicator
              borderColor="var(--text)"
              textColor="var(--text)"
              numberStyle="percentage"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: VYA_MIN_WIDTH,
        width: VYA_WIDTH,
      },
      {
        field: 'salesCompetitorForecast',
        headerName: t('salesPerformance.competitors'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <NumberFormatter
              as="p"
              numberStyle="currencyEstimate"
              color="var(--text-secondary)"
              variant="n-14-b"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: SALES_MIN_WIDTH,
        width: SALES_WIDTH,
      },
      {
        field: 'vlpCompetitorForecast',
        headerName: t('salesPerformance.vya'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return (
            <ChangeIndicator
              allNeutral
              textColor="var(--text-secondary)"
              numberStyle="percentage"
              val={value}
            />
          );
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: VYA_MIN_WIDTH,
        width: VYA_WIDTH,
      },
      {
        field: 'vlpDiffForecast',
        headerName: t('salesPerformance.growthDiff'),
        renderCell: (props: GridRenderCellParams<any, number>) => {
          const value = props.value ?? NaN;
          return <ChangeIndicator numberStyle="percentagePoint" val={value} />;
        },
        renderHeader: (props) => <HeaderCell {...props} />,
        minWidth: GROWTH_DIFF_MIN_WIDTH,
        width: GROWTH_DIFF_WIDTH,
      },
    ],
    [t]
  );

  const columnGroupingModel = useMemo<GridColumnGroupingModel>(
    () => [
      ...(apiUrl
        ? []
        : ([
            {
              groupId: t('salesPerformance.title'),
              children: [{ field: '__tree_data_group__' }],
              renderHeaderGroup: (props) => <HeaderGroupCell {...props} />,
            },
          ] as GridColumnGroupingModel)),
      {
        groupId: t('salesPerformance.trend'),
        headerAlign: 'center',
        children: [{ field: 'salesTrends' }],
        renderHeaderGroup: (props) => <HeaderGroupCell secondary {...props} />,
      },
      {
        groupId: t('salesPerformance.l52wSales'),
        headerAlign: 'center',
        children: [
          { field: 'salesOwnedHistoric' },
          { field: 'vlpOwnedHistoric' },
          { field: 'salesCompetitorHistoric' },
          { field: 'vlpCompetitorHistoric' },
          { field: 'vlpDiffHistoric' },
        ],
        renderHeaderGroup: (props) => <HeaderGroupCell secondary {...props} />,
      },
      {
        groupId: t('salesPerformance.forecastSales'),
        headerAlign: 'center',
        children: [
          { field: 'salesOwnedForecast' },
          { field: 'vlpOwnedForecast' },
          { field: 'salesCompetitorForecast' },
          { field: 'vlpCompetitorForecast' },
          { field: 'vlpDiffForecast' },
        ],
        renderHeaderGroup: (props) => <HeaderGroupCell secondary {...props} />,
      },
    ],
    [t, apiUrl]
  );

  return (
    <DataGridTree
      disableColumnMenu
      columnHeaderHeight={42}
      slots={{
        noRowsOverlay: () =>
          isLoading ? (
            <Skeleton
              baseColor="var(--clay)"
              highlightColor="var(--space)"
              width="100%"
              height="99%"
              containerClassName="flex-skeleton"
            />
          ) : (
            <Flex align="center" justify="center" height="100%">
              <Typography variant="p-14-b">{t('salesPerformance.noDataAvailable')}</Typography>
            </Flex>
          ),
      }}
      sx={{
        '& .MuiDataGrid-columnHeader--filledGroup > .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader--alignCenter > .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader--emptyGroup > .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-columnHeaders:not(:hover) div[aria-colindex="2"].MuiDataGrid-columnHeader--sortable':
          {
            borderLeft: '1px dashed var(--trout)',
            borderRight: '1px dashed var(--trout)',
          },
        '.MuiDataGrid-columnHeaders:not(:hover) div[aria-colindex="7"].MuiDataGrid-columnHeader--sortable':
          {
            borderRight: '1px dashed var(--trout)',
          },
        [`.${gridClasses.row}>div:nth-of-type(2)`]: {
          borderLeft: '1px dashed var(--trout)',
          borderRight: '1px dashed var(--trout)',
        },
        [`.${gridClasses.row}>div:nth-of-type(7)`]: {
          borderRight: '1px dashed var(--trout)',
        },
      }}
      columnGroupingModel={columnGroupingModel}
      groupingColDef={{
        headerName: t('salesPerformance.products'),
        sortable: true,
        minWidth: GROUP_MIN_WIDTH,
        width: GROUP_WIDTH,
        renderHeader: ({ colDef }) => {
          return <Typography secondary>{colDef.headerName}</Typography>;
        },
        renderCell: (params) => <ProductGroupCell {...params} />,
      }}
      rows={rows}
      experimentalFeatures={{
        columnGrouping: true,
      }}
      columns={columns}
      rowSelection={false}
      hideFooter
      showCellVerticalBorder={false}
      defaultGroupingExpansionDepth={2}
      getRowHeight={(props) => {
        return (props.id as string).split('/').length > 3 ? 52 : 72;
      }}
    />
  );
}

function dateStringToNumber(dateString: string) {
  return Math.floor(new Date(dateString).getTime() / 1000);
}
