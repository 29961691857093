import { createContext, Dispatch } from 'react';
import { ProductAttributeFilters } from './ActivePAFiltersContext';

export type ActivePAFiltersAction =
  | {
      type: 'add';
      payload: ProductAttributeFilters;
    }
  | {
      type: 'replace';
      payload: ProductAttributeFilters;
    }
  | {
      type: 'remove';
      payload: keyof ProductAttributeFilters;
    };

export const ActivePAFiltersDispatchContext = createContext<Dispatch<ActivePAFiltersAction>>(
  () => null
);
