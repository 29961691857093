import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import isNumber from 'lodash/isNumber';
import { CSSProperties, PropsWithChildren, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import { PickerLeftIcon } from '../Icons/PickerLeftIcon';
import { PickerRightIcon } from '../Icons/PickerRightIcon';

const Viewport = styled.div<SlideCarouselProps>`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height};
  min-width: 0;
`;

const ScrollLeft = styled(motion.div)`
  display: flex;
  position: absolute;
  left: 0px;
  z-index: 1;
`;

const ScrollRight = styled(motion.div)`
  display: flex;
  position: absolute;
  right: 0px;
`;

const InnerFlex = styled(motion.div)<{ gap: string; alignItems: string }>`
  display: flex;
  height: 100%;
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
`;

interface SlideCarouselProps {
  width?: string;
  height?: number | string;
  gap?: number | string;
  buttonWidth?: number | string;
  scrollPercent?: number;
  cardWidth?: number;
  alignItems?: CSSProperties['alignItems'];
  style?: CSSProperties;
}

const stringify = (prop: number | string) => {
  return isNumber(prop) ? `${prop}px` : prop;
};

export function SlideCarousel({
  width,
  height = 'unset',
  gap = '1rem',
  scrollPercent = 0.9,
  buttonWidth = 30,
  cardWidth,
  alignItems = 'center',
  style,
  children,
}: PropsWithChildren<SlideCarouselProps>) {
  const [left, setLeft] = useState(0);
  const [viewportRef, { width: viewportWidth = 0 }] = useMeasure<HTMLDivElement>();
  const [innerRef, { width: innerWidth = 0 }] = useMeasure<HTMLDivElement>();

  let initScroll: number | undefined;
  let nextScroll: number | undefined;

  if (isNumber(cardWidth) && isNumber(gap) && isNumber(buttonWidth)) {
    const nCards = Math.floor((viewportWidth - 2 * buttonWidth) / (cardWidth + gap));
    const isOdd = nCards % 2 === 1;

    initScroll = isOdd
      ? (nCards + 0.5) * cardWidth + gap * nCards - viewportWidth / 2
      : nCards * cardWidth + gap * (nCards - 0.5) - viewportWidth / 2;

    nextScroll = nCards * (cardWidth + gap);
  }

  const isCarousel = viewportWidth < innerWidth;
  const maxScroll = -(innerWidth - viewportWidth);

  const scrollAmount =
    (left == 0 || left === maxScroll ? initScroll : nextScroll) ?? viewportWidth * scrollPercent;

  useEffect(() => {
    if (!isCarousel) setLeft(0);
    if (left < maxScroll) setLeft(0);
  }, [isCarousel, left, maxScroll]);

  return (
    <AnimatePresence>
      <Viewport width={width} height={stringify(height)} style={style} ref={viewportRef}>
        {isCarousel && left !== 0 && (
          <ScrollLeft
            initial={{ opacity: 0 }}
            transition={{ delay: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PickerLeftIcon
              as="button"
              background="var(--slide-carousel-left)"
              onClick={() => setLeft((left) => (left + scrollAmount > 0 ? 0 : left + scrollAmount))}
              width={stringify(buttonWidth)}
              height={stringify(height)}
              style={{
                borderRadius: 'none',
              }}
            />
          </ScrollLeft>
        )}
        <InnerFlex
          initial={{ translateX: 0 }}
          animate={{ translateX: `${left}px` }}
          transition={{ duration: 0.5 }}
          gap={stringify(gap)}
          alignItems={alignItems}
          ref={innerRef}
        >
          {children}
        </InnerFlex>
        {isCarousel && left !== maxScroll && (
          <ScrollRight
            initial={{ opacity: 0 }}
            transition={{ delay: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PickerRightIcon
              as="button"
              onClick={() =>
                setLeft((left) =>
                  left - scrollAmount < maxScroll ? maxScroll : left - scrollAmount
                )
              }
              background="var(--slide-carousel-right)"
              width={stringify(buttonWidth)}
              height={stringify(height)}
              style={{ borderRadius: 'none' }}
            />
          </ScrollRight>
        )}
      </Viewport>
    </AnimatePresence>
  );
}
