import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { SendIcon } from '../Icons/SendIcon';
import { MessageItem } from '../Providers/ChatBoxProvider/ChatBoxContext';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { StreamingIcon } from './StreamingIcon';

export interface ChatInputProps {
  highlight?: boolean;
  onSubmit?(): void;
}

export const ChatInput = forwardRef<HTMLTextAreaElement, ChatInputProps>(
  ({ highlight, onSubmit }, ref) => {
    const { t } = useTranslation();

    const {
      chatStreamId,
      conversation,
      //  fileList,
      fileStreamId,
      setChatInput,
    } = useChatBox();

    const { handleChange, handleSubmit, setMessage, values } = useChatBoxForm();
    const { isStreaming } = useTalkingPointsPage();

    const disabled = !values.message || !!fileStreamId || !!chatStreamId || isStreaming;

    return (
      <Flex column width="100%" gap="0.5rem">
        {isStreaming && (
          <Flex gap="0.5rem" align="center">
            <StreamingIcon isStreaming={isStreaming} />
            <Typography variant="p-12-i">{t('chatCpg.isStreaming.analysis')}</Typography>
          </Flex>
        )}
        {/* {fileList.length > 0 && (
          <Flex gap="0.5rem" align="center">
            {fileList.map((file) => (
              <FileChip key={file.id} id={file.id} name={file.name} />
            ))}
          </Flex>
        )} */}
        <Flex width="100%" align="center" gap="8px">
          <StyledTextArea
            autoFocus
            className={values.message ? 'p-14-b' : 'p-14-i'}
            value={values.message}
            highlight={highlight}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();

                if (!disabled) {
                  onSubmit?.();
                  handleSubmit();
                }
              }

              if (e.key === 'ArrowUp' && !values.message) {
                const lastMessage = conversation.find(
                  (item) => item.type === 'message' && item.sender === 'HUMAN'
                ) as MessageItem | undefined;

                if (lastMessage) {
                  e.preventDefault();
                  setMessage(lastMessage.text);
                }
              }
            }}
            onChange={(e) => {
              setChatInput(e.target.value);
              handleChange?.(e);
            }}
            id="message"
            name="message"
            placeholder={t('chatCpg.askAQuestion')}
            ref={ref}
          />
          <SendIcon
            as="button"
            type="submit"
            onClick={() => {
              onSubmit?.();
              handleSubmit();
            }}
            disabled={disabled}
            color="var(--action)"
            style={{ position: 'absolute', right: '10px', background: 'none' }}
          />
        </Flex>
      </Flex>
    );
  }
);

const StyledTextArea = styled.textarea<{ highlight?: boolean }>`
  width: 100%;
  height: 60px;
  color: var(--text);
  background-color: var(--clay);
  border-radius: var(--corner);
  padding: 12px 48px 12px 12px;
  border: 1px solid transparent;

  resize: none;

  :focus {
    outline: none;
    border: 1px solid var(--trout);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--clay) inset;
    -webkit-text-fill-color: var(--text);
    border: 1px solid var(--trout);
    caret-color: var(--text);
  }

  ::placeholder {
    color: var(--malibu-70);
  }

  ${({ highlight }) =>
    highlight &&
    `
animation: blink 500ms ease-in;
animation-iteration-count: 2;

@keyframes blink {
  0% {
    border-color: var(--lavender);
  }
}
`}
`;
