import styled from '@emotion/styled';
import { captureException } from '@sentry/react';
import { FC, memo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { ErrorBox } from '../Core/ErrorBox';
import { Flex } from '../Core/Flex';
import { ActiveFiltersProvider } from '../Filters/ActiveFiltersProvider/ActiveFiltersProvider';
import { ElementContext } from './ElementContext';
import { ElementSwitch } from './ElementSwitch';
import {
  ELEMENT_BORDER_COLOR,
  ELEMENT_BORDER_RADIUS,
  ELEMENT_BORDER_WIDTH,
} from './elementConstants';
import { TickrElement } from './types/elementTypes';

export interface ElementProps {
  element: TickrElement;
}

export const Element: FC<ElementProps> = memo(({ element }) => {
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState<string | null>();

  return (
    <ElementContext.Provider value={element}>
      <ActiveFiltersProvider
        typeOverrides={element.elementDef.settings?.filterTypeOverrides}
        filterScope={element.filterDef.activeFilters ?? {}}
      >
        <ElementWrapperFlex
          column
          width="100%"
          height="100%"
          isCard={element.elementDef.settings?.isCard}
          border={`${ELEMENT_BORDER_WIDTH} solid ${ELEMENT_BORDER_COLOR}`}
        >
          <ElementContent>
            <ErrorBoundary
              resetKeys={[resetKey]}
              onError={(err) => {
                captureException(err);
                setResetKey(null);
              }}
              fallback={
                <ErrorBox
                  text={t('error.moduleError', { moduleId: element.title })}
                  onRetry={() => setResetKey('reset')}
                />
              }
            >
              <ElementSwitch />
            </ErrorBoundary>
          </ElementContent>
        </ElementWrapperFlex>
      </ActiveFiltersProvider>
    </ElementContext.Provider>
  );
});

const ElementWrapperFlex = styled(Flex)<{ isCard?: boolean; border?: string }>`
  background: var(--background);
  border-radius: 0 0 ${ELEMENT_BORDER_RADIUS} ${ELEMENT_BORDER_RADIUS};
  border-right: ${({ border }) => border};
  border-left: ${({ border }) => border};
  border-bottom: ${({ border }) => border};
  padding: ${({ isCard }) => (isCard ? 0 : '8px')};
`;

const ElementContent = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;
