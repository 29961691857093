import { FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import omit from 'lodash/omit';
import { HTMLProps, forwardRef } from 'react';
import { TooltipArrow } from './TooltipArrow';
import { useTooltipContext } from './useTooltipContext';

interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
  isHidden?: boolean;
  zindex?: number;
}

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
  function TooltipContent(props, propRef) {
    const { context: floatingContext, ...context } = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const { isMounted, styles: transitionStyles } = useTransitionStyles(floatingContext, {
      initial: { opacity: 0 },
      duration: 300,
    });

    return (
      <FloatingPortal>
        {context.open && isMounted && (
          <div
            ref={ref}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              visibility: props.isHidden || context.x == null ? 'hidden' : 'visible',
              zIndex: props.zindex ?? 100,
              ...props.style,
              ...transitionStyles,
            }}
            {...context.getFloatingProps(omit(props, 'arrowRef', 'isHidden'))}
          >
            <>
              {props.children}
              <TooltipArrow ref={context.arrowRef} />
            </>
          </div>
        )}
      </FloatingPortal>
    );
  }
);
