import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { useDialogContext } from '../../DialogFloating/useDialogContext';
import { TickrPage } from '../../Element/types/elementTypes';
import { FormElementWrapper } from '../../FormElements/FormElementWrapper';
import { TextInput } from '../../FormElements/TextInput';
import { ThumbnailUploader } from '../../FormElements/ThumbnailUploader';
import { PickerRightIcon } from '../../Icons/PickerRightIcon';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useYupValidation } from '../../hooks/useYupValidation';
import { DeletePageDialog } from '../DeletePageDialog/DeletePageDialog';

interface PageSettingsDialogContentProps {
  onClose?: () => void;
  page: TickrPage;
}

export function PageSettingsDialogContent({ page, onClose }: PageSettingsDialogContentProps) {
  const { t } = useTranslation();
  const { updatePage } = useCollectionMutations();
  const { string255 } = useYupValidation();
  const { setOpen } = useDialogContext();
  const [thumbnailData, setThumbnailData] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        title: page.title,
        description: page.description ?? '',
      }}
      onSubmit={async (values) => {
        const pageToUpdate = cloneDeep(page);

        const updatedPage = {
          ...pageToUpdate,
          title: values.title,
          description: values.description,
          ...(thumbnailData ? { encoded_img: thumbnailData } : {}),
        };

        updatePage({ page: updatedPage });
        onClose?.();
      }}
      validationSchema={Yup.object().shape({
        title: string255.required(t('form.error.required')),
        description: string255,
      })}
      validateOnChange
    >
      {({ errors, handleChange, handleSubmit, values, isValid }) => {
        return (
          <>
            <DialogTitle title={t('pageSettings.modalTitle')} size="sm" onClose={onClose} showX />
            <DialogBody>
              <Flex direction="column" width="100%" gap="2rem">
                <TextInput
                  required
                  id="title"
                  name="title"
                  title={t('pageSettings.pageTitle')}
                  error={errors.title}
                  value={values.title}
                  onChange={handleChange}
                  placeholder={t('pageSettings.pageTitle.placeholder')}
                  max={255}
                />
                <TextInput
                  id="description"
                  name="description"
                  title={t('pageSettings.description')}
                  description={t('pageSettings.description.description')}
                  error={errors.description}
                  value={values.description}
                  onChange={handleChange}
                  placeholder={t('pageSettings.description.placeholder')}
                  max={255}
                />
                <ThumbnailUploader existingUrl={page.imageUrl} setFileData={setThumbnailData} />
                <FormElementWrapper
                  title={t('pageSettings.deletePage')}
                  description={
                    page.isPrivate
                      ? t('pageSettings.deletePage.private.description')
                      : t('pageSettings.deletePage.shared.description')
                  }
                >
                  <DeletePageDialog
                    pageId={page.id}
                    onDelete={() => {
                      setOpen(false);
                      onClose?.();
                    }}
                  >
                    <Flex as="button" align="center" padding="8px" gap="1rem">
                      <Typography variant="c-11" color="var(--danger)">
                        {t('pageSettings.deletePage')}
                      </Typography>
                      <PickerRightIcon color="var(--danger)" size="11px" />
                    </Flex>
                  </DeletePageDialog>
                </FormElementWrapper>
              </Flex>
            </DialogBody>
            <DialogFooter>
              <DialogTrigger>
                <Button
                  onClick={onClose}
                  color="var(--trout)"
                  width="min(200px, 50%)"
                  padding="5px 10px"
                >
                  {t('util.cancel')}
                </Button>
              </DialogTrigger>
              <DialogTrigger>
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  width="min(200px, 50%)"
                  padding="5px 10px"
                  disabled={!isValid}
                >
                  {t('util.apply')}
                </Button>
              </DialogTrigger>
            </DialogFooter>
          </>
        );
      }}
    </Formik>
  );
}
