import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';

interface CircleProps {
  className?: string;
  onClick?: () => void;
  border?: string;
  color?: string;
  size?: string;
  style?: CSSProperties;
}

const StyledDiv = styled.div<{
  color?: string;
  size?: string;
  border?: string;
}>`
  position: relative;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  background: ${({ color }) => color || 'var(--white)'};
  min-width: ${({ size }) => size || '30px'};
  min-height: ${({ size }) => size || '30px'};
  border: ${({ border }) => border || 'none'};
`;

export function Circle({
  className,
  color,
  size,
  border,
  onClick,
  style,
  children,
}: PropsWithChildren<CircleProps>) {
  return (
    <StyledDiv
      className={className}
      size={size}
      color={color}
      border={border}
      onClick={onClick}
      style={style}
    >
      {children}
    </StyledDiv>
  );
}
