import truncate from 'lodash/truncate';
import { NewsCard } from '../types/libBiTypes';
import { BasePost } from './BasePost';

interface NewsPostProps {
  currentStory?: number;
  news: NewsCard;
  report?(): JSX.Element;
  textLength?: number;
}

export function NewsPost({ news, report, textLength = 250, currentStory = 1 }: NewsPostProps) {
  return (
    <BasePost
      clusterId={news.clusterId}
      author={news?.source?.name}
      authorHandle={news?.source?.domain}
      authorImage={news?.source?.logo?.src}
      clusterSize={news.clusterSize}
      currentStory={currentStory}
      date={news.date}
      headline={news.title}
      imageSrc={news?.image?.src}
      impact={news?.metrics?.impact}
      postSrc={news.whitelisted && news.url}
      report={report}
      text={truncate(news.body, { length: textLength })}
      topic={news?.topic}
    />
  );
}
