import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StagedFiltersProvider } from '../../Filters/StagedFiltersProvider/StagedFiltersProvider';
import { StagedPAFiltersProvider } from '../../Filters/StagedPAFiltersProvider/StagedPAFiltersProvider';
import { AppFilters } from '../../Filters/filterTypes';
import { SolutionGrid } from '../SolutionsComponents/SolutionGrid';
import { SolutionHeader } from '../SolutionsComponents/SolutionHeader';
import { SolutionPanel } from '../SolutionsComponents/SolutionPanel';
import { SolutionWizard } from './SolutionWizard';

const FILTER_ORDER: (keyof AppFilters)[] = ['cpg_owned_products', 'cpg_dataset', 'cpg_banner'];

export function SubstitutionsSolution() {
  const { t } = useTranslation();
  const [filterIndex, setFilterIndex] = useState(0);
  const activeFilter = FILTER_ORDER[filterIndex];

  return (
    <StagedPAFiltersProvider>
      <StagedFiltersProvider>
        <SolutionGrid>
          <SolutionHeader
            title={
              activeFilter === 'cpg_owned_products'
                ? t('chatCpg.nextWhichProduct')
                : activeFilter === 'cpg_dataset'
                ? t('chatCpg.selectOneOrMoreDataSources')
                : t('chatCpg.selectABanner')
            }
            {...(activeFilter === 'cpg_owned_products' && {
              description: t('chatCpg.nextWhichProduct.description'),
            })}
          />
          <SolutionWizard
            activeFilter={activeFilter}
            filterIndex={filterIndex}
            setFilterIndex={setFilterIndex}
            solutionType="substitutions"
          />
          <SolutionPanel stage="select-scope" solutionType="substitutions" />
        </SolutionGrid>
      </StagedFiltersProvider>
    </StagedPAFiltersProvider>
  );
}
