import { useState } from 'react';
import { DialogContent } from '../DialogFloating/DialogContent';
import { DialogProvider } from '../DialogFloating/DialogProvider';
import { DialogTrigger } from '../DialogFloating/DialogTrigger';
import { ClearIcon } from '../Icons/ClearIcon';
import { MenuIcon } from '../Icons/MenuIcon';
import { SidePanelDialogContent } from './SidePanelDialogContent';

interface SidePanelDialogProps {
  rootId: string;
}

export function SidePanelDialog({ rootId }: SidePanelDialogProps) {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [sidePanelHidden, setSidePanelHidden] = useState(false);

  return (
    <DialogProvider
      open={sidePanelOpen}
      onOpenChange={(open) => {
        setSidePanelOpen(open);
        setSidePanelHidden(false);
      }}
    >
      <DialogTrigger>
        {sidePanelOpen && !sidePanelHidden ? (
          <ClearIcon
            as="button"
            background="transparent"
            onClick={() => {
              setSidePanelOpen((open) => !open);
            }}
            size="14px"
            style={{ zIndex: 10 }}
          />
        ) : (
          <MenuIcon
            as="button"
            background="transparent"
            onClick={() => {
              setSidePanelOpen((open) => !open);
            }}
            size="20px"
            style={{ zIndex: 10 }}
          />
        )}
      </DialogTrigger>
      <DialogContent
        placeItems="left"
        zindex={0}
        transitionProps={{
          initial: { opacity: 0, transform: 'translateX(-100%)' },
        }}
        omitFromOverlay={['transform']}
        asCard={false}
        rootId={rootId}
        overlayStyles={sidePanelHidden ? { zIndex: 100 } : {}}
        contentStyles={sidePanelHidden ? { transform: 'translateX(-100%)' } : {}}
      >
        <SidePanelDialogContent setSidePanelHidden={setSidePanelHidden} />
      </DialogContent>
    </DialogProvider>
  );
}
