export interface FuzzyOptions {
  minPrice: number;
  maxPrice: number;
  minAdjustmentRatio: number;
  maxAdjustmentRatio: number;
}

export function fudgePrice(
  price: number,
  options: FuzzyOptions = {
    minPrice: 0,
    maxPrice: 99999999,
    minAdjustmentRatio: 0.02,
    maxAdjustmentRatio: 0.1,
  }
) {
  if (price === 0) return 0;
  const { minPrice, maxPrice, minAdjustmentRatio, maxAdjustmentRatio } = options;

  const gauss_rand_from_price = (price: number) => {
    const random_seeds = [9319827138, 4937892821, 1111922222, 4492027771];
    const div = 2147483647;
    // doing this N times makes the distribution more gaussian
    return (
      random_seeds.map((seed) => ((seed * price) % div) / div).reduce((acc, x) => acc + x, 0) /
      random_seeds.length
    );
  };

  const bin_from_price = (price: number) => Math.round(gauss_rand_from_price(price));

  const rand_dist = gauss_rand_from_price(price);
  const plus_minus = bin_from_price(price) * 2 - 1;

  const clamped_ratio = rand_dist * (maxAdjustmentRatio - minAdjustmentRatio) + minAdjustmentRatio;

  const clamped_price = Math.min(Math.max(price, minPrice), maxPrice);

  const fudged = Math.round(100 * (price + clamped_price * plus_minus * clamped_ratio)) / 100;
  return fudged;
}
