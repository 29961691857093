import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TickrTab } from '../Element/types/elementTypes';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useAuth } from '../Providers/AuthProvider/useAuth';

export function useCurrentTabs() {
  const { pageId } = useParams();
  const { userId } = useAuth();
  const { get } = useApi();

  const { data: tabs = [], isLoading } = useQuery(
    ['current-tabs', pageId, userId],
    async () => {
      const { data: tabs } = await get<TickrTab[]>('v2/poplar/tabs', {
        params: {
          page_id: pageId,
        },
      });

      return tabs;
    },
    {
      enabled: !!pageId,
    }
  );

  return useMemo(() => ({ tabs, isLoading }), [tabs, isLoading]);
}
