import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import { useApi } from '../ApiProvider/useApi';
import { useApp } from '../AppProvider/useApp';
import { Conversation } from '../ChatBoxProvider/ChatBoxContext';
import {
  ChatCpgMutationsContext,
  ChatFeedbackProps,
  CreateAnalysisProps,
  FeedbackResponse,
  IdResponse,
  PostChatProps,
  PostFileProps,
  TextFeedbackProps,
} from './ChatCpgMutationsContext';

export function ChatCpgMutationsProvider({ children }: PropsWithChildren) {
  const { chatCpgUrl: chatCpgPath } = useApp();
  const { post, postForm } = useApi();
  const queryClient = useQueryClient();

  const createAnalysis = useMutation(
    async ({
      summaryName,
      gtins,
      banner,
      channel,
      model = 'gpt-4',
      weeks = 12,
      dataset,
    }: CreateAnalysisProps) => {
      const { data } = await post<IdResponse>(`${chatCpgPath}/summary`, {
        // In tids it's summary_kind because of a naming "conflict"
        summary_kind: summaryName,
        gtins,
        ...(banner && { banner }),
        ...(channel && { channel }),
        model,
        weeks,
        until: '2022-W47',
        dataset,
      });

      if (!data.id) {
        throw data;
      }

      return data;
    }
  );

  const postChat = useMutation(
    async ({ message, model = 'gpt-4', workflowId }: PostChatProps) => {
      const { data } = await post<IdResponse>(`${chatCpgPath}/summary/${workflowId}/chat`, {
        question: message,
        model,
      });

      return data;
    },
    {
      onMutate: async ({ message, workflowId }) => {
        const existingConversation =
          queryClient.getQueryData<Conversation>(['chat-history', workflowId]) ?? [];

        queryClient.setQueryData<Conversation>(['chat-history', workflowId], () => {
          return [
            {
              id: 'new post',
              sender: 'HUMAN',
              status: 'complete',
              text: message,
              timestamp: Date.now(),
              type: 'message',
            },
            ...existingConversation,
          ];
        });
      },
      onSuccess: (workflowId) => {
        queryClient.invalidateQueries(['chat-history', workflowId]);
      },
      onError: (err, { workflowId }) => {
        queryClient.invalidateQueries(['chat-history', workflowId]);
      },
    }
  );

  const postChatFeedback = useMutation(
    async ({ category, explanation, feedback, label, chatMessageId }: ChatFeedbackProps) => {
      const { data } = await post<FeedbackResponse>(
        `${chatCpgPath}/summary/chat/${chatMessageId}/feedback`,
        {
          category,
          explanation,
          feedback,
          label,
        }
      );

      return data;
    }
  );

  const postSummaryFeedback = useMutation(
    async ({
      category,
      explanation,
      feedback,
      talkingPointId,
      label,
      type,
      workflowId,
    }: TextFeedbackProps) => {
      const { data } = await post<FeedbackResponse>(
        `${chatCpgPath}/summary/${workflowId}/feedback`,
        {
          category,
          explanation,
          feedback,
          label,
          talking_point: talkingPointId,
          type,
        }
      );

      return data;
    }
  );

  const postFile = useMutation(
    async ({ file, workflowId }: PostFileProps) => {
      const { data } = await postForm<IdResponse>(
        `${chatCpgPath}/document`,
        {
          doc: file,
        },
        {
          params: {
            summary_id: workflowId,
            mime: file.type,
            name: file.name,
          },
        }
      );

      return data;
    },
    {
      onMutate: async ({ file, workflowId }) => {
        const existingConversation =
          queryClient.getQueryData<Conversation>(['chat-history', workflowId]) ?? [];

        queryClient.setQueryData<Conversation>(['chat-history', workflowId], () => {
          return [
            {
              id: 'new-file',
              mime: file.type,
              name: file.name,
              status: 'uploading',
              summary: null,
              timestamp: Date.now(),
              type: 'file',
            },
            ...existingConversation,
          ];
        });
      },
      onError: (err, { workflowId }) => {
        queryClient.invalidateQueries(['chat-history', workflowId]);
      },
    }
  );

  const chatCPGMutations = useMemo(
    () => ({
      createAnalysis: createAnalysis.mutateAsync,
      postChat: postChat.mutateAsync,
      postChatFeedback: postChatFeedback.mutateAsync,
      postSummaryFeedback: postSummaryFeedback.mutateAsync,
      postFile: postFile.mutateAsync,
    }),
    [
      createAnalysis.mutateAsync,
      postChat.mutateAsync,
      postChatFeedback.mutateAsync,
      postSummaryFeedback.mutateAsync,
      postFile.mutateAsync,
    ]
  );

  return (
    <ChatCpgMutationsContext.Provider value={chatCPGMutations}>
      {children}
    </ChatCpgMutationsContext.Provider>
  );
}
