import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { FeedbackDialogContent, FeedbackDialogContentProps } from './FeedbackDialogContent';

type FeedbackDialogProps = FeedbackDialogContentProps;

export function FeedbackDialog({
  chatMessageId,
  talkingPointId,
  text,
  feedbackType,
  children,
}: PropsWithChildren<FeedbackDialogProps>) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent variant="md">
        <FeedbackDialogContent
          chatMessageId={chatMessageId}
          talkingPointId={talkingPointId}
          text={text}
          feedbackType={feedbackType}
        />
      </DialogContent>
    </DialogProvider>
  );
}
