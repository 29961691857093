import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'ui/src/Core/Flex';
import { Typography } from 'ui/src/Core/Typography';
import { ConfirmDialog } from 'ui/src/Dialogs/ConfirmDialog/ConfirmDialog';
import { AddIcon } from 'ui/src/Icons/AddIcon';
import { DeleteIcon } from 'ui/src/Icons/DeleteIcon';
import { Role, User } from 'ui/src/Providers/UsersProvider/UsersContext';
import { useUsers } from 'ui/src/Providers/UsersProvider/useUsers';
import { ClickableTable } from 'ui/src/Table/ClickableTable';
import { useDateFormatter } from 'ui/src/hooks/useDateFormatter';
import { InviteUserForm } from './InviteUserForm';

export function UsersPageContent() {
  const { users, deleteUser, roles } = useUsers();
  const { t } = useTranslation();
  const format = useDateFormatter();
  const [showForm, setShowForm] = useState(false);

  return (
    <Flex height="100%" width="100%" direction="column" gap="2rem" style={{ overflow: 'scroll' }}>
      <Flex
        padding="1rem"
        direction="column"
        gap="1rem"
        style={{ background: 'var(--clay)', borderRadius: 'var(--corner)' }}
      >
        {!showForm ? (
          <Flex
            as="button"
            align="center"
            gap="0.5rem"
            onClick={() => setShowForm((showForm) => !showForm)}
          >
            <AddIcon color="var(--action)" />
            <Typography variant="p-14-b" color="var(--action)">
              {t('util.inviteUser')}
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography variant="p-14-b">{t('util.inviteUser')}</Typography>
            <InviteUserForm setShowForm={setShowForm} />
          </>
        )}
      </Flex>
      <Flex>
        <ClickableTable
          initActiveCol="username"
          primaryColor="var(--space)"
          data={users.map((user) => ({ lastLogin: user.meta?.last_login, ...user }))}
          columns={[
            {
              title: t('auth.email'),
              field: 'username',
              defaultSortOrder: 'asc',
            },
            {
              title: t('util.name'),
              field: 'fullname',
            },
            {
              title: t('util.user.role'),
              field: 'role_id',
              defaultSortOrder: 'asc',
              cell: (user) => <>{getUserRole(user, roles) ?? t('util.none')}</>,
            },
            {
              title: t('util.user.createdAt'),
              field: 'team_created',
              defaultSortOrder: 'desc',
              cell: (user) => <>{format(user.team_created, 'full')}</>,
            },
            {
              title: t('util.user.lastLogin'),
              field: 'lastLogin',
              defaultSortOrder: 'desc',
              cell: (user) => (
                <>
                  {user.meta?.last_login ? format(user.meta.last_login, 'full') : t('util.never')}
                </>
              ),
            },
            {
              title: t('util.user.authoredTemplates'),
              cell: () => <>{t('util.unknown')}</>,
            },
            {
              title: '',
              field: 'id',
              cell: (user) => (
                <ConfirmDialog
                  title={t('genericPrompt.deleteUser.title')}
                  description={t('genericPrompt.deleteUser.description', {
                    username: user.username,
                  })}
                  confirmButtonText={t('util.delete')}
                  cancelButtonText={t('util.cancel')}
                  onConfirm={() => {
                    deleteUser(user);
                  }}
                >
                  <DeleteIcon as="button" color="var(--danger)" />
                </ConfirmDialog>
              ),
            },
          ]}
        />
      </Flex>
    </Flex>
  );
}

function getUserRole(user: User, roles: Role[]) {
  const userRole = roles.filter((role) => role.id == user.role_id);
  return userRole[0] ? userRole[0].name : undefined;
}
