import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const ThreeDotIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper
      as="button"
      width="20px"
      svgHeight="18px"
      background="transparent"
      transform="rotate(90deg)"
      {...props}
      ref={ref}
    >
      <svg width="20" height="4" viewBox="0 0 20 4" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4ZM12 2C12 3.10457 11.1046 4 10 4C8.89543 4 8 3.10457 8 2C8 0.895431 8.89543 0 10 0C11.1046 0 12 0.895431 12 2ZM20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2C16 0.895431 16.8954 0 18 0C19.1046 0 20 0.895431 20 2Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
