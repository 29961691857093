import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function SendIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M12.1208 14.1307L15.9768 0.70285C16.1062 0.252322 15.6694 -0.147156 15.2663 0.0530104L0.295573 7.48626C-0.0914475 7.67842 -0.100386 8.25694 0.280491 8.46236L3.39335 10.1508C3.74254 10.3391 4.16264 10.3012 4.47656 10.053L8.66711 7.20768C8.92256 7.00573 9.22839 7.37882 9.00295 7.61737L5.73795 10.853C5.51904 11.0846 5.41056 11.4083 5.44267 11.7339L5.83917 15.7561C5.86254 15.9931 6.14263 16.0858 6.2897 15.9052L8.66711 12.9856L11.3951 14.4569C11.6803 14.6107 12.0277 14.4545 12.1208 14.1307Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
