import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PageAddIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_554_11771)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5858 1.58579C15.2107 1.21071 14.702 1 14.1716 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H12V21H5L5 3L13.5 3V6.5C13.5 7.60457 14.3954 8.5 15.5 8.5H19L19 14H21V7.82843C21 7.29799 20.7893 6.78929 20.4142 6.41421L15.5858 1.58579ZM15.5 6.5V4.32843L17.6716 6.5H15.5Z"
            fill="white"
          />
          <path d="M11 17H7V19H11V17Z" fill="white" />
          <path d="M7 13H15V15H7V13Z" fill="white" />
          <path d="M12 9H7V11H12V9Z" fill="white" />
          <path d="M22 21V19H19V16H17V19H14V21H17V24H19V21H22Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_554_11771">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
}
