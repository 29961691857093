import styled from '@emotion/styled';
import { IconProps } from '../Core/IconWrapper';
import { ChatCpgIcon } from '../Icons/ChatCpgIcon';

interface StreamingIconProps extends IconProps {
  isStreaming: boolean;
  iconSize?: 'lg' | 'sm';
}

export const StreamingIcon = styled(ChatCpgIcon)<StreamingIconProps>`
  ${({ isStreaming, iconSize = 'sm' }) =>
    isStreaming && `animation: rotate ${iconSize === 'sm' ? 2 : 2.5}s linear infinite;`}

  border-radius: 6px;

  :focus-visible {
    outline: 1px solid var(--geyser);
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
