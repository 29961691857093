import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { ConfirmDialogContent } from './ConfirmDialogContent';

interface ConfirmDialogProps {
  cancelButtonText?: string;
  confirmButtonText: string;
  onConfirm: () => void;
  description: string;
  title: string;
  width?: string;
}

export function ConfirmDialog({
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  width = '400px',
  children,
}: PropsWithChildren<ConfirmDialogProps>) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent width={width}>
        <ConfirmDialogContent
          title={title}
          description={description}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          onConfirm={onConfirm}
        />
      </DialogContent>
    </DialogProvider>
  );
}
