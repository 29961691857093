import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ContextMenu } from '../../Core/ContextMenu';
import { ContextMenuItem } from '../../Core/ContextMenuItem';
import { TickrTab } from '../../Element/types/elementTypes';
import { BackupIcon } from '../../Icons/BackupIcon';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { PopoverContent } from '../../Popover/PopoverContent';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCurrentTab } from '../../hooks/useCurrentTab';
import { useCurrentTabs } from '../../hooks/useCurrentTabs';

export function TalkingPointPopoverContent() {
  const { t } = useTranslation();
  const { pageId, tabId } = useParams();
  const currentTab = useCurrentTab();
  const navigate = useNavigate();
  const { tabs } = useCurrentTabs();
  const { deleteTab, updateTabs } = useCollectionMutations();
  const { setOpen } = usePopoverContext();

  return (
    <PopoverContent>
      <ContextMenu column padding="0.5rem" width="250px" style={{ zIndex: 1000 }}>
        <ContextMenuItem
          disabled={!currentTab?.settings?.textUpdatedAt || !currentTab.settings.talkingPoint}
          text={t('chatCpg.clearUserEdits')}
          onClick={async () => {
            if (!tabId || !currentTab) return;

            const tabToUpdate: TickrTab = {
              ...currentTab,
              text: '',
              settings: { ...currentTab?.settings, textUpdatedAt: null },
            };

            updateTabs({ tabs: [tabToUpdate], pageId });

            setOpen(false);
          }}
        >
          <BackupIcon />
        </ContextMenuItem>
        <ContextMenuItem
          text={t('chatCpg.deleteTalkingPoint')}
          textColor="var(--danger)"
          onClick={async () => {
            if (!tabId) return;

            const currentRow = currentTab?.row ?? 0;
            const rowToNavigateTo = currentRow === 0 ? 1 : currentRow - 1;
            const idToNavigateTo = tabs.find(({ row }) => row === rowToNavigateTo)?.id;

            const navToNearbyTab = () => {
              if (idToNavigateTo) navigate(`/page/${pageId}/tab/${idToNavigateTo}`);
            };

            await deleteTab({ id: tabId, pageId, navToNearbyTab });
          }}
        >
          <DeleteIcon color="var(--danger)" />
        </ContextMenuItem>
      </ContextMenu>
    </PopoverContent>
  );
}
