import { useQuery } from '@tanstack/react-query';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { ElementDef, TickrElement } from '../Element/types/elementTypes';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useAuth } from '../Providers/AuthProvider/useAuth';

export function useTemplateElements() {
  const { userId } = useAuth();
  const { get } = useApi();

  const { data: templateElements = [] } = useQuery(['template-elements', userId], async () => {
    const { data: elements } = await get<TickrElement[]>('v2/poplar/elements', {
      params: {
        populate: ['author'],
        is_template: true,
        is_tickr: true,
        is_private: false,
      },
    });

    // NOTE: remove the uniqBy when the backend is fixed
    return uniqBy(
      elements.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
      'id'
    );
  });

  const templateElementsMap = useMemo(
    () =>
      templateElements.reduce((acc, element) => {
        acc[element.elementDef.key] = element;
        return acc;
      }, {} as Record<ElementDef['key'], TickrElement>),
    [templateElements]
  );

  return {
    templateElements,
    templateElementsMap,
  };
}
