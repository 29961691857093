import styled from '@emotion/styled';
import { ElementType, MouseEventHandler } from 'react';

export interface IconProps {
  alt?: string;
  as?: ElementType;
  background?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
  height?: string;
  hoverBackground?: string;
  hoverFill?: string;
  onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onMouseUp?: MouseEventHandler<HTMLDivElement>;
  size?: string;
  src?: string;
  svgWidth?: string;
  svgHeight?: string;
  style?: React.CSSProperties;
  transform?: string;
  transition?: string;
  type?: string;
  width?: string;
}

export const IconWrapper = styled.div<IconProps>`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-grow: 0;

  rect {
    fill: ${({ color }) => color};
  }

  line {
    stroke: ${({ color }) => color};
  }

  path {
    fill: ${({ color }) => color};
    transition: ${({ transition }) => transition};
  }

  svg {
    height: ${({ size = '16px', svgHeight }) => svgHeight ?? size};
    width: ${({ size = '16px', svgWidth }) => svgWidth ?? size};
    transform: ${({ transform }) => (transform !== 'none' ? transform : 'none')};
    transition: ${({ transition }) => transition};
  }

  :disabled {
    opacity: 0.5;
    cursor: default;
  }

  :hover {
    ${({ hoverBackground }) => hoverBackground && `background: ${hoverBackground};`}

    path {
      ${({ hoverFill }) => hoverFill && `fill: ${hoverFill};`}
    }
  }

  background: ${({ background }) => background ?? 'unset'};

  ${({ as, width = '24px', height = '24px' }) =>
    as === 'button' &&
    `
  min-width: ${width};
  min-height: ${height};
  border-radius: 8px;
  `}
`;
