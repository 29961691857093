import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { Flex } from '../Core/Flex';
import { RichTextEditor } from '../Core/RichTextEditor';
import { useElement } from '../Element/useElement';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';

export function RichText() {
  const element = useElement();
  const { tabId } = useParams();
  const { updateElement } = useCollectionMutations();

  const initialValue = element.elementDef.payload as string;

  return (
    <StyledFlex column gap="0.5rem" width="100%">
      <Flex width="100%" padding="8px">
        <RichTextEditor
          initialValue={initialValue}
          onSuccess={(richText) => {
            updateElement({
              element: {
                ...element,
                elementDef: {
                  ...element.elementDef,
                  key: 'richText',
                  payload: richText,
                },
              },
              tabId,
            });
          }}
        />
      </Flex>
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)`
  div > div:focus {
    background: var(--space);
    border-radius: 6px;
  }

  .mce-content-body {
    cursor: text;
    border-radius: 6px;
  }

  h1 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 0.04em;
  }

  h2 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 16px;
  }

  h3 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.08em;
  }

  h4,
  h5,
  h6 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-weight: bold;
    font-size: 12px;
  }

  p,
  li {
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 14px;
  }
`;
