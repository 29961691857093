import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PropsWithChildren, forwardRef } from 'react';
import { PickerRightIcon } from '../Icons/PickerRightIcon';
import { Flex } from './Flex';
import { Typography } from './Typography';

interface ContextMenuItemProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  showPicker?: boolean;
  textColor?: string;
  className?: string;
}

export const ContextMenuItem = forwardRef<HTMLDivElement, PropsWithChildren<ContextMenuItemProps>>(
  ({ text, onClick, disabled, showPicker, textColor, children }, ref) => {
    return (
      <StyledGrid
        ref={ref}
        as="button"
        onClick={onClick}
        disabled={disabled}
        templateColumns={children ? '0.15fr 0.7fr 0.15fr' : '0.7fr 0.3fr'}
      >
        {children}
        <Flex align="center" width="100%" justify="space-between">
          <Typography as="h4" color={textColor} nowrap>
            {text}
          </Typography>
        </Flex>
        {showPicker && <PickerRightIcon color={textColor} style={{ justifySelf: 'end' }} />}
      </StyledGrid>
    );
  }
);

const StyledGrid = styled.div<{ disabled?: boolean; templateColumns: string }>`
  display: grid;
  padding: 0.75rem;
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  align-items: center;
  justify-items: start;
  border-radius: 6px;
  width: 100%;
  z-index: 100;
  position: relative;

  ${({ disabled }) =>
    !disabled
      ? css`
          :hover {
            background: var(--woodsmoke-30);
            cursor: pointer;
          }
        `
      : css`
          opacity: 0.3;
          pointer-events: none;
        `};
`;
