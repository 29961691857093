import dayjs from 'dayjs';

export function getUnixFromIsoWeek(isoString: string) {
  const [year, week] = isoString.split('-RW').map(Number);

  if (!year || !week) return 0;

  return (
    dayjs().utc(true).year(year).isoWeek(week).endOf('isoWeek').subtract(1, 'day').unix() * 1000
  );
}
