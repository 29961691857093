import findLast from 'lodash/findLast';
import { Trend, TrendVal } from '../types/libBiTypes';
import { FuzzyOptions, fudgePrice } from './fudgePrice';
import { getEndOfWeekDate } from './getEndOfWeekDate';

interface FormatTrendProps {
  trend?: ([number, number] | TrendVal)[];
  isFuzzy?: boolean;
  fuzzyOptions?: FuzzyOptions;
  daysToAdd?: number;
}

export function formatTrend({ trend = [], isFuzzy, fuzzyOptions, daysToAdd }: FormatTrendProps) {
  return trend.reduce((trend: [number, number][], trendVal) => {
    const period = Array.isArray(trendVal) ? trendVal[0] : trendVal.period;
    const val = Array.isArray(trendVal) ? trendVal[1] : trendVal.val;

    // Remove blank days
    if (val === 0 || val === null) return trend;

    trend.push([
      getEndOfWeekDate(period, daysToAdd),
      isFuzzy ? fudgePrice(val, fuzzyOptions) : val,
    ]);

    return trend;
  }, []);
}

export function getDiff(primaryTrend: Trend = [], secondaryTrend: Trend = []) {
  const primary = primaryTrend[primaryTrend.length - 1].val;
  const secondary = secondaryTrend[secondaryTrend.length - 1]?.val;
  return relativeChange(secondary, primary);
}

export function getLastNonZeroValue(trend: Trend = []) {
  return findLast(trend, ({ val }) => !!val)?.val ?? 0;
}

export interface ChartSeries {
  trend: [number, number][];
  diff?: number;
  legendVal?: number;
}

export const initialChartSeries = {
  trend: [],
  diff: 0,
  legendVal: 0,
};

function relativeChange(previous: number, current: number) {
  return (current - previous) / previous;
}
