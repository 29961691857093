import styled from '@emotion/styled';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

interface ProgressBarProps {
  name: string;
  amount: number;
  min?: number;
  max?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const StyledFill = styled.div<{ width: string; color: string }>`
  width: ${({ width }) => width};
  height: 100%;
  background-color: ${({ color }) => color};
  border-radius: var(--corner);
`;

const StyledBackground = styled(Flex)<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: var(--corner);
`;

export function ProgressBar({
  name,
  amount,
  min = 0,
  max = 100,
  fillColor = 'var(--action)',
  backgroundColor = 'var(--clay)',
}: ProgressBarProps) {
  return (
    <Flex width="100%" direction="column" gap="0.5rem">
      <Flex align="center" justify="space-between" width="100%">
        <Typography variant="p-14-b">{name}</Typography>
        <Typography color={fillColor} variant="n-14-b">
          {amount}
        </Typography>
      </Flex>

      <StyledBackground width="100%" height="4px" color={backgroundColor}>
        <StyledFill width={`${(amount / (max - min)) * 100}%`} color={fillColor} />
      </StyledBackground>
    </Flex>
  );
}
