import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';

export const StyledDiv = styled.div`
  padding: 0.8rem;
  background-color: var(--clay);
`;

export function DialogFooter({ children }: PropsWithChildren) {
  return (
    <StyledDiv>
      <Flex justify="center" gap="1rem">
        {children}
      </Flex>
    </StyledDiv>
  );
}
