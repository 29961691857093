import { createContext } from 'react';

export interface User {
  id: number;
  username: string;
  salt: string;
  access: number;
  active: boolean;
  meta: UserMeta;
  fullname: string;
  timezone?: string;
  confirmed: boolean;
  settings?: unknown;
  role_id: number;
  team_created: string;
  is_editable: boolean;
}

export interface Role {
  id: number;
  name: string;
}

interface UserMeta {
  last_login?: string;
}

export interface InvitedUser {
  roleId: number;
  recipientEmail: string;
}

export interface UsersContextProps {
  users: User[];
  roles: Role[];
  inviteUser(invitedUser: InvitedUser): Promise<void>;
  deleteUser(user: User): Promise<void>;
}

export const UsersContext = createContext<UsersContextProps>({
  users: [],
  roles: [],
  inviteUser: async () => undefined,
  deleteUser: async () => undefined,
});
