import { extent, line, scaleLinear } from 'd3';
import { GraphProvider, NewGraphProps } from '../Graph/GraphProvider';
import { useGraph } from '../Graph/useGraph';

interface SparklineProps extends NewGraphProps {
  sparklines: (InnerSparklineProps & { id: string })[];
  xDomain?: [number, number];
}

export function Sparkline({ containerPadding = '0', sparklines, xDomain }: SparklineProps) {
  return (
    <GraphProvider containerPadding={containerPadding}>
      {sparklines.map(({ id, historicData, forecastData, circleColor, color, strokeWidth }) => (
        <InnerSparkline
          key={id}
          historicData={historicData}
          forecastData={forecastData}
          color={color}
          circleColor={circleColor ?? color}
          strokeWidth={strokeWidth}
          xDomain={xDomain}
        />
      ))}
    </GraphProvider>
  );
}

interface InnerSparklineProps {
  historicData?: [number, number][];
  forecastData?: [number, number][];
  color?: string;
  circleColor?: string;
  innerPadding?: [number, number];
  xDomain?: [number, number];
  strokeWidth?: number;
}

function InnerSparkline({
  historicData = [],
  forecastData = [],
  color,
  circleColor,
  innerPadding = [3, 3],
  xDomain,
  strokeWidth = 2,
}: InnerSparklineProps) {
  const { width, height } = useGraph();

  const combinedData = [...historicData, ...forecastData];

  const [xMin = 0, xMax = 0] = xDomain ?? extent(combinedData, ([x]) => x);
  const [yMin = 0, yMax = 0] = extent(combinedData, ([, y]) => y);

  const xScale = scaleLinear()
    .range([innerPadding[0], width - innerPadding[0]])
    .domain([xMin, xMax]);

  const yScale = scaleLinear()
    .range([height - innerPadding[1], innerPadding[1]])
    .domain([yMin, yMax]);

  const lineGenerator = line()
    .x(([x]) => xScale(x))
    .y(([, y]) => yScale(y));

  const historicPath = lineGenerator(historicData) ?? '';
  const forecastPath = lineGenerator(forecastData) ?? '';
  const lastPoint = combinedData?.[combinedData.length - 1];

  return (
    <g className="sparkline">
      <path fill="none" d={historicPath} stroke={color} strokeWidth={strokeWidth} />;
      <path
        strokeDasharray="6 3"
        fill="none"
        d={forecastPath}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      ;
      {lastPoint && (
        <circle cx={xScale(lastPoint[0])} cy={yScale(lastPoint[1])} r={3} fill={circleColor} />
      )}
    </g>
  );
}
