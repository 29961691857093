import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { AddElementDialogContent } from './AddElementDialogContent';

export function AddElementDialog({ children }: PropsWithChildren) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <AddElementDialogContent />
      </DialogContent>
    </DialogProvider>
  );
}
