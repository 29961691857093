import { Placement } from '@floating-ui/react';
import { forwardRef } from 'react';
import { useTooltipContext } from './useTooltipContext';

const staticSidePlacement: Record<Placement, string> = {
  top: 'bottom',
  'top-start': 'bottom',
  'top-end': 'bottom',
  bottom: 'top',
  'bottom-start': 'top',
  'bottom-end': 'top',
  left: 'right',
  'left-start': 'right',
  'left-end': 'right',
  right: 'left',
  'right-start': 'left',
  'right-end': 'left',
};

const ARROW_HYPOTENUSE = 12;
const ARROW_SIDE = ARROW_HYPOTENUSE / 2;

export const TooltipArrow = forwardRef<HTMLDivElement>((_, ref) => {
  const { middlewareData, placement } = useTooltipContext();
  const staticPosition = staticSidePlacement[placement];
  const activePosition = middlewareData.arrow?.x ? 'left' : 'top';
  const activePositionOffset = middlewareData.arrow?.x ?? middlewareData.arrow?.y;

  return (
    <div
      ref={ref}
      style={{
        backgroundColor: 'var(--info)',
        position: 'absolute',
        [activePosition]: `${activePositionOffset}px` ?? 0,
        [staticPosition]: `-${ARROW_SIDE}px`,
        width: `${ARROW_HYPOTENUSE}px`,
        height: `${ARROW_HYPOTENUSE}px`,
        transform: 'rotate(45deg)',
        zIndex: 101,
        borderRadius: '2px',
      }}
    />
  );
});
