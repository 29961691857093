import styled from '@emotion/styled';
import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute, KeyboardEvent } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { PickerDownIcon } from '../Icons/PickerDownIcon';
import { PickerUpIcon } from '../Icons/PickerUpIcon';
import { FormElementWrapper } from './FormElementWrapper';

interface TextInputProps {
  autoComplete?: string;
  name: string;
  id: string;
  title?: string;
  error?: string;
  value?: string | number;
  description?: string;
  width?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  max?: number;
  min?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
  disabled?: boolean;
  background?: string;
}

export function TextInput({
  autoComplete,
  title,
  name,
  id,
  description,
  width = '100%',
  error,
  value = '',
  required,
  onChange,
  onSubmit,
  onFocus,
  onBlur,
  placeholder,
  type = 'text',
  max,
  min,
  onIncrement,
  onDecrement,
  disabled,
  background = 'var(--black-30)',
}: TextInputProps) {
  return (
    <FormElementWrapper title={title} description={description} error={error} width={width}>
      <StyledInput
        type={type}
        autoComplete={autoComplete}
        className={value ? 'h-12-b' : 'p-12-i'}
        id={id}
        name={name}
        width={width}
        value={value}
        required={required}
        error={error}
        onChange={(e) => {
          onChange?.(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onSubmit?.(e);
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        max={max}
        min={min}
        background={background}
      />
      {type === 'text' && max && (
        <Typography
          color={error ? 'var(--danger)' : 'var(--text-secondary)'}
          style={{ position: 'absolute', right: 12, bottom: -18 }}
        >
          {value?.toString().length}/{max}
        </Typography>
      )}
      {onIncrement && onDecrement && (
        <Flex direction="column" style={{ position: 'absolute', right: 12, bottom: 3 }}>
          <PickerUpIcon
            as="button"
            width="12px"
            height="12px"
            size="12px"
            onClick={onIncrement}
            disabled={value === max || !!error}
          />
          <PickerDownIcon
            as="button"
            width="12px"
            height="12px"
            size="12px"
            onClick={onDecrement}
            disabled={value === min || !!error}
          />
        </Flex>
      )}
    </FormElementWrapper>
  );
}

const StyledInput = styled.input<Partial<TextInputProps>>`
  width: ${({ width }) => width};
  color: var(--text);
  background-color: ${({ background }) => background};
  border-radius: var(--corner);
  height: 30px;
  padding: 0 12px;
  border: 1px solid var(--black-30);

  :focus {
    border: 1px solid var(--action);
    outline: none;
  }

  :disabled {
    opacity: 0.5;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--clay) inset;
    -webkit-text-fill-color: var(--white);
    border: 1px solid var(--trout);
    caret-color: var(--text);
  }

  ::placeholder {
    color: var(--text-secondary);
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
