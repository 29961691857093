import styled from '@emotion/styled';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';

interface FilterSummaryLayoutProps {
  name: string;
  summary: string;
  isValid: boolean;
  truncate?: boolean;
  isButton?: boolean;
  wrap?: boolean;
}

export function FilterSummaryLayout({
  name,
  summary,
  isButton,
  isValid,
  truncate,
  wrap,
}: FilterSummaryLayoutProps) {
  return (
    <StyledFlex
      isButton={isButton}
      direction="column"
      style={{
        textAlign: 'left',
      }}
      {...(truncate && { width: '100%' })}
    >
      <Typography variant="p-12-r" nowrap secondary>
        {name}
      </Typography>
      <Typography
        color={isValid ? 'var(--text)' : 'var(--salmon)'}
        nowrap={!wrap}
        truncate={truncate}
        variant="h-12-b"
        {...(truncate && { width: '100%' })}
      >
        {summary}
      </Typography>
    </StyledFlex>
  );
}

const StyledFlex = styled(Flex)<{ isButton?: boolean }>`
  :hover {
    opacity: ${({ isButton }) => (isButton ? 0.7 : 1)};
  }
`;
