import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function EmlIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="50" height="60" viewBox="0 0 50 60" fill="none">
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15381 25.6356V3.86633H31.2968V14.5696C31.2968 16.2265 32.6399 17.5696 34.2968 17.5696H45V55.284H7.15381V42.6356H32C33.6569 42.6356 35 41.2924 35 39.6356V28.6356C35 26.9787 33.6569 25.6356 32 25.6356H7.15381ZM4.15381 25.6356V3.86633C4.15381 2.20948 5.49696 0.866333 7.15381 0.866333H31.7278C32.7886 0.866333 33.806 1.28776 34.5562 2.03791L34.6978 2.17955L34.711 2.19262L46.6738 14.1554L46.6868 14.1685L46.8284 14.3102C47.5786 15.0603 48 16.0777 48 17.1386V55.284C48 56.9408 46.6569 58.284 45 58.284H7.15381C5.49696 58.284 4.15381 56.9408 4.15381 55.284V42.6356H3C1.34315 42.6356 0 41.2924 0 39.6356V28.6356C0 26.9787 1.34315 25.6356 3 25.6356H4.15381ZM34.2968 14.5696V6.02115L42.8453 14.5696H34.2968Z"
          fill="#C0D0D8"
        />
        <path
          d="M8.96139 37.1432H12.5014V38.7272H7.30539V30.3272H12.4414V31.9112H8.96139V33.6992H12.1414V35.2592H8.96139V37.1432ZM21.8887 30.3272V38.7272H20.2447V33.2792L17.8807 37.1792H17.6887L15.3247 33.2912V38.7272H13.6687V30.3272H15.3727L17.7847 34.2992L20.1847 30.3272H21.8887ZM25.1098 37.1432H28.3138V38.7272H23.4538V30.3272H25.1098V37.1432Z"
          fill="#15161D"
        />
      </svg>
    </IconWrapper>
  );
}
