import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatInput } from '../../ChatCpg/ChatInput';
import { SuggestionChip } from '../../ChatCpg/SuggestionChip';
import { Flex } from '../../Core/Flex';
import { IconProps } from '../../Core/IconWrapper';
import { SearchBar } from '../../Core/SearchBar';
import { Typography } from '../../Core/Typography';
import { ClearIcon } from '../../Icons/ClearIcon';
import { Mp4Icon } from '../../Icons/Mp4Icon';
import { PdfIcon } from '../../Icons/PdfIcon';
import { PickerLeftIcon } from '../../Icons/PickerLeftIcon';
import { PptxIcon } from '../../Icons/PptxIcon';
import { XlsxIcon } from '../../Icons/XlsxIcon';
import { useChatBoxForm } from '../../Providers/ChatBoxProvider/useChatBoxForm';
import { highlightSearchTerm } from '../../helpers/highlightSearchTerm';

interface File {
  icon: (props: IconProps) => JSX.Element;
  subtitle: string;
  title: string;
}

export interface HundredQuestionsDialogContentProps {
  onClose: () => void;
}

export function HundredQuestionsDialogContent({ onClose }: HundredQuestionsDialogContentProps) {
  const { t } = useTranslation();
  const { knowledgeBaseChatInputRef, highlightChat } = useChatBoxForm();

  const [search, setSearch] = useState('');
  const [viewMoreQuestions, setViewMoreQuestions] = useState(false);

  const files: File[] = [
    {
      icon: (props) => <PdfIcon {...props} />,
      subtitle: '.PDF',
      title: t('chatCpg.100.files.substitutionAnalysis', { title: '' }),
    },
    {
      icon: (props) => <PptxIcon {...props} />,
      subtitle: '.PPTX',
      title: t('chatCpg.100.files.pricingAnalysis', { title: '' }),
    },
    {
      icon: (props) => <Mp4Icon {...props} />,
      subtitle: '.MP4',
      title: t('chatCpg.100.files.presentationVideo', { title: '' }),
    },
  ];

  const myFiles: File[] = [
    {
      icon: (props) => <XlsxIcon {...props} />,
      subtitle: '.XLSX',
      title: t('chatCpg.100.files.productAttributesDemo', { title: '' }),
    },
    {
      icon: (props) => <PdfIcon {...props} />,
      subtitle: '.PDF',
      title: t('chatCpg.100.files.pdfDemo', { title: '' }),
    },
    {
      icon: (props) => <XlsxIcon {...props} />,
      subtitle: '.XLSX',
      title: t('chatCpg.100.files.xlsxDemoNielsen', { title: '' }),
    },
    {
      icon: (props) => <XlsxIcon {...props} />,
      subtitle: '.XLSX',
      title: t('chatCpg.100.files.xlsxDemoIRI', { title: '' }),
    },
  ];

  return (
    <GradientWrap>
      {viewMoreQuestions ? (
        <StyledFlex
          height="100%"
          width="100%"
          column
          padding="2rem 1rem 1rem 1rem"
          gap="2rem"
          style={{ background: 'var(--woodsmoke)' }}
        >
          <Flex width="100%" align="space-between">
            <PickerLeftIcon
              color="var(--white)"
              alt="pickerLeft icon"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setViewMoreQuestions(false);
              }}
            />
            <Typography as="h1" variant="h-16-b" width="100%" secondary textAlign="center">
              {t('chatCpg.popularCpgBusinessQuestions')}
            </Typography>
            <ClearIcon
              color="var(--white)"
              style={{ cursor: 'pointer' }}
              alt="pickerLeft icon"
              onClick={() => {
                onClose();
              }}
            />
          </Flex>

          <SearchBar
            autofocus
            inputId="100-questions-search"
            search={search}
            onSearch={setSearch}
            placeholder={t('chatCpg.100.searchPlaceholder')}
            inputWidth="400px"
            poll
          />
          <Flex
            column
            height="100%"
            width="100%"
            gap="2rem"
            style={{ minHeight: 0, overflow: 'auto' }}
          >
            <QuestionSection
              title={t('chatCpg.100.section.marketShare')}
              questions={[
                t('chatCpg.suggestion.demographicMakeup'),
                t('chatCpg.suggestion.marketShare'),
                t('chatCpg.100.customerResponse'),
                t('chatCpg.100.successRate'),
                t('chatCpg.100.newCompetition'),
                t('chatCpg.100.priceChangesOfCompetitors'),
              ]}
              search={search}
            />

            <QuestionSection
              title={t('chatCpg.100.section.distribution')}
              questions={[
                t('chatCpg.100.successRate'),
                t('chatCpg.100.newCompetition'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
              ]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.assortment')}
              questions={[
                t('chatCpg.100.successRate'),
                t('chatCpg.100.newCompetition'),
                t('chatCpg.100.priceChangesOfCompetitors'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
                t('chatCpg.100.complimentaryItems'),
                t('chatCpg.100.productsLeadingLagging'),
                t('chatCpg.100.redundantProducts'),
                t('chatCpg.100.lowSellingDraws'),
                t('chatCpg.100.trialAndRepeat'),
                t('chatCpg.100.householdsHighestTrial'),
                t('chatCpg.100.topProductsSameBasket'),
                t('chatCpg.100.averageBasketSize'),
              ]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.basket')}
              questions={[
                t('chatCpg.100.complimentaryItems'),
                t('chatCpg.100.topProductsSameBasket'),
                t('chatCpg.100.averageBasketSize'),
                t('chatCpg.100.highlyCorrelatedCategories'),
              ]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.promotion')}
              questions={[
                t('chatCpg.100.pricesOverTime'),
                t('chatCpg.100.priceTrendsVsCompetitors'),
                t('chatCpg.100.priceTrendAcrossRetailers'),
                t('chatCpg.100.priceTrendComparedToInflation'),
                t('chatCpg.100.priceChangesOfCompetitors'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
                t('chatCpg.100.complimentaryItems'),
                t('chatCpg.100.productsLeadingLagging'),
              ]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.pricing')}
              questions={[
                t('chatCpg.100.successRate'),
                t('chatCpg.100.pricesOverTime'),
                t('chatCpg.100.priceTrendsVsCompetitors'),
                t('chatCpg.100.priceTrendAcrossRetailers'),
                t('chatCpg.100.priceTrendComparedToInflation'),
                t('chatCpg.100.priceChangesOfCompetitors'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
                t('chatCpg.100.productsLeadingLagging'),
              ]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.supplyChain')}
              questions={[t('chatCpg.100.productsLeadingLagging')]}
              search={search}
            />
            <QuestionSection
              title={t('chatCpg.100.section.shopperBehaviors')}
              questions={[
                t('chatCpg.100.successRate'),
                t('chatCpg.100.productsLeadingLagging'),
                t('chatCpg.100.pricesOverTime'),
                t('chatCpg.100.priceTrendsVsCompetitors'),
                t('chatCpg.100.priceTrendAcrossRetailers'),
                t('chatCpg.100.priceTrendComparedToInflation'),
                t('chatCpg.100.priceChangesOfCompetitors'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
                t('chatCpg.100.complimentaryItems'),
                t('chatCpg.100.redundantProducts'),
                t('chatCpg.100.lowSellingDraws'),
                t('chatCpg.100.trialAndRepeat'),
                t('chatCpg.100.householdsHighestTrial'),
                t('chatCpg.100.topProductsSameBasket'),
                t('chatCpg.100.averageBasketSize'),
                t('chatCpg.100.highlyCorrelatedCategories'),
              ]}
              search={search}
            />

            <QuestionSection
              title={t('chatCpg.100.section.brandHealthAndStrategy')}
              questions={[
                t('chatCpg.100.successRate'),
                t('chatCpg.100.newCompetition'),
                t('chatCpg.100.productsLeadingLagging'),
                t('chatCpg.100.pricesOverTime'),
                t('chatCpg.100.priceTrendsVsCompetitors'),
                t('chatCpg.100.priceTrendAcrossRetailers'),
                t('chatCpg.100.priceTrendComparedToInflation'),
                t('chatCpg.100.priceChangesOfCompetitors'),
                t('chatCpg.100.promotedPricesCompareAcrossRetailers'),
                t('chatCpg.100.channelLevelPricing'),
                t('chatCpg.100.complimentaryItems'),
                t('chatCpg.100.trialAndRepeat'),
                t('chatCpg.100.householdsHighestTrial'),
                t('chatCpg.100.topProductsSameBasket'),
                t('chatCpg.100.averageBasketSize'),
                t('chatCpg.100.highlyCorrelatedCategories'),
              ]}
              search={search}
            />
          </Flex>

          <ChatInput highlight={highlightChat} onSubmit={onClose} ref={knowledgeBaseChatInputRef} />
        </StyledFlex>
      ) : (
        <StyledFlex
          height="100%"
          width="100%"
          column
          padding="2rem 1rem 1rem 1rem"
          gap="2rem"
          style={{ background: 'var(--woodsmoke)' }}
        >
          <Flex width="100%" align="space-between">
            <span />
            <Typography as="h1" variant="h-16-b" width="100%" secondary textAlign="center">
              {t('chatCpg.resourceCenter')}
            </Typography>
            <ClearIcon
              color="var(--white)"
              style={{ cursor: 'pointer' }}
              alt="pickerLeft icon"
              onClick={() => {
                onClose();
              }}
            />
          </Flex>

          <SearchBar
            autofocus
            inputId="100-questions-search"
            search={search}
            onSearch={setSearch}
            placeholder={t('chatCpg.100.searchResourcePlaceholder')}
            inputWidth="400px"
            poll
          />
          <Flex
            column
            height="100%"
            width="100%"
            gap="2rem"
            style={{ minHeight: 0, overflow: 'auto' }}
          >
            <QuestionSection
              title={t('chatCpg.popularCpgBusinessQuestions')}
              questions={[
                t('chatCpg.suggestion.demographicMakeup'),
                t('chatCpg.suggestion.marketShare'),
                t('chatCpg.100.forecastedRevenue'),
              ]}
              search={search}
            />

            <Typography
              as="button"
              color="var(--action)"
              onClick={() => setViewMoreQuestions(true)}
            >
              {t('util.more')}
            </Typography>

            <FileSection
              title={t('chatCpg.100.presentationBestPractices')}
              search={search}
              files={files}
            />

            <Flex column gap="1rem" width="100%">
              <Typography variant="p-14-r" style={{ letterSpacing: '1px' }}>
                {highlightSearchTerm('Data Source Guides', search)}
              </Typography>
              <Flex column gap="0.5rem" width="100%" padding="0 0 0 2px">
                <StyledLinkButton
                  as="button"
                  color="var(--secondary-button)"
                  width="auto"
                  onClick={() => {
                    // 'nothing happens rn'
                  }}
                >
                  {highlightSearchTerm('Numerator', search)}
                </StyledLinkButton>

                <StyledLinkButton
                  as="button"
                  color="var(--secondary-button)"
                  width="auto"
                  onClick={() => {
                    // 'nothing happens rn'
                  }}
                >
                  {highlightSearchTerm('IRI', search)}
                </StyledLinkButton>

                <StyledLinkButton
                  as="button"
                  color="var(--secondary-button)"
                  width="auto"
                  onClick={() => {
                    // 'nothing happens rn'
                  }}
                >
                  {highlightSearchTerm('NeilsenIQ', search)}
                </StyledLinkButton>

                <StyledLinkButton
                  as="button"
                  color="var(--secondary-button)"
                  width="auto"
                  onClick={() => {
                    // 'nothing happens rn'
                  }}
                >
                  {highlightSearchTerm('Ibotta', search)}
                </StyledLinkButton>
              </Flex>
              <FileSection title={t('chatCpg.100.myFiles')} search={search} files={myFiles} />
            </Flex>
          </Flex>

          <ChatInput highlight={highlightChat} onSubmit={onClose} ref={knowledgeBaseChatInputRef} />
        </StyledFlex>
      )}
    </GradientWrap>
  );
}

interface QuestionSectionProps {
  title: string;
  questions: string[];
  search: string;
}

const QuestionSection: FC<QuestionSectionProps> = ({ title, questions, search }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { onClickSuggestionKnowledgeBase } = useChatBoxForm();

  const searchRegex = new RegExp(search, 'i');

  const displayQuestions =
    expanded || search
      ? questions.filter((question) => searchRegex.test(question) || searchRegex.test(title))
      : questions.slice(0, 3);

  if (!displayQuestions.length) return <></>;

  return (
    <Flex column gap="1rem" width="100%">
      <Typography variant="p-14-r" style={{ letterSpacing: '1px' }}>
        {highlightSearchTerm(title, search)}
      </Typography>
      <Flex column gap="0.5rem" width="100%" padding="0 0 0 2px">
        {displayQuestions.map((question) => (
          <SuggestionChip
            key={title + question}
            onClick={onClickSuggestionKnowledgeBase}
            text={question}
          >
            {highlightSearchTerm(question, search)}
          </SuggestionChip>
        ))}
        {!search && questions.length > 3 && (
          <Typography
            as="button"
            color="var(--action)"
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {expanded ? t('util.less') : t('util.more')}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

interface FileSectionProps {
  title: string;
  search: string;
  files: File[];
}

const FileSection: FC<FileSectionProps> = ({ title, search, files }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const searchRegex = new RegExp(search, 'i');

  const displayQuestions =
    expanded || search
      ? files.filter((file) => searchRegex.test(file.title) || searchRegex.test(file.subtitle))
      : files.slice(0, 3);

  if (!displayQuestions.length) return <></>;

  return (
    <Flex column gap="1rem" width="100%">
      <Typography variant="p-14-r" style={{ letterSpacing: '1px' }}>
        {highlightSearchTerm(title, search)}
      </Typography>
      <Flex column gap="0.5rem" width="100%" padding="0 0 0 2px">
        <Flex>
          {files.map(({ icon, subtitle, title }) => (
            <StyledProjectTileBlank
              key={title}
              backgroundColor="transparent"
              icon={icon}
              subtitle={subtitle}
              title={title}
            />
          ))}
        </Flex>
        {!search && files.length > 3 && (
          <Typography
            as="button"
            color="var(--action)"
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {expanded ? t('util.less') : t('util.more')}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

const StyledFlex = styled(Flex)`
  background: var(--woodsmoke);
  border-radius: 8px;
`;

const GradientWrap = styled.div`
  border-radius: 8px;
  width: 75vw;
  height: 90vh;
  padding: 1px;
  background: var(--heat-map-gradient);
`;

const StyledLinkButton = styled.button<{ width: string }>`
  width: ${({ width }) => width};
  height: 26px;

  padding: 0 8px;

  text-align: center;
  justify: center;
  margin-left: 4px;
  border-radius: var(--corner);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 1px solid var(--trout);

  color: var(--white);
  cursor: pointer;
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: bold;
  font-size: 10px;
  transition: opacity 0.3s;

  :focus {
    outline: 1px solid var(--trout);
  }

  :disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

interface ProjectTileBlankProps {
  iconColor?: string;
  icon(props: IconProps): JSX.Element;
  subtitle?: string;
  title: string;
  backgroundColor?: string;
  onClick?(): void;
  disabled?: boolean;
}

const ProjectTileBlank: FC<ProjectTileBlankProps> = ({
  title,
  subtitle,
  icon,
  iconColor,
  backgroundColor,
  onClick,
  disabled,
}) => {
  return (
    <StyledWrapperFlex
      column
      padding="0.5rem"
      gap="0.2rem"
      background={backgroundColor}
      onClick={onClick}
      {...(onClick && { as: 'button', disabled })}
    >
      <Typography as="h4" style={{ maxWidth: '165px' }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="h-12-b" secondary>
          {subtitle}
        </Typography>
      )}
      {icon({
        size: '50px',
        color: iconColor,
        style: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -20%)',
        },
      })}
    </StyledWrapperFlex>
  );
};

const StyledWrapperFlex = styled(Flex)<{ background?: string }>`
  min-width: 190px;
  // max-width: 200px;
  height: 140px;
  margin: 0.5rem;
  background: ${({ background }) => background ?? 'var(--clay)'};
  border-radius: var(--corner);
  border: 1px solid var(--space);

  :hover {
    border: 1px solid var(--white-50);
  }
`;

const StyledProjectTileBlank = styled(ProjectTileBlank)`
  background-color: transparent;
  margin: 5px;
`;
