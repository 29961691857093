import { Dispatch, SetStateAction, createContext } from 'react';
import { AppFilters } from '../filterTypes';

export interface StagedFiltersContextProps {
  stagedFilters: AppFilters;
  invalidFilters: (keyof AppFilters)[];
  setInvalidFilters: Dispatch<SetStateAction<(keyof AppFilters)[]>>;
}

export const StagedFiltersContext = createContext<StagedFiltersContextProps>({
  stagedFilters: {},
  invalidFilters: [],
  setInvalidFilters: () => null,
});
