import { useQuery } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import { TickrPage } from '../Element/types/elementTypes';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useAuth } from '../Providers/AuthProvider/useAuth';

export interface Project {
  createdAt: string;
  deletedAt: string | null;
  description: string | null;
  id: number;
  isActive: boolean;
  isTemplate: boolean;
  isTickr: boolean;
  msId: number;
  title: string;
  updatedAt: string;
  userId: number;
  uuid: string;
}

type ProjectItem = ProjectPageItem;

interface ProjectPageItem {
  createdAt: string;
  deletedAt: string | null;
  id: number;
  meta: Record<string, never>;
  page: TickrPage;
  project_id: number;
  type: 'page';
  updatedAt: string;
  url: string | null;
  userId: number;
}

export type ProjectItemType = 'page' | 'pdf' | 'csv' | 'googledoc';

export interface PopulatedProject extends Project {
  items: ProjectItem[];
  pages: TickrPage[];
}

interface Response {
  currentProject: PopulatedProject | { pages: TickrPage[] };
  isLoading: boolean;
}

export const useCurrentProject = (): Response => {
  const { get } = useApi();
  const { userId } = useAuth();

  const { data: currentProject = { pages: [] }, isLoading } = useQuery(
    ['current-project', userId],
    async () => {
      const { data } = await get<PopulatedProject[]>('v2/poplar/projects', {
        params: {
          populate: ['items'],
        },
      });

      const currentProject = cloneDeep(data?.[0]);

      currentProject.pages =
        currentProject.items
          .sort((a, b) => {
            if (!a.createdAt || !b.createdAt) return 0;
            return a.createdAt < b.createdAt ? -1 : 1;
          })
          .filter(({ type, page }) => type === 'page' && !!page)
          .map(({ page }) => page) ?? [];

      return currentProject;
    }
  );

  return useMemo(() => ({ currentProject, isLoading }), [currentProject, isLoading]);
};
