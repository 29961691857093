import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const ShareIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper ref={ref} {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M3 11L3 19C3 20.1 3.9 21 5 21L19 21C20.1 21 21 20.1 21 19L21 11C21 9.89 20.1 9 19 9L15 9L15 11L19 11L19 19L5 19L5 11L9 11L9 9L5 9C3.9 9 3 9.89 3 11Z"
          fill="white"
        />
        <path
          d="M8.41 6.41L7 5L12 2.18557e-07L17 5L15.59 6.41L13 3.83L13 13.5L11 13.5L11 3.83L8.41 6.41Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
