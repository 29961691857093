import { isEqual } from 'lodash';
import { TickrPage } from '../Element/types/elementTypes';
import { AppFilters } from '../Filters/filterTypes';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';

interface UseSavePageFilterProps {
  page?: TickrPage;
  onSuccess?(): void;
}

export function useSavePageFilter({ page, onSuccess }: UseSavePageFilterProps) {
  const { updatePage } = useCollectionMutations();

  return async (stagedFilters: AppFilters) => {
    if (!page) return;
    if (isEqual(stagedFilters, page.filterDef.activeFilters)) return;

    await updatePage({
      page: {
        ...page,
        filterDef: {
          activeFilters: stagedFilters,
        },
      },
    });

    onSuccess?.();
  };
}
