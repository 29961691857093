import { withSentryReactRouterV6Routing } from '@sentry/react';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import {
  ActivateAccount,
  ActivePAFiltersProvider,
  ApiProvider,
  AvailableFiltersProvider,
  Button,
  ChatCpgMutationsProvider,
  CodeIcon,
  CollectionMutationsProvider,
  Dashboard,
  Flex,
  Home,
  Login,
  MyWork,
  NotificationsProvider,
  OptimizePricingSolution,
  PAFilesProvider,
  PasswordReset,
  PricingAlert,
  SelectSolutions,
  SolutionLayout,
  SubstitutionsSolution,
  Typography,
  UsersProvider,
  Welcome,
  useAuth,
} from 'ui';
import { MyProfileRoutes } from './Settings/MyProfileRoutes';
import { ProductAttributes } from './Settings/ProductAttributes';
import { SettingsDialog } from './Settings/SettingsDialog';
import { UsersRoutes } from './Settings/UsersRoutes';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export function Pages() {
  const { isLoadingToken, token, logout } = useAuth();
  const { t } = useTranslation();
  const [resetKeys, setResetKeys] = useState<string[]>([]);

  if (isLoadingToken) return <></>;

  // When we send users emails we have a habit of sending them
  // directly to the target page but we always overlook auth and
  // lose the page. Fix this by always directing them to login and
  // adding a forward path just in case they are already logged in.
  // Ex:  /login?forward=/page/<pageId> will redirect to forward path.
  // Login page handles post login flow, this handles token auth flow.
  const params = new URLSearchParams(window.location.search);
  let loginNavigateTo = '/home';

  if (window.location.host === 'chatcpg.ai') {
    loginNavigateTo = '/welcome';
  }

  if (params.get('forward')) {
    loginNavigateTo = params.get('forward') as string;
  }

  return (
    <ErrorBoundary
      resetKeys={resetKeys}
      onReset={() => setResetKeys([])}
      fallback={
        <Flex
          width="100%"
          height="100vh"
          align="center"
          justify="center"
          direction="column"
          gap="3rem"
        >
          <CodeIcon color="var(--danger)" />
          <Typography as="h1" color="var(--danger)">
            {t('error.page')}
          </Typography>
          <Button
            color="var(--danger)"
            onClick={() => {
              logout();
              setResetKeys(['reset']);
            }}
          >
            {t('auth.logout')}
          </Button>
        </Flex>
      }
    >
      {token ? (
        <ApiProvider>
          <PAFilesProvider>
            <UsersProvider>
              <AvailableFiltersProvider>
                <ActivePAFiltersProvider filterScope={{}}>
                  <ChatCpgMutationsProvider>
                    <NotificationsProvider>
                      <CollectionMutationsProvider>
                        <SentryRoutes>
                          <Route path="/" element={<Navigate to="/home" />} />
                          <Route path="/login" element={<Navigate to={loginNavigateTo} />} />
                          <Route path="/pricing-alert" element={<PricingAlert />} />
                          <Route path="/page/:pageId" element={<Dashboard />} />
                          <Route path="/page/:pageId/tab/:tabId" element={<Dashboard />} />
                          <Route path="/page/:pageId/*" element={<Dashboard />} />
                          <Route path="/page" element={<Dashboard />} />
                          <Route path="/settings" element={<SettingsRouteElement />} />
                          <Route path="/home" element={<Home />} />
                          <Route path="/smart-scorecard" element={<Dashboard />} />
                          <Route path="/my-work" element={<MyWork />} />
                          {/* <Route path="/project/:id" element={<ProjectLayout />} /> */}
                          <Route path="/solutions" element={<SolutionLayout />}>
                            <Route path="" element={<SelectSolutions />} />
                            <Route path="optimize-pricing" element={<OptimizePricingSolution />} />
                            <Route path="substitutions" element={<SubstitutionsSolution />} />
                          </Route>
                          <Route path="/solutions/*" element={<Navigate to="/solutions" />} />
                          <Route path="/welcome" element={<Welcome />} />
                          <Route path="/*" element={<Navigate to="/home" />} />
                        </SentryRoutes>
                      </CollectionMutationsProvider>
                    </NotificationsProvider>
                  </ChatCpgMutationsProvider>
                </ActivePAFiltersProvider>
              </AvailableFiltersProvider>
            </UsersProvider>
          </PAFilesProvider>
        </ApiProvider>
      ) : (
        <SentryRoutes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/activate-account" element={<ActivateAccount />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </SentryRoutes>
      )}
    </ErrorBoundary>
  );
}

function SettingsRouteElement() {
  const navigate = useNavigate();
  const { isOwner } = useAuth();

  return (
    <SettingsDialog open onClose={() => navigate(-1)}>
      <ProductAttributes />
      {isOwner && <UsersRoutes />}
      <MyProfileRoutes />
    </SettingsDialog>
  );
}
