import * as Highcharts from 'highcharts';
import last from 'lodash/last';
import some from 'lodash/some';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceRevenueVolumeData } from '../ChatCpg/types/optimizePricingTypes';
import { Flex } from '../Core/Flex';
import { LegendSeries } from '../Core/LegendSeries';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { formatTrend } from '../helpers/chartHelpers';
import { getEndOfWeekDate } from '../helpers/getEndOfWeekDate';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { HighchartsContainer } from './HighchartsContainer';

export function PriceOptimizedRevenueTids() {
  const { t } = useTranslation();
  const { workflowId } = useTalkingPointsPage();
  const revenueData = useChatCpgData<[number, number][]>(['price-data', workflowId]);
  const rangeData = useChatCpgData<[number, number, number][]>(['range-data', workflowId]);
  const pvsChartData = useChatCpgData<PriceRevenueVolumeData>(['pvs-chart', workflowId]);
  const recommendedPrice = useChatCpgData<number>(['recommended-price', workflowId]);

  const rangeStartDate = rangeData?.[0]?.[0];
  const indexOfRangeStartDate = revenueData?.findIndex(([x]) => x >= Number(rangeStartDate));

  const chartData = useMemo(() => {
    return revenueData && rangeData && pvsChartData && recommendedPrice
      ? {
          historicRevenue: formatTrend({
            trend: revenueData.slice(0, indexOfRangeStartDate),
            daysToAdd: 6,
          }),
          revenueProjection: formatTrend({
            trend: revenueData.slice(indexOfRangeStartDate),
            daysToAdd: 6,
          }),
          revenueProjectionArea: rangeData.map(([x, y, y0]) => [getEndOfWeekDate(x, 6), y, y0]),
          historicPrice: formatTrend({
            trend: pvsChartData.price,
            daysToAdd: 6,
          }),
          recommendedPrice,
        }
      : {
          historicRevenue: [],
          revenueProjection: [],
          revenueProjectionArea: [],
          historicPrice: [],
          recommendedPrice: 0,
        };
  }, [revenueData, rangeData, pvsChartData, recommendedPrice, indexOfRangeStartDate]);

  const { historicRevenue, revenueProjectionArea, revenueProjection } = chartData;

  const options = useMemo(() => {
    const slicedHistoricRevenue = historicRevenue.slice(0, historicRevenue.length);

    const lastPointInZone1 = last(slicedHistoricRevenue)?.[0] ?? 0;

    const highchartsOptions: Highcharts.Options = {
      tooltip: {
        shared: true,
        formatter: function () {
          const isHistoric = (this.x as number) <= lastPointInZone1;

          const priceLabel = isHistoric ? t('metrics.price') : t('metrics.optimalPrice');

          const revenueLabel = isHistoric
            ? t('metrics.revenueWeekly')
            : t('metrics.forecastedRevenueWeekly');

          return chartData
            ? `
          <tspan style="fontSize: 10px">${Highcharts.dateFormat(
            `${t('util.weekEnding')} %a, %b %e, %Y`,
            this.x as number
          )}</tspan>
          <br>
          <tspan style="color: ${this.points?.[0]?.color}; fill: ${
                this.points?.[0]?.color
              };">●</tspan>
          <tspan style="fontSize: 12px">${priceLabel}: 
          <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(
            this.y as number,
            2
          )}</tspan>
          </tspan>
          <br>
          <tspan style="color: ${this.points?.[1]?.color}; fill: ${
                this.points?.[1]?.color
              };">●</tspan>
          <tspan style="fontSize: 12px">${revenueLabel}: 
          <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(
            this.points?.[1]?.y as number,
            2
          )}</tspan>
          </tspan>
          `
            : `
          <tspan style="fontSize: 10px">${Highcharts.dateFormat(
            `${t('util.weekEnding')} %a, %b %e, %Y`,
            this.x as number
          )}</tspan>
          <br>
          <tspan style="color: ${this.points?.[0]?.color}; fill: ${
                this.points?.[0]?.color
              };">●</tspan>
          <tspan style="fontSize: 12px">${revenueLabel}: 
          <tspan style="fontSize: 12px; fontWeight: bold;">$${Highcharts.numberFormat(
            this.y as number,
            2
          )}</tspan>
          </tspan>
          <br>
          `;
        },
      },
      xAxis: {
        type: 'datetime',
        startOfWeek: 6,
        crosshair: true,
      },
      ...(chartData && {
        yAxis: [
          {
            type: 'linear',
            labels: {
              formatter: function () {
                return `$${Highcharts.numberFormat(this.value as number, 2)}`;
              },
            },
          },
          {
            type: 'linear',
            opposite: true,
            labels: {
              style: {
                color: 'var(--positive-trend)',
              },
              formatter: function () {
                return `$${this.axis.defaultLabelFormatter.call(this)}`;
              },
            },
          },
        ],
      }),
      legend: {
        enabled: false,
      },
      series: [
        {
          name: t('metrics.price'),
          type: 'line',
          color: 'var(--text)',
          dashStyle: 'Solid',
          data: [
            ...(chartData?.historicPrice ?? []),
            ...revenueProjection.map(([x]) => [x, chartData?.recommendedPrice]),
          ],
          zIndex: 1,
          lineWidth: 2,
          zoneAxis: 'x',
          yAxis: 0,
          zones: [
            {
              value: last(chartData?.historicPrice)?.[0],
              dashStyle: 'Solid',
            },
            {
              dashStyle: 'Dash',
            },
          ],
        },
        {
          name: t('metrics.forecastedWeeklyRevenue'),
          type: 'line',
          color: 'var(--positive-trend)',
          dashStyle: 'Solid',
          data: [...slicedHistoricRevenue, ...revenueProjection],
          zIndex: 1,
          lineWidth: 2,
          ...(chartData && { yAxis: 1 }),
          zoneAxis: 'x',
          zones: [
            {
              value: last(slicedHistoricRevenue)?.[0],
              dashStyle: 'Solid',
            },
            {
              dashStyle: 'Dash',
            },
          ],
        },
        {
          name: t('metrics.confidenceInterval'),
          type: 'arearange',
          data: some(slicedHistoricRevenue)
            ? [
                [
                  last(slicedHistoricRevenue)?.[0],
                  last(slicedHistoricRevenue)?.[1],
                  last(slicedHistoricRevenue)?.[1],
                ],
                ...revenueProjectionArea,
              ]
            : revenueProjectionArea,
          linkedTo: ':previous',
          ...(chartData && { yAxis: 1 }),
          color: 'var(--confidence-interval)',
          marker: {
            enabled: false,
          },
          zoneAxis: 'x',
        },
      ],
    };

    return highchartsOptions;
  }, [historicRevenue, revenueProjection, revenueProjectionArea, t, chartData]);

  return (
    <Flex width="100%" height="100%" justify="stretch" align="stretch" direction="column">
      <Flex width="100%" gap="2rem" padding="0 0 1rem 0">
        <Flex column gap="0.2rem">
          <LegendSeries color="var(--text)" label={t('metrics.price')} indicator="line" />
          <LegendSeries
            color="var(--positive-trend)"
            label={t('metrics.revenue')}
            indicator="line"
          />
        </Flex>
        <Flex column gap="0.2rem">
          <LegendSeries color="var(--text)" label={t('metrics.optimalPrice')} indicator="dash" />

          <LegendSeries
            color="var(--positive-trend)"
            label={t('metrics.forecastedRevenue')}
            indicator="dash"
            indicatorBackground="var(--confidence-interval)"
          />
        </Flex>
      </Flex>
      <HighchartsContainer options={options} />
    </Flex>
  );
}
