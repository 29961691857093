import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { MosaicNode } from 'react-mosaic-component';
import { useParams } from 'react-router-dom';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { useDialogContext } from '../../DialogFloating/useDialogContext';
import { CollectionTile } from '../../Element/CollectionTile';
import { CollectionTileGrid } from '../../Element/CollectionTileGrid';
import { useCollectionMutations } from '../../Providers/CollectionMutationsProvider/useCollectionMutations';
import { useCollectionHelpers } from '../../hooks/useCollectionHelpers';
import { useCurrentPage } from '../../hooks/useCurrentPage';
import { useCurrentTab } from '../../hooks/useCurrentTab';
import { useTemplateElements } from '../../hooks/useTemplateElements';
import { useUpdatePageMosaic } from '../../hooks/useUpdatePageMosaic';
import { useUpdateTabMosaic } from '../../hooks/useUpdateTabMosaic';

export function AddElementDialogContent() {
  const { t } = useTranslation();
  const { pageId, tabId } = useParams();
  const currentPage = useCurrentPage();
  const currentTab = useCurrentTab();
  const { createElements } = useCollectionMutations();
  const { formatNewElement } = useCollectionHelpers();
  const { setOpen } = useDialogContext();
  const { templateElements } = useTemplateElements();
  const updateTabMosaic = useUpdateTabMosaic();
  const updatePageMosaic = useUpdatePageMosaic();

  return (
    <>
      <DialogTitle
        title={t('addElement.modal.title')}
        description={t('addElement.modal.description')}
        showX
      />
      <DialogBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <CollectionTileGrid>
          {templateElements?.map((element) => (
            <CollectionTile
              key={element.id}
              item={element}
              baseZindex={1000}
              onClick={async () => {
                try {
                  const elementToCreate = formatNewElement(element, {
                    pageId: pageId ? Number(pageId) : null,
                    tabId: tabId ?? null,
                  });

                  const [newElement] = await createElements({
                    elementsToCreate: [elementToCreate],
                    tabId,
                    pageId,
                  });

                  const existingNode = tabId
                    ? currentTab?.settings?.mosaicNode
                    : currentPage?.settings?.mosaicNode;

                  if (!existingNode) return;

                  const newNode: MosaicNode<string> = {
                    direction: 'row',
                    first: existingNode,
                    second: newElement.id.toString(),
                  };

                  tabId
                    ? await updateTabMosaic({ node: newNode, updateDefaultMosaic: true })
                    : await updatePageMosaic({ node: newNode, updateDefaultMosaic: true });
                } catch (error) {
                  captureException(error);
                }

                setOpen(false);
              }}
            />
          ))}
        </CollectionTileGrid>
      </DialogBody>
    </>
  );
}
