import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'ui/src/Core/Flex';
import { Typography } from 'ui/src/Core/Typography';
import { ChangePasswordForm } from './ChangePasswordForm';

export const MyProfilePageContent: FC = () => {
  const { t } = useTranslation();
  // const [showSettingsForm, setShowSettingsForm] = useState(false);
  // const [showPasswordForm, setShowPasswordForm] = useState(false);

  return (
    <Flex height="100%" width="100%" direction="column" gap="2rem" style={{ overflow: 'scroll' }}>
      {/* <Flex
        padding="1rem"
        direction="column"
        gap="2rem"
        style={{ background: 'var(--clay)', borderRadius: 'var(--corner)' }}
      >
        {!showSettingsForm ? (
          <Flex
            as="button"
            align="center"
            gap="0.5rem"
            onClick={() => setShowSettingsForm((showForm) => !showForm)}
          >
            <Icon iconId="add" color="var(--action)" />
            <Typography variant="p-14-b" color="var(--action)">
              {t('util.myProfileSettings')}
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography variant="p-14-b">{t('util.myProfileSettings')}</Typography>
            <ProfileSettingsForm setShowForm={setShowSettingsForm} />
          </>
        )}
      </Flex> */}
      <Flex
        padding="1rem"
        direction="column"
        gap="2rem"
        style={{ background: 'var(--clay)', borderRadius: 'var(--corner)' }}
      >
        <Typography variant="p-14-b">{t('util.changePassword')}</Typography>
        <ChangePasswordForm />
        {/* {!showPasswordForm ? (
          <Flex
            as="button"
            align="center"
            gap="0.5rem"
            onClick={() => setShowPasswordForm((showForm) => !showForm)}
          >
            <Icon iconId="add" color="var(--action)" />
            <Typography variant="p-14-b" color="var(--action)">
              {t('util.changePassword')}
            </Typography>
          </Flex>
        ) : (
          <>
            <Typography variant="p-14-b">{t('util.changePassword')}</Typography>
            <ChangePasswordForm setShowForm={setShowPasswordForm} />
          </>
        )} */}
      </Flex>
    </Flex>
  );
};
