import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function DragIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
        <path d="M10.5 8V4H7.5L11.5 0L15.5 4H12.5V8H10.5Z" fill="white" />
        <path d="M8.5 10H4.5V7L0.5 11L4.5 15V12H8.5V10Z" fill="white" />
        <path d="M14.5 10H18.5V7L22.5 11L18.5 15V12H14.5V10Z" fill="white" />
        <path d="M10.5 18V14H12.5V18H15.5L11.5 22L7.5 18H10.5Z" fill="white" />
      </svg>
    </IconWrapper>
  );
}
