import { captureException } from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15000,
      onError: (err) => {
        captureException(err);
      },
    },
    mutations: {
      onError: (err) => {
        captureException(err);
      },
    },
  },
});
