import { styled } from '@mui/material/styles';
import { DataGridPro, DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { useLocalization } from '../Providers/LocalizationProvider/useLocalization';
import { Flex } from './Flex';
import { Typography } from './Typography';

const StyledDataGridPro = styled(DataGridPro)<DataGridProProps>(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--clay)',
    borderRadius: 'var(--corner)',
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--woodsmoke)',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
    {
      outline: 'none',
    },
  '&>.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },

    '& div div div div >.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
  },
  border: 'none',
}));

export function DataGridTree(props: DataGridProProps) {
  const t = useLocalization();

  return (
    <StyledDataGridPro
      treeData
      getTreeDataPath={({ path }) => path}
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
      slots={{
        noRowsOverlay: () => (
          <Flex align="center" justify="center" height="100%">
            <Typography variant="p-14-b">{t('salesPerformance.noDataAvailable')}</Typography>
          </Flex>
        ),
      }}
      {...props}
    />
  );
}
