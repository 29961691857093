import { useFloatingNodeId } from '@floating-ui/react';
import { ReactNode } from 'react';
import { DialogContext } from './DialogContext';
import { DialogOptions, useDialog } from './useDialog';

export function DialogProvider({
  children,
  ...options
}: {
  children: ReactNode;
} & DialogOptions) {
  const nodeId = useFloatingNodeId();
  const dialog = useDialog({ ...options, nodeId });
  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
}
