import { MosaicNode } from 'react-mosaic-component';
import { MosaicLayout } from '../MosaicLayout/MosaicLayout';
import { doesNodeContainZero } from '../MosaicLayout/doesNodeContainZero';
import { useCurrentElements } from '../hooks/useCurrentElements';
import { useCurrentPage } from '../hooks/useCurrentPage';
import { useElementMap } from '../hooks/useElementMap';
import { useNoDuplicateNodes } from '../hooks/useNoDuplicateNodes';
import { useUpdatePageMosaic } from '../hooks/useUpdatePageMosaic';

export function StandardPage() {
  const currentPage = useCurrentPage();
  const currentPageElements = useCurrentElements();
  const updatePageMosaic = useUpdatePageMosaic();

  useNoDuplicateNodes({
    defaultNode: 'chatCpg',
    currentNode: currentPage?.settings?.mosaicNode ?? null,
    update: updatePageMosaic,
  });

  const elementMap = useElementMap(currentPageElements);

  if (!currentPage || !currentPageElements) return null;

  return (
    <div style={{ padding: '0 24px 24px 24px', width: '100vw', height: 'calc(100% - 62px)' }}>
      <MosaicLayout
        elementMap={elementMap}
        initialNode={currentPage.settings?.mosaicNode as MosaicNode<string>}
        onRelease={(currentNode) => {
          if (doesNodeContainZero(currentNode)) return;
          updatePageMosaic({ node: currentNode });
        }}
      />
    </div>
  );
}
