import { useTranslation } from 'react-i18next';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DialogBody } from '../../DialogFloating/DialogBody';
import { DialogFooter } from '../../DialogFloating/DialogFooter';
import { DialogTitle } from '../../DialogFloating/DialogTitle';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';

interface ConfirmDialogContentProps {
  cancelButtonText?: string;
  confirmButtonText: string;
  description: string;
  onConfirm: () => void;
  title: string;
}

export function ConfirmDialogContent({
  confirmButtonText,
  cancelButtonText,
  description,
  onConfirm,
  title,
}: ConfirmDialogContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle title={title} size="sm" centerTitle />
      <DialogBody style={{ backgroundColor: 'var(--clay)' }}>
        <Flex direction="column" gap="0.5rem" width="100%" align="center">
          <Typography color="var(--text-secondary)" style={{ textAlign: 'center' }}>
            {description}
          </Typography>
          <Typography color="var(--text-secondary)">{t('delete.youCantUndo')}</Typography>
        </Flex>
      </DialogBody>
      <DialogFooter>
        <DialogTrigger>
          <Button width="150px" color="var(--trout)">
            {cancelButtonText ?? t('util.cancel')}
          </Button>
        </DialogTrigger>
        <DialogTrigger>
          <Button onClick={onConfirm} width="150px" color="var(--danger)">
            {confirmButtonText}
          </Button>
        </DialogTrigger>
      </DialogFooter>
    </>
  );
}
