import { useMutation, useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import { useApi } from '../ApiProvider/useApi';
import { useAuth } from '../AuthProvider/useAuth';
import { InvitedUser, Role, User, UsersContext } from './UsersContext';

export function UsersProvider({ children }: PropsWithChildren) {
  const { msId, isOwner } = useAuth();
  const api = useApi();

  const users = useQuery(
    ['users', msId],
    async () => {
      const res = await api.get<{ users: User[] }>(`/marketspaces/${msId}/users`);
      return res.data.users;
    },
    {
      enabled: !!msId && isOwner,
    }
  );

  const roles = useQuery(
    ['roles', msId],
    async () => {
      const res = await api.get<Role[]>(`/marketspaces/${msId}/roles`);
      return res.data;
    },
    {
      enabled: !!msId && isOwner,
    }
  );

  const inviteUser = useMutation(
    async ({ recipientEmail, roleId }: InvitedUser) => {
      await api.post('/v2/poplar/user/invite', {
        recipientEmail,
        roleId,
        activateUrl: `${window.location.origin}/activate-account?token=[reset_token]&email=[email]`,
      });
    },
    {
      onSuccess: () => {
        users.refetch();
      },
    }
  );

  const deleteUser = useMutation(
    async (user: User) => {
      await api.delete(`/marketspaces/${msId}/users/${user.id}`);
    },
    {
      onSuccess: () => {
        users.refetch();
      },
    }
  );

  const contextValue = useMemo(
    () => ({
      users: users.data ?? [],
      roles: roles.data ?? [],
      inviteUser: inviteUser.mutateAsync,
      deleteUser: deleteUser.mutateAsync,
    }),
    [users.data, roles.data, inviteUser.mutateAsync, deleteUser.mutateAsync]
  );

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
}
