import { createContext } from 'react';
import { usePopover } from './usePopover';

export type PopoverContextProps =
  | (ReturnType<typeof usePopover> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

export const PopoverContext = createContext<PopoverContextProps>(null);
