import { CSSProperties, FC, SyntheticEvent, useState } from 'react';
import { AccountIcon } from '../Icons/AccountIcon';

interface ImageWithFallbackProps {
  src?: string;
  fallbackSrc?: string;
  alt?: string;
  style?: CSSProperties;
  className?: string;
}

export const ImageWithFallback: FC<ImageWithFallbackProps> = ({
  fallbackSrc,
  src,
  alt,
  style,
  className,
}) => {
  const [error, setError] = useState(false);

  const onError = (e: SyntheticEvent<HTMLImageElement>) => {
    setError(true);

    if (fallbackSrc) {
      e.currentTarget.src = fallbackSrc;
    }
  };

  return !error || !!fallbackSrc ? (
    <img src={src} onError={onError} alt={alt} style={style} className={className} />
  ) : (
    <AccountIcon size="40px" />
  );
};
