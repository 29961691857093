import { captureException } from '@sentry/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/src/Core/Button';
import { Flex } from 'ui/src/Core/Flex';
import { ProductAttribute } from 'ui/src/Filters/ActivePAFiltersProvider/ActivePAFiltersProvider';
import { useApi } from 'ui/src/Providers/ApiProvider/useApi';
import { Augmentations } from 'ui/src/Providers/AuthProvider/AuthContext';
import { useAuth } from 'ui/src/Providers/AuthProvider/useAuth';
import { useToaster } from 'ui/src/Providers/ToasterProvider/useToaster';
import { ClickableTable } from 'ui/src/Table/ClickableTable';
import { SettingsPage } from './SettingsPage';

export function ProductAttributes() {
  const api = useApi();
  const queryClient = useQueryClient();
  const { msId } = useAuth();

  const [file, setFile] = useState<File>();
  const { t } = useTranslation();
  const { sendAlert } = useToaster();

  const uploadFile = useMutation(
    () => {
      return new Promise((resolve, reject) => {
        if (!file) return;

        const reader = new FileReader();

        reader.onload = async () => {
          try {
            await api.post('/v2/poplar/upload/product-attributes', {
              csv: reader.result?.toString().replace(/^data:.*?;base64,/, ''),
            });

            /////////////////////////////////////////////////////////////////
            // TODO REMOVE ME POST DEMO I'm afraid to stop adding this to the
            // ms right before the demo. Don't wait for the call, let it go.
            api.post('/sku-augments', {
              filename: file.name,
              type: file.type,
              data: reader.result?.toString().replace(/^data:.*?;base64,/, ''),
            });
            //////////////////////////////////////////////////////////////////
          } catch (e) {
            captureException(e);
            reject(e);
          }

          resolve(null);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['auth:token']);
      },
      onError(e: AxiosError<{ msg: string }>) {
        captureException(e);

        sendAlert({
          text: e.response?.data.msg ?? '',
          severity: 'error',
        });
      },
    }
  );

  const { data: attributeColumns = [] } = useQuery(
    ['attributeColumns', msId],
    async () => {
      const { data } = await api.get<(keyof Augmentations)[]>(`/v2/poplar/product-attributes`, {
        params: {
          columns_only: true,
          size: 9999,
        },
      });

      return data;
    },
    {
      enabled: !!msId,
    }
  );

  const { data: productAttributes = [] } = useQuery(
    ['productAttributes', msId],
    async () => {
      const { data } = await api.get<ProductAttribute[]>(`/v2/poplar/product-attributes`, {
        params: {
          size: 9999,
        },
      });

      return data.map((attribute) => ({ ...attribute, ...attribute.attributes }));
    },
    {
      enabled: !!msId,
    }
  );

  return (
    <SettingsPage
      // icon="mac"
      title={t('productAttributes')}
    >
      <Flex align="center">
        <input type="file" onChange={(e) => e.target?.files?.[0] && setFile(e.target.files[0])} />
        <Button onClick={() => uploadFile.mutateAsync()} disabled={!file}>
          {t('util.uploadCSV')}
        </Button>
      </Flex>
      <ClickableTable
        width="100%"
        primaryColor="var(--space)"
        initActiveCol="gtin"
        data={productAttributes}
        columns={[
          {
            field: 'gtin',
            title: t('productAttributes.gtin'),
            defaultSortOrder: 'asc',
          },
          ...attributeColumns.map((attribute) => ({
            field: attribute as keyof ProductAttribute,
            title: attribute as string,
            cell: (row: ProductAttribute) => <>{row.attributes[attribute]}</>,
          })),
        ]}
      />
    </SettingsPage>
  );
}
