import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { PropsWithChildren, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { PickerUpIcon } from '../../Icons/PickerUpIcon';
import { usePopoverContext } from '../../Popover/usePopoverContext';
import { useFilterSummary } from '../../hooks/useFilterSummary';
import { useActiveFiltersDispatch } from '../ActiveFiltersProvider/useActiveFiltersDispatch';
import { FilterDefinition } from '../AvailableFiltersProvider/AvailableFiltersContext';
import { useStagedFilters } from '../StagedFiltersProvider/useStagedFilters';
import { useStagedFiltersDispatch } from '../StagedFiltersProvider/useStagedFiltersDispatch';
import { AppFilters } from '../filterTypes';
import { InvalidFilterIndicator } from './InvalidFilterIndicator';

interface CollapsibleFilterProps {
  filterDefinition: FilterDefinition;
  initiallyOpen?: boolean;
  isSingleFilter?: boolean;
  isEmpty?: boolean;
  onSave?(filters: AppFilters): Promise<void>;
}

export function CollapsibleFilter({
  filterDefinition,
  initiallyOpen = false,
  isSingleFilter = false,
  isEmpty,
  onSave,
  children,
}: PropsWithChildren<CollapsibleFilterProps>) {
  const { t } = useTranslation();
  const dispatchActiveFilters = useActiveFiltersDispatch();
  const dispatchStagedFilters = useStagedFiltersDispatch();
  const { stagedFilters, invalidFilters } = useStagedFilters();
  const getFilterSummary = useFilterSummary();
  const [isOpen, setIsOpen] = useState(initiallyOpen || isSingleFilter);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { displayName, filterId } = filterDefinition;
  const isValid = !invalidFilters.includes(filterId);
  const { setOpen } = usePopoverContext();

  return (
    <Flex direction="column" width="100%" ref={buttonRef}>
      <CollapsibleButton
        align="center"
        justify="space-between"
        width="100%"
        padding="0.5rem"
        gap="1rem"
        height="35px"
        ref={buttonRef}
        {...(!isSingleFilter && {
          as: 'button',
          onClick: () => {
            setIsOpen((isOpen) => {
              if (!isOpen) {
                setTimeout(() => {
                  buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
                }, 100);
              }

              return !isOpen;
            });
          },
        })}
      >
        <Flex align="center" gap="1rem">
          {!isSingleFilter && (
            <PickerUpIcon
              color="var(--text)"
              size="12px"
              transform={isOpen ? 'rotate(0deg)' : 'rotate(180deg)'}
              transition="transform 0.2s ease-out"
            />
          )}
          <Typography as="h4" variant="c-11" nowrap>
            {displayName ?? filterId}
          </Typography>
        </Flex>
        <Flex gap="0.6rem" align="center" style={{ overflow: 'hidden' }}>
          {isEmpty && <InvalidFilterIndicator />}
          <Typography
            color={stagedFilters[filterId] && isValid ? 'var(--brand-secondary)' : 'var(--salmon)'}
            truncate
          >
            {getFilterSummary({ filterDefinition, filterValue: stagedFilters[filterId] })}
          </Typography>
        </Flex>
      </CollapsibleButton>
      <CollapsibleContent
        isOpen={isOpen}
        scrollHeight={contentRef.current?.scrollHeight}
        direction="column"
        ref={contentRef}
      >
        {children}
        <Flex
          as="button"
          width="100%"
          padding="0.5rem 1rem"
          gap="0.5rem"
          align="center"
          onClick={async () => {
            if (isSingleFilter) {
              setOpen(false);
              await onSave?.(omit(stagedFilters, filterId));

              dispatchActiveFilters({
                type: 'remove',
                payload: filterId,
              });
            } else {
              dispatchStagedFilters({
                type: 'remove',
                payload: filterId,
              });
            }
          }}
        >
          <DeleteIcon color="var(--danger)" size="12px" />
          <Typography variant="c-11" color="var(--danger)">
            {t('filterMenu.removeFilter')}
          </Typography>
        </Flex>
      </CollapsibleContent>
    </Flex>
  );
}

const CollapsibleButton = styled(Flex)`
  background-color: var(--black-30);
  border-radius: var(--corner);
`;

const CollapsibleContent = styled(Flex)<{ isOpen: boolean; scrollHeight?: number }>`
  max-height: ${({ isOpen, scrollHeight }) => (isOpen ? `${scrollHeight}px` : '0')};
  transition: max-height 0.1s ease-out;
  overflow: hidden;
  width: 100%;
`;
