import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Auth, fetchAuth, fetchAuthToken, resetPassword } from 'lib-auth';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApp } from '../AppProvider/useApp';
import { useToaster } from '../ToasterProvider/useToaster';
import { AuthContext, AuthContextProps, LoginProps, ResetProps } from './AuthContext';

export function AuthProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const { apiUrl } = useApp();
  const { sendAlert } = useToaster();
  const token = window.localStorage.getItem('token');

  const [auth, setAuth] = useState<Auth>();
  const queryClient = useQueryClient();

  // This loads auth on refresh if there's a token in local storage
  const tokenQuery = useQuery(
    ['auth-from-token', token],
    async () => {
      const [error, authFromToken] = await fetchAuthToken(token as string, 'tickr', apiUrl);

      if (!error) {
        setAuth(authFromToken);

        return authFromToken;
      } else {
        throw new Error('Bad Auth');
      }
    },
    {
      enabled: !!token,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError: () => {
        logout();

        sendAlert({
          text: t('error.auth.tokenFailed'),
          severity: 'error',
        });
      },
    }
  );

  const login = useMutation(async ({ email, password }: LoginProps) => {
    const [error, auth] = await fetchAuth(email, password, 'tickr', apiUrl);

    if (error || !auth?.token) throw new Error('Bad Auth');

    setAuth(auth);
    window.localStorage.setItem('token', auth.token);
  });

  const resetPasswordMutation = useMutation(
    async ({ email, newPassword, resetToken }: ResetProps) => {
      const [error, auth] = await resetPassword(email, resetToken, newPassword, apiUrl);
      if (!auth || !auth?.user?.token) throw new Error(error?.msg);
      setAuth(auth.user);
      window.localStorage.setItem('token', auth.user.token);
    }
  );

  const logout = useCallback(() => {
    window.localStorage.clear();
    queryClient.clear();
    setAuth(undefined);
  }, [queryClient]);

  // SYNC AUTH ACROSS TABS
  useEffect(() => {
    async function checkToken() {
      const newToken = window.localStorage.getItem('token');

      if (!newToken) {
        setAuth(undefined);
        queryClient.clear();
        window.sessionStorage.clear();
      }

      if (!token && newToken) {
        const [error, auth] = await fetchAuthToken(newToken, 'tickr', apiUrl);

        if (!error) setAuth(auth);
      }
    }

    window.addEventListener('storage', checkToken);

    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, [token, queryClient, apiUrl]);

  const [presentationMode, setPresentationMode] = useState(false);

  const authContextValue = useMemo<AuthContextProps>(
    () => ({
      isFuzzy: !!auth?.settings?.fudge_numerator,
      isOwner: auth?.marketspaces?.[0]?.role_id === 1,
      fullname: auth?.fullname,
      isLoadingToken: tokenQuery.isInitialLoading,
      login: login.mutateAsync,
      logout,
      msAugmentations: auth?.marketspaces?.[0]?.settings.sku_augments?.augmentations,
      msNameMapping: auth?.marketspaces?.[0]?.settings.sku_augments?.name_mapping,
      msId: auth?.marketspaces?.[0]?.id,
      presentationMode,
      resetPassword: resetPasswordMutation.mutateAsync,
      setPresentationMode,
      token: auth?.token,
      userId: auth?.id,
      username: auth?.username,
    }),
    [
      auth?.settings?.fudge_numerator,
      auth?.fullname,
      auth?.username,
      auth?.id,
      auth?.marketspaces,
      auth?.token,
      login,
      logout,
      presentationMode,
      resetPasswordMutation,
      tokenQuery.isInitialLoading,
    ]
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
