import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ArrowBottomRightIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none">
        <path
          d="M7.77123 0.893742C7.77123 0.526484 7.47351 0.228763 7.10625 0.228763C6.73923 0.228763 6.4416 0.526121 6.44127 0.893146L6.43715 5.49435L1.17156 0.228763C0.911215 -0.0315865 0.489105 -0.0315861 0.228756 0.228763C-0.0315938 0.489113 -0.0315936 0.911223 0.228756 1.17157L5.49434 6.43716L0.898449 6.43305C0.528684 6.43272 0.228755 6.73238 0.228755 7.10214C0.228755 7.47167 0.528319 7.77124 0.89785 7.77124L6.77123 7.77124C7.32351 7.77124 7.77123 7.32352 7.77123 6.77124V0.893742Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
