import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function VisibilityOffIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
        <path
          d="M11 4C14.79 4 18.17 6.13 19.82 9.5C19.23 10.72 18.4 11.77 17.41 12.62L18.82 14.03C20.21 12.8 21.31 11.26 22 9.5C20.27 5.11 16 2 11 2C9.73 2 8.51 2.2 7.36 2.57L9.01 4.22C9.66 4.09 10.32 4 11 4ZM9.93 5.14L12 7.21C12.57 7.46 13.03 7.92 13.28 8.49L15.35 10.56C15.43 10.22 15.49 9.86 15.49 9.49C15.5 7.01 13.48 5 11 5C10.63 5 10.28 5.05 9.93 5.14ZM1.01 1.87L3.69 4.55C2.06 5.83 0.77 7.53 0 9.5C1.73 13.89 6 17 11 17C12.52 17 13.98 16.71 15.32 16.18L18.74 19.6L20.15 18.19L2.42 0.449997L1.01 1.87ZM8.51 9.37L11.12 11.98C11.08 11.99 11.04 12 11 12C9.62 12 8.5 10.88 8.5 9.5C8.5 9.45 8.51 9.42 8.51 9.37ZM5.11 5.97L6.86 7.72C6.63 8.27 6.5 8.87 6.5 9.5C6.5 11.98 8.52 14 11 14C11.63 14 12.23 13.87 12.77 13.64L13.75 14.62C12.87 14.86 11.95 15 11 15C7.21 15 3.83 12.87 2.18 9.5C2.88 8.07 3.9 6.89 5.11 5.97Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
