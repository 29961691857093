import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function NewsIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none">
        <path
          d="M16.6934 0.904297C16.5111 0.923828 16.2507 0.959635 15.9121 1.01172C15.5736 1.0638 15.2676 1.19076 14.9941 1.39258C14.7533 1.56836 14.5807 1.87109 14.4766 2.30078C14.3789 2.72396 14.3301 3.34896 14.3301 4.17578V14.0781H12.5918L3.95898 3.36523V9.86914C3.95898 10.6764 4.01107 11.2917 4.11523 11.7148C4.22591 12.1315 4.40169 12.4375 4.64258 12.6328C4.81836 12.776 5.09831 12.903 5.48242 13.0137C5.86654 13.1243 6.17253 13.1992 6.40039 13.2383V14H0.443359V13.2383C0.697266 13.2188 0.986979 13.1732 1.3125 13.1016C1.64453 13.0234 1.92122 12.9062 2.14258 12.75C2.39648 12.5612 2.56901 12.278 2.66016 11.9004C2.75781 11.5163 2.80664 10.875 2.80664 9.97656V3.41406C2.80664 3.08854 2.75456 2.78255 2.65039 2.49609C2.55273 2.20312 2.39648 1.95247 2.18164 1.74414C1.95378 1.52279 1.6901 1.35026 1.39062 1.22656C1.09766 1.09635 0.76237 0.995443 0.384766 0.923828V0.142578H5.59961L13.1777 9.51758V4.27344C13.1777 3.4401 13.1224 2.82161 13.0117 2.41797C12.901 2.00781 12.7253 1.70508 12.4844 1.50977C12.2956 1.36003 12.0156 1.23307 11.6445 1.12891C11.2734 1.01823 10.974 0.943359 10.7461 0.904297V0.142578H16.6934V0.904297Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
