import { useQuery } from '@tanstack/react-query';
import { TickrElement } from '../Element/types/elementTypes';
import { useApi } from '../Providers/ApiProvider/useApi';
import { useAuth } from '../Providers/AuthProvider/useAuth';

interface UseElementsProps {
  pageId?: number | string;
  tabId?: string;
  queryKey: string;
}

export function useElements({ pageId, tabId, queryKey }: UseElementsProps) {
  const { userId } = useAuth();
  const { get } = useApi();

  const { data: pageElements } = useQuery(
    [queryKey, tabId ?? pageId, userId],
    async () => {
      if (!pageId) return;
      const paramString = tabId ? `tab_id=${tabId}` : `page_id=${pageId}`;

      const { data } = await get<TickrElement[]>(`v2/poplar/elements?${paramString}`);
      return data;
    },
    {
      enabled: !!((tabId ?? pageId) && userId),
    }
  );

  return pageElements;
}
