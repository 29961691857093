import some from 'lodash/some';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../Core/Checkbox';
import { Flex } from '../../Core/Flex';
import { SearchBar } from '../../Core/SearchBar';
import { Typography } from '../../Core/Typography';

export interface CheckboxFilterItem {
  value: string;
  title: string;
  subtitle?: string;
  disabled?: boolean;
}

interface CheckboxFilterDisplayProps {
  filterId: string;
  inputBorderColor?: string;
  onChange(value: string, checked: boolean): void;
  onSelectAll(): void;
  onDeselectAll(): void;
  selected?: string;
  style?: React.CSSProperties;
  values: CheckboxFilterItem[];
}

export function CheckboxFilterDisplay({
  filterId,
  inputBorderColor,
  onChange,
  onSelectAll,
  onDeselectAll,
  selected = '',
  style,
  values,
}: CheckboxFilterDisplayProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  return (
    <Flex direction="column" gap="1rem" padding="1rem" width="100%" style={style}>
      <Flex gap="2rem" align="center" width="100%">
        <Flex gap="1rem">
          <Typography
            as="button"
            variant="h-12-b"
            color="var(--action)"
            nowrap
            onClick={onSelectAll}
          >
            {t('button.selectAll')}
          </Typography>
          <Typography
            as="button"
            variant="h-12-b"
            color="var(--action)"
            nowrap
            onClick={onDeselectAll}
          >
            {t('button.deselectAll')}
          </Typography>
        </Flex>

        {values.length > 10 && (
          <SearchBar
            inputId={`${filterId}-search`}
            search={search}
            placeholder="Search"
            onSearch={setSearch}
            inputWidth="100%"
            poll
          />
        )}
      </Flex>

      <Flex
        as="ul"
        column
        width="100%"
        height="100%"
        gap="1rem"
        padding="0 0 0 3px" // for focus ring
        style={{ flex: '1 1 auto', overflowY: 'scroll' }}
      >
        {values
          .filter(({ title }) =>
            some(search) ? title.toLowerCase().includes(search.toLowerCase()) : true
          )
          .map(({ value, title, disabled = false }) => (
            <Checkbox
              key={value}
              inputBorderColor={inputBorderColor}
              checked={selected.split(',').includes(value)}
              title={title}
              onChange={(e) => {
                onChange(value, e.target.checked);
              }}
              disabled={disabled}
              fullyClickable
              className={`${filterId}-${value}`}
            />
          ))}
      </Flex>
    </Flex>
  );
}
