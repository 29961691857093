import { getEndOfWeekDate } from '../helpers/getEndOfWeekDate';
import { useDateFormatter } from './useDateFormatter';

export function useReplaceTimestamp() {
  const dateFormatter = useDateFormatter();

  return (text?: string) =>
    text?.replace(
      /([^\d])\d{13}(?!\d)/gm,
      (unix, capturePrefix) =>
        `${capturePrefix}${dateFormatter(getEndOfWeekDate(Number(unix), 7), 'full')}`
    ) ?? '';
}
