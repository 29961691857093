import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties, PropsWithChildren } from 'react';
import { Card } from 'ui/src/Core/Card';
import { BREAKPOINTS } from 'ui/src/helpers/constants';
import { Modal } from './Modal';

interface DialogProps {
  open: boolean;
  size?: 'xl' | 'lg' | 'md' | 'sm';
  width?: string;
  height?: string;
  level?: number;
  style?: CSSProperties;
}

const StyledCard = styled(Card)<Omit<DialogProps, 'open'>>(({ size, width, height }) => {
  switch (size) {
    case 'sm':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '50%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '30%'};
        }
      `;
    case 'md':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '75%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '50%'};
        }
      `;
    case 'lg':
      return css`
        max-height: 90vh;
        height: ${height};
        @media screen and (min-width: ${BREAKPOINTS.sm}px) {
          width: ${width ?? '100%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.md}px) {
          width: ${width ?? '85%'};
        }
        @media screen and (min-width: ${BREAKPOINTS.lg}px) {
          width: ${width ?? '75%'};
        }
      `;
    case 'xl':
    default:
      return css`
        max-height: 90vh;
        height: ${height};
        width: ${width ?? '100%'};
        max-height: 100%;
      `;
  }
});

export function Dialog({
  open,
  size = 'xl',
  height = '100%',
  width,
  level = 0,
  style,
  children,
}: PropsWithChildren<DialogProps>) {
  return (
    <Modal open={open} level={level} style={style}>
      <StyledCard size={size} height={height} width={width}>
        {children}
      </StyledCard>
    </Modal>
  );
}
