import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SolutionButton } from '../../ChatCpg/SolutionButton';
import { SolutionType } from '../../ChatCpg/types/chatCpgTypes';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { useAuth } from '../../Providers/AuthProvider/useAuth';
import { useCreateCustomAnalysis } from '../../hooks/useCreateCustomAnalysis';
import { SolutionContent } from '../SolutionsComponents/SolutionContent';
import { SolutionFooter } from '../SolutionsComponents/SolutionFooter';
import { SolutionGrid } from '../SolutionsComponents/SolutionGrid';
import { SolutionHeader } from '../SolutionsComponents/SolutionHeader';
import { SolutionPanel } from '../SolutionsComponents/SolutionPanel';

export function SelectSolutions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { msId } = useAuth();
  const createCustomAnalysis = useCreateCustomAnalysis();

  const [selectedSolution, setSelectedSolution] = useState<SolutionType | null>(null);

  return (
    <SolutionGrid>
      <SolutionHeader
        title={t('chatCpg.firstSelect')}
        description={t('chatCpg.firstSelect.description')}
      />
      <SolutionContent>
        <Flex column width="100%" gap="1rem">
          <SolutionSectionTitle>{t('chatCpg.solutionSection.distribution')}</SolutionSectionTitle>
          <Flex as="ul" column padding="0 0 0 1rem" gap="1rem">
            <SolutionLi
              // id="benchmark-competitors"
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.benchmarkCompetitors')}
            </SolutionLi>
            <SolutionLi
              // id="identify-expansion"
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.identifyExpansion')}
            </SolutionLi>
          </Flex>
        </Flex>
        <Flex column width="100%" gap="1rem">
          <SolutionSectionTitle>{t('chatCpg.solutionSection.pricing')}</SolutionSectionTitle>
          <Flex as="ul" column padding="0 0 0 1rem" gap="1rem">
            <SolutionLi
              id="optimizePricing"
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.optimizePricing')}
            </SolutionLi>
            <SolutionLi
              // id="optimize-promotion-timing"
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.optimizePromotionTiming')}
            </SolutionLi>
            <SolutionLi
              // id="optimize-trade-funds"
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.optimizeTradeFunds')}
            </SolutionLi>
            <SolutionLi
              {...(msId === 24202 && { id: 'substitutions' })}
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.optimizeSubstitutions')}
            </SolutionLi>
          </Flex>
        </Flex>
        <Flex column width="100%" gap="1rem">
          <SolutionSectionTitle>{t('chatCpg.solutionSection.createYourOwn')}</SolutionSectionTitle>
          <Flex as="ul" column padding="0 0 0 1rem" gap="1rem">
            <SolutionLi
              id="custom"
              showPlus
              selectedSolution={selectedSolution}
              setSelectedSolution={setSelectedSolution}
            >
              {t('chatCpg.analysis.createNewAnalysis')}
            </SolutionLi>
          </Flex>
        </Flex>
      </SolutionContent>
      <SolutionPanel stage="select-solution" solutionType={selectedSolution} />
      <SolutionFooter
        onBack={() => navigate('/welcome')}
        onNext={() =>
          selectedSolution === 'custom'
            ? createCustomAnalysis()
            : navigate(
                `/solutions/${selectedSolution?.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}`
              )
        }
        nextDisabled={!selectedSolution}
      />
    </SolutionGrid>
  );
}

function SolutionSectionTitle({ children }: PropsWithChildren) {
  return (
    <Typography as="h2" variant="p-22-r" secondary style={{ letterSpacing: '1px' }}>
      {children}
    </Typography>
  );
}

interface SolutionButtonProps {
  id?: SolutionType;
  hoverInfo?: string;
  selectedSolution: SolutionType | null;
  setSelectedSolution: Dispatch<SetStateAction<SolutionType | null>>;
  showPlus?: boolean;
}

function SolutionLi({
  children,
  selectedSolution,
  setSelectedSolution,
  showPlus,
  id,
}: PropsWithChildren<SolutionButtonProps>) {
  return (
    <Flex as="li" gap="1rem" align="center">
      <SolutionButton
        showPlus={showPlus}
        isActive={selectedSolution === id}
        onClick={id ? () => setSelectedSolution(id) : undefined}
      >
        {children}
      </SolutionButton>
    </Flex>
  );
}
