import { Typography } from '../Core/Typography';

interface InputErrorMessageProps {
  errorMessage: string;
}

export function InputErrorMessage({ errorMessage }: InputErrorMessageProps) {
  return (
    <Typography
      color="var(--danger)"
      variant="p-12-r"
      style={{
        visibility: errorMessage ? 'visible' : 'hidden',
        paddingLeft: '8px',
        position: 'absolute',
        bottom: -18,
      }}
    >
      *{errorMessage}
    </Typography>
  );
}
