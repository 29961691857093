import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SolutionType } from '../ChatCpg/types/chatCpgTypes';
import { Button } from '../Core/Button';
import { Checkbox } from '../Core/Checkbox';
import { Flex } from '../Core/Flex';
import { PageSettingsDialog } from '../Dialogs/PageSettingsDialog/PageSettingsDialog';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { GearIcon } from '../Icons/GearIcon';
import { LaunchIcon } from '../Icons/LaunchIcon';
import { Header } from '../PageLayout/Header';
import { TilePageLayout } from '../PageLayout/TilePageLayout';
import { useCollectionMutations } from '../Providers/CollectionMutationsProvider/useCollectionMutations';
import { ClickableTable } from '../Table/ClickableTable';
import { DASHBOARD_X_PADDING } from '../helpers/constants';
import { getFirstTabId } from '../helpers/getFirstTabId';
import { useCurrentProject } from '../hooks/useCurrentProject';
import { useDateFormatter } from '../hooks/useDateFormatter';

export function MyWork() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deletePage } = useCollectionMutations();
  const formatDate = useDateFormatter();
  const { currentProject } = useCurrentProject();

  const solutionTypeMap: Record<SolutionType, string> = useMemo(
    () => ({
      optimizePricing: t('chatCpg.analysis.optimizePricing'),
      substitutions: t('chatCpg.analysis.substitutions'),
      custom: t('chatCpg.analysis.custom'),
    }),
    [t]
  );

  const pages = useMemo(
    () =>
      currentProject.pages
        .filter(({ settings }) => !settings.isScorecard)
        .sort((a, b) => (dayjs(b.updatedAt).isBefore(a.updatedAt) ? -1 : 1))
        .map((page) => ({
          title: page.title,
          id: page.id,
          solutionType:
            page.settings?.solutionType === 'optimizePricing'
              ? solutionTypeMap[page.settings?.solutionType]
              : t('chatCpg.custom'),
          createdAt: page.createdAt,
          updatedAt: page.updatedAt,
          tabs: page.tabs,
          page: page,
        })),
    [currentProject.pages, solutionTypeMap, t]
  );

  const [selectedPages, setSelectedPages] = useState<number[]>([]);

  return (
    <TilePageLayout pageTitle={t('page.myWork')}>
      <Header />
      {pages.length > 0 && (
        <Flex
          column
          padding={`0 ${DASHBOARD_X_PADDING}px ${DASHBOARD_X_PADDING}px ${DASHBOARD_X_PADDING}px`}
          gap="1rem"
          width="100%"
          height="calc(100% - 100px)"
          style={{ overflow: 'hidden' }}
        >
          <Flex style={{ overflow: 'scroll' }}>
            <ClickableTable
              data={pages}
              initActiveCol="updatedAt"
              columns={[
                {
                  disableSort: true,
                  key: 'checkbox',
                  cell: (page) => (
                    <Checkbox
                      onChange={(e) =>
                        setSelectedPages((pageIds) =>
                          e.target.checked
                            ? [...pageIds, page.id]
                            : pageIds.filter((id) => id !== page.id)
                        )
                      }
                      checked={selectedPages.includes(page.id)}
                    />
                  ),
                  headCell: () => (
                    <Checkbox
                      onChange={(e) =>
                        setSelectedPages(e.target.checked ? pages.map(({ id }) => id) : [])
                      }
                      checked={selectedPages.length === pages.length}
                    />
                  ),
                },
                {
                  title: t('util.page.title'),
                  field: 'title',
                },
                {
                  title: t('chatCpg.solution'),
                  field: 'solutionType',
                },
                {
                  title: t('util.created'),
                  field: 'createdAt',
                  cell: ({ createdAt }) => (
                    <>{createdAt ? formatDate(createdAt, 'full-date-time') : t('util.unknown')}</>
                  ),
                },
                {
                  title: t('util.updated'),
                  field: 'updatedAt',
                  cell: ({ createdAt, updatedAt }) => (
                    <>
                      {updatedAt !== undefined && createdAt !== undefined
                        ? formatDate(updatedAt ?? createdAt, 'full-date-time')
                        : t('util.unknown')}
                    </>
                  ),
                },
                {
                  key: 'actions',
                  cell: ({ tabs, id, page }) => (
                    <Flex align="center" gap="0.5rem">
                      <PageSettingsDialog page={page}>
                        <GearIcon
                          as="button"
                          disabled={selectedPages.length > 0}
                          color="var(--text-secondary)"
                        />
                      </PageSettingsDialog>
                      <LaunchIcon
                        as="button"
                        disabled={selectedPages.length > 0}
                        color="var(--action)"
                        onClick={() => {
                          navigate(tabs ? `/page/${id}/tab/${getFirstTabId(tabs)}` : `/page/${id}`);
                        }}
                        size="14px"
                      />
                      <DeleteIcon
                        as="button"
                        disabled={selectedPages.length > 0}
                        color="var(--danger)"
                        onClick={() => {
                          deletePage({
                            id: id,
                          });
                        }}
                      />
                    </Flex>
                  ),
                },
              ]}
              style={{
                overflow: 'scroll',
                minHeight: 0,
              }}
            />
          </Flex>
          {selectedPages.length > 0 && (
            <Flex grow="1">
              <Button
                color="var(--danger)"
                onClick={() => {
                  selectedPages.map((id) => {
                    deletePage({
                      id,
                    });
                  });

                  setSelectedPages([]);
                }}
                disabled={selectedPages.length === 0}
              >
                {t('util.deleteSelected')}
              </Button>
            </Flex>
          )}
        </Flex>
      )}
    </TilePageLayout>
  );
}
