import styled from '@emotion/styled';
import { FC } from 'react';
import { Flex } from '../Core/Flex';
import { Toggle } from '../Core/Toggle';
import { Typography } from '../Core/Typography';

interface SidePanelToggleProps {
  title: string;
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const SidePanelToggle: FC<SidePanelToggleProps> = ({ title, checked, onClick }) => {
  return (
    <Flex width="100%" padding="0.7rem 0.2rem" height="38px" align="center" justify="space-between">
      <StyledTypography
        as="button"
        variant="p-14-b"
        title={title}
        width="100%"
        onClick={onClick}
        tabIndex={-1}
      >
        {title}
      </StyledTypography>
      <Toggle onSwitch={onClick} checked={checked} />
    </Flex>
  );
};

const StyledTypography = styled(Typography)`
  text-align: start;

  :hover {
    opacity: 0.8;
  }
`;
