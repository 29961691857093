import { captureException } from '@sentry/react';

// RENAME to fileToDataB64Url or combine these two?
export function fileToData(file: File, onSuccess: (file: File, result?: string) => Promise<void>) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        await onSuccess(file, reader.result?.toString().replace(/^data:.*?;base64,/, ''));
        resolve(null);
      } catch (e) {
        captureException(e);
        reject(e);
      }
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function fileToDataArray(
  file: File,
  onSuccess: (file: File, result?: ArrayBuffer) => Promise<void>
) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        await onSuccess(file, reader.result as ArrayBuffer);
        resolve(null);
      } catch (e) {
        captureException(e);
        reject(e);
      }
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}
