import { PropsWithChildren } from 'react';
import { TickrPage } from '../../Element/types/elementTypes';
import { TalkingPointsPageProvider } from './TalkingPointsPageProvider';

interface TalkingPointsPageProviderWrapperProps {
  page?: TickrPage;
}

export function TalkingPointsPageProviderWrapper({
  page,
  children,
}: PropsWithChildren<TalkingPointsPageProviderWrapperProps>) {
  if (!page) return null;

  if (page.settings?.layoutType === 'talkingPoints') {
    return <TalkingPointsPageProvider page={page}>{children}</TalkingPointsPageProvider>;
  }

  return <>{children}</>;
}
