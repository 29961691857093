import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { SidePanelItem } from './SidePanelItem';
import { SidePanelLi } from './SidePanelLi';

interface SidePanelLinkProps {
  to: string;
  disabled?: boolean;
  title: string;
  onClick?: () => void;
}

export function SidePanelLink({ to, onClick, ...props }: PropsWithChildren<SidePanelLinkProps>) {
  return (
    <SidePanelLi>
      <Link
        to={to}
        style={{ width: '100%', borderRadius: 'var(--corner)', display: 'block' }}
        onClick={onClick}
      >
        <SidePanelItem as="div" {...props} />
      </Link>
    </SidePanelLi>
  );
}
