import { ExpandIcon } from '../../Icons/ExpandIcon';
import { useMosaic } from '../useMosaic';
import { useMosaicWindow } from '../useMosaicWindow';
import { toolbarButtonSharedProps } from './toolbarButtonSharedProps';

export function ExpandButton() {
  const { mosaicActions } = useMosaic();
  const { mosaicWindowActions } = useMosaicWindow();

  return (
    <ExpandIcon
      as="button"
      {...toolbarButtonSharedProps}
      onClick={() => {
        mosaicActions.expand(mosaicWindowActions.getPath());
      }}
    />
  );
}
