import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { ReportDialogContent } from './ReportDialogContent';

interface ReportDialogProps {
  report?(): JSX.Element;
}

export function ReportDialog({ report, children }: PropsWithChildren<ReportDialogProps>) {
  return report ? (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <ReportDialogContent report={report} />
      </DialogContent>
    </DialogProvider>
  ) : (
    <>{children}</>
  );
}
