import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { useApi } from '../../Providers/ApiProvider/useApi';
import { useAuth } from '../../Providers/AuthProvider/useAuth';
import { PAFilesContext, ProductAttributesFile } from './PAFilesContext';

export function PAFilesProvider({ children }: PropsWithChildren) {
  const { msId } = useAuth();
  const { get } = useApi();

  const { data: productAttributesFiles = [] } = useQuery<ProductAttributesFile[]>(
    ['product-attributes-files', msId],
    async () => {
      // const response = await get(`/product_attributes/${msId}`);
      return [
        {
          id: 1,
          displayName: 'File 1',
          fileName: 'file1.csv',
          fileSize: 123456,
          rowCount: 123,
          hiddenColumns: ['column1', 'column2'],
        },
        {
          id: 2,
          displayName: 'File 2',
          fileName: 'file2.csv',
          fileSize: 456789,
          rowCount: 420,
          hiddenColumns: ['column1', 'column2'],
        },
      ];
    }
  );

  return (
    <PAFilesContext.Provider value={productAttributesFiles}>{children}</PAFilesContext.Provider>
  );
}
