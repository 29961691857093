import { ChangeEvent, Dispatch, FormEvent, RefObject, SetStateAction, createContext } from 'react';

export interface ChatBoxFormContextProps {
  handleChange: (e: ChangeEvent) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  highlightChat: boolean;
  historyRef: RefObject<HTMLDivElement> | null;
  inputRef: RefObject<HTMLTextAreaElement> | null;
  knowledgeBaseChatInputRef: RefObject<HTMLTextAreaElement> | null;
  onClickSuggestion: (message: string) => void;
  onClickSuggestionKnowledgeBase: (message: string) => void;
  setMessage: (message: string) => void;
  setViewConversation: Dispatch<SetStateAction<boolean>>;
  values: { message: string };
  viewConversation: boolean;
}

export const ChatBoxFormContext = createContext<ChatBoxFormContextProps>({
  handleChange: () => null,
  handleSubmit: () => null,
  highlightChat: false,
  historyRef: null,
  inputRef: null,
  knowledgeBaseChatInputRef: null,
  onClickSuggestion: () => null,
  onClickSuggestionKnowledgeBase: () => null,
  setMessage: () => null,
  setViewConversation: () => null,
  values: { message: '' },
  viewConversation: false,
});
