import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { MyProfilePageContent } from './MyProfilePageContent';
import { SettingsPage } from './SettingsPage';

export function MyProfileRoutes() {
  const { t } = useTranslation();
  return (
    <SettingsPage
      // icon="account"
      title={t('preferences.userProfile')}
    >
      <Routes>
        <Route path="/" element={<MyProfilePageContent />} />
      </Routes>
    </SettingsPage>
  );
}
