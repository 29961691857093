import styled from '@emotion/styled';
import { FC } from 'react';
import { Flex } from '../Core/Flex';
import { PostContent } from './PostContent';
import { PostDetails } from './PostDetails';
import { PostHeader } from './PostHeader';

export interface BasePostProps {
  clusterId: string;
  author?: string;
  authorHandle?: string;
  authorImage?: string;
  clusterSize?: number;
  currentStory?: number;
  date?: string | number | Date;
  hashtags?: JSX.Element[];
  headline?: string;
  imageSrc?: string;
  impact?: number;
  linkText?: string;
  metrics?: [string, number][];
  postSrc?: string | false;
  report?(): JSX.Element;
  text?: string;
  topic?: string;
  videoSrc?: string;
}

const PostContainer = styled(Flex)`
  border: 1px solid var(--background);
  background: var(--clay-gradient);
  border-radius: var(--corner);
  overflow-y: auto;
`;

export const BasePost: FC<BasePostProps> = ({
  clusterId,
  author,
  authorHandle,
  authorImage,
  clusterSize,
  currentStory,
  date,
  imageSrc,
  impact,
  hashtags,
  headline,
  linkText,
  metrics,
  postSrc,
  report,
  text,
  topic,
  videoSrc,
}) => {
  return (
    <PostContainer direction="column" gap="1rem" width="100%" padding="0.6rem">
      {/* header */}
      <PostHeader
        clusterId={clusterId}
        clusterSize={clusterSize}
        currentStory={currentStory}
        impact={impact}
        report={report}
      />
      {/* author */}
      <PostDetails
        author={author}
        authorHandle={authorHandle}
        authorImage={authorImage}
        date={date}
        topic={topic}
      />
      {/* content */}
      <PostContent
        headline={headline}
        hashtags={hashtags}
        imageSrc={imageSrc}
        linkText={linkText}
        metrics={metrics}
        postSrc={postSrc}
        text={text}
        videoSrc={videoSrc}
      />
    </PostContainer>
  );
};
