import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { InputErrorMessage } from './InputErrorMessage';

interface FormElementWrapperProps {
  title?: string;
  description?: string;
  error?: string;
  width?: string;
}

export function FormElementWrapper({
  title,
  description,
  error,
  width = '100%',
  children,
}: PropsWithChildren<FormElementWrapperProps>) {
  return (
    // NOTE: gap needs to be zero here, to prevent UI jumping from children
    <Flex direction="column" width={width} gap="0">
      <Flex gap="0.4rem" direction="column" width="100%" padding="0 0 0.8rem 0">
        {title && (
          <Typography variant="h-12-b" style={{ paddingLeft: '8px' }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            color="var(--text-secondary)"
            style={{ paddingLeft: '8px', lineHeight: '15px' }}
          >
            {description}
          </Typography>
        )}
      </Flex>
      {children}
      {error && <InputErrorMessage errorMessage={error} />}
    </Flex>
  );
}
