import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightCard } from '../Cards/InsightCard';
import { IntroCard } from '../Cards/IntroCard';
import { RichText } from '../Cards/RichText';
import { SalesPerformanceInsightsWeb } from '../Cards/SalesPerformanceInsightsWeb';
import { CategoryVsCpi } from '../Charts/CategoryVsCpi';
import { CompetitorsPriceVsMyVolume } from '../Charts/CompetitorsPriceVsMyVolume';
import { PriceComparison } from '../Charts/PriceComparison';
import { PriceOptimizedRevenueTids } from '../Charts/PriceOptimizedRevenueTids';
import { PriceRevenueVolume } from '../Charts/PriceRevenueVolume';
import { ProjectedRevenue } from '../Charts/ProjectedRevenue';
import { ErrorBox } from '../Core/ErrorBox';
import { Posts } from '../Posts/Posts';
import { ComplementsTable } from '../Tables/ComplementsTable';
import { InfluentialCompetitors } from '../Tables/InfluentialCompetitors';
import { SalesPerformanceWeb } from '../Tables/SalesPerformance/SalesPerformanceWeb';
import { SubstitutesTable } from '../Tables/SubstitutesTable';
import { useElement } from './useElement';

export const ElementSwitch: FC = () => {
  const { t } = useTranslation();
  const element = useElement();

  switch (element.elementDef.key) {
    case 'categoryVsCpi':
      return <CategoryVsCpi />;
    case 'competitorsPriceVsMyVolume':
      return <CompetitorsPriceVsMyVolume />;
    case 'complementsTable':
      return <ComplementsTable />;
    // case 'economicAndCompetitiveLandscape':
    //   return <EconomicLandscapeV2 />;
    case 'influentialCompetitors':
      return <InfluentialCompetitors />;
    case 'insightCard':
      return <InsightCard {...element.elementDef.payload} />;
    case 'introCard':
      return <IntroCard {...element.elementDef.payload} />;
    // case 'mentions':
    //   return <Mentions />;
    case 'posts':
      return <Posts query={element.elementDef.payload} />;
    case 'priceComparison':
      return <PriceComparison />;
    // case 'priceOptimizedRevenue':
    //   return <PriceOptimizedRevenue />;
    case 'priceOptimizedRevenueTids':
      return <PriceOptimizedRevenueTids />;
    case 'priceRevenueVolume':
      return <PriceRevenueVolume />;
    // case 'pricingRecommendationsV2':
    //   return <PricingRecommendationsV2 />;
    case 'projectedRevenue':
      return <ProjectedRevenue {...element.elementDef.payload} />;
    case 'richText':
      if (!element.elementDef.payload) return <></>;
      return <RichText />;
    case 'salesPerformance':
      return <SalesPerformanceWeb />;
    case 'salesPerformanceInsights':
      return <SalesPerformanceInsightsWeb />;
    // case 'sentimentDonut':
    //   return <SentimentDonut />;
    case 'substitutesTable':
      return <SubstitutesTable />;
    // case 'topInfluencers':
    //   return <TopInfluencers />;
    // case 'trendingPhrases':
    //   return <TrendingPhrases />;
    // case 'webEmbed':
    //   return <WebEmbed value={element.elementDef.payload} />;
    default:
      return (
        <ErrorBox
          text={t('error.elementNotFound', {
            key: (element.elementDef as unknown & { key: string })?.key,
          })}
        />
      );
  }
};
