import { PropsWithChildren } from 'react';
import { Flex, FlexProps } from './Flex';

export function ContextMenu({ children, ...props }: PropsWithChildren<FlexProps>) {
  return (
    <Flex
      {...props}
      style={{
        zIndex: 50,
        borderRadius: 'var(--corner)',
        boxShadow: 'var(--shadow-400)',
        maxHeight: 'calc(100vh - 80px)',
        overflowY: 'hidden',
      }}
    >
      {children}
    </Flex>
  );
}
