import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useChatBoxForm } from '../Providers/ChatBoxProvider/useChatBoxForm';
import { useChatCpgMutations } from '../Providers/ChatCpgMutationsProvider/useChatCpgMutations';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useToaster } from '../Providers/ToasterProvider/useToaster';
import { ChatHistory } from './ChatHistory';
import { ChatInput } from './ChatInput';

export function ChatElement() {
  const { t } = useTranslation();
  const { setFileStreamId } = useChatBox();
  const { postFile } = useChatCpgMutations();
  const { sendAlert } = useToaster();
  const { inputRef } = useChatBoxForm();
  const { workflowId } = useTalkingPointsPage();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/octet-stream': ['.eml'],
      'application/pdf': ['.pdf'],
      'text/*': ['.html', '.txt'],
    },
    maxFiles: 1,
    maxSize: 20000000,
    onDropAccepted: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      const { id } = await postFile({ file, workflowId });
      setFileStreamId(id);
    },
    onDropRejected(fileRejections) {
      const error = fileRejections[0]?.errors?.[0]?.code;
      let errorMessage: string;

      switch (error) {
        case 'file-too-large':
          errorMessage = t('chatCpg.fileUpload.fileTooLarge');
          break;
        case 'file-invalid-type':
          errorMessage = t('chatCpg.fileUpload.invalidFileType');
          break;
        default:
          errorMessage = t('chatCpg.fileUpload.unknownError');
          break;
      }

      sendAlert({
        severity: 'error',
        text: errorMessage,
      });
    },
  });

  const [dragCounter, setDragCounter] = useState(0);

  return (
    <Flex
      direction="column"
      justify="flex-end"
      height="100%"
      width="100%"
      padding="0.6rem"
      gap="1rem"
      onDragEnter={() => setDragCounter((prev) => prev + 1)}
      onDragLeave={() => setDragCounter((prev) => prev - 1)}
      onDrop={() => setDragCounter(0)}
      style={{
        maxHeight: '100%',
        minHeight: 0,
        backgroundColor: 'var(--woodsmoke)',
        borderRadius: '0 0 8px 8px',
        overflow: 'hidden',
      }}
    >
      <Flex
        column
        align="center"
        justify="center"
        gap="1rem"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'var(--black-90)',
          width: '100%',
          height: '100%',
          zIndex: dragCounter === 0 ? -1 : 1,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography textAlign="center" variant="h-30-b">
          {t('chatCpg.upload')}
        </Typography>
        <Typography
          multiline
          textAlign="center"
          variant="p-16-b"
          secondary
          style={{ maxWidth: '300px' }}
        >
          {t('chatCpg.upload.description')}
        </Typography>
      </Flex>
      <ChatHistory />
      <Flex direction="column" gap="0.4rem" width="100%" style={{ maxWidth: '100%' }}>
        <ChatInput ref={inputRef} />
      </Flex>
    </Flex>
  );
}
