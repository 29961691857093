import { PropsWithChildren } from 'react';
import { DialogContent } from '../../DialogFloating/DialogContent';
import { DialogProvider } from '../../DialogFloating/DialogProvider';
import { DialogTrigger } from '../../DialogFloating/DialogTrigger';
import { DeletePageDialogContent } from './DeletePageDialogContent';

interface DeletePageDialogProps {
  pageId: number;
  onDelete?(): void;
  onClose?(): void;
}

export function DeletePageDialog({
  pageId,
  onClose,
  onDelete,
  children,
}: PropsWithChildren<DeletePageDialogProps>) {
  return (
    <DialogProvider>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent width="350px" onClickOverlay={onClose}>
        <DeletePageDialogContent pageId={pageId} onDelete={onDelete} onClose={onClose} />
      </DialogContent>
    </DialogProvider>
  );
}
