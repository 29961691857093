import { ChangeEvent } from 'react';
import { Checkbox } from '../Core/Checkbox';
import { Flex } from '../Core/Flex';
import { FormElementWrapper } from './FormElementWrapper';

export interface CheckboxItem {
  title: string;
  value: string;
  disabled?: boolean;
}

interface FormCheckboxesProps {
  title: string;
  values: CheckboxItem[];
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  selected?: string[];
  placeholder?: string;
  description?: string;
  error?: string;
  disabled?: boolean;
}

export function FormCheckboxes({
  title,
  description,
  error,
  values,
  onChange,
  selected,
}: FormCheckboxesProps) {
  return (
    <FormElementWrapper title={title} description={description} error={error}>
      <Flex direction="column" width="100%" gap="1rem" padding="0 0 0 8px">
        {values.map(({ title, value }) => (
          <Checkbox
            key={value}
            title={title}
            value={value}
            onChange={onChange}
            symbol="checkmark"
            checked={selected?.includes(value)}
          />
        ))}
      </Flex>
    </FormElementWrapper>
  );
}
