import { CSSProperties, FC, PropsWithChildren } from 'react';
import { Typography } from '../Core/Typography';

interface TableCellProps {
  title?: string;
  textAlign?: CSSProperties['textAlign'];
  style?: CSSProperties;
}

export const TableCell: FC<PropsWithChildren<TableCellProps>> = ({
  title,
  textAlign = 'unset',
  style,
  children,
}) => {
  return (
    <Typography as="td" title={title} style={{ ...style, textAlign }}>
      {children}
    </Typography>
  );
};
