import styled from '@emotion/styled';
import { CSSProperties, ElementType, PropsWithChildren, ReactNode, forwardRef } from 'react';
import { multilineCSS } from '../helpers/multilineCSS';

/* STYLE VARIANTS */
type Headline =
  | 'h-36-b'
  | 'h-30-b'
  | 'h-26-b'
  | 'h-22-b'
  | 'h-18-b'
  | 'h-16-b'
  | 'h-14-b'
  | 'h-12-b';

type Caption = 'c-30' | 'c-14' | 'c-11' | 'c-10';

export type Paragraph =
  | 'p-22-b'
  | 'p-22-r'
  | 'p-18-r'
  | 'p-16-b'
  | 'p-16-r'
  | 'p-14-b'
  | 'p-14-r'
  | 'p-12-r'
  | 'p-12-i';

type Button = 'button-12' | 'button-10';

export type NumberType =
  | 'n-64-b'
  | 'n-42-b'
  | 'n-32-b'
  | 'n-24-b'
  | 'n-20-b'
  | 'n-16-b'
  | 'n-14-b'
  | 'n-14-r'
  | 'n-12-b'
  | 'n-12-r';

type Table = 't-13-b' | 't-13-r' | 't-cell';

export type Variant = Headline | Caption | Paragraph | Button | NumberType | Table;

/* STYLE MODIFICATIONS */
// NOTE: when using truncate, you need to set the width on the Typography element
const truncateCSS: CSSProperties = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const underlineCSS: CSSProperties = {
  textDecoration: 'underline',
};

const nowrapCSS: CSSProperties = {
  whiteSpace: 'nowrap',
};

const lineClampCSS = (lineClamp: number): CSSProperties => ({
  display: '-webkit-box',
  WebkitLineClamp: lineClamp,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

export interface TypographyProps {
  variant?: Variant;
  as?: ElementType;
  title?: string;
  color?: string;
  underline?: boolean;
  nowrap?: boolean;
  truncate?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  lineClamp?: number;
  multiline?: boolean;
  width?: string;
  onClick?: () => void;
  style?: CSSProperties;
  tabIndex?: number;
  textAlign?: CSSProperties['textAlign'];
  className?: string;
  children?: ReactNode;
}

const defaultVariants = {
  p: 'p-12-r',
  li: 'p-12-r',
  h1: 'h-22-b',
  h2: 'h-16-b',
  h3: 'h-14-b',
  h4: 'h-12-b',
  h5: 'h-12-b',
  h6: 'h-12-b',
  th: 't-13-r',
  td: 't-cell',
};

type DefaultVariants = keyof typeof defaultVariants;

export const Typography = forwardRef<HTMLDivElement, PropsWithChildren<TypographyProps>>(
  (
    {
      variant,
      as = 'p',
      title,
      color = 'var(--text)',
      underline,
      nowrap,
      truncate,
      lineClamp,
      multiline,
      width,
      onClick,
      secondary,
      tabIndex,
      tertiary,
      textAlign,
      style,
      className,
      children,
    },
    ref
  ) => {
    const styles: CSSProperties = {
      color,
      ...(underline && underlineCSS),
      ...(nowrap && nowrapCSS),
      ...(truncate && truncateCSS),
      ...(lineClamp && lineClampCSS(lineClamp)),
      ...(multiline && { lineHeight: multilineCSS[(variant as Paragraph) ?? 'p-12-r'] }),
      ...(width && { width }),
      ...(secondary && { color: 'var(--text-secondary)' }),
      ...(tertiary && { color: 'var(--text-tertiary)' }),
      ...(textAlign && { textAlign }),
      ...style,
    };

    const variantClass = variant ?? defaultVariants[as as DefaultVariants];

    const classes = [className, variantClass].filter((c) => c);

    return (
      <HtmlElement
        as={as}
        className={classes.join(' ')}
        style={styles}
        title={title}
        onClick={onClick}
        tabIndex={tabIndex}
        ref={ref}
      >
        {children}
      </HtmlElement>
    );
  }
);

const HtmlElement = styled.div``;
