import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function KnowledgeRepoIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M2.68799 5.93335L8.64453 7.5294" stroke="white" strokeLinecap="round" />
        <path d="M2.68799 9.63336L8.64453 11.2294" stroke="white" strokeLinecap="round" />
        <path d="M2.68799 13.3333L8.64453 14.9294" stroke="white" strokeLinecap="round" />
        <path d="M18.5166 5.93335L12.5463 7.4772" stroke="white" strokeLinecap="round" />
        <path d="M18.5166 9.63336L12.5463 11.1772" stroke="white" strokeLinecap="round" />
        <path d="M18.5166 13.3333L12.5463 14.8772" stroke="white" strokeLinecap="round" />
        <path
          d="M10.5 19.5L19.9879 17.128C20.2105 17.0724 20.3666 16.8724 20.3666 16.6429V1.64039C20.3666 1.3151 20.0603 1.07754 19.7428 1.14838C18.6598 1.39007 18.4749 1.34001 10.5 3.46667M10.5 19.5L1.01203 17.128C0.78945 17.0724 0.633301 16.8724 0.633301 16.6429V1.64039C0.633301 1.3151 0.942208 1.07691 1.2572 1.15809C3.59431 1.76034 0.750977 1.17279 10.5 3.46667M10.5 19.5V3.46667"
          stroke="white"
        />
      </svg>
    </IconWrapper>
  );
}
