import { useTranslation } from 'react-i18next';
import { Button } from '../../Core/Button';
import { Flex } from '../../Core/Flex';

interface SolutionFooterProps {
  nextButtonText?: string;
  nextDisabled?: boolean;
  onBack: () => void;
  onNext: () => void;
}

export function SolutionFooter({
  nextButtonText,
  nextDisabled,
  onBack,
  onNext,
}: SolutionFooterProps) {
  const { t } = useTranslation();

  return (
    <Flex
      width="100%"
      gap="0.5rem"
      padding="1rem 0 1rem 6.5%"
      style={{ gridRow: '3', gridColumn: '1', paddingLeft: '6.5%' }}
    >
      <Button width="200px" color="var(--trout)" onClick={onBack}>
        {t('util.back')}
      </Button>
      <Button width="200px" disabled={nextDisabled} onClick={onNext}>
        {nextButtonText || t('util.next')}
      </Button>
    </Flex>
  );
}
