import { createContext } from 'react';
import { CheckboxFilterItem } from '../Components/CheckboxFilterDisplay';

export type ProductAttributeFilters = Record<string, string>;

export interface ActivePAFiltersContextProps {
  activePAFilters: ProductAttributeFilters;
  myProductsValues: CheckboxFilterItem[];
  competitorsValues: CheckboxFilterItem[];
}

export const ActivePAFiltersContext = createContext<ActivePAFiltersContextProps>({
  activePAFilters: {},
  myProductsValues: [],
  competitorsValues: [],
});
