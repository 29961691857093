import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function PptIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="50" height="60" viewBox="0 0 50 60" fill="none">
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15381 25.454V3.68481H31.2968V14.3881C31.2968 16.045 32.6399 17.3881 34.2968 17.3881H45V55.1024H7.15381V42.454H32C33.6569 42.454 35 41.1109 35 39.454V28.454C35 26.7972 33.6569 25.454 32 25.454H7.15381ZM4.15381 25.454V3.68481C4.15381 2.02796 5.49696 0.684814 7.15381 0.684814H31.7278C32.7886 0.684814 33.806 1.10624 34.5562 1.85639L34.6978 1.99803L34.711 2.0111L46.6738 13.9739L46.6868 13.987L46.8284 14.1286C47.5786 14.8788 48 15.8962 48 16.9571V55.1024C48 56.7593 46.6569 58.1024 45 58.1024H7.15381C5.49696 58.1024 4.15381 56.7593 4.15381 55.1024V42.454H3C1.34315 42.454 0 41.1109 0 39.454V28.454C0 26.7972 1.34315 25.454 3 25.454H4.15381ZM34.2968 14.3881V5.83963L42.8453 14.3881H34.2968Z"
          fill="#FF584B"
        />
        <path
          d="M10.6698 30.054C12.2898 30.054 13.5378 31.302 13.5378 32.874C13.5378 34.446 12.2898 35.694 10.6698 35.694H9.19381V38.454H7.53781V30.054H10.6698ZM10.6698 34.146C11.3778 34.146 11.8938 33.594 11.8938 32.874C11.8938 32.142 11.3778 31.602 10.6698 31.602H9.19381V34.146H10.6698ZM17.7479 30.054C19.3679 30.054 20.6159 31.302 20.6159 32.874C20.6159 34.446 19.3679 35.694 17.7479 35.694H16.2719V38.454H14.6159V30.054H17.7479ZM17.7479 34.146C18.4559 34.146 18.9719 33.594 18.9719 32.874C18.9719 32.142 18.4559 31.602 17.7479 31.602H16.2719V34.146H17.7479ZM27.0977 30.054V31.638H24.8297V38.454H23.1737V31.638H20.9177V30.054H27.0977Z"
          fill="#15161D"
        />
      </svg>
    </IconWrapper>
  );
}
