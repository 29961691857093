import { forwardRef } from 'react';
import { IconProps, IconWrapper } from '../Core/IconWrapper';

export const AutoArrangeIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  return (
    <IconWrapper {...props} ref={ref}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path
          d="M0.500002 8.14478H5.67815C5.95429 8.14478 6.17815 8.36864 6.17815 8.64478L6.17816 13.7422C6.17817 14.0183 5.95431 14.2422 5.67816 14.2422H0.500014C0.223872 14.2422 1.46304e-05 14.0183 1.39426e-05 13.7422L0 8.64478C0 8.36864 0.223859 8.14478 0.500002 8.14478Z"
          fill="white"
        />
        <path
          d="M5.67817 6.24219H0.500014C0.223872 6.24219 1.46214e-05 6.01833 1.39223e-05 5.74219L0 0.742189C0 0.466046 0.223859 0.242188 0.500002 0.242188H5.67817C5.95431 0.242188 6.17817 0.466045 6.17817 0.742187V5.74219C6.17817 6.01833 5.95431 6.24219 5.67817 6.24219Z"
          fill="white"
        />
        <path
          d="M13.6471 14.2422H8.65508C8.37894 14.2422 8.15508 14.0183 8.15508 13.7422V8.64478C8.15508 8.36864 8.37894 8.14478 8.65508 8.14478H13.6471C13.9232 8.14478 14.147 8.36864 14.147 8.64478V13.7422C14.147 14.0183 13.9232 14.2422 13.6471 14.2422Z"
          fill="white"
        />
        <path
          d="M8.00002 5.74219V0.742188C8.00002 0.466045 8.22387 0.242188 8.50002 0.242188H13.647C13.9232 0.242188 14.147 0.466045 14.147 0.742187V5.74219C14.147 6.01833 13.9232 6.24219 13.647 6.24219H8.50002C8.22387 6.24219 8.00002 6.01833 8.00002 5.74219Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
});
