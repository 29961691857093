import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { AddIcon } from '../Icons/AddIcon';

interface InnerSolutionContentProps {
  isActive?: boolean;
  showPlus?: boolean;
}

export const InnerSolutionContent: FC<PropsWithChildren<InnerSolutionContentProps>> = ({
  isActive,
  showPlus,
  children,
}) => {
  return (
    <StyledInnerFlex
      isActive={isActive}
      width="100%"
      height="100%"
      align="center"
      padding="0 1rem"
      gap="0.6rem"
    >
      {showPlus && <AddIcon size="13px" color="var(--action)" />}
      <Typography nowrap variant="button-12" width="100%">
        {children}
      </Typography>
    </StyledInnerFlex>
  );
};

const StyledInnerFlex = styled(Flex)<{ isActive?: boolean }>`
  border-radius: var(--corner);
  background: ${({ isActive }) => (isActive ? 'var(--lavender)' : 'var(--woodsmoke)')};
  transition: background 0.3s;
  height: 28px;
`;
