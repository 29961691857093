import styled from '@emotion/styled';
import { useState } from 'react';
import { SearchIcon } from '../Icons/SearchIcon';
import { Flex } from './Flex';

const SearchInput = styled.input<{ width?: string }>`
  background-color: var(--clay);
  border-radius: var(--corner);
  color: var(--text);
  font-size: 14px;
  height: 100%;
  padding: 0.45rem 0.45rem 0.45rem 30px;
  width: ${({ width }) => width ?? '250px'};
  border: 1px solid var(--white-10);
  :focus {
    border: 1px solid var(--white-50);
    outline: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-text-fill-color: var(--white);
    -webkit-box-shadow: 0 0 0 20px var(--clay) inset;
    border: 1px solid var(--trout);
    caret-color: var(--text);
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  border-top-left-radius: var(--corner);
  border-bottom-left-radius: var(--corner);
`;

interface SearchBarProps {
  autofocus?: boolean;
  inputId: string;
  onSearch(search: string): void;
  search?: string;
  placeholder?: string;
  poll?: boolean;
  inputWidth?: string;
}

export function SearchBar({
  autofocus,
  inputId,
  onSearch,
  search = '',
  placeholder,
  poll,
  inputWidth,
}: SearchBarProps) {
  const [text, setText] = useState(search);

  return (
    <Flex gap="0.5rem" data-testid="search-bar" align="center" width={inputWidth ?? '100%'}>
      <StyledSearchIcon
        as="button"
        onClick={() => {
          !poll && onSearch?.(text);
          poll && inputId && document.getElementById(inputId)?.focus();
        }}
        background="transparent"
        color="var(--text)"
        size="12px"
        style={{ cursor: poll ? 'text' : 'pointer' }}
      />
      <SearchInput
        autoFocus={autofocus}
        id={inputId}
        value={search}
        placeholder={placeholder}
        onChange={(e) => {
          setText(e.target.value);
          poll && onSearch(e.target.value);
        }}
        onKeyDown={(e) => e.key === 'Enter' && onSearch?.(text)}
        width={inputWidth}
      />
    </Flex>
  );
}
