import { createContext } from 'react';

export interface AppContextProps {
  apiUrl?: string;
  chatCpgUrl?: string;
  dsUrl?: string;
  isTemplateEditor?: boolean;
  envMode?: 'prod' | 'qa';
  tinyMceApiKey?: string;
}

export const AppContext = createContext<AppContextProps>({});
