import { createContext } from 'react';
import { TickrElement, TickrPage, TickrTab } from '../../Element/types/elementTypes';
import { FilterScope } from '../../Filters/filterTypes';
import { Project, ProjectItemType } from '../../hooks/useCurrentProject';

export type ElementToCreate = Partial<TickrElement> & {
  filterDef: FilterScope;
};

export interface DeletePageProps {
  id: number;
}

export interface DeleteElementProps {
  id: number;
  pageId?: string;
  tabId?: string;
}

export interface DeleteElementsProps {
  ids: number[];
  pageId?: string;
  tabId?: string;
}

export interface CreateProjectProps {
  description?: string;
  isActive?: boolean;
  isTemplate?: boolean;
  isTickr?: boolean;
  title: string;
}

export interface DeleteProjectProps {
  id: number;
}

export interface AddProjectItemProps {
  pageId: number;
  projectId: number;
  url?: string;
  type: ProjectItemType;
}

export interface RemoveProjectItemProps {
  itemId: number;
  projectId: number;
}

export interface UpdatePageProps {
  page: TickrPage;
}

export interface CreateElementsProps {
  elementsToCreate: ElementToCreate[];
  pageId?: string;
  tabId?: string;
}

export interface DuplicateElementProps {
  element: TickrElement;
  pageId?: string;
  tabId?: string;
}

export type UpdateElementProps = DuplicateElementProps;

export interface UpdateElementsProps {
  elements: TickrElement[];
  pageId?: string;
  tabId?: string;
}

export interface CreateTabProps {
  tab: Partial<TickrTab>;
  pageId: string;
}

export interface UpdateTabsProps {
  tabs: TickrTab[];
  pageId?: string;
}

export interface DeleteTabProps {
  id: string;
  pageId?: string;
  navToNearbyTab?: () => void;
}

export interface CollectionMutationsContextProps {
  createProject(props: CreateProjectProps): Promise<Project>;
  updateProject(project: Project): Promise<Project>;
  deleteProject(props: DeleteProjectProps): Promise<null | void>;
  addProjectItem(props: AddProjectItemProps): Promise<null | void>;
  removeProjectItem(props: RemoveProjectItemProps): Promise<null | void>;

  createPage(page: Partial<TickrPage>): Promise<TickrPage>;
  updatePage(page: UpdatePageProps): Promise<TickrPage>;
  deletePage(props: DeletePageProps): Promise<null | void>;
  duplicatePage(page: TickrPage): Promise<TickrPage>;

  createTab(props: CreateTabProps): Promise<{ tabs: TickrTab[]; pageId: string }>;
  updateTabs(props: UpdateTabsProps): Promise<TickrTab[]>;
  deleteTab(props: DeleteTabProps): Promise<string>;

  createElements(props: CreateElementsProps): Promise<TickrElement[]>;
  duplicateElement(props: DuplicateElementProps): Promise<TickrElement[]>;
  updateElement(props: UpdateElementProps): Promise<TickrElement>;
  updateElements(props: UpdateElementsProps): Promise<TickrElement[]>;
  deleteElement(props: DeleteElementProps): Promise<null | void>;
  deleteElements(props: DeleteElementsProps): Promise<null | void>;
}

export const CollectionMutationsContext = createContext<CollectionMutationsContextProps>({
  createProject: async () => ({} as Project),
  updateProject: async () => ({} as Project),
  deleteProject: async () => null,
  addProjectItem: async () => null,
  removeProjectItem: async () => null,

  createPage: async () => ({} as TickrPage),
  updatePage: async () => ({} as TickrPage),
  deletePage: async () => null,
  duplicatePage: async () => ({} as TickrPage),

  createTab: async () => ({ tabs: [], pageId: '' }),
  updateTabs: async () => ({} as TickrTab[]),
  deleteTab: async () => '',

  createElements: async () => [],
  duplicateElement: async () => [],
  updateElement: async () => ({} as TickrElement),
  updateElements: async () => [],
  deleteElement: async () => null,
  deleteElements: async () => null,
});
