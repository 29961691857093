import { IconProps, IconWrapper } from '../Core/IconWrapper';

export function ExpandIcon(props: IconProps) {
  return (
    <IconWrapper {...props}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path
          d="M0.5 9.24219H1.5C1.77614 9.24219 2 9.46605 2 9.74219V10.4996V10.6107L5.14763 7.8128C5.3455 7.63691 5.64617 7.64575 5.83337 7.83295L6.62504 8.62462C6.82846 8.82804 6.81869 9.16075 6.60367 9.35188L3.35207 12.2422H4.5C4.77614 12.2422 5 12.466 5 12.7422V13.7422C5 14.0183 4.77614 14.2422 4.5 14.2422H0.5C0.223858 14.2422 0 14.0183 0 13.7422V9.74219C0 9.46605 0.223858 9.24219 0.5 9.24219Z"
          fill="white"
        />
        <path
          d="M8.66923 6.91142L7.87791 6.1201C7.67339 5.91558 7.68454 5.58072 7.90221 5.39025L11.3586 2.3659C11.4498 2.28615 11.5668 2.24219 11.6879 2.24219H10.5C10.2239 2.24219 10 2.01833 10 1.74219V0.742188C10 0.466045 10.2239 0.242188 10.5 0.242188H14.5C14.7761 0.242188 15 0.466045 15 0.742188V4.74219C15 5.01833 14.7761 5.24219 14.5 5.24219H13.5C13.2239 5.24219 13 5.01833 13 4.74219V3.5153C13 3.65948 12.9378 3.79665 12.8293 3.89159L9.35204 6.93415C9.15394 7.10749 8.85536 7.09755 8.66923 6.91142Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
}
