import { ForwardedRef, MutableRefObject, RefObject, useEffect } from 'react';

export function useHandleOutsideClick({
  ref,
  ignoreEls,
  onClose,
}: {
  ref: MutableRefObject<HTMLElement> | RefObject<HTMLElement> | ForwardedRef<HTMLElement> | null;
  ignoreEls?: RefObject<HTMLElement>[];
  onClose?: () => void;
}): void {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      event.stopPropagation();

      if (
        ignoreEls &&
        ignoreEls.filter(
          (ignoreEl) => ignoreEl?.current && ignoreEl?.current.contains(event.target as Node)
        ).length
      )
        return;

      if (
        ref &&
        typeof ref !== 'function' &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        if (onClose) onClose();
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, ignoreEls, onClose]);
}
