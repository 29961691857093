import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useDateTimeLabelFormats() {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      day: `${t('util.weekEnding')} %a, %b %e, %Y`,
      week: `${t('util.weekEnding')} %a, %b %e, %Y`,
      hour: `${t('util.weekEnding')} %a, %b %e, %Y`,
      minute: `${t('util.weekEnding')} %a, %b %e, %Y`,
      second: `${t('util.weekEnding')} %a, %b %e, %Y`,
      millisecond: `${t('util.weekEnding')} %a, %b %e, %Y`,
    }),
    [t]
  );
}
