import { create } from 'zustand';
import { OptimizePricingStoreValues } from '../ChatCpg/types/optimizePricingTypes';
import { SubstitutionsStoreValues } from '../ChatCpg/types/substitutionsTypes';

export interface StreamingText {
  text: string;
  isStreaming: boolean;
}

type StoreValues =
  | StreamingText
  | ClearStreamTextSignature
  | UpdateStoreSignature
  | UpdateStreamTextSignature
  | OptimizePricingStoreValues
  | SubstitutionsStoreValues
  | string;

type UpdateStreamTextSignature = (
  key: string | string[],
  value: StreamingText,
  format?: (value?: string) => string
) => void;
type UpdateStoreSignature = (key: string | string[], value: StoreValues) => void;
type ClearStreamTextSignature = (workflowId: string) => void;

interface ChatCpgStore {
  [key: string]: StoreValues;
  clearStreamText: (partialKey: string) => void;
  updateStreamText: UpdateStreamTextSignature;
  updateStore: UpdateStoreSignature;
}

export const useChatCpgStore = create<ChatCpgStore>((set) => ({
  clearStreamText: (partialKey) =>
    set((state) => {
      const newState = { ...state };

      if (!partialKey) {
        return state;
      }

      Object.keys(newState).forEach((key) => {
        if (key.includes(partialKey)) {
          newState[key] = {
            text: '',
            isStreaming: false,
          };
        }
      });

      return newState;
    }),

  updateStreamText: (key, value, format = (val?: string) => val ?? '') =>
    set((state) => {
      const existingText = (state[JSON.stringify(key)] as StreamingText)?.text ?? '';

      const formattedText =
        existingText.slice(0, existingText.length - 16) +
        format(existingText.slice(existingText.length - 16) + value.text);

      return {
        ...state,
        [JSON.stringify(key)]: {
          isStreaming: value.isStreaming,
          text: formattedText,
        },
      };
    }),

  updateStore: (key, value) =>
    set((state) => ({
      ...state,
      [JSON.stringify(key)]: value,
    })),
}));
