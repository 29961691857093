import styled from '@emotion/styled';
import { AuthorAvatar } from '../Core/AuthorAvatar';
import { Flex } from '../Core/Flex';
import { Typography } from '../Core/Typography';
import { CloudUploadIcon } from '../Icons/CloudUploadIcon';
import { DocIcon } from '../Icons/DocIcon';
import { DocxIcon } from '../Icons/DocxIcon';
import { EmlIcon } from '../Icons/EmlIcon';
import { HtmlIcon } from '../Icons/HtmlIcon';
import { PdfIcon } from '../Icons/PdfIcon';
import { PptIcon } from '../Icons/PptIcon';
import { PptxIcon } from '../Icons/PptxIcon';
import { TxtIcon } from '../Icons/TxtIcon';
import { useAuth } from '../Providers/AuthProvider/useAuth';
import { useDateFormatter } from '../hooks/useDateFormatter';

export interface UserBubbleProps {
  id: string;
  fileName?: string;
  text?: string;
  timestamp: number;
}

export function UserBubble({ id, fileName, text, timestamp }: UserBubbleProps) {
  const format = useDateFormatter();
  const { username, fullname } = useAuth();

  const fileExt = fileName?.slice(fileName.lastIndexOf('.') + 1).toLowerCase();

  return (
    <Flex
      direction="column"
      gap="1rem"
      padding="1rem"
      align="flex-end"
      style={{ alignSelf: 'flex-end', maxWidth: '90%' }}
    >
      <Flex gap="0.6rem" align="center">
        <Typography secondary>{format(timestamp, 'fromNow')}</Typography>
        <AuthorAvatar background="var(--sulu)" size="20px" author={{ fullname, username }} />
      </Flex>
      {text && (
        <Typography variant="p-14-r" secondary style={{ whiteSpace: 'pre-line' }}>
          {text}
        </Typography>
      )}
      {fileName && (
        <Flex
          style={{ background: 'var(--cornflower-blue', borderRadius: '8px', height: '52px' }}
          gap="0.5rem"
          align="center"
          padding="8px 12px"
        >
          {id === 'new-file' ? (
            <StyledCloudUploadIcon size="36px" />
          ) : fileExt === 'doc' ? (
            <DocIcon size="36px" />
          ) : fileExt === 'docx' ? (
            <DocxIcon size="36px" />
          ) : fileExt === 'eml' ? (
            <EmlIcon size="36px" />
          ) : fileExt === 'html' ? (
            <HtmlIcon size="36px" />
          ) : fileExt === 'pdf' ? (
            <PdfIcon size="36px" />
          ) : fileExt === 'ppt' ? (
            <PptIcon size="36px" />
          ) : fileExt === 'pptx' ? (
            <PptxIcon size="36px" />
          ) : fileExt === 'txt' ? (
            <TxtIcon size="36px" />
          ) : (
            <></>
          )}
          <Flex column>
            <Typography variant="p-14-b" lineClamp={2} title={fileName}>
              {fileName}
            </Typography>
            <Typography variant="h-12-b" secondary>
              {fileName.slice(fileName.lastIndexOf('.') + 1).toUpperCase()}
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

const StyledCloudUploadIcon = styled(CloudUploadIcon)`
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;
