import { CSSProperties, PropsWithChildren } from 'react';
import { Grid } from '../../Core/Grid';

interface SolutionGridProps {
  style?: CSSProperties;
}

export function SolutionGrid({ style, children }: PropsWithChildren<SolutionGridProps>) {
  return (
    <Grid
      width="100%"
      height="calc(100vh - 57px)"
      templateColumns="63fr 37fr"
      templateRows="90px auto 50px"
      padding="2rem 0 2rem 0"
      style={style}
    >
      {children}
    </Grid>
  );
}
