import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SolutionType } from '../../ChatCpg/types/chatCpgTypes';
import { Flex } from '../../Core/Flex';
import { Typography } from '../../Core/Typography';
import { FilterDefinition } from '../../Filters/AvailableFiltersProvider/AvailableFiltersContext';
import { useAvailableFilters } from '../../Filters/AvailableFiltersProvider/useAvailableFilters';
import { FilterSummaryLayout } from '../../Filters/Components/FilterSummaryLayout';
import { useStagedFilters } from '../../Filters/StagedFiltersProvider/useStagedFilters';
import { useStagedPAFilters } from '../../Filters/StagedPAFiltersProvider/useStagedPAFilters';
import { DoneIcon } from '../../Icons/DoneIcon';
import { useFilterSummary } from '../../hooks/useFilterSummary';

interface SolutionsPanelProps {
  solutionType: SolutionType | null;
  stage: 'select-solution' | 'select-scope';
}

export function SolutionPanel({ solutionType, stage }: SolutionsPanelProps) {
  const isStageOne = stage === 'select-solution';

  const { t } = useTranslation();
  const { stagedFilters } = useStagedFilters();
  const getFilterSummary = useFilterSummary();
  const { universalFilters, datasourceFilters, datasourceDisplayNames } = useAvailableFilters();
  const { myProductsValues } = useStagedPAFilters();

  const productSummary = getFilterSummary({
    filterValue: stagedFilters.cpg_owned_products,
    filterDefinition: {
      ...universalFilters.cpg_owned_products,
      values: myProductsValues,
    } as FilterDefinition,
  });

  const bannerSummary = getFilterSummary({
    filterValue: stagedFilters.cpg_banner,
    filterDefinition: datasourceFilters.numerator?.cpg_banner as FilterDefinition,
  });

  const filterComplete =
    stagedFilters.cpg_owned_products && stagedFilters.cpg_dataset && stagedFilters.cpg_banner;

  return (
    <Flex
      column
      width="100%"
      gap="1.6rem"
      padding="0 2rem 0 2rem"
      overflow="hidden"
      style={{ gridRow: '2', gridColumn: '2' }}
    >
      <Typography variant="p-18-r">
        {isStageOne
          ? t('chatCpg.firstOfTwoSteps')
          : filterComplete
          ? t('util.finished')
          : t('chatCpg.secondOfTwoSteps')}
      </Typography>
      <Flex gap="1rem" align="center" padding="0 0 0 1rem">
        <Typography variant="h-16-b" secondary={!isStageOne}>
          {t('chatCpg.selectASolution')}
        </Typography>
      </Flex>
      {!isStageOne && solutionType && (
        <Flex gap="1rem" padding="0 0 0 2.1rem" width="100%">
          <Typography variant="h-12-b" secondary>
            {t('chatCpg.solutionSelected', {
              solution:
                solutionType === 'optimizePricing'
                  ? t('chatCpg.analysis.optimizePricing')
                  : t('chatCpg.analysis.optimizeSubstitutions'),
            })}
          </Typography>
          <DoneIcon size="10px" color="var(--positive-trend)" style={{ paddingTop: '2px' }} />
        </Flex>
      )}
      <Flex column gap="1rem" padding="0 0 0 1rem" width="100%">
        <Flex gap="1rem" align="center">
          <Typography variant="h-16-b" tertiary={isStageOne}>
            {t('chatCpg.selectScope')}
          </Typography>
          {filterComplete && <DoneIcon color="var(--positive-trend)" />}
        </Flex>
        <Flex column gap="1rem" padding="0 0 0 1rem" width="100%">
          {stagedFilters.cpg_owned_products && (
            <SelectedFilter name={t('filters.cpg_owned_products')} summary={productSummary} />
          )}
          {stagedFilters.cpg_dataset && (
            <SelectedFilter
              name={t('filters.cpg_dataset')}
              summary={stagedFilters.cpg_dataset
                .split(',')
                .map((dataSource) => datasourceDisplayNames[dataSource])
                .join(', ')}
            />
          )}
          {stagedFilters.cpg_banner && (
            <SelectedFilter
              name={t('filters.cpg_banner')}
              summary={bannerSummary || t('util.allBanners')}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

interface SelectedFilterProps {
  name: string;
  summary: string;
}

const SelectedFilter: FC<SelectedFilterProps> = ({ name, summary }) => {
  return (
    <Flex align="flex-end" gap="1rem" style={{ maxWidth: 'calc(100% - 2rem)' }}>
      <FilterSummaryLayout name={name} summary={summary} isValid truncate wrap />
      <DoneIcon color="var(--positive-trend)" size="10px" style={{ paddingBottom: '2px' }} />
    </Flex>
  );
};
