import { PropsWithChildren } from 'react';
import { Flex } from '../Core/Flex';

export function LoginLayout({ children }: PropsWithChildren) {
  return (
    <Flex
      as="form"
      direction="column"
      gap="2rem"
      align="center"
      justify="center"
      height="100vh"
      width="100%"
    >
      {children}
    </Flex>
  );
}
