import styled from '@emotion/styled';

interface StyledTableProps {
  primaryColor?: string;
  secondaryColor?: string;
  borderStyle?: 'solid' | 'dashed';
}

export const Table = styled.table<StyledTableProps>`
  border-collapse: collapse;
  border-spacing: 0;
  z-index: 0;
  width: ${({ width }) => width ?? 'unset'};
  overflow-y: scroll;

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  // display: block;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${({ primaryColor }) => primaryColor ?? 'var(--background)'};

    tr td:first-of-type {
      border-top-left-radius: var(--corner);
      border-bottom-left-radius: var(--corner);
    }
    tr td:last-of-type {
      border-top-right-radius: var(--corner);
      border-bottom-right-radius: var(--corner);
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background: ${({ secondaryColor }) => secondaryColor ?? 'var(--clay)'};
    }
    tr td:first-of-type {
      border-top-left-radius: var(--corner);
      border-bottom-left-radius: var(--corner);
    }
    tr td:last-of-type {
      border-top-right-radius: var(--corner);
      border-bottom-right-radius: var(--corner);
    }
  }

  td,
  th {
    text-align: left;
    padding: 0.7rem 0.5rem;
  }
`;
