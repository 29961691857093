import { shallow } from 'zustand/shallow';
import { useChatBox } from '../Providers/ChatBoxProvider/useChatBox';
import { useTalkingPointsPage } from '../Providers/TalkingPointsPageProvider/useTalkingPointsPage';
import { useChatCpgData } from '../hooks/useChatCpgData';
import { StreamingText } from '../hooks/useChatCpgStore';
import { ChatCpgBubble } from './ChatCpgBubble';

interface FileStreamBubbleProps {
  fileName: string;
}

export function FileStreamBubble({ fileName }: FileStreamBubbleProps) {
  const { workflowId } = useTalkingPointsPage();

  const { fileStreamId } = useChatBox();

  const fileStream = useChatCpgData<StreamingText>([fileStreamId, workflowId], shallow) ?? {
    text: '',
    isStreaming: false,
  };

  return (
    <ChatCpgBubble id="file-stream-bubble" isStreaming fileName={fileName} text={fileStream.text} />
  );
}
