import { ReactNode, useEffect } from 'react';

interface ExpireProps {
  until: number;
  onExpire(): void;
  children: ReactNode;
}

export function Expire({ until, onExpire, children }: ExpireProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onExpire();
    }, until);

    return () => {
      clearTimeout(timer);
    };
  }, [until, onExpire]);

  return <>{children}</>;
}
